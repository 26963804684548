import { render, staticRenderFns } from "./BaseCellDisplayList.vue?vue&type=template&id=6da2cbb1&scoped=true&functional=true&"
var script = {}
import style0 from "./BaseCellDisplayList.vue?vue&type=style&index=0&id=6da2cbb1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "6da2cbb1",
  null
  
)

export default component.exports