
import Vue from 'vue'

import { BootstrapVue, IconsPlugin, VBHover, ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import dynamicLoader from '@/components/DynamicLoader'
import baseTable from '@/components/BaseTable/BaseTable'
import baseColumnFilter from '@/components/BaseTable/BaseColumnFilter'
import baseCellSelector from '@/components/BaseTable/subcomponents/BaseCellSelector'

import baseHelp from '@/components/BaseHelp/BaseHelp'
import baseEditor from '@/components/BaseEditors/BaseEditor'
import baseEditorField from '@/components/BaseEditors/BaseEditorField'
import markupTextField from '@/components/BaseEditors/MarkupText'
import baseDatePicker from '@/components/BaseDatePicker/DatePicker'
import baseColorPicker from '@/components/BaseColorPicker/BaseColorPicker'

import baseViewer from '@/components/BaseViewer/BaseViewer'
import baseExporter from '@/components/BaseExporter/BaseExporter'

import baseModuleConfigurator from '@/components/BaseConfigurator/BaseModuleConfigurator'

import sseConnector from '@/components/SSEConnector'

import collapseBox from '@/components/collapseBox'

import filterStorage from '@/components/BaseFilterManager/FilterStorage'

import baseSelector from '@/components/BaseSelector/BaseSelector'
import baseChecklist from '@/components/BaseChecklist/Checklist'
import baseChecklistCreator from '@/components/BaseChecklist/ChecklistCreator'
import baseChecklistList from '@/components/BaseChecklist/ChecklistList'

import baseDisplay from '@/components/BaseDisplay/BaseDisplay'

import baseNotificationDisplay from '@/components/BaseEvaluation/NotificationDisplay'

import basePostits from '@/components/BaseNotepad/BaseNotepad'

import baseComments from '@/components/BaseComments/itemComments'
import baseSubtask from '@/components/BaseSubtask/Subtask'

import gridViewer from '@/components/GridViewer'

import vSelect from 'vue-select'
import vueMermaid from 'vue-mermaid'

import baseGantt2 from '@/components/BaseGantt2/BaseGantt2'


import ganttItemSelector from '@/components/BaseGantt2/subcomponents/ganttItemSelector.vue'

import revisionMonitor from '@/components/RevisionMonitor/Monitor'
import impressum from '@/components/Impressum/Impressum'



Vue.use(ToastPlugin, { 'BToast': { toaster: 'b-toaster-bottom-right' }})
Vue.use(ModalPlugin, { 'BModal': { 'ignoreEnforceFocusSelector': ['form.ck'] }})
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(vueMermaid)

Vue.component('v-select', vSelect)
Vue.component('collapseBox', collapseBox)

Vue.component('dynamicLoader', dynamicLoader)
Vue.component('gridViewer', gridViewer)

Vue.component('baseTable', baseTable)
Vue.component('baseColumnFilter', baseColumnFilter)
Vue.component('baseCellSelector', baseCellSelector)

Vue.component('sseConnector', sseConnector)

Vue.component('baseHelp', baseHelp)
Vue.component('baseEditor', baseEditor)
Vue.component('baseEditorField', baseEditorField)
Vue.component('baseDatePicker', baseDatePicker)
Vue.component('baseColorPicker', baseColorPicker)
Vue.component('markupText', markupTextField)

Vue.component('baseViewer', baseViewer)
Vue.component('baseDisplay', baseDisplay)
Vue.component('genericDisplay', baseDisplay)

Vue.component('filterStorage', filterStorage)


Vue.component('baseModuleConfigurator',baseModuleConfigurator)

Vue.component('baseSelector', baseSelector)
Vue.component('basePostits', basePostits)

Vue.component('notificationDisplay', baseNotificationDisplay)

Vue.component('itemComments', baseComments)
Vue.component('subtasks', baseSubtask)


Vue.component('baseGantt', baseGantt2)
Vue.component('baseGantt2', baseGantt2)
Vue.component('ganttItemSelector', ganttItemSelector)

Vue.component('revisionMonitor', revisionMonitor)

Vue.component('baseChecklist', baseChecklist)
Vue.component('baseChecklistCreator', baseChecklistCreator)
Vue.component('baseChecklistList', baseChecklistList)
Vue.component('impressum', impressum)

Vue.directive('b-hover', VBHover)

export default {}
