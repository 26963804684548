<template>
  <div>
    <grid-layout class="view-grid"
		:layout="$store.getters.getViewLayout"
		:responsive="false"
		:row-height="rowHeight"
		:colNum="columnCount"						   
		:is-draggable="draggable"
		:is-resizable="resizable"
		:margin="[0,0]"
		:verticalCompact="false">
		<grid-item v-for="item in $store.getters.getViewLayout" class="view-item border-right border-primary" :key="item.i"
                           v-bind="item"
                        >
            <dynamicLoader v-bind="item.props" :module="item.module" :widget="item.widget" :customProperties="item.customProperties || {}" :value="item.value || ''"></dynamicLoader>
		</grid-item>
	</grid-layout>
	
	
	
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
const config = require('@/configuration.json');

export default {
  name: 'GridViewer',
  components: {
	'GridLayout': VueGridLayout.GridLayout,
    'GridItem': VueGridLayout.GridItem
  },
  data() {return {
	draggable: false,
	resizable: false,
	columnCount: config.layout.columns,
	activeLayout: [],
	rowHeight: 1
  }
  },
  mounted() {
	window.addEventListener('resize', function() {
		this.calculateHeight()
	}.bind(this))
	this.calculateHeight()
  },
  watch: {
	  '$store.getters.getViewLayout': function(val) {
		  this.updateLayout(val)
	  }
  },
  methods: {
	  calculateHeight() {
		this.rowHeight = Math.floor(document.getElementById('app-body-content').clientHeight / 6)
	  },
	  updateLayout(newLayout) {
		  let addItems = []
		  newLayout.forEach(item => {
			  let idx = this.activeLayout.findIndex(e => e.i === item.i)
			  if(idx == -1) {
				  //add new element
				  addItems.push(item)
				  return
			  }
			  //otherwise just replace the layout data and dont create a new object
				this.activeLayout[idx] = Object.assign(this.activeLayout[idx],item)
		  });
		  //remove no longer available items
		  this.activeLayout.filter(item => newLayout.findIndex(e => e.i === item.i) != -1)
	  }
  }
}
</script>

<style scoped lang="scss">
@import "../style/customvars";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/bootstrap-vue/src/index.scss';
</style>
