<i18n lang="yaml" locale="en">
    noSelection: "No selection"
    selectbutton: "Select..."
    mouseover.assignToMe: "Assign to logged in user"
    custom.element: "Custom"
</i18n>
<i18n lang="yaml" locale="de">
    noSelection: "Keine Auswahl"
    selectbutton: "Auswählen..."
    mouseover.assignToMe: "Dem eingeloggten Benutzer zuweisen"
    custom.element: "Benutzerdefiniert"
</i18n>

<template>
    <div :style="inline ? 'max-height: 21px;' : ''" >
        <b-input-group class=" flex-row flex-nowrap w-100"       
            :class="{
                     'w-auto': inline,
                     'disabled' : disabled,
                     'bg-white justify-content-end' : !!$scopedSlots['alternate-display'] || (!!alternateDisplay && !isInTable)
                      }"    
            :style="inline ? 'max-height: 21px;' : ''"            
            >
            <b-input-group-prepend>
                <slot name="selector-prepend"></slot>
            </b-input-group-prepend>
            <div class="position-relative">
                
                <div v-if="searchDropdown.length > 0"
                    class=" bg-white
                            overflow-scroll 
                            position-absolute 
                            d-block"
                    style="top:31px; width: 200px;; z-index: 999; max-height: 200px;                            
                            overflow-y: auto;"        
                            >
                        
                    <div 
                        v-for="(item, idx) in searchDropdown" 
                        :key="$id('search-'+idx)"

                        class="p-1"
                        style="cursor: pointer;"
                        @click="curValue = item, clearSearch(); emitUpdate()"
                        >        
                        {{ $render.displayByIri(item) }}                        
                    </div>
                    </div>
            </div>
            <b-input 
                    type="text" 
                    v-if="!disabled && !buttonMode && (!!alternateDisplay && !isInTable) && isSearchable" 
                    
                    class="bg-transparent m-0" 
                    style="outline: none;" 
                    v-model="searchValue" 
                    :placeholder="!curValue || curValue.length == 0 ? $t('noSelection') : ''"
                    @input="showSearchDropdown()"
                    @keypress.enter="curValue = searchDropdown[0], clearSearch(); emitUpdate()"
                    />
            <div 
                v-if="!buttonMode && !$scopedSlots['alternate-display'] && (!alternateDisplay || isInTable)"
                class="border d-flex overflow-hidden flex-grow-1 justify-content-start px-1"                      
                :class="{
                    'rounded rounded-left ' : !$scopedSlots['selector-prepend'],
                    'border-secondary' : optional,
                    'border-success' : !optional && !!selectedItems && (selectedItems !== '' || selectedItems.length > 0), 
                     'border-danger' : !optional && (!selectedItems || (selectedItems === '' || selectedItems.length == 0)),
                     'flex-wrap' : allowWrap,
                     'flex-nowrap' : !allowWrap,
                     'flex-column' : multiline, 
                     'flex-row align-items-center' : !multiline, 
                     
                     }"
                    @click.self="$root.$emit('bv::show::modal',$id(bvModalId))"
                style="text-overflow: hidden">
                <template v-if="!!curValue && curValue.length > 0">
                    <template v-for="(item, key) in curValue" >
                        <b-tag 
                            v-if="item !== ''" 
                            no-remove 
                            :key="'selectedItems_'+key" 
                            variant="light" 
                            class="border border-secondary"
                            :class="{'mt-1 w-100' : multiline}"
                            :style="inline ? 'padding: 2px;' : ''">
                            <slot name="tag" v-bind:iri="item">
                                <dynamic-loader 
                            
                                module="relation" 
                                widget="Display" 
                                neverOverflow
                                v-model="curValue[key]" 
                                compClass="p-0" 
                                class=""/>
                            </slot>
                        </b-tag>
                    </template>
                </template>
                <template v-else  >
                    <slot name="btn-content"><span class="text-nowrap">{{ isSearchable ? '' : $t('noSelection') }}</span></slot>
                </template>
                <b-input 
                    type="text" 
                    v-if="!disabled && !buttonMode  && isSearchable" 
                    plain
                    class="bg-transparent  m-0 p-0 border-0" 
                    style="outline: none;" 
                    v-model="searchValue" 
                    :placeholder="!curValue || curValue.length == 0 ? $t('noSelection') : ''"
                    @input="showSearchDropdown()"
                    @keypress.enter="curValue = searchDropdown[0], clearSearch(); emitUpdate()"
                    />
            </div>
            
            
            <b-input-group-append v-if="!displayOnly">  
                <b-button-group>
                    <b-button 
                        v-b-modal="$id(bvModalId)" 
                        @click="$emit('open',true)"
                        :variant="buttonVariant || 'secondary'"
                        :class="{'rounded': buttonMode ,
                        'p-0' : inline,
                        'p-1' : !inline,
                        'border-0' : buttonMode,
                        'border-secondary' : optional,
                        'border-success' : !optional && !!selectedItems && (selectedItems !== '' || selectedItems.length > 0), 
                        'border-danger' : !optional && (!selectedItems || (selectedItems === '' || selectedItems.length == 0))}"
                        :disabled="disabled">
                            <slot name="btn-label"><b-icon-search /></slot>
                        </b-button>
                    <b-button v-if="assignToMe" 
                        variant="outline-primary"
                        :class="{
                        'p-0' : inline,
                        'p-1' : !inline,
                        'border-secondary' : optional,
                        'border-success' : !optional && !!selectedItems && (selectedItems !== '' || selectedItems.length > 0), 
                        'border-danger' : !optional && (!selectedItems || (selectedItems === '' || selectedItems.length == 0))}"
                        :title="$t('mouseover.assignToMe')"
                        @click="curValue = $store.getters.getCurrentUser; emitUpdate()"
                        :disabled="disabled"><b-icon-person-check-fill /></b-button>   
                </b-button-group>
            </b-input-group-append>            
        </b-input-group>
        <slot name="alternate-display" v-bind:values="value" v-bind:selected="selectedItems">
            <template v-if="alternateDisplay && !isInTable">
                <dynamic-loader v-for="(val) of curValue" :key="$id(val)" :value="val" v-bind="alternateDisplay" />
            </template>
        </slot>

        <b-modal 
            v-if="!displayOnly" 
            size="xl" 
            :id="$id(bvModalId)" 
            :title="selectTitle || 'Select'"
            @ok="emitUpdate" 
            @close="resetValues" 
            @cancel="resetValues" 
            @shown="$emit('focus',true)"
            @hidden="$emit('blur',false)"
            no-footer 
            scrollable 
            class="selector-container">
                <template #modal-header="{ ok , close }">
                    <div class="d-flex align-items-start">
                        <b-button @click="ok" variant="success"><b-icon-check2/></b-button><div class="h3 ml-2">{{ selectTitle || 'Select' }}</div>
                    </div>
                    <div class="align-items-center border border-secondary d-flex flex-grow-1 h-100 justify-content-start ml-3 p-1 rounded titlebar selectDisplay" v-if="multiSelect">
                        <span v-if="displaySelectedItems.length == 0">{{ $t('noSelection') }}</span>
                        <b-tag v-for="(tag,idx) in displaySelectedItems" :key="tag.iri+idx" @remove="curValue = tag.iri">
                            {{ tag.text }}
                        </b-tag>
                    </div>
                    <b-icon-x font-scale="3" @click="close" />
                </template>
                <component :is="(selectables && selectables.length > 1) || ($scopedSlots && $scopedSlots.customTabs) ? 'b-tabs' : 'div'" card>
                <component 
                    v-for="(component, idx) in selectables"
                    :is="(selectables && selectables.length > 1) || ($scopedSlots && $scopedSlots.customTabs) ? 'b-tab' : 'div'"  
                    :key="'componentTables_'+idx" 
                    class="mh-100 h-100" 
                    :lazy="!denyLazy">
                    <template #title>
                        <slot v-if="!!component.titleSlotName" :name="component.titleSlotName"></slot>
                        {{ safeGetTitle(component) }}
                    </template>
                    <div class="d-flex flex-column align-items-center mh-100 h-100">
                        <dynamic-loader v-if="component.allowCreate || allowCreate" :module="component && component.module || component" value="" :widget="component && component.createWidget || 'Creator'" class="d-block" />
                        <div v-if="component.allowCreate || allowCreate" class="separator flex-grow-1 d-block">OR</div>
                        <div v-if="allowCustom">
                            <b-form-group
                                label-cols="3"
                                :label="$t('custom.element')"
                            >
                            <b-input class="d-block" v-if="component.allowCustom ||allowCustom" :value="customEntry" @input="updateValue($event)"/>
                            </b-form-group>
                        </div>
                        <div v-if="allowCustom" class="separator flex-grow-1 d-block"></div>
                        <dynamic-loader  
                            :module="component.module || component" 
                            :customProperties="{contextSensitive: component.context, owners: cleanOwners,serverSideRender: true,...(component.loader || {})  }" 
                            value="" 
                            :widget="component.widget || 'Table'" 
                            overflow 
                            class="w-100" />                    
                    </div>
                </component>
                <slot name="customTabs" 
                    v-bind:sendToSelector="storeFromSelector" 
                    v-bind:customValues="customValues" 
                    v-bind:iriValues="selectedItems" 
                    v-bind:removeByIndex="removeByIndex"
                    v-bind:updateByIndex="updateByIndex"
                    
                    ></slot>
                </component>
            </b-modal>
    </div>
</template>
<style scoped>
    .selectDisplay {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .separator {
        position: relative;
        width: 100%;
        text-align: center;
    }
    .separator::before {
        display: block;
        position: absolute;
        background-color: lightgray;
        width: 47%;
        height: 1px;
        content: "";
        left: 0;
        top: 50%;
    }
    .separator::after {
        display: block;
        position: absolute;
        background-color: lightgray;
        width: 47%;
        height: 1px;
        content: "";
        right: 0;
        top: 50%;
    }
</style>
<script>
import DynamicLoader from '../DynamicLoader.vue'
export default {
  components: { DynamicLoader },
    name: "baseSelector",
    props: {
        selectables: { type: Array, default: () => [{module: 'user', context: false, title: "User"},{module: 'task', context: true, title: "Aufgabe"}] },
        multiSelect: { type:Boolean, default: true },
        multipleCustomValues: { type:Boolean, default: true },
        customValueRenderer: {type: Function, default: null},
        allowCreate: { type: Boolean, default: false },
        allowCustom: { type: Boolean, default: false },
        buttonMode: { type: Boolean, default: false },
        assignToMe: { type: Boolean, default: false },
        displayOnly: { type: Boolean, default: false },
        allowWrap: { type: Boolean, default: false },
        multiline: { type: Boolean, default: false },
        denyLazy: { type: Boolean, default: false },
        disabled : Boolean,
        optional: {type: Boolean, default: false},
        returnObject: { type: Boolean, default: false },
        value: null,
        alternateDisplay: Object,
        buttonVariant: String,
        selectTitle: { type: String, default: "Select"},
        owners: {type: null, default: () => []},
        inline: {type: Boolean, required: false, default: false},
        isInTable: {type: Boolean, required: false, default: false}
    },
    data() {
        return {
            selectedItems: [],
            bvModalId: "selectorModal",
            customEntry: "",
            searchDropdown: [],
            searchValue: ""
        }
    },
    watch: {
        "value": {
            immediate: true,
            handler: function(val) {
                if(this.value !== this.selectedItems) {
                    this.selectedItems = []
                    this.curValue = this.value
                }
            }
        }
    },
    computed: {
        cleanOwners: function(vm) {
            if(vm.owners.length != 0) {
                return vm.owners
            }
            let ownerList= vm.getOwnerIri()
            if(Array.isArray(ownerList) && ownerList.length != 0) {
                return ownerList
            }
            if(typeof ownerList == "string") {
                return [ownerList]
            }
            return []
        },
        displaySelectedItems: function(vm) {
            let itemArray = Array.isArray(vm.selectedItems) ? vm.selectedItems : [vm.selectedItems]
            return itemArray.map(iri => {
                if(iri[0] == "/") {
                 return {iri: iri, text: vm.$render.displayByIri(iri) }
                } else if(typeof vm.customValueRenderer == "function"){
                    return {iri: iri, text: vm.customValueRenderer(iri) }
                } 
                //fallback
                return {iri: iri, text: iri }
            })
        },
        curValue: {
            get: function() { 
                return (typeof this.selectedItems === "string") ? [this.selectedItems] : this.selectedItems 
                },
            set: function(val) {
                let newValue = val && typeof val == "object" && val.hasOwnProperty("value") ? val.value : val
                let mode = (val && (typeof val.mode === "boolean")) ? val.mode : null
                if(val && val.mode && val.mode === "replace") {
                    mode = "replace"
                }
                let newSet = this.selectedItems;

                let idx = val && val.hasOwnProperty('idx') ? val.idx : (this.selectedItems && this.selectedItems.indexOf(newValue))

                switch(true) 
                {
                    case !this.multiSelect && (mode === null || mode === true):
                        newSet = newValue;
                        break;
                    case !this.multiSelect && (mode === false && newSet === newValue):
                        newSet = null;
                        break;
                    case !this.multiSelect && (mode === "replace"):
                        newSet = newValue;
                        break;
                    case this.multiSelect && (idx === -1 && (mode === true || mode === null)):
                            if(newSet.length === 0) {
                                if(Array.isArray(newValue)) {
                                    newSet = newValue
                                } else {
                                    newSet = [ newValue ]
                                }
                            } else { 
                                newSet.push(newValue) 
                            }
                        break;
                    case this.multiSelect && (idx !== -1 && (mode === false || mode === null)):
                        newSet.splice(idx,1)
                        break;
                    
                    case this.multiSelect && (idx !== -1 && (mode === true)):
                        //force an already existing item to be added again
                        newSet.push(newValue)
                        break;

                    case this.multiSelect && (idx !== -1 && (mode === "replace")):
                        newSet.splice(idx,1,newValue)
                        break;
                }
                if(Array.isArray(newSet)) {
                    this.selectedItems.splice(0,this.selectedItems.length,...newSet.filter(e => e === false || !!e ))
                } else {
                    this.selectedItems = newSet
                }
                
                return newSet
            }
        },
        customValues: function(){
                let iris = this.$store.getters.getIriMap
                let customElements = this.curValue && this.curValue.map((c,i) => ({value: c, "@idx": i})).filter((item) => iris.findIndex((iriTest) => iriTest.regex.test(item.value)) === -1)
                if(this.multipleCustomValues) {
                    return customElements || []
                } else {
                    return customElements[0] && customElements[0].value || ""
                }
            },
            isSearchable: function(vm) {
                return vm.selectables.some(({module, context}) => !!vm.$store.getters[module+'/searchModule'])
            }
    },
    inject: {
      getOwnerIri : { from: "getOwnerIri" , default: () => () => [] }
    },
    provide: function() { return ({
            sendToSelector: this.storeFromSelector,
            isSelected: this.isSelected,
            getSelected: () => this.curValue,
            isSelector: true,
            getDefaultSortHandler: (a,b) => (b.id && b.id.value || 0) - (a.id && a.id.value || 0)
    })},
    methods: {
        safeGetTitle(component) {
            if(component && typeof component == "object") {
                return component.title && component.title[this.$root.$i18n.locale] || component.title
            } else {
                return component
            }
        },
        emitUpdate(event) {
            this.$emit('input', this.selectedItems)
            this.$emit('finalSelection',this.selectedItems)
        },
        updateValue(event) {
            this.customEntry = event
            this.curValue = {"value": this.customEntry, "mode": null}
        },
        resetValues($event) {
            this.curValue = {"value": this.value || "", "mode": "replace"}
        },
        removeByIndex: function(idx) {
            this.selectedItems.splice(idx,1)
        },
        updateByIndex: function(idx,value) {
            this.curValue = {"value": value || "", "mode": "replace", "idx" : idx}
        },
        storeFromSelector: function(module, iri, mode = null) {
            this.curValue = {"value": iri, "mode": mode}
            return this.curValue 
        },
        isSelected: function(iri) {
            let arrayItem = (typeof this.selectedItems === "string") ? [this.selectedItems] : (this.selectedItems || [])
            let idx = arrayItem.findIndex(item => item === iri)
            return idx > -1 ? idx : false
        },
        showSearchDropdown: function() {
            // this needs to search through all selectables and try to find the items that fit the input type content
            if(this.searchValue.length == 0) {
                this.searchDropdown = []
                return
            }

            let results = this.selectables.map(({module, context}) => {
                //this.owners can be null, empty array or string

                //convert this.owners to an array
                let curOwners = Array.isArray(this.cleanOwners) ? this.cleanOwners : (!!this.cleanOwners ? [this.cleanOwners] : null);
                if(!this.cleanOwners || this.cleanOwners.length == 0) {
                    curOwners = this.getOwnerIri();
                    //ensure getOwnerIri is an array
                    curOwners = Array.isArray(curOwners) ? curOwners : [curOwners]
                }
                if(this.$store.getters[module+'/searchModule']) {
                    return this.$store.getters[module+'/searchModule'](this.searchValue, context ? curOwners : null, this.selectables ?? [])
                } 
                return []
            })

            this.searchDropdown = [].concat.apply([],results).map(r => r.iri)
        },
        clearSearch: function() {
            this.searchValue = ""
            this.searchDropdown = []
        }
	},
}
</script>
