
import html2canvas from 'html2canvas';
import Vue from 'vue'


var Screenshotter = function() {
    this.vueInstance = null
}


Screenshotter.prototype.addImageToClipboard = function(blob) {
    try {
     navigator.clipboard.write([
      new ClipboardItem({
          'image/png': blob
      })
    ]);
    window.globalVue.$root.$bvToast.toast(window.globalVue.$root.$t('screenshot.success.message'), {title: window.globalVue.$root.$t('screenshot.success.title'), position: "bottom", variant:"success", solid: true, toaster: 'b-toaster-bottom-full' })
    } catch {

    }
  }

Screenshotter.prototype.render = async function(ref) {      
    try {

      let canvas = await html2canvas(ref,{
          scrollX : 0,
          scrollY : 0,
          allowTaint: true,
          useCORS: true
      })
      canvas.toBlob(this.addImageToClipboard)
    } catch {
        window.globalVue.$root.$bvToast.toast(window.globalVue.$root.$t('screenshot.failed.message'))
        return false
    }
    
    return true
  }

Screenshotter.prototype.install = function(Vue, options) {
    
    Vue.prototype.$screenshot = this

}

const screenshotter = new Screenshotter()
export { screenshotter }