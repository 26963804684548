var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('collapse-box',{staticClass:"my-1",attrs:{"id":_vm.$id('subtask'),"bodyClass":"p-0","title":_vm.$t('title.subtasks')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('b-button',{attrs:{"variant":_vm.toggleCompleted ? 'warning' : 'outline-warning',"size":"sm"},on:{"click":function($event){_vm.toggleCompleted = !_vm.toggleCompleted}}},[_c('b-icon-archive',{attrs:{"variant":"dark"}})],1)]},proxy:true}])},[_c('dynamic-loader',{attrs:{"widget":"Table","module":"task","customProperties":{ 
                            filter: {iri : _vm.subTasks }, 
                            noSubtasks: true, 
                            noHeader: true,
                            noTitle: true,
                            openAsModal: true, 
                            hideArrow: true, 
                            templateOverrides: {},
                            showCreateRow: true,
                            relateTo : _vm.relateTo,
                            fixed: true,
                            forceCompleted: _vm.toggleCompleted
                            }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }