//import 'babel-polyfill';

import Vue from 'vue'
import App from './App.vue'
import { store } from './store'
import './registerServiceWorker'
import router from './router'
import { persister } from '@/helper/PersistenceManager'
import { libraryManager } from '@/helper/LibraryManager'
import { globalUploader } from '@/helper/UploadManager/UploadManager'
import { Registry } from '@/helper/Registry'
import { Render } from '@/helper/Rendering'
import { Currency } from '@/helper/Currency'
import { Timing } from '@/helper/Timing'
import { HkDelegator } from '@/helper/HotkeyDelegator'

import '@/components/components'

import i18n from './i18n'
import uniqid from '@/plugins/uniqid'
import PortalVue from 'portal-vue'
import { screenshotter } from './helper/Exporter/Screenshotter'


Vue.use(PortalVue)
Vue.use(persister)
Vue.use(uniqid)
Vue.use(libraryManager)
Vue.use(Registry)
Vue.use(globalUploader)
Vue.use(screenshotter)
Vue.use(HkDelegator)
Vue.use(Render)
Vue.use(Currency)
Vue.use(Timing)


const lf = require('localforage')
let errorStore = lf.createInstance({
  name: "errorStore"
});

const addErrorToErrorLog = async function(err) {
  let keys = await errorStore.keys()
  
  if(keys.length > 3) {
    await errorStore.removeItem(keys[0]);
  }
  errorStore.setItem(+new Date(),err)
}

let __globalErrorState = null

Vue.config.errorHandler = function(err,vm,info) {
  console.error('Exception: '+err.message, err)
  window.baitLog.push(err)
  //persist error if possible
  addErrorToErrorLog(err).then(async() => {
    const h = window.globalVue.$createElement

    let nodes = [
      h('div',{},'An unexpected error occured. The page needs to reload. Should the error be sent as a ticket?'),      
      h('textarea', {
        attrs: {
          placeholder: 'how did the error occur?',
          class: 'errorMessageTextBox_MAIN w-100 border-secondary p-1'
        }
      })
    ]
    
    if(!__globalErrorState) {
      __globalErrorState = true
      
      let res = await window.globalVue.$bvModal.msgBoxConfirm(
        nodes, 
        {
          'title': 'Unexpected error',
          'cancelTitle' : 'Just reload',
          'cancelVariant' : 'danger',
          'okTitle' : "Send a ticket and reload",
          'okVariant' : 'warning',
          'noStacking' : true
        })
      try {
        if(res) {
          if(window.globalVue.$libraries.isAvailable('feedback')) {

            let textareas = document.querySelectorAll('.errorMessageTextBox_MAIN')
            let textAreaContent = textareas[textareas.length-1].value.replace(/\n/g,"<br/>")

            await window.globalVue.$cache.post('feedback','/reports', {
              timestamp: (new Date()).toISOString(),
              sender: window.globalVue.$store.getters.getCurrentUser,
              consoleOutput: JSON.stringify({message: err.message, stack: err.stack ?? {}}),
              route: window.globalVue.$route.path,
              ownerContext: JSON.stringify(window.globalVue.$store.getters.getOwnerContext),
              registeredModule: JSON.stringify(await window.globalVue.$store.getters.getRegistry),
              errorType: 5,
              userInput: textAreaContent ?? "Submitted as critical fail",
              status: "new"
            })
          }
        }
      } catch {
        //silently fail
      } finally {
        window.globalVue.$bvModal.msgBoxOk('Wait for reload...')
      }

    window.location.reload()
    }
  })
}

Vue.config.productionTip = false
Vue.config.performance = true

var globalVue = new Vue({
  router,
  store,
  i18n,
  persister,
  render: h => h(App)
}).$mount('#app')

window.globalVue = globalVue

Currency.loadCurrencyConfig()
window.baitLog = []
window.onerror = function(err) {
  window.baitLog.push(err)
}



setInterval(() => globalVue.$cache.apihandler.checkAuthenticationStatus(),3*60*1000)