<template functional>
<td class="base-table--cell base-cell-edit-select" 
 :class="props.value && props.value.cellClasses || ''"
        :title="props.value && (props.value.mouseover && props.value.mouseover[parent.$i18n.locale] || props.value.value) || ''"
        :style="(props.config['@stickyStyle'] || '')"
        >
       <div class="cell-nowrap" :style="'width: '+(32+props.config['@width'])+'px;'">
      <v-select 
        v-if="(props.config.selectOptions || props.config.customProperties.selectOptions).multiple || false"
        :options="(props.config.selectOptions || props.config.customProperties.selectOptions).entries.map(sO => Object.assign({}, sO, {text: sO.text[parent.$root.$i18n.locale] || sO.text }))"
        :value="(props.value && (props.value.hasOwnProperty('value') ? props.value.value : props.value) || []).filter(v => !!v)"
        :reduce="e => e.value"
        @input="listeners.input($event)"
        label="text"
        class=" bg-white multiselect"
        :placeholder="props.config.placeholder && props.config.placeholder[parent.$root.$i18n.locale] || props.config.placeholder || ''"
        :disabled="props.config.immutable"
        :multiple="true">
      </v-select>
      <b-form-select   
          v-else             
          :value="props.value && (props.value.hasOwnProperty('value') ? props.value.value : props.value)"
          :size="size"
          @input="listeners.input($event)"
          :options="(props.config.selectOptions || props.config.customProperties.selectOptions).entries.map(sO => Object.assign({}, sO, {text: sO.text[parent.$root.$i18n.locale] }))"
        :placeholder="props.config.placeholder && props.config.placeholder[parent.$root.$i18n.locale] || props.config.placeholder || ''"
          :disabled="props.config.immutable"
      ></b-form-select>
       </div>
       </td>
</template>
<script>
export default {
  functional: true,
  name: "BaseCellDisplaySelect"
}
</script>
<style lang="scss" >
.base-cell-edit-select .cell-nowrap {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base-cell-edit-select * .vs__selected-options {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
}
</style>
