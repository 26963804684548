import { render, staticRenderFns } from "./BaseCellGroupComponent.vue?vue&type=template&id=5b7c9da2&scoped=true&functional=true&"
var script = {}
import style0 from "./BaseCellGroupComponent.vue?vue&type=style&index=0&id=5b7c9da2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5b7c9da2",
  null
  
)

export default component.exports