<script>

 import sc from './subcomponents'

export default {
    name: "ganttItemSelector",
    functional: true,
    props: {
        type: {
            type: String,
            required: true,
            default: 'element'
        },
        config: {
            type: Object,
            required: false
        }
    },
    render: function (createElement, context) {
        function selectComponent () {
            let type = context?.props?.type?.toLowerCase() ?? 'element'
            let compName = null

            switch(type) {
                case 'element': compName  = 'ganttItemElement'; break;
                case 'group': compName  = 'ganttGroupElement'; break;
                case 'tempgroup': compName  = 'ganttGroupElement'; break;
                case 'externalgroup': compName  = 'ganttGroupElement'; break;
                case 'external': compName  = 'ganttItemElement'; break;
                case 'milestones': compName  = 'ganttGroupElement'; break;
                case 'phases': compName  = 'ganttGroupElement'; break;
            }
            return sc.components[compName] ?? sc.components['ganttItemElement']
        }

        return createElement(
        selectComponent(),
        {...context.data}, 
        context.children
        )
    }
}
</script>