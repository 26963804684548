<template>
  <div>       
    <b-button
      block 
      variant="light" 
      @click="$emit('setFilterStatus',!filterStatus)" 
      class="d-flex flex-nowrap mb-1 justify-content-start align-items-center"
      >
         <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('filters.filter.filter') }}
      </div>
    </b-button>
    <b-form-checkbox
      size="sm"
      class="ml-1"
      :checked="displayItems.length === options.length"
      :indeterminate="activeItems.length > 0 && (activeItems.length !== options.length)"
      @change="updateList($event)"
      >{{ $t(activeItems.length === options.length ? 'deselectAll' : 'selectAll')}}</b-form-checkbox>
    <b-form-checkbox-group
      class="pl-1 ml-2"
      size="sm"
      stacked
      :checked="displayItems"
      @change="updateFilter($event)"
      :options="options"
    />
  </div>
</template>

<script>


export default {
  name: "baseCellFilterApproval",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: null
    },
    filterTag: {
      type: String
    },
  },  
  watch: {
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(typeof newVal == "string" && newVal !== oldVal && (!this.filterValue || !this.filterValue.filterHandler)) {
          
          if(newVal.substr(0,7) != 'select-') return
          let list = newVal.substr(7).split('@@')

          this.updateFilter(list.includes("") ? [] : list)
        }
      }
    }
  },
  data() {
    return {
      activeItems: []
    }

  },
  computed: {
    displayItems: function(vm) {
      if(!vm.filterStatus) return vm.options.map(i => i.value)
      return vm.activeItems
    },
    options: function(vm) { return [
          { text: vm.$t('approved'), value: 'approved' },
          { text: vm.$t('declined'), value: 'declined' },
          { text: vm.$t('pending'), value: 'pending' }
        ]
      },
    localFilterTag: function(vm) {
      
      return 'select-'+ vm.activeItems.join('@@')
    },
    filterConfiguration: function(vm) {
      return {'filterHandler' : vm.filterHandler, 
              'filterTag' : vm.localFilterTag, 
              'filterValue' : vm.activeItems}
    }

  },
  methods: {


    updateFilter: function($event) {
      this.activeItems = $event
      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue', this.filterConfiguration)
      
    },
    
    updateList(ev) {
      this.activeItems = ev ? this.options.map(i => i.value).slice() : []
      this.$emit('setFilterValue', this.filterConfiguration)
    },

    /**
     * The filterHandler is a function that returns a boolean
     * it gets handed the arguments:
     * 
     * cellData     any   the cell data as specified by the type
     * filterValue  any   the filterValue as stored in the filter value
     * columnData   any   the full column configuration
     */
     filterHandler: (cellData, filterData) => {
            let safeValue = cellData && typeof cellData == "object" && cellData.hasOwnProperty('value') ? cellData.value : cellData
            let parsedData = JSON.parse(safeValue ?? null)
            if((parsedData == null ) && filterData.indexOf("pending") > -1){
                return true
              }
            if( !!parsedData && typeof parsedData == "object" && parsedData.hasOwnProperty('approvalState')) {
             
              return filterData.indexOf(parsedData.approvalState) > -1
            }
        }

  }
}
</script>