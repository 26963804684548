<i18n locale="en" lang="yaml" >
    help.ok: "Understood"
</i18n>
<i18n locale="de" lang="yaml" >
    help.ok: "Verstanden"
</i18n>
<template>
    <div>
        <h2>Impressum</h2>
<p>VERANTWORTLICH FÜR DEN INHALT:</p>

<p>
    BOS Technology Services GmbH<br/>
Ernst-Heinkel-Str. 2<br/>
D-73760 Ostfildern<br/>
Tel.: +49 711 9360-0<br/>
Fax: +49 711 9360-1150<br/>
info@bos-ts.de<br/>
<br/>
Amtsgericht Stuttgart HRB 760047<br/>
Umsatzsteuer-Ident.-Nr.: DE 31 11 12 840<br/>

Geschäftsführer:<br/>
Marcel Lehmann, Axel Ziethe

</p>

<h2>Datenschutzerklärung</h2>
<p>Wir freuen uns über Ihr Interesse an unserer Website. 
    Der Schutz Ihrer persönlichen Daten ist uns sehr wichtig. 
    In dieser Datenschutzerklärung informieren wir Sie deshalb, welche Daten wir auf unseren Webseiten erheben. 
    Unsere Datenschutzbestimmungen stehen im Einklang mit den anwendbaren Rechtsvorschriften zum Schutz personenbezogener Daten, 
    insbesondere dem Bundesdatenschutzgesetz (BDSG) und dem Telemediengesetz (TMG).
</p>

<h5>Gegenstand des Datenschutzes</h5>

<p>Gegenstand des Datenschutzes sind personenbezogene Daten. 
    Diese sind nach § 3 Abs. 1 BDSG Einzelangaben über persönliche oder sachliche
     Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. 
     Hierunter fallen z. B. Angaben wie Name, Post-Adresse, 
     E-Mail-Adresse oder Telefonnummer, ggf. aber auch Nutzungsdaten wie Ihre IP-Adresse. 
    </p>

<h5>Datenerfassung und Datenspeicherung</h5>

<p>Sie können unsere Seiten besuchen, ohne Angaben zu Ihrer Person zu machen. 
    Wir speichern lediglich die Zugriffsdaten ohne Personenbezug. 
    Diese sogenannten Nutzungsdaten erlauben keine Rückschlüsse auf Ihre Person und werden ausschließlich 
    zur Verbesserung unseres Angebots ausgewertet. Um Ihnen die passende Sprachversion unserer Website anzubieten, 
    nutzen wir Ihre anonymisierte IP-Adresse, um festzustellen, aus welchem Land Sie unsere Website besuchen. 
    Die anonymisierte IP-Adresse wird nicht gespeichert. Personenbezogene Daten, die Sie uns freiwillig mitteilen 
    (Kontaktformular, Bewerbung), werden nur für den Zweck verarbeitet, zu dem Sie uns diese zur Verfügung gestellt haben. 
    Wir speichern Ihre Daten nur so lange, wie es nötig ist, um Ihre Anfrage vollumfänglich zu bearbeiten. 
    Wir geben Ihre Daten nicht an Dritte weiter und nutzen diese auch nicht zu Werbe- oder Marktforschungszwecken.
</p>

<h5>Datensicherheit</h5>

<p>Wir treffen alle aus technischer und organisatorischer Sicht sinnvollen Vorkehrungen, 
    um Personendaten bestmöglich vor Verlust, Zerstörung, Manipulation oder unberechtigtem Zugriff zu schützen.
</p>

<h5>Auskunft- und Widerrufsrecht</h5>

<p>Sie können jederzeit erfahren, welche personenbezogenen Daten von Ihnen gespeichert wurden. 
    Außerdem haben Sie das Recht auf die Berichtigung fehlerhafter Daten, Sperrung und Löschung 
    Ihrer personenbezogenen Daten, sofern wir diese nicht aufgrund gesetzlicher Bestimmungen aufbewahren müssen. 
    Bei Fragen zum Datenschutz wenden Sie sich bitte an:
</p>

<p>BOS Technology Services GmbH<br/>
Axel Ziethe - Datenschutzbeauftragter<br/>
Ernst-Heinkel-Str. 2 73760 Ostfildern <br/>
aziethe@bos.de
</p>

<h5>Cookies</h5>

<p>
    Diese Website nutzt ausschließlich technisch notwendige Cookies. Für Benutzer werden ausschließlich Informationen 
    zur gewählten Spracheinstellung (deutsch oder englisch) gespeichert.
</p>

<h5>Änderungen an der Datenschutzerklärung</h5>

<p>Wir behalten uns vor, unsere Datenschutzerklärung ohne Vorankündigung jederzeit zu ändern. 
    Daher empfehlen wir Ihnen, sich regelmäßig über etwaige Änderungen zu informieren. 
</p>

<h5>Marken- und Urheberrechte</h5>

<p>Die auf diesen Seiten dargestellten Marken und Logos stehen im Eigentum von BOS. 
    Die Darstellung auf diesen Seiten stellt keine Lizenzgewährung zur Verwendung der Marken und Logos dar. 
    Eine Verwendung ohne ausdrückliche schriftliche Einwilligung von BOS ist untersagt. Die Vervielfältigung, Bearbeitung, 
    Verbreitung und jede Art der Verwertung der von BOS erstellten Inhalte auf diesen Seiten bedürfen der schriftlichen 
    Zustimmung des jeweiligen Autors. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
    Nicht von BOS erstellte Inhalte Dritter sind als solche gekennzeichnet. Bei Bekanntwerden von Rechtsverletzungen werden derartige Inhalte umgehend entfernt. 
</p>

<h5>Inhalte</h5>

<p>Die Inhalte unserer Seiten werden mit größter Sorgfalt erstellt. 
    Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernimmt BOS jedoch keine Haftung. 
    Die Inhalte dienen lediglich der Information und stellen keine rechtlich bindenden Angebote dar.
</p>

<h5>Links</h5>

<p>
    Unser Internetangebot kann Links zu externen Webseiten Dritter enthalten, auf deren Inhalte wir keinen Einfluss haben. 
    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets 
    der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche 
    Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle 
    der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
     werden wir derartige Links umgehend entfernen.
</p>
    </div>
</template>

<script>
export default {
    name: "baseHelp",
    props: {
        title: [Object,String],
        content: [Object, String]
    }
}
</script>

<style>

</style>