<i18n locale="en" lang="yaml">
    batch.processing: "Batch processing"
    status.processing: "Processing"
    status.notActive: "deactivated or lock acquisition failed"
    status.processed: "Successfully updated"
    btn.title: "Process all active items"
    batchedItems: "Selected items ({count})"
    btn.cancel: "Close"
</i18n>

<i18n locale="de" lang="yaml">
    batch.processing: "Stapelverarbeitung"
    status.processing: "Verarbeite..."
    status.notActive: "Deaktiviert oder auschecken fehlgeschlagen"
    status.processed: "Erfolgreich aktualisiert"
    btn.title: "Alle aktiven Elemente abarbeiten"
    batchedItems: "Ausgewählte Elemente"
    btn.cancel: "Close"
</i18n>
<template>
  <div>
      <b-button variant="transparent" v-b-modal="$id('batchProcessor')" >
          <b-icon-list-check variant="primary" />
      </b-button>
      <b-modal :id="$id('batchProcessor')" :title="$t('batch.processing')" size="xl" @shown="batchCheckout()" @hidden="batchCheckIn()">
        <template v-slot:modal-title>
            <div class="">
                        <h4 class="ml-1">{{$t('batch.processing')}}</h4>
                        <b-button variant="success" class="d-flex align-items-center" type="button" @click="startBatch()" :title="$t('btn.title')">
                        <b-icon-list-check class="mr-1"/><span>{{ $t('btn.title') }}</span>
                        </b-button>
            </div>
        </template>
            <b-row>
                <b-col cols="8">
                    
                    <base-editor-field
                        v-for="(field, idx) in batchFields"
                        :key="$id(`baseEditor_${idx}_${field.name}`)"
                        :id="field.name"
                        :value="dataValues[field.name]"
                        @input="addToUpdate(field.name, $event, field)"
                        :editMode="true"
                        :creationMode="false"
                        :disabled="false"
                        :field="{...field,'optional' : true, 'immutable' : false , 'canEdit' : true}"                    
                        :immutable="false"
                        class="mb-0 mx-2"
                        :noLabel="false"
                    ></base-editor-field>
                </b-col>
                <b-col cols="4">
                    <collapse-box :id="$id('items')" :title="$t('batchedItems', {'count': activeItemCount})" body-class="p-0">
                        <b-list-group>
                            <b-list-group-item
                                v-for="(item,idx) in selectedItems"
                                :key="$id(item.iri)"
                                class="d-flex justify-content-start align-items-center p-1">
                                <b-spinner small v-if="item.processing" :title="$t('status.processing')" style="min-width: 15px;"/>
                                <b-icon-exclamation-circle-fill v-else-if="!item.checkedOut" variant="warning" :title="$t('status.notActive')"  style="min-width: 15px;"/>
                                <b-icon-check-circle-fill v-else-if="item.updated" variant="success" :title="$t('status.processed')"  style="min-width: 15px;"/>
                                <b-icon-blank v-else  style="min-width: 15px;"/>
                                <span class="ml-3 text-truncate" :title="$render.displayByIri(item.iri)">{{ $render.displayByIri(item.iri) }}</span>
                                <b-form-checkbox class="ml-auto" :checked="item.active" @change="toggleLock(item)" switch />
                            </b-list-group-item>
                        </b-list-group>
                    </collapse-box>
                </b-col>
            </b-row>
            <template #modal-footer="{ cancel }">
                <b-button variant="danger" class="ml-auto d-flex align-items-center" type="button" @click="cancel" :title="$t('btn.cancel')">
                    <b-icon-x class="mr-1"/><span>{{ $t('btn.cancel') }}</span>
                </b-button>

                
            </template>
      </b-modal>
  </div>
</template>

<script>
export default {
    name: "batchProcessor",
    props: {
        /**
         * array of iris
         */
        value: {
            type: [Array],
            required: false,
            default: () => []
        },
        /**
         * the field config object for tables
         */
        fields: {
            type: [Array],
            required: false
        },
        batchPreProcessor:{
            type:Function,
            required:false,
            default: (a,b) => b
        }
    },
    watch: {
        'value': function() {
            this.selectedItems = this.value.map(v => {
                let obj = {}

                this.$set(obj,'iri', v)
                this.$set(obj,'active', false)
                this.$set(obj,'checkedOut', false)
                this.$set(obj,'processing', true)
                this.$set(obj,'updated', false)

                return obj
            })
        }
    },
    computed: {
        activeItemCount: function(vm) {
            return vm.selectedItems.filter(i => i.checkedOut).length
        },
        batchFields: function(vm) {
            return vm.fields.filter(field => field.batchable)
        }
    },
    methods: {
        toggleLock: async function(i) {
            i.processing = true
            let mod = this.$store.getters.getModuleFromIri(i.iri).module
            if(i.checkedOut) {
                try {
                    this.$cache.post(mod, '/unlock',{ resource: i.iri }, true)
                } catch {

                } finally {
                    i.checkedOut = false
                    i.active = false
                    i.processing = false
                }
            } else {
            
                try {
                    
                    let lock = await this.$cache.post(mod, '/lock',{ resource: i.iri }, true)
                    if(lock.status == 200) {
                        i.checkedOut = true      
                        i.active = true    
                        i.updated = false              
                    }
                } catch {
                    i.checkedOut = false
                    i.active = false 
                } finally {
                    i.processing = false
                }
            }
        },
        addToUpdate(name, data, field) {
            if(field.preSave) {
                this.$set(this.dataValues, name, field.preSave(data))
            } else {
                this.$set(this.dataValues, name, data)
            }
        },
        batchCheckout: async function() {
            this.selectedItems.map(this.toggleLock)
        },
        batchCheckIn: async function() {
            this.selectedItems.filter(i => !!i.checkedOut).map(this.toggleLock)
        },
        startBatch: function() {
            this.selectedItems.filter(i => i.active && i.checkedOut).map(this.processSingle)
        },
        processSingle: async function(i) {
            i.processing = true
            try {
                if(this.batchPreProcessor) {
                    this.dataValues = await this.batchPreProcessor(i.iri, this.dataValues)
                }

                let mod = this.$store.getters.getModuleFromIri(i.iri).module
                
                await this.$cache.update(mod,i.iri,this.dataValues)
                i.updated = true
            } catch {
                i.updated = false
            } finally {
                i.processing = false
            }

        },
       
    },
    data() {
        return {
            dataValues: {},
            selectedItems: []

        }
    }
}
</script>

<style>

</style>