<i18n locale="en" lang="yaml">
    title.autoscale: "Scale view either to selected timeframe or autofit"
    title.pin: "Always show element"
    timetable.title: "Timetable"
    selectedItems: "Selected elements"
    scrollToToday: "Scroll to today"
    reloadGantt: "Reload"
    requestFullscreen: "Switch to fullscreen"
    scaleToView: "Fit all in screen"
    add.external: "Add other elements"
    group.expand: "Show subgroups of all direct subelements"
    group.collapse: "Collapse all subgroups"
    milestone.expand: "Unfold all milestone rows"
    milestone.collapse: "Collapse all milestone rows"


    reload.confirm.message: "Reload removes changes. Continue?"
    reload.confirm.title: "Please Confirm"
    reload.confirm.yes: "YES"
    reload.confirm.no: "NO"

    filter.warning: "For formatting in timetables to work, the timetable has to be checked out before creating the filter and then saved, so the settings can be saved correctly"

    responsible: "Responsible"
    StatusToggle: "Status (Button)"
    StatusPercent: "Status (percentage)"
    Labels: "Show labels"
    statusIndicator: "Status indicator"
    statusIndicatorOnToday: "Status indicator today"
    hideNotRelevant: "Hide n.r. points"
    crosshair: "Crosshair"
</i18n>

<i18n locale="de" lang="yaml">
    title.autoscale: "Skaliere in den angezeigten Bereich oder automatisch"
    title.pin: "Element immer anzeigen"
    timetable.title: "Terminplan"
    selectedItems: "Ausgewählte Elemente"
    scrollToToday: "Auf heute zentrieren"
    reloadGantt: "neu laden"
    requestFullscreen: "In Vollbild wechseln"
    scaleToView: "Auf Bildschirm skalieren"
    add.external: "Weitere Elemente anzeigen"
    group.expand: "Zeige alle Untergruppen unter dieser Ebene"
    group.collapse: "Verstecke alle Untergruppen"
    milestone.expand: "Zeige alle Meilensteine unter diesem Level"
    milestone.collapse: "Reduziere alle Meilensteine unter diesem Level"

    
    reload.confirm.message: "Neuladen löscht alle Änderungen. Fortfahren?"
    reload.confirm.title: "Bitte bestätigen"
    reload.confirm.yes: "JA"
    reload.confirm.no: "NEIN"
    
    filter.warning: "Damit Formatierungen korrekt funktionieren, müssen die betroffenen Terminpläne erst ausgecheckt und anschließend gespeichert werden, damit die Einstellungen korrekt gespeichert werden"

    
    
    responsible: "Verantwortlicher"
    StatusToggle: "Status (Button)"
    StatusPercent: "Status (Prozent)"
    Labels: "Labels einblenden"
    statusIndicator: "Statusindikator"
    statusIndicatorOnToday: "Statusindikator heute"
    hideNotRelevant: "Verstecke n.r. Punkte"
    crosshair: "Fadenkreuz"
</i18n>
<template>
  <div>
    <b-button 
        v-if="!!modal"
        variant="light" 
        v-b-modal="modal">
            <b-icon-calendar2-day variant="primary" />
    </b-button>
      <component 
        :is="!!modal ? 'b-modal' : 'div'"
        fluid 
        size="xl" 
        body-class="p-0" 
        dialog-class="baseditor-maxwidth gantt-modal" 
        header-class="titlebar"
        :class="{'gantt-modal' : !modal, 'bg-white' : true}"
        scrollable 
        :id="modal" 
        hide-footer
        no-fade
        @shown="updateGantt"
        @hide="checkForCheckout($event)"
        @hidden="clearGantt">
        <template #modal-header="{ close }">
            <div class="h4 text-nowrap mr-5"><slot name="title">{{ $t('timetable.title') }}</slot></div>
            <gantt-header />            

            
            <b-icon-x @click="close" class="ml-2 align-self-center mb-0 h4"/>
        </template>
        
        <div 
            class="gantt-container" 
            :class="{'hidepointer' : crosshair}" 
            ref="ganttContainer"
            :style="scaleToSingleView ? 'transform: scale('+singlePageScalingFactor+'); transform-origin: 0 0 0; ' : ''">
            
            <div v-if="!modal" class="p-1 titlebar d-flex w-100" >
                <div class="position-sticky d-flex" style="left:0;">
                    <div class="h4 text-nowrap mr-5"><slot name="title"></slot> \ {{ $t('timetable.title') }}</div>
                    
                    <gantt-header /> 
                </div>

            </div>
            
            <div 
                    class="gantt--header position-sticky bg-white d-flex" style="top:0; z-index:1900;width: fit-content; height: 60px;">
                <div 
                    class="gantt--controlbox border bg-light d-flex flex-column"
                    :style="'z-index: 1900; width:'+(getStartOffset()+30)+'px;max-width:'+(getStartOffset()+30)+'px;min-width:'+(getStartOffset()+30)+'px;'"
                    >
                    <div class="d-flex justify-content-start align-items-start flex-nowrap">
                        
                        
                        <b-dropdown variant="link" boundary="window" style="z-index: 1800;" menu-class="high-z-index">
                            <template #button-content>
                                <b-icon-eye variant="primary" />
                            </template>
                            <div class="d-flex justify-content-around flex-column">
                                

                                <b-form-checkbox v-model="showResponsible">{{ $t("responsible") }}</b-form-checkbox>
                                <b-form-checkbox v-model="showStatus">{{ $t("StatusToggle") }}</b-form-checkbox>
                                <b-form-checkbox v-model="showStatusPercent">{{ $t("StatusPercent") }}</b-form-checkbox>
                                <b-form-checkbox v-model="showLabels">{{ $t("Labels") }}</b-form-checkbox>
                                <b-form-checkbox v-model="showStatusIndicator">{{ $t("statusIndicator") }}</b-form-checkbox>
                                <b-form-checkbox v-model="showStatusIndicatorToday">{{ $t("statusIndicatorOnToday") }}</b-form-checkbox>
                                <b-form-checkbox v-model="hideNotRelevant">{{ $t("hideNotRelevant") }}</b-form-checkbox>
                                <b-form-checkbox v-model="crosshair" @input="toggleCrosshair($event)">{{ $t("crosshair") }}</b-form-checkbox>
                            </div>
                            </b-dropdown>

                        <filter-storage
                            suffix="__gantt"
                            noDefault
                            noReset
                            variant="transparent"
                            buttonVariant="primary"
                            :currentConfig="filterConfiguration"
                            @restoreFilter="updateFilterConfig($event)"
                        />
                        
                        <base-selector 
                            buttonMode
                            optional
                            :selectables="[{module: 'owner', widget:'TimeTablesTable', context: true, title: 'Timetables'}, {module: 'owner', widget:'Table', context: true, title: 'Projects'}]"
                            @input="addTimetablesToView($event)"  />

                        <b-button 
                            type="button" 
                            @click="allCheckedOut ? lockAllTimetables() : unlockAllTimetables()" 
                            class="ml-2" 
                            variant="transparent">
                                <b-icon :icon="allCheckedOut ? 'unlock-fill' : 'lock-fill'" variant="primary"></b-icon>
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-between">
                        <b-icon 
                            style="width: 15px;" 
                            :icon="collapseState == 'collapsed' ? 'chevron-right' : (collapseState == 'unfolded' ? 'chevron-down' : 'chevron-double-down')" 
                            href="#" 
                            :title="$t(collapseState == 'unfolded' ? 'group.collapse' : 'group.expand')"
                            @click="rotateUnfolding(collapseState == 'expanded' ? 'expanded' : 'unfolded')"/>

                        <b-icon 
                            :icon="(oneLineState == 'collapsed') ? 'caret-right' : (oneLineState == 'unfolded' ? 'caret-down' : 'skip-forward')"
                            variant="dark" 
                            :rotate="oneLineState == 'expanded' ? 90 : 0"
                            :title="$t(oneLineState == 'expanded' ? 'milestone.collapse' : 'milestone.expand')"
                            @click="rotateMilestoneUnfolding(oneLineState == 'expanded' ? 'expanded' : 'unfolded')"/>
                    </div>
                    
                    
                </div>

                <gantt-header-timeline
                    ref="timeline-header--main"
                    :today="today"
                    :maximumDateRange ="decoupledMaximumDateRange"
                    :itemWidth="itemWidth"
                    :displayMode="displayMode"
                    :startOffset="startOffsetWidth"
                    :totalWidth="fullTimetableWidth"
                    />

            </div>

            <div class="gantt--body" style="width: fit-content;">
                <div  
                    v-for="(item,idx) in mergedData" 
                    :key="$id('block-'+idx)"
                    class="h-100 d-flex"
                    :style="item.pinned ? 'position:sticky; top: 60px;z-index:1702;' : ''"
                    
                    >
                    <div 
                        class="d-flex flex-column position-sticky overflow-hidden bg-light align-items-center" 
                        style="width: 30px; left: 0px; z-index: 1701;" >
                        <b-badge 
                            v-if="item.data && (item.data.type == 'group' || item.data.type == 'element') && item.unlocked === -1 || item.unlocked === 0 "
                            variant="danger" 
                            class="p-1 rounded-0"
                            size="sm"
                            href="#"
                            @click="unlockTimetable(item)">
                                <b-icon-lock-fill v-if="item.unlocked === -1"/>
                                <b-spinner style="width: 1em; height: 1em;" v-else-if="item.unlocked === 0"/>
                        </b-badge>
                        <template 
                            v-if="item.unlocked >= 1"
                            >
                            <b-badge 
                                variant="success" 
                                size="sm"
                                class="p-1 rounded-0"
                                href="#"
                                @click="item.unlocked == 1 ? updateTimetable(item) : (item.unlocked == 5 ? prepareCreateTimetable(item) : '')">
                                    <b-icon-unlock-fill v-if="item.unlocked == 1"/>
                                    <b-icon-file-plus small v-else-if="item.unlocked === 5"/>
                                    <b-spinner style="width: 1em; height: 1em;" v-else-if="item.unlocked === 2"/>
                            </b-badge>
                            <b-badge 
                                v-if="item.unlocked !== 5"
                                variant="danger" 
                                size="sm"
                                class="p-1 rounded-0"
                                href="#"
                                @click="resetTimetable(item)">
                                    <b-icon-x />
                            </b-badge>
                        </template>



                        <div style="width: 30px;" class="align-self-start d-flex justify-content-center text-nowrap " v-if="item.data && item.data.hasOwnProperty('collapsed') && !item.data.collapsed">
                            <span :style="'transform: rotate(270deg) translateX(-50%);color: '+(item.configuration && fontColor(item.configuration.wsColor))+';'"
                            ><!--{{ item.name }}--></span>
                        </div>
                    </div>

                    <gantt-item-selector    
                        :type="item.data && item.data.type || 'element'" 
                        :value="item.data"
                        :disabled="item.hasOwnProperty('unlocked') ? item.unlocked < 1 : disabled"
                        :markings="markings"
                        :showResponsible="showResponsible"
                        :showStatus="showStatus"
                        :showStatusPercent="showStatusPercent"
                        :showStatusIndicator="showStatusIndicator"
                        :showStatusIndicatorToday="showStatusIndicatorToday"
                        :hideNotRelevant="hideNotRelevant"
                        :toplevel="!!modal"
                        :backgroundClass="item && item.configuration && item.configuration.bgClass || {}"
                        :owner="item.owner"
                        :group="$id('')"
                        :startOffset="startOffsetWidth"
                        :totalWidth="fullTimetableWidth"
                        :itemWidth="itemWidth"
                        class="dragitem-1" 
                        @input="updateValue(idx,$event)"
                        @reorder="updateOrder($event)"
                        >
                        <template #title-prepend >
                            <b-badge 
                                class="p-1 rounded-0"
                                variant="none" 
                                size="sm"
                                href="#"
                                :title="$t('title.pin')"
                                @click="pinItem(item)">
                                    <b-icon :icon="item.pinned ? 'tag-fill' : 'tag'" />
                            </b-badge>
                        </template>
                        <template #title-append >                            
                            <dynamic-loader 
                                class="flex-grow-1"
                                module="owner" 
                                widget="UserGroups" 
                                :value="item.userGroup" 
                                @input="updateTimetableField(idx,'userGroup',$event)" 
                                compClass="align-items-center"
                                style="width: 40px;"
                                :customProperties="{
                                    'size':'16px',
                                    'owner' : null,
                                    'ownerIri' : item.owner,
                                    'edit': item.unlocked == 1,
                                    'showOnlySelected': item.unlocked != 1
                                }"/>
                        </template>
                    </gantt-item-selector>
                </div>
            </div>
            
            <dynamic-loader v-if="subItemModal.module != '' && subItemModal.iri != ''" :module="subItemModal.module" widget="Single" :value="subItemModal.iri" :customProperties="{'modal': $id('showSubRowElement')}"/>
            
        </div>
            <div v-show="crosshair" class="crosshair-horizontal"></div>
            <div v-show="crosshair" class="crosshair-vertical"></div>
      </component>

      <b-modal :id="$id('createNewTimetable')" @ok="createNewTimetable()">
          <dynamic-loader 
            module="owner" 
            widget="Dropdown" 
            v-model="newTimetableOwner" 
            overflow
            :customProperties="{
                single : true,
                onlyActiveOwners: true}"/>
          <b-input v-model="newTimetableName" type="text" />

      </b-modal>
  </div>
</template>

<script>
import moment from 'moment'


import GanttHeaderTimeline from './subcomponents/ganttHeaderTimeline.vue'
import ganttItemSelector from './subcomponents/ganttItemSelector.vue'
import DynamicLoader from '../DynamicLoader.vue'


import filterStorage from '@/components/BaseFilterManager/FilterStorage'

import ganttHeader from './subcomponents/_header.vue'

import folding from './mixins/folding.js'
import FilterStorage from '../BaseFilterManager/FilterStorage.vue'


const GANTT_ITEMWIDTH = 30

export default {
    name: "BaseGantt2",
    mixins: [folding],
    components: {
        GanttHeaderTimeline,
        ganttItemSelector,
        DynamicLoader,
        ganttHeader,
        filterStorage
    },
    props: {
        value: {
            type: [String, Array],
            required: false,
            default: () => []
        },
        ownerIri: {
            type: [String, Boolean],
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modal: {
            type: [String, Boolean],
            default: false,
            required: false
        },
        titlePrefix: {
            type: [String, Boolean],
            default: false,
            required: false
        },
        noOwnerContext: {
            type: Boolean, 
            default: false,
            required: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$forceUpdate()
        })
        this.$root.$on('bts::gantt::loadItem', function(ev) {
            if(this.modal && ev.target == this.modal) {
                this.$bvModal.show(ev.target)
                this.calculationPending = true
                this.addTimetablesToView(ev.content).then(() => this.setMaximumDateRange())
            }
        }.bind(this))

        this.$root.$on('bts::gantt::recalculate', function(ev) {
            
            if(this.calculationPending === false) {
                setTimeout(() => this.setMaximumDateRange(),1000)
                this.calculationPending = true
            }
        }.bind(this))
    },
    watch: {
        'value' : {
            handler: function() { 
                   if(!this.modal) {
                        this.calculationPending = true
                        this.addTimetablesToView(this.value).then(() => this.setMaximumDateRange())
                   } else {
                    this.setMaximumDateRange()
                   }
                },
            immediate: true
        }
    },
    methods: {
        setMaximumDateRange() {
            this.calculationPending = false
             if(this.autoScale) {                
                let dataSet = [...this.localDataConfig]
                    let dataRange

                    dataRange = this.getDateRange(dataSet.map(d => (d.data ?? d ?? [])))

                    //add two months on each side
                    let unit = moment.normalizeUnits(this.displayMode)
                    switch(unit) {
                        case "day":
                            dataRange.min.subtract(2,'week')
                            dataRange.max.add(2,'week')
                            break;
                        case "week":
                            dataRange.min.subtract(2,'month')
                            dataRange.max.add(2,'month')

                            break;
                        case "month":
                            dataRange.min.subtract(4,'month')
                            dataRange.max.add(4,'month')
                            break;
                }
                this.decoupledMaximumDateRange = dataRange
             } else {
            
            //depending on the selected displaying, move to start/end of month/week
            let mode = moment.normalizeUnits(this.displayMode)
            let returnStart =  moment(this.calendarStart)
            let returnEnd =  moment(this.calendarEnd)
            switch(mode) {
                    case "day": 
                        //no adjustment needed
                        break;
                    case "week":
                        returnStart.startOf('isoWeek')
                        returnEnd.endOf('isoWeek')
                        break;
                    case "month":
                        returnStart.startOf('month')
                        returnEnd.endOf('month')
                        break;
                
                }

            this.decoupledMaximumDateRange =  {
                min: returnStart,
                max: returnEnd
            }
             }


        },

        toggleFullCheckout: function() {
            if(this.allCheckedOut) {
                this.$root.$emit('gantt::checkAllIn')
                this.allCheckedOut = false
            } else {
                this.$root.$emit('gantt::checkoutAll')
                this.allCheckedOut = true
            }
        },

        toggleScaling: function(ev) {
            if(!this?.$refs?.['ganttContainer']?.parentNode?.clientWidth) {
                return
            }
            this.scaleToSingleView = ev
            this.singlePageScalingFactor = Number((this.$refs['ganttContainer'].parentNode.clientWidth / this.$refs['ganttContainer'].clientWidth).toFixed(2))
        },
        requestFullscreen: function(className) {
            if(!document.fullscreenElement) {
                document.querySelector(className).requestFullscreen()
            } else {
                document.exitFullscreen()
            }
        },
        checkForCheckout: function(ev) {
            if(this.checkoutList.length > 0) {
                ev.preventDefault()
                this.verifyWindow().then(res => {
                    if(res) {
                        this.checkoutList = [];
                        this.$bvModal.hide(this.modal)
                    }
                })
            } 
        },
        verifyWindow: async function() {
            return await this.$bvModal.msgBoxConfirm(this.$t("reload.confirm.message"), {
                            title: this.$t('reload.confirm.title'),
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "danger",
                            okTitle: this.$t('reload.confirm.yes'),
                            cancelTitle: this.$t('reload.confirm.no'),
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true
                        })
        },
        restoreDefault: function(ev) {
            this.showResponsible = ev.showResponsible ?? false
                this.showStatus = ev.showStatus ?? false
                this.showStatusPercent = ev.showStatusPercent ?? false
                this.showStatusIndicator = ev.showStatusIndicator ?? false
                this.showStatusIndicatorToday = ev.showStatusIndicatorToday ?? false
                this.showLabels = ev.showLabels ?? false
                this.hideNotRelevant = ev.hideNotRelevant ?? false
                this.crosshair = ev.crosshair ?? false
        },
        restoreCollapseConfig: function(ev) {
            if(ev?.folding) {
                this.$set(this,"foldingConfiguration", ev.folding ?? {})
                this.showResponsible = ev.showResponsible ?? false
                this.showStatus = ev.showStatus ?? false
                this.showStatusPercent = ev.showStatusPercent ?? false
                this.showStatusIndicator = ev.showStatusIndicator ?? false
                this.showStatusIndicatorToday = ev.showStatusIndicatorToday ?? false
                this.showLabels = ev.showLabels ?? false
                this.hideNotRelevant = ev.hideNotRelevant ?? false
                this.crosshair = ev.crosshair ?? false
                
            } else {
                this.$set(this,"foldingConfiguration", ev ?? {})
            }

            this.$root.$emit("bts::gantt::triggerFilter", this.foldingConfiguration)
        },
        refreshGantt: async function() {
            let res = true
            if(this.checkoutList.length > 0) {
                res = await this.verifyWindow()
            }
            if(res) {
                this.updateGantt()
            }
        },
        
        updateGantt: function() {
            this.addTimetablesToView(this.value).then(() => {
                this.setMaximumDateRange()
                this.$nextTick(() => this.$nextTick(() => {
                    if(this.decoupledMaximumDateRange.max.isAfter() && !this.scrolledToToday) {
                        this.scrollToToday()
                        this.scrolledToToday = true
                    }
                }))
            });
        },
        clearGantt: function() {
            this.localDataConfig = []
            this.ownerData = []
        },
        toggleCrosshair: function(ev) {
            if(this.crosshair) {
                document.querySelector('.gantt-container').addEventListener('mousemove', this.updateCrosshair)
            } else {
                document.querySelector('.gantt-container').removeEventListener('mousemove', this.updateCrosshair)
            }
        },
        updateCrosshair: function(ev) {
            let container = document.querySelector('.gantt-container')

            let horizontalLine = document.querySelector('.crosshair-horizontal')
            const totalOffset = function(offset, item) {
                if(item === document || !item) return offset;
                let curOffset = totalOffset(offset, item.offsetParent)
                return {x: offset.x + curOffset.x - item.scrollLeft, y: offset.y + curOffset.y - item.scrollTop }
            }
            let offset = totalOffset({x:0,y:0}, container)
            horizontalLine.style.setProperty('top',ev.clientY  + 'px')
        
        
            let verticalLine = document.querySelector('.crosshair-vertical')
            verticalLine.style.setProperty('left',ev.clientX  + 'px')
        },
        getWidth: function() { return GANTT_ITEMWIDTH },
        getStartOffset: function() { 
            return this.startOffsetWidth             
        },
        getMode: function() {
            return this.displayMode
        },
        scrollToToday() {
            //Steps: get the offset for today
            //calculate the scroll position
            //scroll container
            let todayString = null;
            switch(moment.normalizeUnits(this.displayMode)) {
                    case "day": 
                        todayString = moment().format("DD.MM. YY")
                        break;
                    case "week":
                        todayString = moment().format('WW/GG')
                        break;
                    case "month":
                        todayString = moment().format('MM.YY')
                        break;                
            }
            let todayIndex = this.arrCalendarWeeks.findIndex(e => e == todayString)
            if(todayIndex == -1) return

            let pixelPositionInBox = this.getStartOffset() + todayIndex*GANTT_ITEMWIDTH

            let scrollLeftValue = pixelPositionInBox - this.getStartOffset() - GANTT_ITEMWIDTH*4

            this.$el.parentNode.scrollTo({
                left: scrollLeftValue,
                behavior: 'smooth'
            })
            this.$refs['ganttContainer'].parentNode.scrollTo({
                left: scrollLeftValue,
                behavior: 'smooth'
            })
        },
        getDateRange(arrElement) {
            let minDate = null
            let maxDate = null
            let safeArray = Array.isArray(arrElement) ? arrElement : [arrElement]
            safeArray.forEach((item) => {
                let startDate, endDate, result                

                if(!!item.dates && item.dates.length > 0) {
                    startDate = moment.min(item.dates.map((elem) => {
                        let m = moment(elem.start) 
                        return m.isValid() ? m : null
                        }).filter(n => n !== null ))
                    endDate = moment.max(item.dates.map((elem) => {
                        let m = moment(elem.end) 
                        return m.isValid() ? m : null
                        }).filter(n => n !== null ))               
                }

                if(!!item.children) {
                    result = this.getDateRange(item.children)
                    startDate = !!startDate ? moment.min([result.min, startDate]) : result.min
                    endDate = !!endDate ? moment.max([result.max, endDate]) : result.max
                } 

                if(!!minDate) {
                    if(!!startDate) {
                        minDate = moment.min([minDate, startDate])
                    }
                } else {
                    minDate = startDate
                }

                if(!!maxDate) {
                    if(!!endDate) {
                        maxDate = moment.max([maxDate, endDate])
                    }
                } else {
                    maxDate = endDate
                }

            })

            if(!minDate || (minDate.isValid && !minDate.isValid())) { minDate = moment() }
            if(!maxDate || (maxDate.isValid && !maxDate.isValid())) { maxDate = moment() }

            //set minDate to start of the week
            minDate = minDate.startOf('isoWeek')
            maxDate = maxDate.endOf('isoWeek')

            return { min: minDate, max: maxDate}
        },       
        updateValue(idx,data) {
            if(this.localDataConfig.length == 0 && !this.disabled && !this.modal) {
                this.localDataConfig = JSON.parse(JSON.stringify(this.emptySet))
            }
            let newIdx = idx - this.ownerData.length
            if(newIdx < 0) {
                this.ownerData[idx].data = data                
            } else {
                this.localDataConfig[newIdx].data = data
                this.$emit('input', this.localDataConfig)
            }
            this.setMaximumDateRange()
        },
        updateTimetableField(idx,name,data)
        {
            if(this.localDataConfig.length == 0 && !this.disabled && !this.modal) {
                this.localDataConfig = JSON.parse(JSON.stringify(this.emptySet))
            }
            let newIdx = idx - this.ownerData.length
            if(newIdx < 0) {
                //changes to an owner element, update that
                this.$set(this.ownerData[idx],name,data)
            } else {
                this.$set(this.localDataConfig[newIdx],name,data)
                this.$emit('input', this.localDataConfig)
            }
        },
        activateModal: async function(iri) {

            let mod = this.$store.getters.getModuleFromIri(iri)

            this.subItemModal = {module: mod.module, iri: iri}
            if(!this.subItemModal) return
            this.$nextTick(function() {
            this.$nextTick(function() {
            this.$nextTick(function() {
            this.$nextTick(function() {
                this.$bvModal.show(this.$id('showSubRowElement'))      
            })})})          
            })
        },
        addTimetablesToView: async function(list) {
            const addOwnerfromIri = (ownerIri) => {
                let o = this.$store.getters['owner/getItems']?.[ownerIri]
                let preppedData = o.gantt?.content ?? false
                if(!preppedData) return
                if(!preppedData.type) {
                    preppedData.type = 'group'
                }
                if(!preppedData.title) {
                    preppedData.title = this.$render.displayByIri(ownerIri)
                }


                let newDataSet = {
                    'data': o.gantt.content, 
                    'name': this.$render.displayByIri(ownerIri), 
                    'owner': ownerIri, 
                    'configuration' : { bgClass : {"highlightRow--primary":true}}, 
                    unlocked : -1, 
                    'iri': o.gantt.iri , 
                    'pinned': false
                    }
                
                if(newDataSet.data.title) {
                        newDataSet.data.title = this.$render.displayByIri(ownerIri)
                    }
                
                //add validation of visibility in meeting mode
                let addToGantt = true
                if(this.$store.getters.getMeetingModes.length > 0) {
                    addToGantt = false
                }

                if(addToGantt) {
                    this.addMarkings(this.getMarkings(preppedData))                                
                    this.ownerData.splice(this.ownerData.length,0,newDataSet) 
                    //now search for the corresponding customer timetable if available and add it below
                }



                let customerLead = this.$store.getters['owner/getTimetables'].find(tt => tt.owner == ownerIri && tt.category == "customer" && tt.status == "lead")
                if(!!customerLead) {
                    let customerDataSet = {
                    'data': customerLead.data, 
                    'name': customerLead.name, 
                    'owner': ownerIri, 
                    'configuration' : { bgClass : {"highlightRow--customer":true}}, 
                    unlocked : -1, 
                    'iri': customerLead.iri , 
                    'pinned': false
                    }
                    if(!customerDataSet.data || Array.isArray(customerDataSet.data)) {
                        customerDataSet.data = { type: "group" }
                    }
                    if(!customerDataSet.data.title || customerDataSet.data.title != customerLead.name) {
                        customerDataSet.data.title = customerLead.name
                    }


                    this.ownerData.splice(this.ownerData.length,0,customerDataSet) 


                    this.temporaryCache[customerDataSet.iri] = JSON.parse(JSON.stringify(customerDataSet))
                }


                this.temporaryCache[ownerIri] = JSON.parse(JSON.stringify(newDataSet))
            }



            if(!this.modal) {
                this.localDataConfig = list.map(l => {
                    if(l.data) return l
                    return {
                        data: l
                    }
                })

                if(this.ownerIri && this.ownerData.length == 0 && !this.noOwnerContext) {
                    addOwnerfromIri(this.ownerIri)
                }
                return
            }
            
            //prep the value elements here
            let tmpValue = []
            let tmpOwners = []
            if(Array.isArray(this.value)) {
                tmpValue = [...this.value]
            } else {
                tmpValue = [this.value]
            }

            tmpValue = tmpValue.filter((v,idx,arr) => arr.indexOf(v) == idx)

            let noOwnerNoTimetableValues = tmpValue.filter(val => !/\/(timetables|owners|gantts)\//.test(val))

            let preppedValues = await Promise.all(noOwnerNoTimetableValues.filter(val => !!this.$store.getters.getItemFromIri(val))
            .map(async itemIri => {
                let builtItem = await this.buildExternal(itemIri)
                //prep as element

                return builtItem
            }))

            tmpOwners = tmpValue.map(itemIri => {
                return this.$store.getters.getOwnerFromIri(itemIri)
            }).filter(o => !!o)
            
            let uniqueOwners = new Set()
            tmpValue.filter(val => /\/(owners)\//.test(val)).forEach(ownerIri => {
                uniqueOwners.add(ownerIri)
            })
            tmpOwners.forEach(owner => {
                if(typeof owner == "string") {
                    uniqueOwners.add(owner)
                } else if(typeof owner == "object" && owner.hasOwnProperty('iri')) {
                    uniqueOwners.add(owner.iri)
                }
            })

            this.localDataConfig = []

            let timetables = list.filter(tt => typeof tt == "string" && tt.includes('/timetables/'))
            list.filter(tt => typeof tt == "string" && tt.includes('/owners/') && tmpValue.indexOf(tt) == -1).forEach(owner => 
                uniqueOwners.add(owner)
            )

            let owners = [...uniqueOwners]

            //also force the fetch of customer lead timetables if available
            let customerTimetables = this.$store.getters['owner/getTimetables'].filter(tt => owners.indexOf(tt.owner) != -1 && tt.category == "customer" && tt.status == "lead")
            let customerPromise = Promise.allSettled(customerTimetables.map(l => {
                return this.$cache.get('owner',l);
            }))
            let resultPromise = Promise.allSettled(timetables.map(l => {
                return this.$cache.get('owner',l);
            }))

            let resolvedPromises = await Promise.allSettled([customerPromise, resultPromise])
            let results = resolvedPromises[1].value

            
            results.forEach(resultPromise => {
                if(resultPromise.status !== 'fulfilled') {
                    this.$bvToast.toast("Missing access rights for Timetable",{variant: 'danger'});
                    return
                }
                let r = resultPromise.value
                this.temporaryCache[r.iri] = JSON.parse(JSON.stringify(r))
                let newDataSet = {
                    'data': r.data, 
                    'name': r.name, 
                    'owner': r.owner, 
                    'configuration' : r.configuration ?? {}, 
                    'unlocked' : -1, 
                    'iri': r.iri, 
                    'pinned': false,
                    'userGroup' : r.userGroup ?? []
                    }
                    if(!newDataSet.data || Array.isArray(newDataSet.data)) {
                        newDataSet.data = { type: "group" }
                    }
                    if(!newDataSet.data.title || newDataSet.data.title != r.name) {
                        newDataSet.data.title = r.name
                    }

                //add color coding for timetables to configuration, overwriting existing props
                switch(true) {
                    case r.status == "lead" && r.category == "customer":
                        newDataSet.configuration.bgClass = {"highlightRow--customer":true}
                        break;
                    case r.status == "lead" && r.category != "customer":
                        newDataSet.configuration.bgClass = {"highlightRow--success":true}
                        break;
                    case r.status == "project":
                        newDataSet.configuration.bgClass = {"highlightRow--primary":true}
                        break;
                    default:
                        newDataSet.configuration.bgClass = {'timeline-groupheader-bg' : true}
                        break;
                }

                //add validation of visibility in meeting mode
                let addToGantt = this.$store.getters.isItemVisible(r.iri)

                if(addToGantt) {
                    this.localDataConfig.splice(this.localDataConfig.length,0,newDataSet)
                }


                if(owners.findIndex(o => o == r.owner) == -1) {
                    owners.splice(0,0,r.owner)
                }
            })
            
            this.markings = [];
            
            this.ownerData = []

            if(!this.noOwnerContext) { 
                owners.forEach(addOwnerfromIri)
            }

            let selectedItemsGroup = {
                'data' : {
                     "type": "group", 
                     "title": this.$t('selectedItems'), 
                     "children": preppedValues, 
                     "collapsed": false,
                     "unlocked" : 5,
                     '@collapse_children' : 'unfolded'
                    },
                'name' : this.$t('selectedItems'),
                'owner': null,
                'configuration' : {bgClass : {'timeline-groupheader-bg' : true}},
                'unlocked': 5,
                'iri' : null,
                'pinned' : false
                

            }
            
            if(preppedValues.length > 0) {
                this.localDataConfig.splice(this.localDataConfig.length,0, selectedItemsGroup)
            }



        },
        getMarkings: function(owner) {
            let markings = []
            if(owner.children) {
                owner.children.forEach(child => {
                    markings = markings.concat(...this.getMarkings(child))
                })
            }
            if(owner.dates) {
                owner.dates.forEach(date => {
                    if(date.marking) {
                        markings.push(date)
                    }
                })
            }
            return markings
        },
        addMarkings(markings) {
            //get all project wide markings
            this.markings = this.markings.concat(markings)  
        },
        buildExternal: async function(iri) {
            //run script interpreter to build a full row set
            let mod = this.$store.getters.getModuleFromIri(iri)
            let externalObject = this.$store.getters.getItemFromIri(iri)

            //force loading of all related items
            let allRelatedLoading = await this.$store.dispatch('relation/loadAllRelated',iri)

            if(!mod || !externalObject) return {}
            let ganttObj = await window?.[mod.module]?.['functions']?.['Timing']?.buildGantt?.(externalObject,{}) ?? {}
            if(!ganttObj.hasOwnProperty('type')) {
                ganttObj.type = !!ganttObj.children ? "externalGroup" : "external"
            }
            ganttObj.fromIri = iri
            return ganttObj 
        },
        fontColor: function(hex) {
            if(hex == null) return 'black'
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
            });

            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            let rgbObj = result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
            if(!rgbObj) return 'black'
            let brightness = Math.round(((parseInt(rgbObj.r) * 299) +
                      (parseInt(rgbObj.g) * 587) +
                      (parseInt(rgbObj.b) * 114)) / 1000);

            return (brightness > 125) ? 'black' : 'white';
        },

        pinItem(item) {
            if(item.pinned) {
                this.$set(item,'pinned',false)
                return
            }
            this.localDataConfig.forEach(elem => {
                elem.pinned = false;
            })
            this.$set(item,'pinned',true)
        },
        unlockAllTimetables: async function() {
            await Promise.allSettled(this.mergedData.map(this.unlockTimetable))
            this.allCheckedOut = true
        },
        lockAllTimetables: async function() {
            await Promise.allSettled(this.mergedData.map(this.updateTimetable))
            this.allCheckedOut = false
        },
        unlockTimetable: async function(item) {
            this.$set(item,'unlocked',0)
            try {
                if(item.iri.includes('/gantts/')) {
                    //lock owner instead
                   await this.$cache.post('owner','/lock',{'resource' : item.owner})
                } else {
                    await this.$cache.post('owner','/lock',{'resource' : item.iri})
                }
                this.$set(item,'unlocked',1)
                this.registerCheckoutItem(item.iri)
            } catch {
                this.$set(item,'unlocked',-1)
                this.$bvToast.toast("Checkout failed",{variant: 'danger'});
                this.unregisterCheckoutItem(item.iri)
            }
        },
        prepareCreateTimetable: function(ev) {
            this.createBuffer = ev
            this.newTimetableName = ev.name ?? ''
            this.$bvModal.show(this.$id('createNewTimetable'))
        },
        createNewTimetable: async function() {
            try {
                this.$set(this.createBuffer,'unlocked',2)
                this.createBuffer.name = this.newTimetableName
                this.createBuffer.owner = this.newTimetableOwner.iri
                let timetableIri = await this.$cache.post('owner','/timetables',this.createBuffer)
                this.createBuffer.iri = timetableIri
                this.$bvToast.toast("Creation successful",{variant: 'success'});

                this.$set(this.createBuffer,'unlocked',null)

            } catch {
                this.$bvToast.toast("Creation failed",{variant: 'danger'});
                this.$set( this.createBuffer,'unlocked',1)
                //add error message
            }
        },
        updateTimetable: async function(item) {
            try {
                this.$set(item,'unlocked',2)
                
                 if(item.iri.includes('/gantts/')) {
                    //lock owner instead
                    await this.$cache.update('owner',item.iri,{content: this.$gantt.clearDataForSaving(item.data)})
                    await this.$cache.post('owner','/unlock',{'resource' : item.owner}) 
                } else {
                    await this.$cache.update('owner',item.iri,{data: this.$gantt.clearDataForSaving(item.data), userGroup: item.userGroup})
                    await this.$cache.post('owner','/unlock',{'resource' : item.iri}) 
                }
                
                
                this.$bvToast.toast("Update successful",{variant: 'success'});

                this.$set(item,'unlocked',-1)
                this.unregisterCheckoutItem(item.iri)

            } catch {
                this.$bvToast.toast("Update failed",{variant: 'danger'});
                this.$set(item,'unlocked',1)
                //add error message
            }
        },
        resetTimetable: async function(item) {
            try {
                if(item.iri.includes('/gantts/')) {
                    //lock owner instead
                    item = Object.assign(item, JSON.parse(JSON.stringify(this.temporaryCache[item.owner])))
                    await this.$cache.post('owner','/unlock',{'resource' : item.owner}) 
                } else {                    
                    item = Object.assign(item, JSON.parse(JSON.stringify(this.temporaryCache[item.iri])))
                    await this.$cache.post('owner','/unlock',{'resource' : item.iri}) 
                }
                this.$set(item,'unlocked',-1)
                this.unregisterCheckoutItem(item.iri)
            } catch {
                this.$bvToast.toast("Reset failed",{variant: 'danger'});
                this.$set(item,'unlocked',2)
            }
            
        },
        registerCheckoutItem(name) {
           let idy = this.checkoutList.indexOf(name) 
           if(idy == -1) {
               this.checkoutList.push(name)
           }
        },
        unregisterCheckoutItem(name) {
            let idy = this.checkoutList.indexOf(name) 
           if(idy > -1) {
               this.checkoutList.splice(idy,1)
           }
        },
        updateFoldingConfiguration(foldId, data) {
            this.$set(this.foldingConfiguration,foldId,data)
        },
        updateFilterConfig(filterData) {
            this.showResponsible = filterData?.showResponsible,
            this.showStatus = filterData?.showStatus,
            this.showStatusPercent = filterData?.showStatusPercent,
            this.showStatusIndicator = filterData?.showStatusIndicator,
            this.showStatusIndicatorToday = filterData?.showStatusIndicatorToday,
            this.showLabels = filterData?.showLabels,
            this.hideNotRelevant = filterData?.hideNotRelevant,
            this.crosshair = filterData?.crosshair
        }
    },  
    computed: {
        filterConfiguration: function(vm) {
            let obj = {
                showResponsible : vm.showResponsible,
                showStatus : vm.showStatus,
                showStatusPercent : vm.showStatusPercent,
                showStatusIndicator : vm.showStatusIndicator,
                showStatusIndicatorToday : vm.showStatusIndicatorToday,
                showLabels : vm.showLabels,
                hideNotRelevant : vm.hideNotRelevant,
                crosshair : vm.crosshair
            }
            return obj
        },
        startOffsetWidth: function(vm) {
            const baseWidth = 300;
            let startWidth = baseWidth;
            startWidth += (this.showResponsible ? 150 : 0)
            startWidth += (this.showStatus ? 30 : 0)
            startWidth += (this.showStatusPercent ? 60 : 0)
            return startWidth 
        },
        mergedData: function(vm) {
            let newSet = [...vm.ownerData]
            if(vm.localDataConfig.length == 0) {
                //only add new set if not disabled
                if(!vm.disabled) {
                    newSet.push(...vm.emptySet)
                }
            } else {
                let localDataCopy = [...vm.localDataConfig]
                if(vm.$store.getters.getMeetingModes.length > 0 && !vm.modal) {
                    if(vm.$store.getters.getMeetingModes.includes('customer')) {
                        localDataCopy = localDataCopy.filter(set => (set.userGroup?.some(g => {
                            let uGObj = vm.$store.getters['owner/getUserGroups']?.[g]
                            return uGObj?.category?.includes("customer") ?? false  }) ?? false))
                    }
                }
                newSet.push(...localDataCopy)
                
                
            }
            
           

            return newSet
        },

        fullTimetableWidth: function(vm) {
            return vm.arrCalendarWeeks.length*vm.itemWidth
        },
        arrCalendarWeeks: function(vm) {
            let start = moment(vm.decoupledMaximumDateRange.min.startOf('isoWeek'))
            let end = vm.decoupledMaximumDateRange.max.endOf('isoWeek')
            let arr = []
            let mode = moment.normalizeUnits(this.displayMode)
            while (start < end) {
                switch(mode) {
                    case "day": 
                        arr.push(start.format("DD.MM. YY"))
                        break;
                    case "week":
                        arr.push(start.format('WW/GG'))
                        break;
                    case "month":
                        arr.push(start.format('MM.YY'))
                        break;
                
                }
                start.add(1,mode)
            }
            return arr
        }, 
    },
    data() {
        return {
            allCheckedOut: false,
            itemWidth: GANTT_ITEMWIDTH,
            foldingConfiguration: {},
            temporaryCache: {},
            checkoutList: [],
            displayMode: "weeks",
            today: moment(),
            subItemModal: {module: "", iri: ""},
            autoScale: true,
            calendarStart: moment().subtract(4,'months'),
            calendarEnd: moment().add(4,'months'),
            localDataConfig: [],
            ownerData: [],
            emptySet: [{
                'data' : {
                    "type": "group", 
                    "title": "", 
                    "children": [], 
                    "collapsed": false 
                },
                'name' : "",
                'owner': null,
                'pinned' : false,
            }],
            showResponsible: false,
            showStatus: true,
            showStatusPercent: false,
            showStatusIndicator: false,
            showStatusIndicatorToday: false,
            showLabels: true,
            hideNotRelevant: false,
            crosshair: false,
            markings: [],
            newTimetableName: "",
            newTimetableOwner: null,
            boundingBox: {},
            scaleToSingleView: false,
            singlePageScalingFactor: 1,
            decoupledMaximumDateRange: { min: moment(), max: moment()},
            calculationPending: false,
            scrolledToToday: false
        }
    },    
    provide() { return {
        getFoldingSettings: () => { return this.foldingConfiguration },
        updateFolding: function(foldId, data) {
             this.updateFoldingConfiguration(foldId, data)             
        }.bind(this),
        getUniqueFoldingId: () => {
            return "FID-"+(moment().unix())
        },
        registerCheckout: function(name) {
             this.registerCheckoutItem(name)
        }.bind(this),
        unregisterCheckout: function(name) {
             this.unregisterCheckoutItem(name)
        }.bind(this),
        showLabels: () => { return this.showLabels },
        getStartDate: () => { return this.decoupledMaximumDateRange.min },
        getFullWidth: () => { return this.fullTimetableWidth },
        getMode: this.getMode,
        getWidth: this.itemWidth,
        getStartOffset: this.getStartOffset,
        getBoundingBox: () => Object.assign(
            this.$refs['ganttContainer'].getBoundingClientRect(), 
            {
                scrollLeft : this.$refs['ganttContainer'].parentNode.scrollLeft, 
                scrollTop : this.$refs['ganttContainer'].parentNode.scrollTop, 
                displayWidth: this.$refs['ganttContainer'].parentNode.clientWidth}),
        locked: () => this.disabled,
        activateModal: this.activateModal,
        getScalingFactor: () => this.scaleToSingleView ? this.singlePageScalingFactor : 1
        
    }},
}
</script>

<style lang="scss">
@import "../../style/customvars";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import '../../../node_modules/bootstrap-vue/src/index.scss';


    .hidepointer {
        cursor: none;
    }
    .crosshair-horizontal {        
        position:fixed;
        height: 2px;
        left:0;
        right:0;
        background-color: rgba(0, 0, 0, 0.685);;
        z-index: 2000;
        pointer-events: none;
    }
    .crosshair-vertical {    
        position:fixed;
        top:0;
        bottom:0;
        width: 2px;
        background-color: rgba(0, 0, 0, 0.685);
        z-index: 2000;
        pointer-events: none;
    }

    .modalpopover {
        z-index: 2000 !important;
    }
    
    .gantt-modal {
        min-height: 95%;
        height: fit-content;
    }

    .gantt-container {
        width: fit-content !important;
        height: fit-content !important;
        max-height: fit-content !important;
    }

    .gantt--body {
        position: relative;
    }

    .gantt--controlbox {
       position: sticky;
       left:0;
       padding: 2px;

    }

    .high-z-index {
        z-index: 1705;
    }
    
    .timeline-row, .timeline-groupheader  {
        display: flex;
        align-items: center;
        border-bottom: 2px solid #eaeaea;
        color: black;
    }
    
    .timeline-items {
        position: relative;
        height: 20px;
        display: block;
        align-items:center;
        color: black;
         background-image: radial-gradient(circle, #000000 0.2px, rgba(0, 0, 0, 0) 1px);
    }

.grip-handler {
    cursor: grab;
}
    
.highlightRow--success {
  @extend .alert-success;
}

.highlightRow--primary {
  @extend .alert-primary;
}

.highlightRow--customer {
  background-color: #88b0ec;
}

.highlightRow--danger {
  @extend .alert-danger;
}

.highlightRow--secondary {
  @extend .alert-secondary;
}

.highlightRow--warning {
  @extend .alert-warning;
}

.timeline-items:not(.highlightRow--primary):not(.highlightRow--success):not(.highlightRow--customer):not(.highlightRow--danger):not(.highlightRow--secondary):not(.highlightRow--warning) {
        background-color: white;
    }

</style>
