<i18n locale="en" lang="yaml" >
    Lock:
        fetching: "Checking out"
        updating: "Refreshing"
        committing: "Commiting"
        unlocking: "Locking"
    btn.saveAndUnlock: "Save & Lock"
    btn.save: "Save"
    btn.readMode: "Locked"
    btn.readMode.mouseover: "Click to edit"
    btn.edit.mouseover: "Commit changes and switch to read mode"
    btn.save.mouseover: "Commit changes"
    redacted: "REDACTED"
    create: "Create New"
    dataMissing: "Form is missing required data"
    discard.mouseover: "Discard changes and close"
    invalidFields: "The following fields are not valid:"
    invalidFieldsTitle: "Invalid fields"
    toast.title.failed: "Saving failed"
    toast.message.locked: "Element not checked out correctly/lock expired"
    toast.message.badrequest: "Invalid request"
    toast.message.unknownfailed: "Saving failed due to unknown reasons"
    lockrenewal.failed.text: "Could not renew lock. Changes might not be saved"
    lockrenewal.failed.title: "Lock renewal failed"
    lockaquisition.failed.text: "Could not aquire lock"
    lockaquisition.failed.title: "Lock acquisition failed"
    no: "NO"
    yes: "YES"
    confirm: "Please Confirm"
    exit.without.saving: "Exit without saving?"
    exit.save: "Save changes?"
    saving.success.title: "Element updated"
    saving.success.message: "Update successful"
    creation.success.title: "Element created"
    creation.success.msg: "Creation successful"
</i18n>
<i18n locale="de" lang="yaml" >
    Lock:
        fetching: "auschecken"
        updating: "aktualisieren"
        committing: "Element einchecken"
        unlocking: "Sperren"
    btn.saveAndUnlock: "Speichern & Einchecken"
    btn.readMode: "Gesperrt"
    btn.readMode.mouseover: "Zum Bearbeiten klicken"
    btn.edit.mouseover: "Speichern und anschließend wieder in Lesemodus wechseln"
    btn.save: "Speichern"
    btn.save.mouseover: "Änderungen speichern"
    redacted: "GESCHWÄRZT"
    create: "Neu erstellen"
    dataMissing: "Es fehlen notwendige Einträge"
    discard.mouseover: "Änderungen verwerfen und schließen"
    invalidFields: "Folgende Felder sind nicht gültig:"
    invalidFieldsTitle: "Ungültige Felder vorhanden"
    toast.title.failed: "Speichern fehlgeschlagen"
    toast.message.locked: "Element nicht korrekt ausgecheckt"
    toast.message.badrequest: "Ungültige Anfrage"
    toast.message.unknownfailed: "Speichern aus unbekannten Grund fehlgeschlagen "
    lockrenewal.failed.title: "Verlängerung fehlgeschlagen"
    lockrenewal.failed.text: "Die ausgecheckte Zeit konnte nicht verlängert werden. Änderungen können verloren gehen"
    lockaquisition.failed.title: "Auschecken fehlgeschlagen"
    lockaquisition.failed.text: "Element konnte nicht ausgecheckt werden"
    no: "JA"
    yes: "NEIN"
    confirm: "Bitte bestätigen"
    exit.without.saving: "Ohne Speichern fortfahren?"
    exit.save: "Änderungen speichern?"
    saving.success.title: "Element aktualisiert"
    saving.success.message: "Änderungen erfolgreich gespeichert"
    creation.success.title: "Element erstellt"
    creation.success.msg: "Erstellung erfolgreich"
</i18n>
<template>
  <component 
    :is="modal ? 'b-modal' : 'b-container'"  
    fluid 
    :size="modalSize" 
    body-class="p-0" 
    :dialog-class="dialogClass" 
    scrollable 
    :id="modal" 
    @show="confirmCloseProp = true" 
    @hide="confirmClose($event)" 
    hide-header hide-footer>
    
    <component 
      :is="modal ? 'b-container' : 'div'" 
      fluid 
      ref="editorContainer" 
      class="editor-bg minh-100 position-relative" 
      :class="{'minh-100': !!modal}"
      
      @dragend="dragSafe($event)" 
      @dragover="dragSafe($event)" 
      @dragenter="dragSafe($event); isHovered = true"      
      @drop="handleDrop($event)" 
      >
      <slot 
        name="default" 
        v-bind:editMode="editMode"
        v-bind:creationMode="creationMode"
        v-bind:createElement="createElement"
        v-bind:value="dataValues"
        v-bind:dataValues="dataValues"
        v-bind:changeValue="remoteSet" 
        v-bind:fields="fieldByName"
        v-bind:owner="owner"   
        v-bind:toggleEditMode="toggleEditMode"
        v-bind:acquiringLock="acquiringLock"
        v-bind:lockAcquisitionStatus="lockAcquisitionStatus"
        v-bind:lockAcquired="lockAcquired"
        v-bind:forceCheckout="forceCheckout"
        v-bind:confirmClose="confirmClose"
        >
      <!-- TITLE BAR -->
      <b-row class="position-sticky editor-bg editor-border" style="top:0em;z-index:9999;">
      <b-col>
        <b-container fluid>
          <b-row class="mr-3">
            <b-col>
              <div class="align-items-center d-flex flex-grow-1 justify-content-between pt-2 my-auto col-8">
                <!-- if an owner is defined always prefix with the project number -->
                <div class="d-flex flex-grow-1">
                  <!--  
                  @slot TopRow elements that are placed directly under the title
                  @binding {object} owner the current owner iri
                  @binding {object|null} creationMode if set the item is being created
                  @binding {Boolean} editMode if true the item is checked out or otherwise editable
                  -->
                  <div class="mw-25 d-flex justify-content-start">
                  <slot name="top-row" v-bind:owner="owner" v-bind:editMode="editMode" v-bind:creationMode="creationMode">
                    <span class="text-muted h4">{{ (ownerObject && ((ownerObject.customAbbreviation || ownerObject.customIdentifier || ownerObject.name || '??')+' / ') || '') }}</span>
                  </slot> 
                  </div>
                  
                  <!-- 
                    @slot TitleBar Inject the title for the element here -->
                  <div class="h4 ml-1"><slot name="title-bar"></slot></div>
                  <base-editor-field
                          v-if="titleBarField"
                          :id="titleBarField.name"
                          v-model="dataValues[titleBarField.name]"
                          :editMode="editMode"
                          :creationMode="creationMode"
                          :field="titleBarField"                    
                          :immutable="titleBarField.immutable"
                          class="flex-grow-1"
                          :class="{'mb-0' : groupedFields.layout === 'grid' }"
                          @checkout="forceCheckout()"
                          noLabel
                        ></base-editor-field>
                </div>
                <div class="labelGroups">
                  <dynamic-loader 
                    widget="UserGroups" 
                    module="owner" 
                    v-model="dataValues.userGroup" 
                    :customProperties="{'ownerIri': (creationMode && creationMode.owner || owner || null), edit: false, size: 'sm', showOnlySelected : true }">
                  </dynamic-loader>                
                  <dynamic-loader 
                    widget="TopFive" 
                    module="relation" 
                    neverOverflow
                    :customProperties="{'owner': (creationMode && creationMode.owner || owner || null), edit: false, size: 'md', iri: dataValues.iri, showOnlySelected: true }">
                  </dynamic-loader>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              >
              <b-container fluid class="p-0">
              

                <b-row >
                  <b-col class="d-flex" cols="4">
                    <b-button-group class="my-1">
                      <template v-if="!!creationMode">
                        <b-button type="button" @click="createElement" variant="success" v-if="!!creationMode">
                          <b-spinner small v-if="acquiringLock"></b-spinner>
                          {{ $t('create') }}
                        </b-button>
                      </template>
                      <template v-else>
                        <b-button
                          type="button"
                          @click="toggleEditMode"
                          :variant="editMode ? 'success' : 'danger'"
                          v-if="!readonly"
                          v-b-tooltip.bottom
                          :title="$t(editMode ? 'btn.edit.mouseover' : 'btn.readMode.mouseover')"
                        >
                          <template v-if="!acquiringLock && lockAcquired === 1">
                            <b-icon-unlock></b-icon-unlock> {{ $t('btn.saveAndUnlock') }}
                          </template>
                          <template v-else-if="!acquiringLock && lockAcquired !== 1">
                            <b-icon-lock></b-icon-lock> {{ $t('btn.readMode') }}
                          </template>
                          <template v-if="acquiringLock">
                            <b-spinner type="grow" small></b-spinner>
                            <span v-if="lockAcquisitionStatus === 0">{{ $t("Lock['fetching']") }}</span>
                            <span v-if="lockAcquisitionStatus === 50">{{ $t("Lock['updating']") }}</span>
                            <span v-if="lockAcquisitionStatus === 99">{{ $t("Lock['committing']") }}</span>
                            <span v-if="lockAcquisitionStatus === 49">{{ $t("Lock['unlocking']") }}</span>
                          </template>
                        </b-button>
                        <b-button v-if="editMode" type="button" @click="confirmClose" variant="danger" v-b-tooltip.bottom :title="$t('discard.mouseover')">
                          <b-icon-x></b-icon-x>
                        </b-button>
                      </template>
                    </b-button-group>
                    
                    <b-button class="my-1" variant="transparent" @click="renderScreenshot('editorContainer')">
                      <b-iconstack >
                        <b-icon-camera-fill stacked variant="primary" />
                        <b-icon-star-fill stacked animation="throb" variant="light" v-show="renderingScreenshot" />
                      </b-iconstack>    
                    </b-button>

                    <base-viewer  class="my-1 mx-1"
                      v-if="showOverview"
                      :fieldConfig="mergedConfiguration"
                      :dataValues="dataValues"
                    />

                    <mail-sender v-if="!!value.iri" :items="[value.iri]" />
                      <!--  
                        @slot Header icons or buttons to add to the action bar
                        @binding {object} dataValues all current values of the editors fields
                        @binding {object} owner the current owner iri
                        @binding {object|null} creationMode if set the item is being created
                        @binding {Boolean} editMode if true the item is checked out or otherwise editable
                        -->
                        <slot name="header" 
                          v-bind:value="dataValues" 
                          v-bind:owner="owner"    
                          v-bind:creationMode="creationMode"       
                          v-bind:editMode="editMode"></slot>

                
                  </b-col>
                  <b-col cols="8">
                    <div class="d-flex flex-nowrap align-items-center justify-content-end">
                      <base-editor-field
                                v-for="(field, idx) in headerFields"
                                :key="$id(`baseEditor_${idx}_${field.name}`)"
                                :id="field.name"
                                v-model="dataValues[field.name]"
                                :editMode="editMode"
                                :creationMode="creationMode"
                                :field="field"                    
                                :immutable="field.immutable"
                                class="mb-0 mx-2"
                                @checkout="forceCheckout()"
                                :noLabel="field.nolabel || false"
                              ></base-editor-field>
                    </div>
                  </b-col>
                </b-row>
                </b-container>

            </b-col>
            
          </b-row>

          
        </b-container>
      </b-col>
        <span  class="position-absolute" style="right: 5px; top: 0px;font-size: 24px;" >
          <b-icon-x v-if="modal" button @click="$root.$emit('bv::hide::modal',modal)"/>
        </span>
    </b-row>
    <!-- TITLE ROW -->
    <b-row class="editor-bg">
      <b-col :cols="!!$slots['sidebar'] ? (12 - sideBarWidth) : 9">
          <div class="editor--title d-flex align-items-baseline">
            <!--  
              @slot TitlePrepend allow elements to be placed before the title box. These are added into a flexbox container 
              @binding {object} dataValues all current values of the editors fields
              @binding {object} owner the current owner iri
              @binding {object|null} creationMode if set the item is being created
              @binding {Boolean} editMode if true the item is checked out or otherwise editable
            -->
          <slot name="title-prepend" 
            v-bind:value="dataValues" 
            v-bind:owner="owner"       
            v-bind:defaultConfig="defaultConfig"            
            v-bind:editMode="editMode"></slot>
          <base-editor-field
              v-for="(field, idx) in titleFields"
              :key="$id(`baseEditor_${idx}_${field.name}`)"
              :id="field.name"
              v-model="dataValues[field.name]"
              :editMode="editMode"
              :creationMode="creationMode"
              :field="field"
              :immutable="field.immutable"
              noLabel
              class="flex-grow-1"
              @checkout="forceCheckout()"
            ></base-editor-field>
            <!--  
                  @slot Title this is appended to the title fields. This is inside a flexbox
                  @binding {object} dataValues all current values of the editors fields
                  @binding {object} owner the current owner iri
                  @binding {object|null} defaultConfig the configuration of all fields
                  @binding {Boolean} editMode if true the item is checked out or otherwise editable
                  -->
          <slot name="title" 
            v-bind:value="dataValues" 
            v-bind:owner="owner"       
            v-bind:defaultConfig="defaultConfig"            
            v-bind:editMode="editMode"></slot>
        </div>
      </b-col>
      <b-col :cols="!!$slots['sidebar'] ? sideBarWidth : 3">        
      </b-col>
    </b-row>
    <b-row class="editor-bg">
      <!-- MAINBODY -->
      <b-col :cols="!hideSidebar && (!!$slots['sidebar'] || sidebarGroups.length > 0)  ? (12 - sideBarWidth) : 12">
        <b-form @submit.prevent class="p-0">
          <!--  
            @slot Mainprepend slot before the groups in the main window are added
            @binding {object} dataValues all current values of the editors fields
            @binding {object} value deprecated. Use dataValues instead
            @binding {object} owner the current owner iri
            @binding {object|null} creationMode if set the item is being created
            @binding {Boolean} editMode if true the item is checked out or otherwise editable
            @binding {Function} changeValue this function should be used to propagate data to the parent.
                                    changeValue(fieldName, newValue, index (if field is an array))

          -->      
          <slot name="mainprepend"
            v-bind:editMode="editMode"
            v-bind:creationMode="creationMode"
            v-bind:value="dataValues"
            v-bind:dataValues="dataValues"
            v-bind:changeValue="remoteSet" 
            v-bind:owner="owner"       >
            </slot>
          <template v-if="mainGroups.length > 0">
            <collapse-box
              v-for="(groupedFields) in mainGroups"
              :key="$id(groupedFields.name)"
              :id="$id('collapse-'+groupedFields.name)"
              :accordion="groupedFields.accordion"
              :class="groupedFields.class || ''"
              :hidden="evaluateLocally(groupedFields.collapsed)"
              :meetingConfiguration="groupedFields.meetingConfiguration || {}"
              :variant="groupedFields.variant || 'light'"
              :title="(groupedFields.label && groupedFields.label[$i18n.locale]) || 'Default'"
              :bodyClass="{...groupedFields.boxClass }"
            >
              <!--  
              @slot [group]-prepend-nolayout slot inside of the [group], but before the layout container
              @binding {object} dataValues all current values of the editors fields
              @binding {object} value deprecated. Use dataValues instead
              @binding {object} owner the current owner iri
              @binding {object|null} creationMode if set the item is being created
              @binding {Boolean} editMode if true the item is checked out or otherwise editable
              @binding {Function} changeValue this function should be used to propagate data to the parent.
                                      changeValue(fieldName, newValue, index (if field is an array))

            -->   
              <slot :name="groupedFields.name+'-prepend-nolayout'"
                    v-bind:editMode="editMode"
                    v-bind:creationMode="creationMode"
                    v-bind:value="dataValues"
                    v-bind:dataValues="dataValues"
                    v-bind:changeValue="remoteSet" 
                    v-bind:owner="owner"       
                    ></slot>
                <div 
                  :class="{'grid-display' : groupedFields.layout === 'grid', ['grid-columns-'+(groupedFields.grid || 5)] : true }"
                >
                <!--  
                  @slot [group]-prepend slot inside of the [group] inside the layout container. any defined layout will affect this container
                  @binding {object} dataValues all current values of the editors fields
                  @binding {object} value deprecated. Use dataValues instead
                  @binding {object} owner the current owner iri
                  @binding {object|null} creationMode if set the item is being created
                  @binding {Boolean} editMode if true the item is checked out or otherwise editable
                  @binding {Function} changeValue this function should be used to propagate data to the parent.
                                          changeValue(fieldName, newValue, index (if field is an array))

                -->  
                  <slot :name="groupedFields.name+'-prepend'"
                    v-bind:editMode="editMode"
                    v-bind:creationMode="creationMode"
                    v-bind:value="dataValues"
                    v-bind:dataValues="dataValues"
                    v-bind:changeValue="remoteSet" 
                    v-bind:owner="owner"       
                    ></slot>
                  <base-editor-field
                        v-for="(field, idx) in groupedFields.fields"
                        :key="$id(`baseEditor_${idx}_${field.name}`)"
                        :id="field.name"
                        v-model="dataValues[field.name]"
                        :editMode="editMode"
                        :creationMode="creationMode"
                        :field="field"                    
                        :immutable="field.immutable"
                        :class="{'mb-0' : groupedFields.layout === 'grid' }"
                        @checkout="forceCheckout()"
                        :noLabel="field.nolabel || false"
                      ></base-editor-field>
                  <!--  
                    @slot [group]-append slot inside of the [group], inside the layout container after all fields have been displayed
                    @binding {object} dataValues all current values of the editors fields
                    @binding {object} value deprecated. Use dataValues instead
                    @binding {object} owner the current owner iri
                    @binding {object|null} creationMode if set the item is being created
                    @binding {Boolean} editMode if true the item is checked out or otherwise editable
                    @binding {Function} changeValue this function should be used to propagate data to the parent.
                                            changeValue(fieldName, newValue, index (if field is an array))

                  -->      
                  <slot :name="groupedFields.name+'-append'"
                    v-bind:editMode="editMode"
                    v-bind:creationMode="creationMode"
                    v-bind:value="dataValues"
                    v-bind:dataValues="dataValues"
                    v-bind:changeValue="remoteSet"
                    v-bind:owner="owner" >
                    </slot>
                </div>

            <!--  
              @slot [group]-append-nolayout slot inside of the [group], but after the layout container
              @binding {object} dataValues all current values of the editors fields
              @binding {object} value deprecated. Use dataValues instead
              @binding {object} owner the current owner iri
              @binding {object|null} creationMode if set the item is being created
              @binding {Boolean} editMode if true the item is checked out or otherwise editable
              @binding {Function} changeValue this function should be used to propagate data to the parent.
                                      changeValue(fieldName, newValue, index (if field is an array))

            -->  
                <slot :name="groupedFields.name+'-append-nolayout'"
                v-bind:editMode="editMode"
                v-bind:creationMode="creationMode"
                v-bind:value="dataValues"
                v-bind:dataValues="dataValues"
                v-bind:changeValue="remoteSet"
                v-bind:owner="owner" >
                </slot>
            </collapse-box>
          </template>       
          <!--  
              @slot mainappend slot in the main window, but after all groups in the mainwindow have been rendered
              @binding {object} dataValues all current values of the editors fields
              @binding {object} value deprecated. Use dataValues instead
              @binding {object} owner the current owner iri
              @binding {object|null} creationMode if set the item is being created
              @binding {Boolean} editMode if true the item is checked out or otherwise editable
              @binding {Function} changeValue this function should be used to propagate data to the parent.
                                      changeValue(fieldName, newValue, index (if field is an array))

            -->     
          <slot name="mainappend"
            v-bind:editMode="editMode"
            v-bind:creationMode="creationMode"
            v-bind:value="dataValues"
            v-bind:dataValues="dataValues"
            v-bind:changeValue="remoteSet"
            v-bind:owner="owner">
            </slot>
        </b-form>
            
        <div class="separator position-absolute" 
            :style="'right: '+(hideSidebar ? 0 : -5) +'px;top: 0;bottom:0;z-index: 1;'"
              v-if="!!$slots['sidebar'] || sidebarGroups.length > 0"
            @click="hideSidebar = !hideSidebar"
          >
          <div class="m-0 p-0 text-dark position-sticky editor-bg rounded-circle" style="top: 50%;">
            <b-icon variant="primary" :icon=" hideSidebar ? 'chevron-double-left' : 'chevron-double-right'" />
          </div>
        </div>

      </b-col>
      <!-- SIDEBAR -->
      <b-col 
        class="border-left border-primary"
        v-show="!hideSidebar" :cols="!hideSidebar && (!!$slots['sidebar'] || sidebarGroups.length > 0) ? sideBarWidth : 0">    
        <template v-if="sidebarGroups.length > 0 || $slots[groupedFields.name+'-prepend'] || $slots[groupedFields.name+'-append']">
            <!--  
              @slot sidebar-top before the first group of the sidebar
              @binding {object} dataValues all current values of the editors fields
              @binding {object} owner the current owner iri
              @binding {object|null} creationMode if set the item is being created
              @binding {Boolean} editMode if true the item is checked out or otherwise editable
              @binding {Function} changeValue this function should be used to propagate data to the parent.
                                      changeValue(fieldName, newValue, index (if field is an array))

            -->  
          <slot 
            name="sidebar-top" 
            v-bind:dataValues="dataValues"  
            v-bind:editMode="editMode" 
            v-bind:creationMode="creationMode"
            v-bind:owner="owner"
            v-bind:changeValue="remoteSet" >
          </slot>

          <collapse-box
            :bodyClass="{...groupedFields.boxClass ,'grid-display' : groupedFields.layout === 'grid', ['grid-columns-'+(groupedFields.grid || 5)] : true }"
            v-for="(groupedFields) in sidebarGroups.filter(sG => !sG.footer)"
            :key="$id(groupedFields.name)"
            :id="$id('collapse-'+groupedFields.name)"
            :accordion="groupedFields.accordion"
            :meetingConfiguration="groupedFields.meetingConfiguration || {}"
            :variant="groupedFields.variant || 'light'"
            :title="(groupedFields.label && groupedFields.label[$i18n.locale]) || 'Default'"
            :hidden="evaluateLocally(groupedFields.collapsed)"
          > 
              <slot :name="groupedFields.name+'-prepend'"
                v-bind:editMode="editMode"
                v-bind:creationMode="creationMode"
                v-bind:value="dataValues"
                v-bind:dataValues="dataValues"
                v-bind:changeValue="remoteSet"
                v-bind:owner="owner" ></slot>

            <base-editor-field
                  v-for="(field, idx) in groupedFields.fields"
                  :key="$id(`baseEditor_${idx}_${field.name}`)"
                  :id="field.name"
                  v-model="dataValues[field.name]"
                  :editMode="editMode"
                  :creationMode="creationMode"
                  :field="field"                  
                  :immutable="field.immutable"
                  :noLabel="field.nolabel || false"
                  
                  @checkout="forceCheckout()"
                ></base-editor-field> 
              <slot :name="groupedFields.name+'-append'"
                v-bind:editMode="editMode"
                v-bind:creationMode="creationMode"
                v-bind:value="dataValues"
                v-bind:dataValues="dataValues"
                v-bind:changeValue="remoteSet"
                v-bind:owner="owner" ></slot>

          </collapse-box>
        </template>

          <!--  
              @slot sidebar renders after all other groups have been rendered
              @binding {object} dataValues all current values of the editors fields
              @binding {object} owner the current owner iri
              @binding {object|null} creationMode if set the item is being created
              @binding {Boolean} editMode if true the item is checked out or otherwise editable
              @binding {Function} changeValue this function should be used to propagate data to the parent.
                                      changeValue(fieldName, newValue, index (if field is an array))

            -->  
          <slot name="sidebar" 
            v-bind:dataValues="dataValues" 
            v-bind:editMode="editMode" 
            v-bind:creationMode="creationMode"
            v-bind:changeValue="remoteSet" 
            v-bind:owner="owner"></slot>

        <collapse-box
            :bodyClass="{...groupedFields.boxClass ,'grid-display' : groupedFields.layout === 'grid', ['grid-columns-'+(groupedFields.grid || 5)] : true }"
            v-for="(groupedFields) in sidebarGroups.filter(sG => !!sG.footer)"
            :key="$id(groupedFields.name)"
            :id="$id('collapse-'+groupedFields.name)"
            :meetingConfiguration="groupedFields.meetingConfiguration || {}"
            :accordion="groupedFields.accordion"
            :variant="groupedFields.variant || 'light'"
            :title="(groupedFields.label && groupedFields.label[$i18n.locale]) || 'Default'"
            :hidden="evaluateLocally(groupedFields.collapsed)"
          >
              <slot :name="groupedFields.name+'-prepend'"
                v-bind:editMode="editMode"
                v-bind:creationMode="creationMode"
                v-bind:value="dataValues"
                v-bind:dataValues="dataValues"
                v-bind:changeValue="remoteSet"
                v-bind:owner="owner" ></slot>

            <base-editor-field
                  v-for="(field, idx) in groupedFields.fields"
                  :key="$id(`baseEditor_${idx}_${field.name}`)"
                  :id="field.name"
                  v-model="dataValues[field.name]"
                  :editMode="editMode"
                  :creationMode="creationMode"
                  :field="field"                  
                  :immutable="field.immutable"
                  :noLabel="field.nolabel || false"
                  
                  @checkout="forceCheckout()"
                ></base-editor-field>
              
              <slot :name="groupedFields.name+'-append'"
                v-bind:editMode="editMode"
                v-bind:creationMode="creationMode"
                v-bind:value="dataValues"
                v-bind:dataValues="dataValues"
                v-bind:changeValue="remoteSet"
                v-bind:owner="owner" ></slot>

          </collapse-box>
      </b-col>
    </b-row>

    </slot>

    </component>
    <div 
      class="position-absolute bg-secondary"
      style="top:0; left:0; bottom: 0; right: 0; opacity: 0.7;"
      v-show="fileDropBoxId !== '' && isHovered"
      @dragleave="isHovered = false"      
      @dragend="dragSafe($event); isHovered = false" 
      @dragover="dragSafe($event)"      
      @drop="handleDrop($event)" 
    >
      <div class="position-absolute" style="left: 50%; top: 50%; transform: translate(-50%,-50%);">
          <h1 class="m-0 p-0"> <b-icon-plus /> </h1>
      </div>
    </div>

  </component>
</template>

<style lang="scss">
  .editor--title {
    font-weight: bold;
    font-size: 1.5em;
  }

  .minh-100 {
    min-height: 100%;
  }

  .baseditor-maxwidth {
    max-width: 90% !important;
  }

  .grid-display {
    display: grid;
    grid-auto-columns: 200px;
    grid-auto-flow: row;
    grid-auto-rows: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-gap: 2px 30px;

    & .col-form-label span {
      text-align: right;
    }

    .gridspan {
        grid-column-start: 1;
        grid-column-end: -1;
    }


    @for $i from 1 through 8 {
      &.grid-fieldspan-#{$i} { 
        grid-column: span #{$i};
      }
    }


    @for $i from 4 through 8 {
      &.grid-columns-#{$i} { 
        grid-template-columns: repeat($i,1fr);
      }
    }

    &.grid-columns-1 {
      grid-template-columns: repeat(1,99%);
    }

    &.grid-columns-2 {
      grid-template-columns: repeat(2,49%);
    }

    &.grid-columns-3 {
      grid-template-columns: repeat(3,32%);
    }
}
</style>

<script>
import { ownerManager } from "@/helper/OwnerManager";
import moment from 'moment'

import MailSender from '@/components/BaseMailer/MailSender'

/** 
 * @displayName BaseEditor 
*/
export default {
  name: "baseEditor",
  components: {
    MailSender
  },
  props: {
    /**
     * Whether the editor should disable all fields
     */
    readonly: { type: Boolean, default: false },

    /**
     * The value object in the form [key] : value.
     * The field content is interpreted depending on the 
     * default config prop
     */
    value: Object,
    /**
     * An array containing all the configuration for each field to render
     * Each field can have the following properties relevant for the editor:
     * 
     * name: {String} The fields internal name. This is used to fetch the data from the value object
     * group: {String} The internal name for the group this item should be assigned to
     * validator: {Function(val) : Bool} A function to check whether a valid input was given. If this returns false, the element cannot be saved or created
     * hidden: {true|false} Hidden fields are removed from the set of fields and are never rendered
     * tableOnly: {true|false} tableOnly fields are removed before rendering just as hidden fields
     * 
     * This property is cloned upon creation and not responsive to changes to the field config
     * 
     * Other properties such as type etc. are required by the {@link BaseEditorField}
     * 
     * @see BaseEditorField
     */
    defaultConfig: Array,

    dialogClass: {
      type: String,
      default: "baseditor-maxwidth h-100"
    },

    /**
     * The ownerIRI of the element
     * 
     * This is used to find owner specific fields and access restrictions based on the owner configuration
     * 
     * Check the owner module documentation for more information
     */
    owner: String,

    /**
     * was used to set the iri 
     * 
     * @deprecated
     */
    iri: String,

    /**
     * an object defining the origin. This object is required to contain the following fields
     * module: The origin module
     * widget: The widget name
     */
    origin: Object,
    /**
     * if the editor is opened for a new element, creationMode needs to be set and contain the following data:
     * owner: The IRI of the owner to which the element will be assigned
     * targetIRI: the  collection or iri which should be used when creating the element
     */
    creationMode: null,

    /**
     * this toggles the display of the overview button
     */
    showOverview: { type: Boolean, default: false},

    /**
     * the width of the sidebar. Can be adjusted if required, but should be kept at the default value
     */
    sideBarWidth: { type: Number, default: 3 },
    
    /**
     * setting modal converts the editor into a modal window with the id in the property. 
     * It then listens to all events as defined in the b-modal property
     */
    modal: { type: [String, Boolean], default: false },

    /**
     * an array defining the groups which should be created by the editor.
     * Each field is assigned a group, if no group is given it is send to the "default" group
     * A group definition requires the following properties:
     * 
     * name {String}: internal name as used in the fields
     * label {Object}: An object containing the translation strings under the corresponsing locale
     * sidebar {Boolean}: Whether the  group is placed in the sidebar block
     * footer {Boolean}: Whether the group is placed in the footer block. The footer block is always in the sidebar
     * title {Boolean}: Whether the group is placed in the title block
     * collapsed {Function}: a function that returns a boolean. If true, the group starts collapsed
     */
    groups: { type: null, default: () => [] },

    fileDropBoxId: { type: String, default: '' },

    modalSize: { type: String, default: "xl" }
  },
  data() {
    return {
      acquiringLock: false,
      hideSidebar: false,
      lockAcquisitionStatus: 0,
      lockAcquired: 0, //0: not tried. 1 success. -1 failed
      editMode: false,
      createView: false,
      dataValues: null,
      defaultClone: this.defaultConfig,
      confirmCloseProp: true,
      renderingScreenshot: false,
      isHovered: false,
      renewLockTimer: null,
    };
  },
  provide() { return {
    "getOwnerIri": this.ownerIri,
    "getDataSnapshot" : () => this.dataSnapshot
    
  }},
  inject: {
    forceUpdate: "forceUpdate",
    storeData: {name: "storeData", default: false },
    createData: {name: "createData", default: false },
    sendToSelector: { name: "sendToSelector", default: false }
  },
  watch: {
    owner: {
      immediate: true,
      handler: function() {
        this.updateOwner();
      }
    },
    value: {
      immediate: true,
      handler: function() {
        this.updateValue();
      }
    }
  },
  computed: {
    dataSnapshot: function(vm) {
      return JSON.parse(JSON.stringify(this.dataValues))
    },
    ownerObject: function(vm) {
      
      return vm.owner && vm.owner !== "" && vm.$store.getters['owner/getItems'] && vm.$store.getters['owner/getItems'][vm.owner] || null
    },
    registry: vm => vm.$store.getters.getRegistry,
    mergedConfiguration: vm => {
      let owner = vm.ownerObject;

      //support elements without an owner
      if(!owner && !!vm.defaultConfig) {
        return _.clone(vm.defaultConfig);
      }

      if (!owner || !vm.defaultConfig) {
        return [];
      }
      let moduleName = vm.origin.ownerModuleName ?? vm.origin.module;
      let baseConfig = _.clone(vm.defaultConfig);
      let moduleSettings =
        vm.ownerObject &&
        vm.ownerObject.modules &&
        vm.ownerObject.modules.find(elem => elem.name === moduleName);
      if (moduleSettings && moduleSettings.addedFields) {
        //ignore added fields with subitem property set
        moduleSettings.addedFields.filter(field => !field.subitem || field.subitem == "").forEach(fieldObj => {
          let field = {isAddedField: true, optional: true, ...fieldObj.config, ...fieldObj}
          if (!!field.order) {
            baseConfig.splice(field.order, 0, field);
          } else {
            baseConfig.push(field);
          }
        });
      }

      if (moduleSettings && moduleSettings.overwrites) {
        moduleSettings.overwrites.forEach(field => {
          let index = baseConfig.find(elem => (elem.name = field.name));
          if (index) {
            baseConfig.splice(index, 1, field);
          } else {
            baseConfig.push(field);
          }
        });
      }
      return baseConfig;
    },

    accessRightsAdjustedFields: vm => {
      let fieldOutput = vm.mergedConfiguration.filter(field => !(field.tableOnly || field.hidden || false));
      //support elements without an owner
      /*if(!vm.owner && !vm.ownerObject) {
        return fieldOutput
      }*/

      let moduleName = vm.origin.module;
      
      let redactedFields = (vm.value && vm.value["__REDACTED__"]) || [];



      //fill config with values
      fieldOutput.forEach(field => {
        field.canEdit = true;
        field.value = vm.dataValues[field.name] || null;

        if (redactedFields.indexOf(field.name) !== -1) {
          field.redacted = true;
          field.canEdit = false;
        }
        if(field.immutable) {
          field.canEdit = false
        }
      });
      return fieldOutput;
    },
    fieldByName: vm => {
      return Object.fromEntries(vm.accessRightsAdjustedFields.map(f => [f.name, f]))      
    },
    groupedFields: vm => {
      let allGroups = [...vm.groups, { name: "default", fields: [] }];
      allGroups.forEach(e => (e.fields = []));
      vm.accessRightsAdjustedFields.forEach(field => {
        if(field.titlebar) return;
        if (field.group) {
          allGroups.find(e => e.name === field.group).fields.push(field);
        } else {
          allGroups.find(e => e.name === "default").fields.push(field);
        }
      });
      return allGroups;
    },
    allNonEmptyGroups: vm => vm.groupedFields.filter(item => item.fields.length > 0),
    sidebarGroups: function(vm) {
      return vm.allNonEmptyGroups.filter(x => !!x.sidebar);
    },
    mainGroups: function(vm) {
      return vm.allNonEmptyGroups.filter(x => !x.sidebar && !x.title && !x.header);
    },
    headerFields: function(vm) {
      return [].concat(...vm.allNonEmptyGroups.filter(x => !!x.header).map(x => x.fields))
    },
    titleFields: function(vm) {
      return vm.allNonEmptyGroups.filter(x => !!x.title ).map(x => x.fields[0]);
    },
    titleBarField: function(vm) {
      return vm.accessRightsAdjustedFields.find(f => f.titlebar === true)
    }
  },
    beforeDestroy: function() {
      if(this.renewLockTimer !== null) {
        clearInterval(this.renewLockTimer)
      }
    },
    mounted() {
      this.$root.$on('bv::modal::show', this.resetOnOpen.bind(this))
      this.$root.$on('bts::root::fileDropped',function() {
        this.isHovered = false
      }.bind(this));

      if(this.creationMode) {
        this.$hotkey.register(this.$id('baseEditorHandle'),'alt+Enter',this.runHotkey)
      }
    },
  methods: {    
    dragSafe(ev) {
      if(ev.stopPropagation) {
          ev.stopPropagation()
      }
      ev.preventDefault()
     },
    handleDrop(ev) {
      if(ev.stopPropagation) {
          ev.stopPropagation()
      }
      ev.preventDefault()
      this.isHovered = false
      if(this.fileDropBoxId == '') {
        return
      }
      console.log({'event': ev, 'boxId': this.fileDropBoxId })
      this.$root.$emit('bts::root::fileDrop',{'event': ev, 'boxId': this.fileDropBoxId })
    },
    evaluateLocally: function(handler) {
      if(typeof handler == "function") {
        return handler(this.dataValues)
      } else {
        return false
      }
    },
    renderScreenshot: async function(ref) {
      this.renderingScreenshot = true
      await this.$screenshot.render(this.$refs[ref])
      this.renderingScreenshot = false
    },
    ownerIri() {
      return this.owner
    },
    resetOnOpen(bvEvent, modalId) {
        if(this.modal && modalId == this.modal && this.creationMode) {
            this.dataValues = Object.assign({}, this.value)
            this.confirmCloseProp = true
        }
    },
    updateOwner() {
      if (!this.owner || this.owner === "") {
        return;
      }
      this.dataValues = this.value


    },
    remoteSet(name, val, idx = null) {
      if(idx !== null) {
        if(!this.dataValues[name]) {
          this.$set(this.dataValues,name,Number.isInteger(idx) ? [] : {})
        }
        this.$set(this.dataValues[name],idx,val)

      } else {
        //set a specific field
        this.$set(this.dataValues,name,val)
      }
    },
    updateValue(val = null) {
      if (this.lockAcquired || this.creationMode) {
        if(!this.dataValues) {
          this.dataValues = this.value
          //this.$set(this, "dataValues", JSON.parse(JSON.stringify(this.value || {})));
        }
        return;
      }
      if(!val) {
        this.dataValues = this.value
        //this.$set(this, "dataValues", JSON.parse(JSON.stringify(this.value || {})));
      } else {
        this.dataValues = val
        //this.$set(this, "dataValues", val);
      }
    },
    toggleEditMode: async function(ev) {
      if (!this.editMode) {
        await this.acquireLock();
        this.confirmCloseProp = true
      } else {
        if(await this.saveChanges()) {
          this.releaseLock();
        }
      }
      //acquire lock from owning instance
      //if lock is granted switch edit mode
      //else notify about unavailable lock
    },
    beforeRouteLeave(to,from,next) {
      this.confirmClose().then(next)
    },
    preventExitEdit: async function(ev) {
      let choice = await this.$bvModal.msgBoxConfirm(this.$t('exit.save'), {
        title: this.$t('confirm'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.$t('yes'),
        cancelTitle: this.$t('no'),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      })  
      if(choice === true) {
          if(await this.saveChanges()) {
            this.releaseLock();
            this.editMode = false;
            return true
          }
        } else if(choice === false) {
          //reset data values
          await this.releaseLock();
          this.updateValue();
          return true
        } else {
          //backdrop or close -> do nothing
          return false
        }
    },
    preventExitCreate: async function(ev) {
      let choice = await this.$bvModal.msgBoxConfirm(this.$t('exit.without.saving'), {
        title: this.$t('confirm'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.$t('no'),
        cancelTitle: this.$t('yes'),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      })
      if (choice === true) {
        return false
      } else {
        return true 
      }  
    },
    confirmClose: function(ev,forceClose = null) {
      if(forceClose === true) {
        this.confirmCloseProp = false
        this.$bvModal.hide(this.modal);
        this.$emit('hidden',ev)
        return
      }
      if (this.confirmCloseProp && (this.editMode || this.creationMode)) {
        if(ev) { ev.preventDefault(); this.confirmCloseProp = false }   
        
        if(this.creationMode) {
          this.preventExitCreate(ev).then(res => { 
            if(res) {
              this.$bvModal.hide(this.modal);
              this.$emit('hidden',ev)
            } else {
              this.confirmCloseProp = true
            }})
        } else if(this.editMode) {
          this.preventExitEdit(ev).then(res => { 
            if(res === true) {
              this.$bvModal.hide(this.modal);
              this.$emit('hidden',ev)
            } else {
              //reset confirmCloseProp to be required again
              this.confirmCloseProp = true
            }
          })
        }
      }
    },
    lockNavigation: async function() {
      await this.confirmClose()
      return true
    },
    forceCheckout: async function() {
      if(this.editMode || this.creationMode) return
      this.acquireLock()
    },
    acquireLock: async function() {
      this.acquiringLock = true;
      this.lockAcquisitionStatus = 0;
      try {
        let serverUrl = await this.registry.getURLForModuleByName(
          this.origin.module
        );
        let response = await this.$cache.post(this.origin.module, '/lock',{ resource: this.dataValues.iri }, true)
        if (response.status === 200) {
          this.lockAcquisitionStatus = 50;
          //fetch data source again to prevent accidental collision
          //this is currently obligatory until versions of items can be timestamped
          /* let actualServerData = await this.$cache.cached(this.origin.module,this.iri,false, true)
                    this.$set(this, "dataValues", actualServerData) */
          await this.forceUpdate();
          this.acquiringLock = false;
          this.editMode = true;
          this.lockAcquired = 1;
          this.lockAcquisitionStatus = 100;
          this.$store.dispatch('addNavigationHook',this.lockNavigation)

          this.renewLockTimer = setInterval(this.renewLock.bind(this),20*1000)
        }
      } catch (err) {
        this.editMode = false;
        this.lockAcquired = -1;
        this.acquiringLock = false;
        this.$bvToast.toast(this.$t('lockaquisition.failed.text'), { variant: "danger" , title: this.$t('lockaquisition.failed.title')});
      }
    },
    renewLock: async function() {
      try {
        let response = await this.$cache.post(this.origin.module, '/lock',{ resource: this.dataValues.iri }, true)
      } catch (err) {
        this.$bvToast.toast(this.$t('lockrenewal.failed.text'), { variant: "danger" , title: this.$t('lockrenewal.failed.title')});
      }
    },

    runHotkey: function() {
      if(this.creationMode) {
        this.createElement()
      } else if(this.editMode) {
        this.saveChanges()
      } else {
        this.forceCheckout()
      }
    },

    saveChanges: async function() {
      try {
        this.acquiringLock = true;
        const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        //force a pause so all lazy values are included
        await wait(600)

      //validate
      if(!this.validateData()) {
        return false
      }

        //store data to the original instance
        this.lockAcquisitionStatus = 99;
        let saveResponse = 500; 
        this.$root.$emit('bts::editor::saving',this.dataValues.iri)       
        if (!this.storeData) {
          let saveData = await this.$cache.update(this.origin.module,this.dataValues.iri,this.dataValues,true)
          saveResponse = saveData.status;
        } else {
          saveResponse = await this.storeData(this.dataValues, this.accessRightsAdjustedFields);
        }
        if (saveResponse === 200) {
          this.$bvToast.toast(this.$t('saving.success.message'),{variant: 'success', title: this.$t('saving.success.title')});
          this.lockAcquisitionStatus = 100;
          this.$root.$emit('bts::editor::saving::success',this.dataValues.iri)  
          
        }
        return true
      } catch (err) {
        switch(err.message) {
          case "409":
            this.$bvToast.toast(this.$t('toast.message.locked'), {variant: "danger", title: this.$t('toast.title.failed')})
            break
          case "400":
            this.$bvToast.toast(this.$t('toast.message.badrequest'), {variant: "danger", title: this.$t('toast.title.failed')})
            break
          default:
            this.$bvToast.toast(this.$t('toast.message.unknownfailed'), {variant: "danger", title: this.$t('toast.title.failed')})
            break
        }
        this.$root.$emit('bts::editor::saving::failed',this.dataValues.iri)  
        
      } finally {
          this.acquiringLock = false;
      }
    },
    releaseLock: async function() {
      try {
        if(this.renewLockTimer !== null) {
          clearInterval(this.renewLockTimer)
        }
        this.lockAcquisitionStatus = 49;
        let response = await this.$cache.post(this.origin.module, "/unlock",{ resource: this.dataValues.iri },true)
        if (response.status === 200) {
          this.editMode = false;
          this.lockAcquired = 0;
          this.lockAcquisitionStatus = 0;
          this.acquiringLock = false;
          this.$store.dispatch('removeNavigationHook',this.lockNavigation)
        }
      } catch (err) {
        this.editMode = true;
        this.lockAcquired = 1;
        this.acquiringLock = false;
        this.lockAcquisitionStatus = 100;
        this.$bvToast.toast("Lock release failed", { variant: "danger" });
      }
    },

    validateData: function() {
      let invalidFields = []
      this.accessRightsAdjustedFields.forEach(field => {
        let isValid = false
        if(field.optional && !field.hasOwnProperty('validator') || field.canEdit === false || field.immutable === true) {
          //optional fields without validator are not required
          isValid = true

            //if this field would cause a problem, there is a configuration mistake
            return
          
        }
        
        if(!field.optional && !field.validator) {
          //if no validator is defined but field is required check if field is set
          isValid = this.dataValues.hasOwnProperty(field.name)
        }

        if(!!field.validator) {
          //run field through its validator
          isValid = field.validator(this.dataValues[field.name])
        }

        if(!isValid) {
          invalidFields.push(field)
        }
      })

      //create a toast if at least one field is invalid
      if(invalidFields.length > 0) {
        let h = this.$createElement
        let nodes = [
            h('div',this.$t('invalidFields')),
            ...invalidFields.map(field => h('div',field.label[this.$root.$i18n.locale]))]



        this.$root.$bvToast.toast(nodes, {
          variant: "danger",
          title: this.$t('invalidFieldsTitle')
        })
      }

      return invalidFields.length == 0
    },

    createElement: async function() {
      this.acquiringLock = true;
      try {
      let createStatus = 500;
      let createBody = { ...this.dataValues, owner: this.creationMode.owner };
      let response;

      //validate
      if(!this.validateData()) {
        return 
      }

      if (!!this.createData) {
        createStatus = await this.createData(createBody);
      } else {
        //use default basic create setup
        response = await this.$cache.create(
          this.origin.module,
          this.creationMode.targetIRI,
          createBody
        );
        createStatus = 201;
      }

      if (createStatus === 201) {
        this.$bvToast.toast(this.$t('creation.success.msg'),{variant: 'success', title: this.$t('creation.success.title')});
        //reset data to default values if given
        this.$set(this, "dataValues", this.value || {});

        //if item is from a selector, push it as selected item
        if (!!this.sendToSelector && !!response) {
          this.sendToSelector(this.origin.module, response.iri);
        }


        if (!!this.modal) {
          this.$nextTick(function() {
            this.confirmClose(null,true)
          })
          
        }
      }
      } catch(e) {

      } finally {
        this.acquiringLock = false
      }
    }
  }
};
</script>
