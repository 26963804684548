import Vue from 'vue'
import Vuex from 'vuex'
import { Registry } from '@/helper/Registry'
import { libraryManager } from '@/helper/LibraryManager'
import { routes, groups } from "@/assets/Routes.js";

import localforage from 'localforage'

const moment = require('moment')
const config = require('@/configuration.json');

Vue.use(Vuex)

var store =  new Vuex.Store({
  state: {
    serverRegistryPromise: Registry,
    registeredIRIs: [],
    viewGridLayout: [],
    redirectPath: null,
    jwtToken: null,
    refreshToken: null,
    authValidUntil: null,
    currentUser: null,
    currentUserRoles: [],
    lastSelectedItem: null,
    ownerContexts: [],
    availableOwners: {},
    editorContext: null,
    validAuth: false,
    navigationState: false,
    breadcrumbs: [],
    statusModes: [],
    meetingModes: [],
    navigationHooks: [],
    loadingScreen: true,
    loadingModules: 0,
    loadedModules: 0,
    loadAllDataTriggered: false,
    environment: {}
  },
  mutations: {
	  //updateRegistration: (state, payload) => state.serverRegistryFile = payload,
    changeGridLayout: (state, payload) => state.viewGridLayout = payload,
    setRedirect: (state, payload) => state.redirectPath = payload,
    storeAuthenticationToken(state, value) { state.jwtToken = value },
    storeRefreshToken(state, value) { state.refreshToken = value },
    authenticatedUntil(state,value) { state.authValidUntil = value },
    setLastSelectedItem(state, value) { state.lastSelectedItem = value },
    setAvailableOwners(state, value) { state.availableOwners = value },
    setOwnerContext(state, value) { Vue.set(state,'ownerContexts',value) },
    setRegisteredIRIs: (state, value) => state.registeredIRIs = value,
    setCurrentUser: (state, value) => state.currentUser = value,
    setCurrentUserRoles: (state, value) => state.currentUserRoles = value,
    setEditorContext: (state, value) => state.editorContext = value,
    setAuthenticationValid: (state, value) => state.validAuth = value,
    setNavigationState: (state, value) => state.navigationState = value,
    setBreadcrumbs: (state,value) => state.breadcrumbs = value,
    addNavigationHook: (state, value) => state.navigationHooks.push(value),
    removeNavigationHook: (state, idx) => state.navigationHooks.splice(idx,1),
    addMode: (state, value) => state.statusModes.push(value),
    setMeetingModes(state, value) { Vue.set(state,'meetingModes',value) },
    quitMode: (state, value) => {
      let idx = state.statusModes.indexOf(value)
      if(idx > -1) {
      state.statusModes.splice(idx,1)
      }
    },
    showLoadScreen: (state, value) => state.loadingScreen = value,
    setLoadingModules: (state, value) => state.loadingModules = value,
    setLoadedModules: (state, value) => state.loadedModules = value,
    loadAllDataTriggered: (state) => state.loadAllDataTriggered = true,
    setEnvironment: (state,val) => state.environment = val
  },
  actions: {
    changeTitle: function(context, value) {
      //allow more complex building of the document title
      document.title = value
    },
  forceReload: function(){
    libraryManager.reloadData()
  },
  loadEnvironment: async function(context) {
    let cl = await fetch(config.baseUrl + 'environment.json')
    context.commit('setEnvironment', await cl.json())
  },
    loadAllData: async function(context, restartLoading = false) {
      
      if(context.state.loadAllDataTriggered && !restartLoading) {
        return false
      }
      context.commit('setLoadedModules',0)
      context.commit('setLoadingModules',0)
      context.commit('loadAllDataTriggered')
      context.commit('showLoadScreen',true)

      let loadingLibs = Object.keys(Registry.getModules()).map(async modName => {
        try {
          await libraryManager.addLibrary(modName)   
          return true       
        } catch {
          return true
        } finally {
          context.commit('setLoadedModules',context.getters.loadedModules + 1)
        }
      })
      context.commit('setLoadingModules',loadingLibs.length)

      await Promise.allSettled(loadingLibs)
      context.commit('showLoadScreen',false)
    },
    logout: async function(context, onlyInvalidateSession = false) {
      context.commit('setNavigationState',true)
      context.commit('setAuthenticationValid', false)
      context.commit('authenticatedUntil', null)
      await context.dispatch('saveRefreshToken', null)
      await context.dispatch('saveToken', {token: null})
      context.commit('setNavigationState',false)
      if(!onlyInvalidateSession) {
        try {
        await window.globalVue.$cache.post('user','/logout',null)
        } catch { 
          /* silently fail */ 
        }        
        window.location.reload()
      }
    },
    addNavigationHook(context, hookFunc) {
      if(typeof hookFunc == "function") {
        context.commit('addNavigationHook', hookFunc)
      }
    },
    removeNavigationHook(context, hookFunc) {
      let idx = context.state.navigationHooks.findIndex((nh) => nh === hookFunc)
      if(idx > -1) {
        context.commit('removeNavigationHook',idx)
      }
    },
    setOfflineMode(context, payload) {
      if(payload) {
        context.commit('addMode','offline')
      } else {
        context.commit('quitMode','offline')
      }
    },
    updateAvailableOwners(context,payload) {
      localforage.getItem('__OWNER_CONTEXT__').then(res => {
        let parsed = JSON.parse(res)
        if(res && parsed) {
          //check if parsed owners are in the available owners context. Only add matching owners
          let newContext = parsed.filter(owner => !!payload[owner.iri])
          context.commit('setOwnerContext', newContext)
        }
      })
      context.commit('setAvailableOwners',payload)
    },
    setMeetingModes(context, modes){
      context.commit('setMeetingModes', modes )

    },
    setNavigationState(context, payload) {
      context.commit('setNavigationState', !!payload)
    },
    routerUpdateBreadcrumbs(context, iri = null) {
      let route = window.globalVue.$route
      let res = (route.meta.breadcrumbs || []).map(item => {
        let text = item && item.text && item.text[window.globalVue.$i18n.locale]
        if(item.text === "@iri") {
          let module = context.getters.getModuleFromIri(iri || route.path)
          if(module && module.module) {
            let e = context.getters.getItemFromIri(iri || route.path)
            text = window[module.module] && window[module.module].functions.Display.display(e) || ""
          }
        }
          return {'text': text, to: item.to}
      })
      context.commit('setBreadcrumbs',res)
    },
    getRedirect(context) {
      var current_redirect = context.state.redirectPath
      if(current_redirect !== null) {
        context.commit('setRedirect', null)
      } 
      return current_redirect;
    },
    saveToken: async(context, {token, forceReload }) => {
      
      document.cookie = "authorizationKey="+token
      context.commit('storeAuthenticationToken', token)
      
      try {
        await localforage.setItem('__JWT_TOKEN__', token)
      } catch( e ) { //catch errors like quota exceeded etc
      }
      
      await context.dispatch('loadTokenFromStorage', !!forceReload)
      return true
    },
    saveRefreshToken: async(context, token) => {
      document.cookie = "refreshKey="+token
      context.commit('storeRefreshToken', token)
      try {
        await localforage.setItem('__JWT_REFRESHTOKEN__', token)
      } catch( e ) { //catch errors like quota exceeded etc
      }
      
      return true
    },
    loadTokenFromStorage: async(context, forceReload = false) => {
      let refreshToken = await localforage.getItem('__JWT_REFRESHTOKEN__')
      if(refreshToken === null) {
        //check if a cookie is set
        let rfCookie = document.cookie.split('; ').find(row => row.startsWith('refreshKey='))
        if(!!rfCookie) {
          refreshToken = rfCookie.split('=')[1] ?? null
        }
      }
      context.commit('storeRefreshToken', refreshToken || null)


      var token = await localforage.getItem('__JWT_TOKEN__')
      if(token === null) {
        //check if a cookie is set
        let cookie = document.cookie.split('; ').find(row => row.startsWith('authorizationKey='))
        if(!!cookie ) {
          token = cookie.split('=')[1] ?? null
          if(token === "null") {
            token = null
          }
        }
      }
      if(token === null) { 
        return null 
      }
      var splitted = token.split('.');

      if(splitted.length <= 1) {
        return null;
      }

      var payload = JSON.parse(atob(splitted[1]))
      if(!payload?.exp) { return null }
      //milliseconds since EPOCH
      var validUntil = new moment.utc(payload.exp*1000);
      if(validUntil.isAfter()) {
        if(token !== null) {
          context.commit('storeAuthenticationToken', token)
          context.commit('authenticatedUntil', validUntil)
          context.commit('setCurrentUser', payload['username'])
          context.commit('setCurrentUserRoles', payload['roles'])
          if(context.getters.isAuthenticated === false) {
            context.commit('setAuthenticationValid', true)
          }
          await window.globalVue.$libraries.addLibrary('user')
          context.dispatch('user/getUserFiltersFromServer')      
					context.dispatch('loadAllData' , forceReload)
        } 
      }
      
    },

    changeOwnerContext: (context, payload) => {
      context.commit('setOwnerContext', payload)
      //also save last context in localforage
      localforage.setItem('__OWNER_CONTEXT__', JSON.stringify(payload))
    },    
    loadByIRI: async(context,param)  => {
      let module = context.getters.getModuleFromIri(param.iri || param)
      if(!module) { return null }
      return await context.dispatch(module.module+"/loadByIRI", param)
    },
    searchAllModules: async(context, searchTerm) => {
      let libraries = libraryManager.list()
      let ownerIris = context.getters.getOwnerContext?.map(owner => owner?.iri)
      let resultList = []
      let promiseList = []
      libraries.forEach(lib => {
        if(context.getters[lib+'/searchModule']) {
          let getterAction = context.getters[lib+'/searchModule'](searchTerm, ownerIris)
          if(Array.isArray(getterAction)) {
            resultList = resultList.concat( getterAction.map(r => r?.iri || null).filter(res => !!res) )
          }          
        } 
        if(store._actions[lib+'/searchModule']) {
          promiseList.push(
            context.dispatch(lib+'/searchModule',searchTerm,{root: true}).then(res => {
              if(Array.isArray(res)) {
                resultList = resultList.concat( res.map(r => r?.iri || null).filter(res => !!res) )
              }
            })
          )
          
        }
      });
      await Promise.allSettled(promiseList)
      return resultList
    }
  },
  modules: {
  },
  getters: {
    loadScreen: (state) => state.loadingScreen,
    loadedModules: (state) => state.loadedModules,
    loadingModules: (state) => state.loadingModules,
    languages: (state) => ["de","en"],
    getRegistry: (state) => state.serverRegistryPromise,
    getViewLayout: (state) => state.viewGridLayout,
    getAuthenticationToken: (state) => state.jwtToken,
    getRefreshToken: (state) => state.refreshToken,
    isAuthenticated: state => state.validAuth,
    authenticatedUntil: state => state.authValidUntil,
    getCurrentUser: state => state.currentUser,
    getCurrentUserRoles: state => state.currentUserRoles ?? [],
    getLastSelectedItem: state => state.lastSelectedItem,
    allAccessibleOwners: state => state.availableOwners,
    isNavigating: state => state.navigationState,
    breadcrumbs: state => state.breadcrumbs,
    getOwnerContext: state => state.ownerContexts,
    getMeetingModes: state => state.meetingModes,
    getIriMap: state => state.registeredIRIs,
    getModuleFromIri: state => (iri) => state.registeredIRIs.find(iriTest => iriTest.regex.test(iri)) || {},
    getItemFromIri: (state, getters) => (iri) => {
      let targetModule = getters['getModuleFromIri'](iri)
      if(!targetModule) { return null }
      if(!getters[targetModule.module+'/getItems']) { return null }
      return getters[targetModule.module+'/getItems'][iri]
    },
    getOwnerFromIri: (state, getters) => (iri) => {
      if(iri?.includes('/owners/')) return iri
      let targetModule = getters['getModuleFromIri'](iri)
      if(!targetModule || !state[targetModule.module]) { return null }
      let targetItem = getters[targetModule.module+'/getItems'][iri]
      return targetItem && targetItem.owner || null

    },
    isIriLoaded: (state, getters) => (iri) => {
      let targetModule = getters['getModuleFromIri'](iri)
      return !!targetModule && !!getters[targetModule.module+'/getItems'][iri]
    },
    editorContext: (state) => state.editorContext,
    isOffline: state => state.statusModes.indexOf('offline') != -1,
    inMode: state => (mode) => state.statusModes.indexOf(mode) != -1,
    modes: state => state.statusModes,
    navigationHooks: state => state.navigationHooks,
    getDisplayTitle: (state,getters) => (iri, config = {}) => {
        let mod = getters.getModuleFromIri(iri)
        let item = getters[mod.module+'/getItems'][iri]
        if(!item) return iri
        return window && window[mod.module] && window[mod.module]['functions']['Display'].display(item, config) || iri
    },
    getRouteConfiguration: () => routes,
    getSidebarGroups: () => groups,
    env : (state) => state.environment,
    config: () => config,
    isItemVisible: (state, getters) => (iri) => {
      let item = getters.getItemFromIri(iri)

      if(!item) { return false }
      if(getters.getMeetingModes.length == 0) {
        return true
      } else {
        if(getters.getMeetingModes.includes('customer')) {
          return item?.userGroup?.some(g => {
          let uGObj = getters['owner/getUserGroups']?.[g]
          return uGObj?.category?.includes("customer") ?? false  }) ?? false
        }
      }
      //fallback
      return true
    }
    
  }
})

store.dispatch('loadEnvironment')
store.dispatch('loadTokenFromStorage')

export { store }
