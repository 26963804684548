<i18n lang="yaml" locale="en">
    title.checklist: "Checklists"
</i18n>
<i18n lang="yaml" locale="de">
    title.checklist: "Checklisten"
</i18n>

<template>
    <collapse-box :id="$id('collapse-checklists')" :title="$t('title.checklist')" body-class="p-0">
        <template #actions>
            <checklist-creator v-if="!hideCreate" :relationTarget="target" />
        </template>
        <div class="d-flex flex-wrap">
            <base-checklist
                v-for="checklistIri in checklists"
                :key="$id(checklistIri)"
                :value="checklistIri"
            />
        </div>
    </collapse-box>
</template>

<script>
import collapseBox from '../collapseBox.vue'

import ChecklistCreator from './ChecklistCreator.vue'
export default {
  name: "checklistList",
  components: { collapseBox , ChecklistCreator },
  props: {
      /**
       * the iri of the checklists parent object (origin of the relation)
       */
      parentIri: {
          type: String,
          required: false          
      },
      /**
       * hides the button for creating new checklists
       */
      hideCreate: {
          type: Boolean,
          default: false
      }
  },
  computed: {
      target: function(vm) {
          let owner = vm.$store.getters.getOwnerFromIri(vm.parentIri)
          return {
              "owner": owner,
              "iri" : vm.parentIri
          }
      },
      checklists: function(vm) {
          //todo fetch relation with checklists as target
            let allOutgoingRelations = vm.$store.getters?.['relation/outgoingRelations']?.[vm.parentIri] ?? []
            return allOutgoingRelations.filter(relation => relation.toIRI.includes('/checklists/')).map(rel => rel.toIRI)
      }
  }


}
</script>

<style>

</style>