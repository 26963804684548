<i18n lang="yaml" locale="en">
    unfoldAll: "Unfold all"
    foldAll: "Fold all"
    btn.unlocked: "Save & Lock"
    btn.locked: "Locked"
    description.block: "Description"
    settings.block: "Settings"
    checklist.statusType: "Status type for entries"
    checklist.status: "Status of checklist"
    status.trafficlights: "Traffic lights"
    status.checkboxes: "Checkboxes"
    checklistStatus.notreviewed: "Not reviewed"
    checklistStatus.active: "Red"
    checklistStatus.closed: "Yellow"
    checklistStatus.signed: "Green"
    description.placeholder: "Description of the checklist"
    loading.failed: "Loading checklist failed"
    loading.failed.title: "Loading failed"
    heading.title: "Title"
    heading.status: "Status"
    heading.responsible: "Responsible"
    heading.comments: "Comments"
    heading.actions: "Actions"
    snapshot.block: "Snapshots"
    screenshot.return: "Return to current state"
    snapshot.loadFailed: "Loading snapshot failed"
    snapshot.create.failed: "Failed to create snapshot"
    snapshot.create.name: "Name"
    snapshot.create.confirm: "Create snapshot"
    snapshot.create.cancel: "Cancel"
    snapshot.description.block: "Snapshot Details"
    snapshot.signature.notsigned: "Snapshot not signed"
    snapshot.signature.label: "Signature:"
    snapshot.isSigned: "signed"
    confirm.discard: "Discard changes?"
    confirm.yes: "YES"
</i18n>
<i18n lang="yaml" locale="de">
    unfoldAll: "Alle ausklappen"
    foldAll: "Alle einklappen"
    btn.unlocked: "Speichern & Sperren"
    btn.locked: "Gesperrt"
    description.block: "Beschreibung"
    settings.block: "Einstellungen"
    checklist.statusType: "Statustyp der Einträge"
    checklist.status: "Status der Checkliste"
    status.trafficlights: "Ampel"
    status.checkboxes: "Checkboxen"
    checklistStatus.notreviewed: "Nicht bearbeitet"
    checklistStatus.active: "Rot"
    checklistStatus.closed: "Gelb"
    checklistStatus.signed: "Grün"
    description.placeholder: "Beschreibung der Checkliste"
    loading.failed: "Laden der Checkliste fehlgeschlagen"
    loading.failed.title: "Laden fehlgeschlagen"
    heading.title: "Titel"
    heading.status: "Status"
    heading.responsible: "Verantwortlich"
    heading.comments: "Kommentare"
    heading.actions: "Aktionen"
    snapshot.block: "Speicherstände"
    screenshot.return: "Zurück zum aktuellen Stand"
    snapshot.loadFailed: "Speicherstand konnte nicht geladen werden"
    snapshot.create.failed: "Speicherstand konnte nicht erzeugt werden"
    snapshot.create.name: "Name"
    snapshot.create.confirm: "Speicherstand erstellen"
    snapshot.create.cancel: "Abbrechen"
    snapshot.description.block: "Speicherstand Details"
    snapshot.signature.notsigned: "Speicherstand nicht signiert"
    snapshot.signature.label: "Signatur:"
    snapshot.isSigned: "signiert"
    confirm.discard: "Änderungen verwerfen?"
    confirm.yes: "JA"
</i18n>


<template>
    <b-button style="max-width: 180px;" v-b-modal="$id('modal')" variant="light border-secondary" class="d-flex align-items-center text-truncate btn-fmea text-left m-1"  :title="localData.title">
        <b-skeleton v-if="fetching"></b-skeleton>
        <div v-else
            class="d-flex justify-content-start flex-column w-100" >
            <span class="text-truncate">
                <span class="small">
                    <b-icon-box-arrow-up-right class="mr-1" />
                    <b-icon :icon="statusIcon" :variant="statusVariant" class="mr-1"/>
                </span> {{ localData.title }}
            </span>
            <div class="d-flex justify-content-end align-items-center">
                <b-badge variant="success" class="mt-1 mr-1 align-self-end p-1" >{{ summary.green }}</b-badge>
                <b-badge variant="warning" class="mt-1 mr-1 align-self-end p-1" >{{ summary.yellow }}</b-badge>
                <b-badge variant="danger" class="mt-1 align-self-end p-1" >{{ summary.red }}</b-badge>
                
            </div>
        </div>        

        <b-modal 
            :id="$id('modal')" 
            fluid 
            size="xl" 
            body-class="p-0 vh-100" 
            dialog-class="baseditor-maxwidth" 
            scrollable  
            hide-header 
            hide-footer
            @hide="safeHide($event)"
            >
            <template #default="{ close }">
            <b-overlay
            :show="loadingSnapshot"
            >
                <div class="titlebar p-1">
                    <h3 class="p-1 ml-2">{{ localData.title }} {{ !!snapshot ? '('+snapshot.name+')' : '' }}</h3>
                    <b-button 
                        type="button"
                        variant="transparent"
                        size="lg"
                        class="p-2 position-absolute" 
                        style="right: 5px;top: 5px;" 
                        @click="safeClose(close)" 
                        >
                        <b-icon-x 
                            size="16px"
                        />
                    </b-button>
                    <div class="d-flex align-items-start ml-2 pb-1">
                        <b-button 
                            v-if="snapshot === null"
                            class="d-flex flex-nowrap"
                            :variant="checkedOut ? 'success' : 'danger'"  
                            @click="toggleEditMode()"
                        >
                        <b-spinner v-if="isProcessing" small class="mr-1" />
                            <b-icon v-else :icon="checkedOut ? 'unlock' : 'lock'"  class="mr-1"  />                    
                            {{ checkedOut ? $t('btn.unlocked') : $t('btn.locked') }}
                        </b-button>

                        <b-button 
                            v-else 
                            variant="warning"
                            @click="snapshot = null">
                            {{ $t('screenshot.return') }}
                        </b-button>
                        
                        <b-button 
                            v-if="snapshot === null && checkedOut"
                            variant="danger" 
                            @click="discardChanges()">
                            <b-icon-x />
                        </b-button>

                        <b-button 
                            class="ml-auto"
                            variant="transparent"
                            @click="safeReload"
                            >
                                <b-icon-arrow-clockwise />
                        </b-button>
                    </div>
                </div>
                <b-container class="mb-2" fluid>
                    <b-row>
                        <b-col cols="9">
                            <collapse-box :id="$id('description')" :title="$t('description.block')" hidden body-class="p-0">
                                <markup-text 
                                    :value="relevantData.description" 
                                    @input="localData.description = $event" 
                                    :disabled="!checkedOut"
                                    :placeholder="$t('description.placeholder')" />
                            </collapse-box>

                            <collapse-box v-if="snapshot !== null" :id="$id('snapshot')" :title="$t('snapshot.description.block')">
                                <div class="d-flex align-items-center">
                                    
                                    <div class="mr-2">{{ $t('snapshot.signature.label') }}</div>

                                    <dynamic-loader 
                                        v-if="snapshot.signed"
                                        module="user" 
                                        widget="SignatureField" 
                                        v-model="snapshot.signature" 
                                        :customProperties="{'contentToSign' : relevantDataMap}" 
                                    />
                                    <div v-else >
                                        {{ $t('snapshot.signature.notsigned') }}
                                    </div>

                                </div>
                            </collapse-box>
                        </b-col>
                        <b-col cols="3">
                            <collapse-box :id="$id('settings')" :title="$t('settings.block')" hidden body-class="p-0">
                                <b-form-group
                                    id="fieldset-3"
                                    :label="$t('checklist.statusType')"
                                    :label-for="$id('statusType')"
                                >
                                    <b-form-select 
                                        class="ml-1 flex-shrink-1" 
                                        v-model="relevantData.statusType" 
                                        :options="checklistStatusOptions" 
                                        :disabled="!checkedOut" 
                                        :id="$id('statusType')"/>
                                </b-form-group>
                                
                                <b-form-group
                                    id="fieldset-3"
                                    :label="$t('checklist.status')"
                                    :label-for="$id('status')"
                                >
                                    <b-form-select 
                                        class="ml-1 flex-shrink-1" 
                                        v-model="relevantData.status" 
                                        :options="statusOptions" 
                                        :disabled="!checkedOut" 
                                        :id="$id('status')"/>
                                </b-form-group>

                                
                            </collapse-box>

                            <collapse-box :id="$id('snapshots')" :title="$t('snapshot.block')" hidden body-class="p-1">
                                <b-button :disabled="checkedOut" variant="success" v-b-modal="$id('snapshotCreator')" class="m-1"><b-icon-plus /></b-button>

                                <b-list-group>
                                    <b-list-group-item 
                                        v-for="(snapshotItem, idx) in snapshots"
                                        :key="$id('snapshot-'+idx)"
                                        href="#"
                                        class="d-flex"
                                        @click="loadSnapshot(snapshotItem)"
                                        :variant="(snapshot && snapshotItem.iri == snapshot.iri) ? 'primary' : 'light'"
                                        >
                                        {{ snapshotItem.name }} ({{ formatDate(snapshotItem.createdate) }}) 
                                        <b-icon-pen v-if="snapshotItem.signed" class="ml-auto" :title="$t('snapshot.isSigned')" />
                                    </b-list-group-item>
                                </b-list-group>
                                <b-modal 
                                    :id="$id('snapshotCreator')" 
                                    ok-variant="success" 
                                    cancel-variant="danger"
                                    :cancel-title="$t('snapshot.create.cancel')"
                                    @ok="createSnapshot()" 
                                    >
                                    <b-form-group
                                        label-cols="3"
                                        :label="$t('snapshot.create.name')"
                                        label-for="snapshot.create.name"
                                        >
                                        <b-form-input v-model="snapshotCreate.name" id="snapshot.create.name" size="sm"></b-form-input>

                                        <dynamic-loader module="user" widget="SignatureField" v-model="snapshotCreate.signature" :customProperties="{'contentToSign' : relevantDataMap}" />
                                    </b-form-group>
                                    <template #modal-ok>
                                        <b-spinner v-if="creatingSnapshot" small class="mr-1"/> {{ $t('snapshot.create.confirm') }}
                                    </template>
                                </b-modal>
                            </collapse-box>
                        </b-col>
                    </b-row>
                </b-container>


                <div class="m-2 d-flex justify-content-between">
                    <div  @click="forceAllChildren()" ><b-icon :icon="!unfolded ? 'chevron-right' : 'chevron-down'" /> {{ $t(!unfolded ? 'unfoldAll' : 'foldAll') }}</div>
                    <b-button v-if="checkedOut" variant="success" @click="addToplevelItem()"><b-icon-file-plus /></b-button>
                    
                </div>
                <div class="m-2 text-bold">

                    <div 
                    class="checklist--entry border-bottom border-top border-dark my-1"
                    >
                    <div class="checklist-fixed-width-block d-flex flex-nowrap">
                        {{ $t('heading.title') }}                
                    </div>
                    <div class="checklist-status">
                        {{ $t('heading.status') }}
                    </div>
                    <div class="checklist-responsible">
                        {{ $t('heading.responsible') }}
                    </div>
                    <div class="checklist-comments">
                        {{ $t('heading.comments') }}
                    </div>
                    <div class="checklist-actions mr-2 d-flex align-items-center">
                            {{ checkedOut ? $t('heading.actions') : '' }}
                    </div>
                </div>



                    <checklist-item 
                        v-for="(entry,idx) in relevantDataMap['@top']"
                        :key="$id(entry.iri || entry['@new'])"
                        :checklistMap="relevantDataMap"
                        :level="0"
                        :cid="(idx+1)+'.'"
                        :value="entry"
                        :statusType="relevantData.statusType"
                        :owner="relevantData.owner"
                        :isSnapshot="!!snapshot"
                        :disabled="!checkedOut"
                        :parentId="$id('parent')">
                    </checklist-item>
                </div>
                <dynamic-loader 
                    v-if="subItemModal.module != '' && subItemModal.iri != ''" 
                    :module="subItemModal.module" 
                    widget="Single" 
                    :value="subItemModal.iri" 
                    :customProperties="{'modal': $id('showSubRowElement')}"/>
            </b-overlay>
            </template>
        </b-modal>
    </b-button>
</template>

<script>
import CollapseBox from '../collapseBox.vue'
import checklistItem from './ChecklistItem.vue'

const moment = require('moment')

export default {
    name: "Checklist",
    components: {
        checklistItem,
        CollapseBox
    },
    props: {
        /**
         * the iri of the checklist to display
         */
        value: {
            type: String,
            required: false,

            default: ""
        }
    },
    watch: {
        'value': {
            handler: function() {
                this.loadChecklist()
            },
            immediate: true
        }

    },
    methods: {
        safeHide: function(ev) {
            if(!this.checkedOut) {
                return
            }
            ev.preventDefault()
            this.discardChanges().then(res => {
                if(res) {
                    this.$bvModal.hide(this.$id('modal'))
                }
            })
        },
        safeClose: async function(closeHandle) {
            if(!this.checkedOut) {
                closeHandle()
            }
            let res = await this.discardChanges()
            if(res) {
                closeHandle()
            }
        },
        safeReload: async function() {
            try {
                if(this.checkedOut) {
                    if(await this.discardChanges()) {
                        this.loadChecklist();
                    }
                } else {
                    this.loadChecklist();
                }
                
            } catch {}
        },
        discardChanges: async function() {
            let res = await this.$bvModal.msgBoxConfirm(this.$t('confirm.discard'), {
                okVariant: "danger",
                okTitle: this.$t('confirm.yes')
            })

            if(res) {
                try {
                    await this.$cache.post('relation','/unlock', { resource : this.value })
                    this.checkedOut = false
                    return true
                } catch {
                    return false
                }
            } 
            return false
        },
        loadSnapshot: async function(snapshot) {
            if(!snapshot.iri) return
            try {
                this.loadingSnapshot = true
                let snapshotResponse = await this.$cache.get('relation', snapshot.iri)

                this.snapshot = snapshotResponse ?? {}
                this.checkedOut = false
            } catch {
                this.$bvModal.toast(this.$t('snapshot.loadFailed'), {variant: "danger"})
                this.snapshot = null
            } finally {
                this.loadingSnapshot = false
            }
        },
        createSnapshot: async function() {
            try {
                this.creatingSnapshot = true
                this.snapshotCreate.checklist = this.value,
                this.snapshotCreate.data = {
                    "checklist": {
                        owner: this.localData.owner ?? '',
                        description: this.localData.description ?? '',
                        statusType:  this.localData.statusType ?? 'trafficlight',
                        status:  this.localData.status ?? 'active',
                    },
                    "map": JSON.parse(JSON.stringify(this.checklistMap))
                }

                await this.$cache.post('relation','/checklist_snapshots',this.snapshotCreate)
                await this.loadChecklist()
                this.$bvModal.hide(this.$id('snapshotCreator'))
            } catch {
                this.$bvToast.toast(this.$t('snapshot.create.failed'), {variant: "danger"})
            } finally {
                this.creatingSnapshot = false
            }
        },
        forceAllChildren: function() {
            this.unfolded = !this.unfolded
            if(this.unfolded) {
                this.$root.$emit('bts::checklist::toggleAllItems', {id: this.$id('parent'), collapsed: false })
            } else {
                this.$root.$emit('bts::checklist::toggleAllItems', {id: this.$id('parent'), collapsed: true })
            }
        },
        openExternalReference: async function(iri) {

            let mod = this.$store.getters.getModuleFromIri(iri)

            this.subItemModal = {module: mod.module, iri: iri}
            if(!this.subItemModal) return
            this.$nextTick(function() {
            this.$nextTick(function() {
            this.$nextTick(function() {
            this.$nextTick(function() {
                this.$bvModal.show(this.$id('showSubRowElement'))      
            })})})          
            })
        },
        addToplevelItem: function() {
            this.checklistMap['@top'].splice(this.checklistMap['@top'].length, 0, {
                "title": "",
                "status": "r",
                "externalReference": null,
                "responsible" : null,
                "parent": null,
                "@new" : moment().valueOf(),
                "@dirty": true,
                "@delete" : false
            })
        },
        addChildToMap: function(parentId, childObject) {
            if(!this.checklistMap[parentId]) {
                this.$set(this.checklistMap,parentId,[])
            }
            this.checklistMap[parentId].splice(this.checklistMap[parentId].length,0,childObject)

        },
        loadChecklist: async function() {
            try {
                this.fetching = true
                //todo load item
                let res =  await this.$cache.cached('relation',this.value);
                this.$store.dispatch('relation/loadItemByObject',res)
                this.localData = res
                this.buildChecklistMap()
            } catch {
                //error handling
                this.$bvToast.toast(this.$t('loading.failed'), {title: this.$t('loading.failed.title'), variant: "danger"})
            } finally {
                this.fetching = false
            }            

        },
        buildChecklistMap: function() {
            let adjacencyMap = {"@top" : []}
            this.summary.red = 0, this.summary.yellow = 0, this.summary.green = 0
            
            this.localData?.items?.forEach(entry => {
                if(!!entry.parent) {
                    if(!adjacencyMap[entry.parent]) {
                        adjacencyMap[entry.parent] = []
                    }

                    adjacencyMap[entry.parent].push(entry)

                } else {
                    //toplevel, add to toplevel list
                    adjacencyMap['@top'].push(entry)
                }  
                
                if(entry.hasOwnProperty('status')) {
                    switch(entry.status) {
                        case "r" :
                            this.summary.red++
                            break
                        case "y" :
                            this.summary.yellow++
                            break;
                        case "g" :
                            this.summary.green++
                            break;
                    }
                }
            });

            this.checklistMap = adjacencyMap
        },
        saveChecklist: async function() {
            try {
                this.saving = true
                //todo save item

                let submitMap = JSON.parse(JSON.stringify(this.checklistMap))

                let submitBody = {
                    "checklist": {
                        description: this.localData.description ?? '',
                        statusType:  this.localData.statusType ?? 'trafficlight',
                        status:  this.localData.status ?? 'active',
                    },
                    "map": submitMap
                }

                
                let res = await this.$cache.post('relation',this.value+'/updateByMap', submitBody)
                await this.$cache.post('relation','/unlock', { resource : this.value })

                this.localData = res
                this.buildChecklistMap()
            } catch {
                //error handling
            } finally {
                this.saving = false
            }  
            
        },
        toggleEditMode: async function() {
            this.isProcessing = true
            try {
                if(this.checkedOut) {
                    await this.saveChecklist()
                    this.checkedOut = false
                } else {
                    let res = await this.$cache.post('relation','/lock', { resource : this.value })
                    if(res.status == 200) {
                        this.checkedOut = true
                    }
                }                    
            } catch {
                if(this.checkedOut) {
                    this.$bvToast.toast('Saving failed')
                } else {
                    this.$bvToast.toast('Unlocking failed')
                }
            } finally {
                this.isProcessing = false
            }
        },
        formatDate(dateString) {
            return moment(dateString).format('DD.MM.YYYY')
        }
    },
    computed: {
        relevantData: function(vm) {
            return vm.snapshot?.data?.checklist ?? vm.localData
        },
        relevantDataMap: function(vm) {
            return vm.snapshot?.data?.map ?? this.checklistMap
        },
        statusIcon: function(vm) {
            switch(vm.localData.status) {
                case "notreviewed" : return 'circle-fill';
                case "active" : return 'circle-fill';
                case "closed" : return 'exclamation-circle-fill';
                case "signed" : return 'check-circle-fill';
                default: return 'blank';
            }
        },
        statusVariant: function (vm) {
            switch(vm.localData.status) {
                case "notreviewed" : return 'secondary';
                case "active" : return 'danger';
                case "closed" : return 'warning';
                case "signed" : return 'success';
                default: return 'transparent';
            }
        },
        checklistStatusOptions: function(vm) {
            return [
                { value: 'trafficlight', text: vm.$t('status.trafficlights') },
                { value: 'checkbox', text: vm.$t('status.checkboxes') }
            ]
        },
        statusOptions: function(vm) {
            return [
                { value: 'notreviewed', text: vm.$t('checklistStatus.notreviewed') },
                { value: 'active', text: vm.$t('checklistStatus.active') },
                { value: 'closed', text: vm.$t('checklistStatus.closed') },
                { value: 'signed', text: vm.$t('checklistStatus.signed') }
            ]
        },
        snapshots: function(vm) {
            return vm.localData?.checklistSnapshots ?? []
        }
    },
    provide() {
        return {
        "getOwnerIri" : () => this.localData.owner,
        "openExternalReference" : this.openExternalReference,
        "addChildToMap" : this.addChildToMap,
        }
    },
    data() {
        return {
            summary: {
                "red" : 0,
                "yellow" : 0,
                "green" : 0
            },
            signature: "",
            isProcessing: false,
            creatingSnapshot: false,
            loadingSnapshot: false,
            checklistMap: {},
            checkedOut: false,
            toggleDisabled: true,
            saving: false,
            fetching: false,
            unfolded: false,
            subItemModal: {
                module: '',
                iri: ''
            },
            localData: {},
            snapshot: null,
            snapshotCreate: {
                name: "",
                data: {},
                checklist: "",
                signature: ""
            }
        }
    }
}
</script>

<style>

</style>