import { APIHandler } from '@/helper/APIHandler'
const CHUNK_SIZE = 200 * 1000 //kilobytes
const NUMBER_OF_SIMULTANEOUS_CONNECTIONS = 3

const UploadManager = function(loader = null, targetIri = null)
{
    this.ckEditorLoader = loader
    this.targetIri = targetIri
    this.ownerIri = null
    this.serverRequests = []
    this.apihandler = new APIHandler()
    this.fileUploads = []
    this.vue = null
    this.registerFileUpload = null
    this.unregisterFileUpload = null
    if(window['globalVue']) {
        this.vue = window['globalVue']
    }

}

UploadManager.prototype.getFileServers = async function()
{
    let registryEntry = await window['globalVue'].$registry.getModuleRegistration('file')
    let serverList = registryEntry.map((server) => server.url)
    //initialize servers to the serverList, making them available for Promise.race
    this.serverRequests = serverList.map(item => ({"config" : {"url" : item}}))
    return serverList

}

UploadManager.prototype.uploadFileWithRelation = async function(file,targetIri, ownerIri, versioningTitle = "Upload")
{    
    this.getFileServers()
    let versioning = {historyId: null, historyTitle: versioningTitle, historyOwner: ownerIri}
    let upload = await this.addFileUpload(file, versioning)
    let result = await upload.uploadPromise

        let body = {
            "fromIRI": targetIri,
            "toIRI": result.versionIri,
            "fromOwnerIRI": ownerIri,
            "toOwnerIRI": ownerIri,
            "type": "information",
            "status": 0,
            "statusThreshold": 0
          }
        await window['globalVue'].$cache.create('relation','/relations',body)
    

}

UploadManager.prototype.addFileUpload = async function(file, versioning = null)
{
    
    this.getFileServers()
    let version = versioning !== null ? versioning : {historyId: null, historyTitle: "Upload"}
    let newUpload = new FileUpload(file, this, version)
    this.fileUploads.push(newUpload)
    return newUpload
}

UploadManager.prototype.fetch = async function(route, formdata)
{
    if(this.serverRequests.length === 0) { return false }
    //wait for the first open fileServer/for the next server to open up
    //Server promises should always return the data and the address
    let openServer = await Promise.race(this.serverRequests)

    let cfg = await this.apihandler.buildAxiosConfig(false)
    //manual type override
    delete cfg.headers['Content-Type'];
    //Upload Manager is only sending post requests
    cfg.method = "POST"
    cfg.body = formdata
    let newRequest = fetch(openServer.config.url + route, cfg)

    openServer = newRequest
    return newRequest
}


//FOR CKEditor support
UploadManager.prototype.upload = async function()
{

    this.getFileServers()
    if(!this.ckEditorLoader) { throw "not in CKEditor context" }
    let file = await this.ckEditorLoader.file

    //try to get the owner from the targetIri
    let targetIri = this.targetIri()
    let targetOwner = window['globalVue'].$store.getters.getOwnerFromIri(targetIri)

    if(targetOwner == "" || !targetOwner) {
        targetOwner = this.ownerIri()
    }

    let upload = await this.addFileUpload(file, {historyId: null, historyTitle: "Editor", historyOwner: targetOwner})
    
    if(this.registerFileUpload) {
        this.registerFileUpload(upload)
    }

    let result = await upload.uploadPromise

    let fileServer = await window['globalVue'].$registry.getModuleRegistration('file')


        let body = {
            "fromIRI": this.targetIri(),
            "toIRI": result.versionIri,
            "fromOwnerIRI": targetOwner,
            "toOwnerIRI": targetOwner,
            "type": "information",
            "status": 0,
            "statusThreshold": 0
          }
        await window['globalVue'].$cache.create('relation','/relations',body)
    
    if(this.unregisterFileUpload) {
        this.unregisterFileUpload(upload)
    }


    return { default: fileServer[0].url + '/preview?version=' + result.versionIri + '&token=' + window['globalVue'].$store.getters.getAuthenticationToken }
}

UploadManager.prototype.abort = function()
{

}
UploadManager.prototype.install = function(Vue, options) {
      //needs adjustments
    Vue.prototype.$upload = this

}



var FileUpload = function(file, broker, versioning)
{
    
    this.file = file
    this.broker = broker
    this.versioning = versioning
    this.chunkId = 0
    
    this.uploadToken = null
    this.uploadPromise = this.startUpload()
    
}

FileUpload.prototype.resolveFile = async function() {
    this.file = await this.file
    this.fileName = this.file.name
    this.fileSize = this.file.size

    this.totalNumberOfChunks = Math.ceil(this.fileSize / CHUNK_SIZE)
}

FileUpload.prototype.startUpload = async function() {
    await this.resolveFile()

    this.checkVersioningConfiguration(this.versioning)
    await this.requestUploadToken()
    let versioning = await this.sendChunks()
    return versioning
}

FileUpload.prototype.checkVersioningConfiguration = function(versioning)
{
    this.versioning = versioning

    if(!this.versioning) {
        console.error("No version control information provided");
        return false;
    }

    this.historyId = this.versioning.historyId || null

    if(!this.historyId && !this.versioning.historyTitle) {
        console.error("Not sufficient version control information provided");
        return false;
    }

    return true
}

FileUpload.prototype.requestUploadToken = async function()
{
    let tokenRequest = new FormData()
    
    tokenRequest.append("fileName",this.fileName)
    if(!!this.versioning.historyId) {
        tokenRequest.append("historyId", this.versioning.historyId)
    } else {
        tokenRequest.append("historyTitle", this.versioning.historyTitle)
        tokenRequest.append("historyDescription", this.versioning.historyDescription)
        if(this.versioning.historyOwner) {
            tokenRequest.append("historyOwner", this.versioning.historyOwner)
        }
    }

    let axiosResponse = await this.broker.fetch('/uploadToken', tokenRequest)
    if(axiosResponse.ok) {
        this.uploadToken = (await axiosResponse.json()).uploadToken
    }
}

FileUpload.prototype.getChunk = function(chunkId)
{
    let newChunk = this.file.slice(chunkId*CHUNK_SIZE, (chunkId+1)*CHUNK_SIZE)
    return newChunk
}

FileUpload.prototype.sendChunks = async function()
{    
    let chain = []
    for(let j=0;j<NUMBER_OF_SIMULTANEOUS_CONNECTIONS;j++)
    {
        //number of simultaneous chains started
        chain.push(this.sendNextChunk())        
    }
    await Promise.all(chain)
    
    let assembled = (await this.assembleOnServer())
    return (await assembled.json())

    //call assemble endpoint when all chunks have been uploaded successfully
}

FileUpload.prototype.sendNextChunk = function(currentChunkId = null, retry = 0)
{
    return new Promise((resolve, reject) => {
        if(!currentChunkId) {
            //immediately increment chunkId after assigning
            currentChunkId = this.chunkId++
        }

        if(currentChunkId >= this.totalNumberOfChunks) {
            return resolve("chain completed")
        }

        let chunkRequest = new FormData()
        chunkRequest.append('filepart',currentChunkId)
        chunkRequest.append('chunkData',this.getChunk(currentChunkId))
        chunkRequest.append('uploadToken', this.uploadToken)
        this.broker.fetch('/uploadChunk',chunkRequest)
        .then(async() => ((await this.sendNextChunk()), resolve()))
        .catch(function(response) { 
            if(retry > 3) {
                reject("chunk "+currentChunkId+" failed")
            }
            this.sendNextChunk(currentChunkId, ++retry )
        })
    })
}

FileUpload.prototype.assembleOnServer = function()
{
    let assembleRequest = new FormData()
    assembleRequest.append('uploadToken', this.uploadToken)
    return this.broker.fetch('/assembleChunks', assembleRequest)
}

FileUpload.prototype.getName = function()
{
    return this.fileName
}

FileUpload.prototype.getToken = function()
{
    return this.uploadToken
}

FileUpload.prototype.getProgress = function()
{
    return (this.chunkId/this.totalNumberOfChunks).toFixed(0)
}

const globalUploader = new UploadManager()
export { UploadManager, globalUploader }