export default [
    {
        "path" : "\/personal",
        "name" : "personalLandingPage",
        "meta" : {
            "show" : {"sidebar" : true },
            "label" : {"de" : "Persönlicher Bereich", "en" : "Personal area"},
            "requiresAuthentication" : true,
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "feedback", "context": {"ownTicketsOnly" : true} }
                    ]
                }
            },
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 5,
                    "h": 6,
                    "i": "personalResponsibility",
                    "static": false,
                    "module" : "relation",
                    "widget" : "Responsibility",
                    "customProperties": {
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                },
                
                {
                    "x": 5,
                    "y": 0,
                    "w": 5,
                    "h": 6,
                    "i": "personalTickets",
                    "static": false,
                    "module" : "feedback",
                    "widget" : "Table",
                    "customProperties": {
                        "card": false,
                        "filter": {"sender" : (val) => val == window.globalVue.$store.getters.getCurrentUser}                        
                    },  
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    },
    {
        "path" : "\/impressum",
        "name" : "impressumAndDataProtection",
        "meta" : {
            "show" : {"sidebar" : false },
            "label" : {"de" : "Impressum und Datenschutz", "en" : "Contact and data protection"},
            "requiresAuthentication" : false,
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "impressumAndDataProtection",
                    "static": false,
                    "module" : "",
                    "widget" : "impressum",
                    "customProperties": {
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    }
]
