import { render, staticRenderFns } from "./BaseCellSortCurrency.vue?vue&type=template&id=1fcd31ef&"
import script from "./BaseCellSortCurrency.vue?vue&type=script&lang=js&"
export * from "./BaseCellSortCurrency.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaseCellSortCurrency.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml&locale=de"
if (typeof block0 === 'function') block0(component)
import block1 from "./BaseCellSortCurrency.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml&locale=en"
if (typeof block1 === 'function') block1(component)

export default component.exports