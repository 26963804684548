/**
 * @event select returns an array of iri strings of all currently selected elements
 */
export default {
  inject: {
    "sendToSelector" : { name: 'sendToSelector', default: false },
    "isSelected" : { name: 'isSelected', default: false },
    "getSelected" : { name: 'getSelected', default: false },
  },
  props: {
    columns: Array,
    rows: Array,
    contextSensitive: {
      type: Boolean,
      default: true
    },
    noOwner: {
      type: Boolean,
      default: false
    },
    /**
     * the already selected elements
     */
    selected: { type: Array, default: () => []}
  },
  watch: {
    'selected' : {
      immediate: true,
      handler: function(newVal) {
        this.selectedIris = newVal ?? []
      }
    },
    '$store.getters.getMeetingModes' : {
      immediate: true,
      handler: function() {
        if(this.$store.getters.getMeetingModes.length > 0) {
          this.selectedIris.splice(0,this.selectedIris.length)
        }
      }
    }
  },
  
  created() {
    this.$watch("sendToSelector",function(val) {
        if(val) { 
        this.selectionMode = true 
        let items = this.getSelected() ?? []
        this.selectedIris = items
        }

      }, { immediate: true })
    
    this.$watch(function() { return this.getSelected && this.getSelected() },
      function(newVal, oldVal) {
        this.selectedIris = newVal ?? []
      }
    )
    
  },
  computed: {
      anySelected: function(vm) {  
        return vm.selectedIris.length > 0 && !vm.allVisibleSelected
      },
      allVisibleSelected: function(vm) {
        return vm.selectedIris.length === vm.preppedRowData.filter(row => !row['@subgroup'] && !row['@header']).length
      },
      selectedRowItems: function(vm) {
        return vm.selectedIris.map(iri => {
          let item = vm.rows.find(row => row.iri.value == iri)
          if(!item) return null
          let reduced = {}
          for(const [key, content] of Object.entries(item)) {
            reduced[key] = content.hasOwnProperty('value') ? content.value : content
          }
          return reduced
        })
                              
      }
    },
  
  methods: {
    toggleAllVisible: function(event) {
      if(!this.selectionMode) { return }
      if(event) {
        let newSelect = this.preppedRowData.filter(row => !row['@header'] && !row['@subgroup'])
        newSelect.forEach(row => {
          if(row?.iri?.value) {
            this.updateSelection(row.iri.value,true)
          }
          })
      } else {
        let toRemove = [...this.selectedIris]
        toRemove.forEach(iri => this.updateSelection(iri,false))
      }
    },  
    toggleGroup(event, idx) {
        for(let i = idx; i < this.preppedRowData.length; i++) {
          if(i > idx && this.preppedRowData[i] && this.preppedRowData[i]['@header']) break;
          if(this.preppedRowData[i]['@header']) continue;
          this.updateSelection(this.preppedRowData[i].iri.value,event)
        }
    },
    updateSelection: function(iri, val) {
      //add to selection abject
      let idx = this.selectedIris && this.selectedIris.findIndex(e => e == iri) || -1
      if(this.sendToSelector) {
        this.selectedIris = this.sendToSelector(this.getOrigin().module ,iri, val) ?? []
      } else {        
        if(!val) {
          this.selectedIris.splice(idx, 1)
        } else {
          this.selectedIris.splice(this.selectedIris.length, 0, iri)
        }        
      }
      this.$emit('select',this.selectedIris)
    },
    toggleSelectionMode: function(val) {
      this.selectionMode = val
    },
  },
  data() {
    return {
      selectedIris: [],
      selectionMode: true
    }
  }
};