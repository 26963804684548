<i18n lang="yaml" locale="de">
  filters.group.down: "Gruppieren"
</i18n>
<i18n lang="yaml" locale="en">
  filters.group.down: "Group"
</i18n>
<template>
<div>                 
    <b-button
    @click="(groupPosition > -1) ? turnGroupOff() : $emit('setGroupData',groupConfiguration);"
        block
        squared
        variant="light"
        class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
    >
      <b-icon-list-ul
        :variant="'dark'"
      ></b-icon-list-ul>
      <div class="small flex-grow-1 text-left pl-1">
          {{ $t('filters.group.down') }}
      </div>
      <b-badge v-if="groupPosition > 0"
      >{{ groupPosition }}</b-badge>
    </b-button>
</div>
</template>
<script>
export default {
  name: "baseCellGroupSelect",
  props: {
    groupData: {
      required: true,
      type: Object
    },
    groupPosition: {
      required: true,
      type: Number
    },
    groupTag: {
      required: false,
      type: String
    },
    config: {
      type: Object,
      required: true
    }
  },
  watch: {
    'groupTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.groupData || !this.groupData.groupHandler)) {
          this.updateByTag(newVal)
        }
      }
    }
  },
  computed: {
    groupConfiguration: function(vm) {
      return {'groupHandler' : vm.groupHandler, 
              'groupLabeller' : vm.groupLabeller(),
              'groupSorter' : vm.groupSorter(),
              'groupTag' : '+', 
              'groupData' : {}}
    }
  },
  methods: {
    updateByTag: function(tag) {
      this.$emit('setGroupData',this.groupConfiguration)
    },
    groupHandler: function(cellData, groupData, column) {
      let val = (cellData.hasOwnProperty('value') ? cellData.value : cellData)
      return val ?? ""
    },
    groupLabeller: function() {
      let cfg = this.config.selectOptions.entries || []
      return (fieldName) => (cfg.find(i => i.value == fieldName) || {text: {}}).text[this.$root.$i18n.locale] || fieldName
    },
    groupSorter: function() {
      let cfg = this.config.selectOptions.entries || []
     return (a,b) => {
          let aVal =  (typeof a == "object" && a.hasOwnProperty('value')) ? a.value : a
          let bVal =  (typeof b == "object" && b.hasOwnProperty('value')) ? b.value : b
          let aItem = cfg.find(i => i.value == aVal) || {}
          let bItem = cfg.find(i => i.value == bVal) || {}
          if(aItem.hasOwnProperty('sortValue') && bItem.hasOwnProperty('sortValue')) {
            return aItem.sortValue - bItem.sortValue
          } else {
            return ((aItem && aItem.text && aItem.text[this.$root.$i18n.locale]) ?? aItem.text ?? aItem.value ?? '').localeCompare((bItem && bItem.text && bItem.text[this.$root.$i18n.locale]) ?? bItem.text ?? bItem.value ?? '')
          }
     }
    },
    turnGroupOff() {
      this.groupDirection = 0
      this.$emit('groupOff')
    },

  }
}
</script>