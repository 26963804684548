<i18n locale="de" lang="yaml">
    restrictions: "Beschränkungen"
    title.restriction.for: "Feld"
    title.restrictor.read: "Lesen"
    title.restrictor.write: "Schreiben"
    restriction.global: "Global"

    restriction.create.fieldName: "Feld, das beschränkt werden soll"
    restriction.create.read: "Berechtigung zum Lesen"
    restriction.create.write: "Berechtigung zum Schreiben"
    restriction.create.failed: "Konnte Beschränkung nicht erstellen"
    restriction.create.exists: "Feld ist schon beschränkt"
    restriction.create.success: "Beschränkung erstellt"

    addedField.typelist.text: "Einfaches Textfeld"
    addedField.typelist.number: "Zahlenfeld"
    addedField.typelist.boolean: "Checkbox"
    addedField.typelist.date: "Datum"
    addedField.typelist.currency: "Währung"
    addedField.typelist.markup: "Beschreibungsfeld (langer Text)"
    addedField.typelist.history: "Dateistapel"


    title.addedFields.name: "Interne Feldbezeichnung"
    title.addedFields.type: "Feldtyp"
    title.addedFields.group: "Zugeordnete Gruppe"
    title.addedFields.labelling: "Bezeichnungen"

    addedFields.create.fieldName: "Interner Feldname"
    addedFields.create.fieldType: "Feldtyp"
    addedFields.create.fieldGroup: "Gruppenzugehörigkeit"
    addedFields.create.labels.de: "Anzuzeigender Titel (de)"
    addedFields.create.labels.en: "Anzuzeigender Titel (en)"

    addedField.create.exists: "Ein Feld mit dieser Bezeichnung existiert bereits in diesem Modul"
    addedFields.create.success: "Feld wurde hinzugefügt"
    addedFields.create.failed: "Feld konnte nicht hinzugefügt werden"
</i18n>
<i18n locale="en" lang="yaml">
    restrictions: "Restrictions"
    title.restriction.for: "Field"
    title.restrictor.read: "Read"
    title.restrictor.write: "Write"
    restriction.global: "Global"

    restriction.create.fieldName: "Field to restrict"
    restriction.create.read: "Read restrictor"
    restriction.create.write: "Write restrictor"
    restriction.create.failed: "Restriction could not be created"
    restriction.create.exists: "Field already has restrictions"
    restriction.create.success: "Restriction created"

    addedField.typelist.text: "Simple text field"
    addedField.typelist.number: "Number field"
    addedField.typelist.boolean: "Checkbox"
    addedField.typelist.date: "Date"
    addedField.typelist.markup: "Description (long text)"
    addedField.typelist.currency: "Currency"
    addedField.typelist.history: "File stack"

    title.addedFields.name: "Internal field name"
    title.addedFields.type: "Field type"
    title.addedFields.group: "Attached group"
    title.addedFields.labelling: "Labelling"

    addedFields.create.fieldName: "Internal field name"
    addedFields.create.fieldType: "field type"
    addedFields.create.fieldGroup: "Attached group"
    addedFields.create.labels.de: "Display text (de)"
    addedFields.create.labels.en: "Display text (en)"

    addedField.create.exists: "Field name already exists"
    addedFields.create.success: "Field successfully added"
    addedFields.create.failed: "Field could not be added"

</i18n>
<template>
  <collapse-box :id="$id('restrictions')" :title="title" hidden>
        <collapse-box :id="$id('restrictions')" :title="$t('restrictions')">            
            <table class="">
                <thead class="bg-secondary">
                    <tr>
                        <th class="pr-2">
                            <b-button variant="success" size="sm" v-b-modal="$id('addRestr')" class="py-0 px-1" :disabled="disabled"><b-icon-plus /></b-button>
                        </th>
                        <th>{{ $t('title.restriction.for') }}</th>
                        <th class="px-2">{{ $t('title.restrictor.read') }}</th>
                        <th class="px-2">{{ $t('title.restrictor.write') }}</th>
                    </tr>
                </thead>
                <tbody  class="pt-1 border-top border-secondary moduleConfigurationTable">
                    <tr class="border-bottom border-secondary font-weight-bold">
                        <td><b-button variant="danger" size="sm" class="py-0 px-1" @click="removeRestriction('@global')" :disabled="disabled"><b-icon-x /></b-button> </td>
                        <td class="pr-4 border-right border-secondary">{{ getFieldLabel(globalRestriction.field) }}</td>
                        <td class="px-2">{{ globalRestriction.read || '' }}</td>
                        <td class="px-2">{{ globalRestriction.write || '' }}</td>
                    </tr>
                    <tr v-for="restriction in otherRestrictions"  :key="$id('restr-'+restriction.id)">
                        <td><b-button variant="danger" size="sm" class="py-0 px-1" :disabled="disabled"><b-icon-x /></b-button> </td>
                        <td class="pr-4 border-right border-secondary">{{ getFieldLabel(restriction.field) }}</td>
                        <td class="px-2">{{ restriction.read || '' }}</td>
                        <td class="px-2">{{ restriction.write || '' }}</td>
                    </tr>
                </tbody>
            </table>
            <b-modal size="lg" :id="$id('addRestr')" @ok="buildNewRestriction()">
                {{ $t('restriction.create.fieldName') }} <b-form-select 
                    :disabled="disabled"
                    v-model="createRestriction.field"
                    :options="createRestrictionDropdown" />
                {{ $t('restriction.create.read') }} <b-form-input v-model="createRestriction.readAccessor" :disabled="disabled"/>
                {{ $t('restriction.create.write') }} <b-form-input v-model="createRestriction.writeAccessor" :disabled="disabled"/>

            </b-modal>
        </collapse-box>
        <collapse-box :id="$id('addedfields')" :title="$t('addedFields')">
            <b-button variant="success" size="sm" v-b-modal="$id('addField')"><b-icon-plus /></b-button>
            <table class="">
                <thead class="bg-secondary">
                    <tr>
                        <th>{{ $t('title.addedFields.name') }}</th>
                        <th class="px-2">{{ $t('title.addedFields.type') }}</th>
                        <th class="px-2">{{ $t('title.addedFields.group') }}</th>
                        <th class="px-2">{{ $t('title.addedFields.subitem') }}</th>
                        <th class="px-2">{{ $t('title.addedFields.labelling') }}</th>
                    </tr>
                </thead>
                <tbody  class="pt-1 border-top border-secondary moduleConfigurationTable">
                    <tr v-for="(addedField,idx) in addedFieldList"  :key="$id('af-'+(addedField.id || idx))">
                        <th>{{ addedField.name }}</th>
                        <th class="px-2">{{ addedField.type }}</th>
                        <th class="px-2">{{ addedField.group }}</th>
                        <th class="px-2">{{ addedField.subitem || '' }}</th>
                        <th class="px-2 d-flex flex-column">
                            <div>DE: {{ addedField.label && addedField.label['de'] || '' }}</div>
                            <div>EN: {{ addedField.label && addedField.label['en'] || '' }}</div>
                        </th>
                    </tr>
                </tbody>
            </table>
            <b-modal size="lg" :id="$id('addField')" @ok="buildNewAddedField()">
                {{ $t('addedFields.create.fieldName') }} <b-form-input 
                    v-model="createAddedField.name" />
                {{ $t('addedFields.create.fieldType') }} <b-form-select 
                    v-model="createAddedField.type"
                    :options="addedFieldTypeList" />
                {{ $t('addedFields.create.fieldGroup') }} <b-form-select 
                    v-model="createAddedField.grouping"
                    :options="addedFieldGroupList" />
                {{ $t('addedFields.create.subitem') }} <b-form-input 
                    v-model="createAddedField.subitem" />
                {{ $t('addedFields.create.labels.de') }} <b-form-input v-model="createAddedField.label.de" />
                {{ $t('addedFields.create.labels.en') }} <b-form-input v-model="createAddedField.label.en" />

            </b-modal>
        </collapse-box>
  </collapse-box>
</template>

<script>
export default {
    name: "BaseModuleConfigurator",
    props: {
        addedFields: Array,
        restrictions: Array,
        fields: Array,
        groups: Array,
        moduleIri: String,
        title: String,
        disabled: Boolean,
        owner: String
    },
    computed: {
        globalRestriction: function(vm) {
            let gRes = (vm.restrictions ?? []).filter(restriction => restriction.field == "@module")
            if(gRes.length == 1) return gRes[0]
            return {}
        },
        otherRestrictions: function(vm) {
            return (vm.restrictions ?? []).filter(restriction => restriction.field !== "@module")
        },

        createRestrictionDropdown: function(vm) {
            let globalOption = {name: '@global', label: {'de' : 'Global', 'en' : 'Global'}}
            let basicFieldsOptions = vm.fields.map(f => ({"value": f.name, "text": f.label && f.label[vm.$root.$i18n.locale] || f.name}))
            let addedFieldsOptions = vm.addedFields.map(f => ({"value": f.name, "text": f.label && f.label[vm.$root.$i18n.locale] || f.name}))
            return [globalOption, ...basicFieldsOptions,...addedFieldsOptions]
        },
        addedFieldTypeList: function(vm) {
            return [
                {value: 'text', text: this.$t('addedField.typelist.text')},
                {value: 'number', text: this.$t('addedField.typelist.number')},
                {value: 'boolean', text: this.$t('addedField.typelist.boolean')},
                {value: 'date', text: this.$t('addedField.typelist.date')},
                {value: 'currency', text: this.$t('addedField.typelist.currency')},
                {value: 'history', text: this.$t('addedField.typelist.history')},
                {value: 'markup', text: this.$t('addedField.typelist.markup')}
            ]
        },
        addedFieldGroupList: function(vm) {
            return vm.groups.map(g => ({
                "value": g.name,
                "text": g.label && g.label[vm.$root.$i18n.locale] || g.name
            }))
        },
        addedFieldList: function(vm) {
            return vm.addedFields.map(af => ({
                "name" : af.name,
                "type" : vm.addedFieldTypeList.find(field => field.value == af.type).text,
                "group" : vm?.addedFieldGroupList?.find(field => field.value == af.group)?.text || '',
                "label": af.label
            }))
        }
    },
    inject: {
        "saveAddedField" : {name: "saveAddedField", default: false }
    },
    methods: {
        getFieldLabel: function(fieldName) {
            if(fieldName == '@module') return this.$t('restriction.global')
            let field = this.fields.find(f => f.name == fieldName)
            if(!field) return this.$t('restriction.unknown')
            return field.label && field.label[this.$root.$i18n.locale] || fieldName
        },
        buildNewRestriction: async function() {
            //check that the field isnt already restricted
            
            let existing = this.restrictions.find(r => r.field == this.createRestriction.field)
            if(existing) {
                this.$bvToast.toast(this.$t('restriction.create.exists'), {variant: "danger"})
                return
            }

            if(!this.moduleIri) return 


            //@todo: send restriction to the server
            let createObject = {...this.createRestriction, "module": this.moduleIri}
            try {
                await this.$cache.create('owner','/restrictions',createObject)

                this.restrictions.push(this.createRestriction)
                this.createRestriction = { readAccessor: '', writeAccessor: '', field: '@global'}

                this.$emit('updateRestrictions', this.restrictions)
                this.$bvToast.toast(this.$t('restriction.create.success'), {variant: "success"})
            } catch {
                this.$bvToast.toast(this.$t('restriction.create.failed'), {variant: "danger"})
            }
        },
        buildNewAddedField: async function() {
            //check that the field isnt already restricted
            if(this.saveAddedField) {
                this.saveAddedField(this.createAddedField, this.owner)
            } else {
                let existing = this.addedFields.find(r => r.name == this.createAddedField.name)
                if(existing) {
                    this.$bvToast.toast(this.$t('addedField.create.exists'), {variant: "danger"})
                    return
                }

                if(!this.moduleIri) return 


                //@todo: send restriction to the server
                let createObject = {...this.createAddedField, "module": this.moduleIri}
                try {
                    await this.$cache.create('owner','/added_fields',createObject)

                    this.addedFields.push(this.createObject)
                    this.createAddedField = {name: '', grouping: '', label: {de: '', en: ''}, subitem: ''}

                    this.$emit('updateAddedFields', this.addedFields)
                    this.$bvToast.toast(this.$t('addedFields.create.success'), {variant: "success"})
                } catch {
                    this.$bvToast.toast(this.$t('addedFields.create.failed'), {variant: "danger"})
                }
            }
        },
    },
    data() {
        return {
            createRestriction: { readAccessor: '', writeAccessor: '', field: '@global'},
            createAddedField: {name: '', grouping: '', label: {de: '', en: ''}, subitem: ''}
        }
    }
}
</script>

<style lang="scss">
    .moduleConfigurationTable {
        & tr:nth-child(odd) {
            background-color: #eee;
        }
    }
</style>