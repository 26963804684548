
<script>
export default {
  functional: true,
  name: "BaseCellDisplaySelect",
  render: function(createElement, context) {

    let val = (context.props.value && (typeof context.props.value == "object" && !Array.isArray(context.props.value)) ? context.props.value.value : context.props.value) ?? null
    if(val === null) return createElement('td',{attrs: {class: "base-table--cell "}})

    if(!context.props.config.selectOptions) return createElement('td',{attrs: {class: "base-table--cell "}})

    //get all active text items
    let valArray = context.props.config.selectOptions.multiple ? val : [val]

    let tokens = valArray.map(
        item => (context.props.config.selectOptions.entries.find(
                              i => item == i.value) || {text: {}}).text[context.parent.$root.$i18n.locale] 
        )
    let nodes = []
    if(context.props.config.selectOptions.multiple) {
      nodes = tokens.map(item => createElement('b-badge',{props: {variant: "light"}},item))
    } else {
      nodes = [createElement('span',{attrs: { class: "text-truncate "}},tokens[0])]
    }
    let suffix = null
    if(context.props.config.suffixComponent) {
      suffix = createElement(context.props.config.suffixComponent, {props: {
        value : context.props.value.value,
        valueObject : context.props.value,
        row: context.props.row
      }})

      nodes.push(suffix)
    }


    return createElement('td',{
        attrs: {
          class: "base-table--cell "+(context.props.value && context.props.value.cellClasses || ''),
          style: (context.props.config['@stickyStyle'] || '')
          }
        },
        [
          createElement('div',{attrs: {class: "d-inline-flex justify-content-start align-items-center overflow-auto ", style: 'width: '+(32+(context.props?.config?.['@width'] || 0))+'px;'+(context.props.config['@stickyStyle'] || '')}},nodes)
        ])
  }
}
</script>
<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
