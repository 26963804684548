<i18n lang="yaml" locale="de">
  weeks: 'Heute | {n} Woche | {n} Wochen'
  allPast: "Alle vergangenen"
  allFuture: "Alle zukünftigen"
  label.from: "ab"
  label.to: "bis"
</i18n>
<i18n lang="yaml" locale="en">
  weeks: 'Today | {n} week | {n} weeks'
  allPast: "all past"
  allFuture: "all future"
  label.from: "from"
  label.to: "to"
</i18n>

<template>
  <div>       
    <b-button
      block 
      variant="light" 
      @click="$emit('setFilterStatus',!filterStatus)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
      >
         <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('filters.filter.filter') }}
      </div>
    </b-button>

    <b-button
      block 
      :variant="filterTypeMode == 'past' ? 'secondary' : 'light'" 
      @click="updateFilter(-Infinity)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center my-0"
      >
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('allPast') }}
      </div>
    </b-button>

    <b-button
      v-for="(cnt) in [-2,-1,0,1,2]"
      :key="$id('btn-'+cnt)"
      block 
      :variant="filterTypeMode == 'range' && cnt == rangeModifier ? 'secondary' : 'light'" 
      @click="updateFilter(cnt)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center my-0"
      >
      <div class="small flex-grow-1 text-left pl-1">
       {{ $tc('weeks',cnt) }}
      </div>
    </b-button>
    
    <b-button
      block 
      :variant="filterTypeMode == 'future' ? 'secondary' : 'light'" 
      @click="updateFilter(Infinity)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center my-0"
      >
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('allFuture') }}
      </div>
    </b-button>

    <div class="p-1" :class="{'bg-secondary' : filterTypeMode == 'exact'}" @click="rangeModifier = null;updateFilter()">
      <b-input-group style="width: 195px;" class="mb-1">
        <b-input-group-prepend is-text style="width: 45px;">
          {{ $t('label.from') }}
        </b-input-group-prepend>
        <b-form-input 
            type="date" 
            :value="exactFrom && exactFrom.format('YYYY-MM-DD')" 
            @input="setExactFrom($event)" 
        />
        <b-input-group-append>
          <b-button variant="danger" class="p-0"  @click="setExactFrom(null)"><b-icon-x /></b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group style="width: 195px;">
        <b-input-group-prepend is-text style="width: 45px;">
          {{ $t('label.to') }}
        </b-input-group-prepend>
        <b-form-input 
            type="date" 
            :value="exactTo && exactTo.format('YYYY-MM-DD')" 
            @input="setExactTo($event)" 
        />
        <b-input-group-append>
          <b-button variant="danger" class="p-0"  @click="setExactTo(null)"><b-icon-x /></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-input class="d-none" type="text" />
  
  </div>
</template>

<script>

const moment = require('moment')

export default {
  name: "baseCellFilterDate",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: Object
    },
    filterTag: {
      type: String
    }
  },
  //automatically push the filter back up on initialization
  watch: {
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.filterValue || !this.filterValue.filterHandler)) {
          this.buildFromTag(newVal)
          this.updateFilter()
        }
      }
    }
  },
  data() {
    return {
      exactFrom: null,
      exactTo: null,
      rangeModifier: null
    }
  },
  computed: {
    filterTypeMode: function(vm) {
      switch(true) {
        case vm.rangeModifier == -Infinity: return "past"
        case vm.rangeModifier == Infinity: return "future"
        case vm.rangeModifier !== null: return "range"
        default: return "exact"
      }

    },
    filterConfiguration: function(vm) {
      return {
          type: vm.filterTypeMode,
          exact: {
            from: vm.exactFrom,
            to: vm.exactTo
          },
          range: {
            rangeString: vm.rangeModifier
          }

        }
    },
    filterObject: function(vm) {
      return {
        "filterHandler": vm.filterHandler(),
        "filterTag" : vm.filterTagComputed,
        "filterValue" : vm.filterConfiguration
      }
    },
    filterTagComputed: function(vm) {
      return JSON.stringify(vm.filterConfiguration)
    }
  },
  methods: {  
    buildFromTag(newVal) {
      let item = JSON.parse(newVal)
      this.exactFrom = moment(item.exact.from)
      this.exactTo = moment(item.exact.to)
      this.rangeModifier = item.range.rangeString
    },  
    setExactFrom(newVal) {      
      this.exactFrom = moment(newVal)
      if(this.exactFrom.isValid()) {
        this.rangeModifier = null
      }
      this.updateFilter()
    },  
    setExactTo(newVal) {      
      this.exactTo = moment(newVal)
      if(this.exactTo.isValid()) {
        this.rangeModifier = null
      }
      this.updateFilter()
    },


    /**
     * updateFilter
     * pushed the filter object to store in the table
     * The filter object needs to have the following fields:
     * 
     *  filterHandler   Function        the function called by the table 
     *  filterTag       Array | String  the tag(s) defined by this filter
     *  filterValue     any             the filter value as defined by this filter
     * 
     */
    updateFilter(rangeModifier = null) {
      if(rangeModifier !== null) {
        this.rangeModifier = rangeModifier
      }
      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue',this.filterObject)
    },

    /**
     * The filterHandler is a function that returns a boolean
     * it gets handed the arguments:
     * 
     * cellData     any   the cell data as specified by the type
     * filterValue  any   the filterValue as stored in the filter value
     * columnData   any   the full column configuration
     */
    filterHandler: function() {
      
      let rangeMoment,maxLimitMoment,minLimitMoment, now
      now = moment().startOf('day')
      if(this.filterTypeMode == "range") {
        rangeMoment = moment().startOf('day').add(this.rangeModifier, 'weeks')
        maxLimitMoment = moment.max(rangeMoment,now)
        minLimitMoment = moment.min(rangeMoment,now)
      }

      return (cellDataPure, filterData, columnData) => {
        if(!cellDataPure || !cellDataPure.value) return false

        let cellData = moment.isMoment(cellDataPure) ? moment(cellDataPure) : (typeof cellDataPure == "object" ? moment(cellDataPure.value) : moment(cellDataPure))
        //make it automatically start of day
        cellData = cellData.startOf('day')
        switch(filterData.type) {
          case "exact" : 
            if(!filterData.exact) {
              console.error("Filter misconfiguration for Date Filter")
              return true
            }
            if(filterData.exact.from && filterData.exact.from.isValid() && cellData.isBefore(filterData.exact.from.startOf('day'))) {
              return false
            }
            if(filterData.exact.to && filterData.exact.to.isValid() && cellData.isAfter(filterData.exact.to.endOf('day'))) {
              return false
            }
            return true
            break;

          case "range" :
            if(!filterData.range || !maxLimitMoment || !minLimitMoment) {
              console.error("Filter range misconfiguration for Date Filter")
              return true
            }
            return minLimitMoment.isSameOrBefore(cellData) && maxLimitMoment.isSameOrAfter(cellData)
            break;
          case "past" :
            return now.isSameOrAfter(cellData)
          case "future" :
            return now.isSameOrBefore(cellData)
        }
      //failsafe
      return true
      }        
    }
  }
}
</script>