<i18n locale="en" lang="yaml">
    pickacolor: "Selected color"
</i18n>

<i18n locale="de" lang="yaml">
    pickacolor: "Farbauswahl"
</i18n>
<template>
  <div 
    class="swatch-color-picker py-1 d-flex justify-content-between align-items-center"
    :id="$id('picker')" >
      {{ $t('pickacolor') }}<b-avatar 
        href="#"
        size="sm" 
        square
        :style="'background-color:'+value+';'" >&nbsp;</b-avatar>
      <b-popover 
        :target="$id('picker')"
        @shown="$emit('focus',true)"
        @hidden="$emit('blur',false)"
        >
          <div class="d-flex flex-nowrap">
              <div 
                v-for="(colorSet, idx) in colorArray"
                :key="$id('colorRow-'+idx)"
                class="d-flex flex-column mr-1">
                  <b-avatar 
                    v-for="(color,idy) in colorSet"
                    :key="$id(idx+'-'+color.name+'-'+idy)"
                    square
                    size="sm" 
                    href="#"
                    class="mb-1"
                    :style="'background-color:'+color.rgb+';'"
                    :title="color.name"
                    v-b-tooltip.top.d1000
                    @click="$emit('input',color.rgb);$root.$emit('bv::hide::popover', $id('picker'))">&nbsp;</b-avatar>
              </div>
          </div>

          <div class="d-flex mt-2 flex-wrap" style="width:140px;">
            <b-avatar 
                    v-for="(color,idy) in basicColors"
                    :key="$id('base-'+color.name+'-'+idy)"
                    square
                    size="sm" 
                    href="#"
                    class="mr-1 mb-1"
                    :style="'background-color:'+color.rgb+';'"
                    :title="color.name"
                    v-b-tooltip.top.d1000
                    @click="$emit('input',color.rgb);$root.$emit('bv::hide::popover', $id('picker'))">&nbsp;
              </b-avatar>
          </div>
      </b-popover>
  </div>
</template>

<script>

import { gray, blue, red, yellow, green, basicColors } from './swatchlist'

export default {
    name: "BaseColorPicker",
    props: {
        value: {
            required: true,
            type: null
        }

    },
    data() {
        return {
            colorArray: [gray, red, blue, yellow, green],
            basicColors: basicColors
        }
    }
}
</script>

<style>
    .swatch-color-picker {
        cursor: pointer;
    }
</style>