<i18n locale="en" lang="yaml" >
    confirm.remove.approval: "Are you sure you want to remove the approval?"
    confirm.remove.yes: "Yes, remove approval"
    confirm.remove.no: "No"
    approval.required: "Approval pending"
    approval.not.required: "not required / optional"
    modal.approve: "Approve request"
    modal.decline: "Decline request"
    modal.unset: "Reset field"
</i18n>
<i18n locale="de" lang="yaml" >
    confirm.remove.approval: "Soll die Freigabe wirklich gelöscht werden?"
    confirm.remove.yes: "Ja, Freigabe löschen"
    confirm.remove.no: "Nein"
    approval.required: "Freigabe ausstehend"
    approval.not.required: "nicht benötigt / optional"
    modal.approve: "Anfrage bestätigen"
    modal.decline: "Anfrage ablehnen"
    modal.unset: "Feld zurücksetzen"
</i18n>
<template>    
    <td class="base-table--cell" 
     :class="value && value.cellClasses || ''"
        >
        <div class="d-flex align-items-center no-wrap" :title="tooltipForAll">
            <div class="pr-1 small ">
                {{ totalSumOfValues }}
            </div>
            <div class="d-flex flex-column" style="max-height: 100%; width: 25px;">
                <b-progress 
                    v-for="(item , idx) in localData.data"
                    :id="$id('pb-'+idx)"
                    class="w-100 rounded-0" 
                    :style="'height:'+localData.barHeight+'; background-color: #0000000F;'" 
                    :max="totalSumOfValues"
                    >
                    <b-progress-bar :value="item.amount" :style="'background-color: '+item.color+';'"></b-progress-bar>
                </b-progress>
            </div>
        
        </div>
    </td>

</template>
<script>
const moment = require("moment")

 export default {
  name: "baseCellDisplayEvaluator",
  props: {
    value: {
      type: null
    },
    config: {
      type: Object
    },
    row: {
        type: Object
    }
  },
  
    watch: {
        "value" : {
            immediate: true,
            handler: function() {
                this.localData = this.value
            }
        }
    },
    methods: {
    },
    computed: {
        tooltipForAll: function(vm) {
            return vm.localData?.data?.map(item => item.label + ': ' + item.amount).join('\n');
        },
        totalSumOfValues: function(vm) {
            return vm.localData.data.reduce((p,e) => e.amount + p , 0);
        },
        numberOfDataPoints: function(vm) {
            return vm.localData.data.length
        },
    },
    data() {
        return {
            localData: null
        }
    }
 }
</script>
<style lang="scss" scoped>

</style>
