<i18n locale="en" lang="yaml">
    tooltip.create: "Create a new element in this table"
    tooltip.reset: "Reset"
    reset.content: "Reset to default state?"
    reset.title: "Reset?"
    reset.ok: "Yes"
    reset.cancel: "No"
    toast.success.body: "Element successfully created"
    toast.success.title: "Creation success"
    toast.failure.body: "Element creation failed"
    toast.failure.title: "Creation failed"
</i18n>

<i18n locale="de" lang="yaml">
    tooltip.create: "Neues Element in dieser Tabelle erstellen"
    tooltip.reset: "Zurücksetzen"
    reset.content: "Felder zurücksetzen?"
    reset.title: "Zurücksetzen?"
    reset.ok: "Ja"
    reset.cancel: "Nein"
    toast.success.body: "Element erfolgreich erstellt"
    toast.success.title: "Erstellung erfolgreich"
    toast.failure.body: "Element konnte nicht erstellt werden"
    toast.failure.title: "Erstellung fehlgeschlagen"
</i18n>
<template>
    <tr
        class="base-table--row" 
        >
        <td v-if="creating">
            <b-spinner small variant="dark" />
        </td>
        <td v-else> 
            <b-badge href="#" variant="success" @click="create()" v-b-tooltip.window.bottom.d600 :title="$t('tooltip.create')">
                <b-icon-check />
            </b-badge>
            <b-badge href="#" variant="danger" @click="reset()" v-b-tooltip.window.bottom.d600 :title="$t('tooltip.reset')">
                <b-icon-x />
            </b-badge>
        </td>
        <td v-if="foldingStatus !== null"></td>
        <td v-if="!noLink"></td>
         <base-cell-selector
            v-for="(cell,idx) in columnData" 
            :key="$id('-bc-'+idx)" 
            :value="safeGet(cell.name)"
            :config="cell"
            :mode="cell.immutable ? 'Display' : 'Edit'"
            @input="addToCreateObject(cell.name,$event)"
        ></base-cell-selector>
    </tr>
</template>
<style lang="scss">
</style>
<script>

import componentMixin from './subcomponents.js'

export default {
    name: "createRow",
    mixins: [componentMixin],
    props: {
        foldingStatus: {
            required: false,
            type:Boolean,
            default: null
        },
        defaultData: {
            required: true,
            type: Object
        },
        createHandler: {
            required: true,
            type: Function
        },
        relateTarget: {
            required: false,
            type: Object
        },
        columnData: Array,
        indent: Number,
        noLink: Boolean
    },
    data() {
        return {
            createBody: this.defaultData,
            creating: false
        }
    },
    provide() {
        return {
            "getOwnerIri": () => this.relateTarget && this.relateTarget.owner || this.defaultData && this.defaultData.owner
        }
    },
    watch: {
        'defaultData' : {
            immediate: true,
            handler: function(newVal) {
                this.createBody = {...newVal}
            }
        }
    },
    inject: {
        getOrigin: 'getOrigin'
    },
    computed: {
    },

    methods: {
        safeGet(name) {
            return this.createBody[name] ?? this.defaultData[name] ?? null
        },
        create: async function(ev) {
            try {
                this.creating = true
            let result = await this.createHandler(this.createBody, this.relateTarget ?? null)
            this.$bvToast.toast(this.$t('toast.success.body'), {
                title: this.$t('toast.success.title'),
                solid: true,
                variant: "success"
                })
            
            this.$emit('elementAdded',result)
            this.reset(null, true)
            } catch {
                this.$bvToast.toast(this.$t('toast.failure.body'), {
                    title: this.$t('toast.failure.title'),
                    solid: true,
                    variant: "danger"
                    })
            } finally {
                this.creating = false
            }

        },
        reset: async function(ev, force = false) {
            if(!force) {
                let choice = await this.$bvModal.msgBoxConfirm(this.$t('reset.content'), {
                    title: this.$t("reset.title"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t('reset.ok'),
                    cancelTitle: this.$t('reset.cancel'),
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true
                })
                if(!choice) return
            }
            this.createBody = Object.assign({},this.defaultData)
        },
        addToCreateObject: function(name, val) {
            if(!this.createBody.hasOwnProperty(name)) {
                this.$set(this.createBody,name, val)
            } else {
                this.createBody[name] = val
            }
            
        }
    }
}
</script>