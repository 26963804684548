

export default {
    props: {
      /**
       * an array of columns defining the table structure
       */
        columns: Array,
        /**
         * the provided array of rows.
         * 
         */
        rows: Array,
        /**
         * If set, the table doesn't add columns from the owners 
         * and ignores any owner context that might be set
         */
        noOwner: {
            type: Boolean,
            default: false
        },
        /**
         * this allows to define the owners that should be used for the table,
         * superceding any context.
         */
        owners: {
            type: [Array, Object,String],
            default: () => null,
            required: false
        },
        /**
         * if table is context sensitive, it applies a filter by the owners in the ownerContext
         * fetched from the Vuex store.
         */
        contextSensitive: {
          type: Boolean,
          default: true
        },
        /**
         * 
         */
        additionalSearchHandler: {
          type: null,
          default: null
        }
    },
    watch: {
      'rows': {
        immediate: true,
        handler: function(allRows) {
          this.rows.forEach((_,idx) => {
              this.applyFiltersToRow(this.rows[idx])
          })
          this.buildFilteredRows()
        }
      },
      'baseSearchTerm' : function() {
        this.buildFilteredRows()
      },
      'columns' : {
        immediate: true,
        handler: function() {
          this.buildAllColumns(this.columnsWithAddedFields)
        }
      },
      'filter' : {
        immediate: true,
        handler: function() {
          this.importFilter()
        }
      },
      '$store.getters.getOwnerContext' : {
        handler: function() {
          this.buildFilteredRows()
        }
      },
      '$store.getters.getMeetingModes' : {
        handler: function() {
          this.buildFilteredRows()
        }
      }
    },
    computed: {
/**
 * * * * * * * * * * * * * *
 * COLUMNS
 * * * * * * * * * * * * * * 
 */    

      selectedShownColumns: function(vm) {
        return vm.enhancedColumns.filter(col => (col.show ?? true) && (col.hasOwnProperty('table') ? col.table : true) !== false )
      },
      enhancedColumns(vm) {
        let lang = vm.$root.$i18n.locale
        let relevantColumns = vm.shownColumns || vm.allColumns
        vm.applyColumnOrdering(relevantColumns)

        let stickyOffset = 28+(vm.showSubgroups ? 15 : 0)+(vm.noLink ? 0 : 19);
        return relevantColumns.map((col,idx,rCols) => {
          let assignedWidth = this.widths[col.name] ?? col['@width'] ?? col['width'] ?? 100
          let stickyData = {}
          if(!!col['@sticky']) {
            stickyData['@stickyStyle'] = 'z-index:2; left:'+stickyOffset+'px;position: sticky;'
            stickyOffset += assignedWidth + 32
          }
          return Object.assign(col, {'@width' : assignedWidth}, {'@lang' : lang}, stickyData)
        }) 
      },
      columnsWithAddedFields(vm) {
        if(vm.noOwner ) { vm.buildAllColumns(vm.columns) }
        let moduleName = vm.getOrigin().ownerModuleName ?? vm.getOrigin().module;
        let baseConfig = vm.columns;
        if(!vm.$libraries.isAvailable('owner')) return []
        vm.allRowOwners.forEach(owner => {
          
          let ownerObject =  vm.$store.getters['owner/getItems'] && vm.$store.getters['owner/getItems'][owner] || []
          let moduleSettings =
          ownerObject &&
          ownerObject.modules &&
          ownerObject.modules.filter(elem => elem.name === moduleName)[0];
          if (moduleSettings && moduleSettings.addedFields) {
            moduleSettings.addedFields.filter(field => !field.subitem || field.subitem == "").forEach(field => {
              let adjField = {isAddedField: true, optional: true, ...field.config, ...field}
              
              if (!!field.order) {                
                baseConfig.splice(field.order, 0, adjField);
              } else {
                baseConfig.push(adjField);
              }
            });
          }


        })
        vm.buildAllColumns(baseConfig)
        return baseConfig
      },
      columnsForViewSelector: function(vm) {        
        return vm.enhancedColumns.filter(col => col.hasOwnProperty('table') ? col.table : true)
                        .map(col => {
                          let text = vm.getLanguageDependentDescription(col.label)
                          if(Array.isArray(text)) {
                            text = text.join(' ')
                          }
                          if(!col.hasOwnProperty('show')) {
                            col.show = true
                          }
                          return {value: col, text: text }})
      },






      getGroupPosition(vm) {
        return name => vm.groupData.indexOf(name);
      },
        headerOffset: (vm) => vm.groupData.length > 0 ? vm.groupData.length : 0,

      ownerIris: function(vm) {
        if(!vm.owners) return null
          switch(true) {
            case typeof vm.owners == "string" : return [vm.owners]; 
            case !Array.isArray(vm.owners) && typeof vm.owners == "object" : return vm.owners.hasOwnProperty('iri') ? [vm.owners.iri] : [];
            case Array.isArray(vm.owners) : return vm.owners.map(owner => typeof owner == "object" && owner.hasOwnProperty('iri') ? owner.iri : owner)
          }
      },
        //context sensitive tables remove items from not selected owners
        //@todo: maybe implement this as a global filter which then is also buffered and always active
        ownerRowData: function(vm) {
          let rows = vm.rows
          if(!vm.noOwner) {

          let ownerContext = []
          if(vm.contextSensitive) {
           ownerContext = vm.$store.getters.getOwnerContext.map((owner) => owner.iri)
          } 
          if(vm.ownerIris) {
            ownerContext = ownerContext.concat(vm.ownerIris)
          }
          
          
          rows = vm.rows.filter(row => 
            //check for the basic owner
            row.owner && row.owner.value && ownerContext.indexOf(row.owner.value) > -1 || 
            //if the allOwners field is set, check if any match was found
            ((row.allOwners && 
            (typeof row.allOwners === "object" &&
            row.allOwners.hasOwnProperty('value')) ?
            row.allOwners.value : row.allOwners) ?? []).some(o => ownerContext.indexOf(o) !== -1))
            
          }

          if(vm.$store.getters.getMeetingModes.length > 0) {
            if(vm.$store.getters.getMeetingModes.includes('customer')) {
              rows = rows.filter(row => (row.userGroup?.userGroup?.some(g => {
              let uGObj = vm.$store.getters['owner/getUserGroups']?.[g]
              return uGObj?.category?.includes("customer") ?? false  }) ?? false))
            }
          }
            
          let searchableColumns = this.columns.filter(c => c.name == "title" || c.search && c.search === true)
            if(this.baseSearchTerm !== "") {
              let searchTerm = this.baseSearchTerm.toLowerCase()
                rows = rows.filter(row => {
                  let baseResult = searchableColumns.map(c => row.hasOwnProperty(c.name) ? this.$render.displayByField((typeof row[c.name] == "object" && row[c.name].hasOwnProperty('value') ? row[c.name].value : row[c.name]),c) : "").join("%%").toLowerCase().indexOf(searchTerm) > -1
                  let specialResult = !!this.additionalSearchHandler ? this.additionalSearchHandler(row, searchTerm) : false
                  return baseResult || specialResult
                })            
            }
            return rows
        },


        //apply sorting to the remaining non filtered rows
        sortedRowData: function(vm) {
          let sortFunction = null
          if(vm.sortData.length == 0 && vm.defaultSortHandler) {
            sortFunction = vm.defaultSortHandler.bind(vm)
          } else {
            sortFunction = vm.basicSortFunction.bind(vm)
          }

          try {
            vm.filteredRowData.sort(sortFunction)
          } catch {}

          return vm.filteredRowData
        },


        groupedRowData(vm) {   
          if(vm.groupData.length == 0) return vm.sortedRowData
          let groupDataClone = [...vm.groupData]
          return vm.buildGroups(vm.sortedRowData, groupDataClone,0)    
        },

        // if pagination is on (default: true) reduce to the set number of items displayed
        paginatedRowData(vm){
          if(vm.pagination) {
            let cnt = 0
            let start = (vm.page-1)*vm.itemsPerPage
            let itemCount = 0
            let pgnRows = []
            for(let i = 0;itemCount < vm.itemsPerPage && i<vm.groupedRowData.length; i++) {
              if(cnt >= start || vm.groupedRowData[i]['@header']) {
                if(vm.groupedRowData[i]['@header'] && pgnRows.length > 0) {
                  //slice previous header if it is the one before and of the same type
                  if(pgnRows[pgnRows.length - 1]['@header'] && pgnRows[pgnRows.length - 1]['@name'] === vm.groupedRowData[i]['@name']) pgnRows.splice(-1,1)
                }
                if(!vm.groupedRowData[i]['@header']) itemCount++
                pgnRows.push(vm.groupedRowData[i])
              } 
              cnt++
            }
            return pgnRows
          } else {
            return vm.groupedRowData
          }
        },

        //add some additional properties to each row
        preppedRowData(vm) {
          return vm.paginatedRowData.map(row => {
            row['@groupCollapsed'] = false
            row['@hiddenByGroups'] = vm.foldedGroupings.length > 0 && vm.foldedGroupings.some(group => {
              let checkForSame = group == row['@groups']
              if(row['@header']) {
                row['@groupCollapsed'] = row['@groupCollapsed'] || checkForSame
              }
               return !checkForSame && group.length <= row['@groups'].length && group.every((g,i) => row['@groups'].indexOf(g) > -1)
              }
            )
            
            if(row['@header']) {
              
              return row
            }

            row['@subgroup'] = vm.subgroups.indexOf(row.iri && (row.iri.value || row.iri)) != -1
           row['@edit'] = vm.editList.indexOf(row.iri && (row.iri.value || row.iri)) != -1
           row['@selected'] = vm.selectedIris.indexOf(row.iri && (row.iri.value || row.iri)) != -1

           row['@id'] = row.iri && (row.iri.value || row.iri)
            
           if(vm.customizeTable) {
             if(typeof row['@class'] == "object") {
               row['@class'] = {...row['@class'],...vm.customizeTable('row',row)}
             }
           }
            return row
          })
        },
        
      allRowOwners(vm) {
        if(vm.noOwner) return []
        if(vm.ownerIris) return vm.ownerIris
        let owners = {}
        vm.rows.forEach(row => {
          let owner = row.owner && row.owner.value
          owners[owner] = true
          if(row.allOwners) {
            let allOwners = (typeof row.allOwners == "object" && row.allOwners.hasOwnProperty('value')) ? row.allOwners.value : row.allOwners
            (allOwners ?? []).forEach(o => owners[o] = true)
          }
        })
        return Object.keys(owners)
      }

    },
    methods: {
      buildAllColumns(fromCols) 
      {
        this.allColumns = fromCols.map(c => Object.assign({},c))
      },
      applyColumnOrdering(columns) 
      {
        let maxOrder = columns.length
        columns.sort((a,b) => (a.order ?? maxOrder) - (b.order ?? maxOrder))
        columns.forEach((c,idx) => c.order = idx)
      },

      changeOrder(col,mod = 1) {
        col.order += mod
      },



      /**
       * 
       * @param {array} rows 
       * @param {array} groupStack 
       */

      buildGroups(rows, groupStack, lvl) {
        //Step 1: group all items by their groupHandler and sort the groups by the groupSorter
        if(groupStack.length == 0) return rows
        
        let groupData = groupStack.shift()

        let col = this.getColumnByName(groupData.name)
        let groupHandler = groupData.groupHandler || null
        if(!groupHandler) return rows
        let groupSorter = groupData.groupSorter
        let groupLabeller = groupData.groupLabeller


        let groupValue = groupData.groupData
        let groupedRows = {}
        let groups = []
        rows.forEach(row => {
          let group = groupHandler(row[groupData.name], groupValue, col)
          if(groups.indexOf(group) == -1) groups.push(group)
          if(!groupedRows[group]) {
            groupedRows[group] = []
          }
          groupedRows[group].push(row)
        })

        groups.sort(groupSorter)
        //sortedGroupData is now an array of objects
        let sortedGroupData = groups.map(group => ({label: groupLabeller(group), entries: groupedRows[group]}))

        //Step 2: send each group to the next group handler in the stack
        let newStack = sortedGroupData.map(group => {
            //the destructuring creates a new array which then can be manipulated by each tree individually
            let enhancedGroup = this.buildGroups(group.entries, [...groupStack], lvl + 1)
            
            //Step 3: attach a header row to each group 
            
            let headerRow = {"@header": true, "@name" : groupData.name, "@indent": lvl + 1, "@label": this.getLanguageDependentDescription(col.labelTable ?? col.label ?? col.description)+': '+ this.getLanguageDependentDescription(group.label), "@collapsed" : false}
            enhancedGroup.splice(0,0,headerRow)
            enhancedGroup.forEach(row => {
              if(!row['@groups'] || groupStack.length == 0) row['@groups'] = []
              row['@groups'].push(groupData.name+'@'+group.label)
              })
            return enhancedGroup
        })

        //Step 4: return the new flat structure
        return [].concat(...newStack)

      },

/**
 * * * * * * * * * * * * * *
 * GROUPING
 * * * * * * * * * * * * * * 
 */

      removeGroup(name) {
        let idx = this.groupData.findIndex(e => e.name == name)
        if(idx != -1) {
          let oldGroup = this.groupData.splice(idx, 1)
          this.groupData.forEach((s,i) => s['groupPosition'] = i)


          //additionally, remove the items from folded groupings that involve this grouping
          this.foldedGroupings = []

        }
      },
      setGroupData(name, groupObject) {
        groupObject['name'] = name
        let idx = this.groupData.findIndex(e => e.name == name)
        if(idx !== -1) {
          this.$set(this.groupData,idx , {... this.groupData[idx],...groupObject})
        } else {
          this.groupData.splice(this.groupData.length, 0, groupObject)
        }
        this.groupData.forEach((s,i) => s['groupPosition'] = i)
      },

      getGroupData(name) {
        return this.groupData.find(s => s.name == name) ?? {}
      },


/**
 * * * * * * * * * * * * * *
 * SORTING
 * * * * * * * * * * * * * * 
 */

      removeSorter(name) {
        let idx = this.sortData.findIndex(e => e.name == name)
        if(idx != -1) {
          this.sortData.splice(idx, 1)
          this.sortData.forEach((s,i) => s['sortPosition'] = i)
        }
      },
      setSortData(name, sortObject) {
        sortObject['name'] = name
        let idx = this.sortData.findIndex(e => e.name == name)
        if(idx !== -1) {
          this.sortData.splice(idx, 1, sortObject)
        } else {
          this.sortData.splice(this.sortData.length, 0, sortObject)
        }
        this.sortData.forEach((s,i) => s['sortPosition'] = i)

        this.sortData = this.sortData
      },

      getSortData(name) {
        return this.sortData.find(s => s.name == name) || {}
      },

      basicSortFunction: function(rowA, rowB) {            
        //run through all sorters until one returns a non zero sort result and return that
        for(let i=0;i<this.sortData.length;i++) { 
          if(!this.sortData[i].sortHandler) continue
          if(rowA.hasOwnProperty(this.sortData[i].name) && rowB.hasOwnProperty(this.sortData[i].name)) {
            let sortResult = this.sortData[i].sortHandler(rowA[this.sortData[i].name],rowB[this.sortData[i].name])
            if(sortResult != 0) return sortResult
          }
        }
        // default sort behaviour if no sorters where selected
        return 0

      },




/**
 * * * * * * * * * * * * * *
 * FILTERING
 * * * * * * * * * * * * * * 
 */

      importFilter: function() {
        if(!this.filter) return 
        for(const [key,val] of Object.entries(this.filter)) {
        this.addFilter(key, 'filter', {"filterTag": val,
                                            "filterHandler": () => true,
                                            "filterValue": {}}) 
        }
      },
    /**
      * populates the filteredRowData property based on the filter buffers
      */
      buildFilteredRows() {
        //get all active filter column names
        // colName: ...
        // filterTag: ...


        let activeFilters = this.columns.map(col => ({
          colName: col.name,
          tag:  (this.filterData && 
                this.filterData[col.name] && 
                !!this.filterData[col.name].status &&
                this.filterData[col.name].filter &&
                this.filterData[col.name].filter.hasOwnProperty('filterTag')) ? this.filterData[col.name].filter.filterTag : null
        })).filter(item => item.tag !== null)

        let deactivatedFilters = this.columns.filter(col => !!this.filterData?.[col.name]?.status ? !this.filterData[col.name].status : true).map(col => col.name)

        //check if row property is buffered
        this.filteredRowData = []
        if(activeFilters.length !== 0) {
          this.ownerRowData.forEach((row,idx) => {
            let rowFilter = this.rowFilterBuffer.get(this.ownerRowData[idx])
            //deactivate all filters no longer active

            if(!row['@filterConfig']) {
              row['@filterConfig'] = {}
            }

            deactivatedFilters.forEach(fieldname => {
              row['@filterConfig'][fieldname] = true
            })


            if(!rowFilter) return 
            if(activeFilters.length == activeFilters.reduce((a,filter) => {
              if(!rowFilter[filter.colName] || !rowFilter[filter.colName][filter.tag]) {
                this.applyFiltersToRow(this.ownerRowData[idx])
                rowFilter = this.rowFilterBuffer.get(this.ownerRowData[idx])
              }
              return a + +(rowFilter && rowFilter[filter.colName] && rowFilter[filter.colName][filter.tag] || 0) },0)) {
              this.filteredRowData.push(row)
            } 
          })
        } else {
          this.filteredRowData = this.ownerRowData.map(row => Object.assign(row,{ '@filterConfig': {}}))
        }    
        return this.filteredRowData
      },

      /**
       * applies all currently defined filters to the row and stores the data in the filter buffer
       * 
       * @param {Object} row 
       */
      applyFiltersToRow(row) {
        let fullState = this.rowFilterBuffer.get(row)
          for (const [fieldname, filter] of Object.entries(this.filterData)) {
            //only run active filters
            //check if the filterbuffer contains the current filter tag in the fieldname
              //run the filter and buffer the result
              //hands the arguments cell data, filterValue, columnData
              if(!filter || !filter.filter || !filter.filter.filterHandler) {
                if(!row.hasOwnProperty('@filterConfig')) { row['@filterConfig'] = {} }
                  row['@filterConfig'][fieldname] = true
                continue
              }
              let res
              if(row['@header']) {
                res =true
              } else {
                res = filter.filter.filterHandler(
                row[fieldname], //cellData
                filter.filter.filterValue, //filterValue as defined by the filter
                this.getColumnByName(fieldname) //column definition
                )

              //safely build the path to the buffer storage location
              if(!fullState) { fullState = {} }
              if(!fullState[fieldname]) { fullState[fieldname] = {} }

              if(!row['@filterConfig']) { row['@filterConfig']  = {} }              
              row['@filterConfig'][fieldname] = !!res

              fullState[fieldname][filter.filter.filterTag] = !!res 
              
              this.rowFilterBuffer.set(row,fullState)
              }
          }
      },

      /**
       * applies the filter set for the current column to all rows and stores the result in the filter buffer
       * 
       * @param {Object} col 
       */
      applyFiltersToColumn(col) {
        let fieldname = col.name || col.internalName
        let filterConfig = this.filterData[fieldname] && this.filterData[fieldname].filter || null
        

        this.rows.forEach((row,idx) => {
          if(!filterConfig || !filterConfig.filterHandler) {
            row['@filterConfig'][fieldname] = true
            return
          }

          let fullState = this.rowFilterBuffer.get(this.rows[idx])
          let res
          if(this.rows[idx]['@header']) {
            res = true
          } else {
            res = filterConfig.filterHandler(
              row[fieldname], //cellData
              filterConfig.filterValue, //filterValue as defined by the filter
              col //column definition
            )
          }
          if(!fullState) { fullState = {} }
          if(!fullState[fieldname]) { fullState[fieldname] = {} }
          fullState[fieldname][filterConfig.filterTag] = !!res 
          
          if(!row['@filterConfig']) { row['@filterConfig']  = {} }              
          row['@filterConfig'][fieldname] = !!res

          this.rowFilterBuffer.set(this.rows[idx],fullState)
        })
      },


      /**
       * 
       * sets the status flag for a specific column based on the field name and then applies the filter buffer to the table
       * 
       * @param {String} fieldName name of the column for which the filter status is changed
       * @param {Boolean} newStatus the new status of the filter
       */
        setFilterStatus(fieldName, newStatus) {
          this.addFilter(fieldName, 'status', newStatus)
          this.buildFilteredRows()
        },


        /**
         * 
         * sets the filter object for a specific column. Afterwards, the filter is applied to all rows and the table is updated 
         * 
         * @param {String} fieldName 
         * @param {Object|null} filterObject the filter object requiring fields 
         *        filterHandler, 
         *        filterTag and 
         *        filterValue. 
         */
        setFilterValue(fieldName, filterObject) {
            //verify that the required fields are set
            if(typeof filterObject == "object") {
              if(filterObject.hasOwnProperty('filterHandler') &&
              filterObject.hasOwnProperty('filterTag') &&
              filterObject.hasOwnProperty('filterValue')) {
                //save in filter data
                this.addFilter(fieldName, 'filter', filterObject)
                this.applyFiltersToColumn(this.getColumnByName(fieldName))
              }
            }
          this.buildFilteredRows()
        },

        /**
         * this function is used to safely assign a filter field to the filter storage
         * 
         * @param {String} fieldName 
         * @param {String} field 
         * @param {Object} value 
         */
        addFilter(fieldName, field, value) {
          if(!this.filterData[fieldName]) {
            this.$set(this.filterData,fieldName,{}) 
          }
            this.$set(this.filterData[fieldName],field,value)
        },


      
/**
 * * * * * * * * * * * * * *
 * HELPERS
 * * * * * * * * * * * * * * 
 */

      getColumnByName: function(name) {
        return this.allColumns.filter(col => col.name === name || col.internalName === name)[0] || false;
      },
      getColumnByDescription: function(description) {
        return (
          this.allColumns.filter(col => {
            let useCol = col.labelTable ?? col.label ?? col.description
            if(typeof useCol === "object") {
              var localizedDescription = this.getLanguageDependentDescription(useCol)
              return localizedDescription === description
            } else {return useCol === description}})[0] || false
        );
      },
      getLanguageDependentDescription(descriptionObject) {
        return descriptionObject && (descriptionObject[this.$root.$i18n.locale] || descriptionObject['en'] || descriptionObject) || ''
      },
      clearAll(wipe = true) {
          this.sortData = this.sortData.splice(0,-1)
          this.$set(this,"filterData",{})
          this.groupData = this.groupData.splice(0,-1)
          this.presetId = false
          this.filterCleared = true
          if(wipe) {
            this.shownColumns = null
           this.buildAllColumns(this.columnsWithAddedFields) 

           this.$nextTick(() => {
             this.loadDefault()
              this.buildFilteredRows()
           })
          }
          this.buildFilteredRows()
      }

    },
    data: function() {
        return {
          editList: [],
          allColumns: [],
          subgroups: [],
          filterData: {},
          sortData: [],
          groupData: [],
          rowFilterBuffer: new WeakMap(),
          filteredRowData: [],
          foldedGroupings: [],
          shownColumns: null,
          baseSearchTerm: ""
        };
      }
}