<i18n locale="de" lang="yaml" >
    module.baseapp: "BAIT-Hauptapp"
    module.part: "Bauteile und Stücklisten"
    module.changemanagement: "Änderungsmanagement"
    module.user: "Benutzer"
    module.task: "Aufgaben"
    module.file: "Dateimanager"
    module.relation: "Verknüpfungen"
    module.owner: "Projektmanager"
    module.meeting: "Meetingmodul"
    title.appstatus: "App-Versionen / Änderungshistorie"

</i18n>
<i18n locale="en" lang="yaml" >
    module.baseapp: "BAIT main app"
    module.part: "Parts and BOM"
    module.changemanagement: "Changemanagement"
    module.user: "Users"
    module.task: "Tasks"
    module.file: "File manager"
    module.relation: "Link manager"
    module.owner: "Project manager"
    module.meeting: "Meeting module"
    title.appstatus: "App version / Changelog"
</i18n>
<template>
  <div>
      <h2 class="m-3">{{ $t('title.appstatus') }}</h2>
        <collapse-box 
            :title="$t('module.baseapp')" 
            :id="$id('service-app')"
            accordion="changelogs">
        <template #actions>
            <b-badge  class="mr-2" light pill>{{ mainVersion }}</b-badge>
        </template>
        <b-card-text
            v-html="changelogs['_baseapp']">                
        </b-card-text>
        </collapse-box>

        <collapse-box 
            v-for="(service, name) in $registry.getModules()"
            :key="$id('service'+name)"
            :title="$t('module.'+name)" 
            :id="$id('service-'+name)"
            accordion="changelogs"
            hidden
            >
        <template #actions>
            <b-badge light pill  class="mr-2" :title="service[0] && service[0].version">{{service[0] && service[0].revision }}</b-badge>
        </template>
        <b-card-text
            v-html="changelogs[name]">                
        </b-card-text>
        </collapse-box>
  </div>
</template>

<script>

import appVersionString from '!raw-loader!@/assets/version.info'

export default {
    name: "RevisionMonitor",
    mounted() {
        this.loadLogs()
    },
    data() {
        return {
            'publicPath': process.env.BASE_URL,
            'mainVersion': appVersionString,
            'item' : this.getChangelog('_baseapp'),
            changelogs: {}
        }
    },
    methods: {
        getChangelog(key) {
            return this.changelogs && this.changelogs[key] || ''
        },
        loadLogs: async function() {
            let modules = this.$registry.getModules()

            Object.keys(modules).forEach((async(key) => {
                let cl = await fetch('changelog/'+key+'.info')
                this.$set(this.changelogs,key, await cl.text())
            }).bind(this));
            let cl = await fetch('changelog/_baseapp.info')
            this.$set(this.changelogs,'_baseapp', await cl.text())
        }
    }
}
</script>

<style>

</style>