import localforage from 'localforage'
import config from '@/configuration'
import { APIHandler } from '@/helper/APIHandler'

const moment = require("moment")


const PersistenceManager = function() {
    this.databaseName = config.cache.databaseName || "BAIT"
    this.stores = {}
    this.apihandler = new APIHandler()
    this.pendingRequests = {}
    this.expireStore = {}
    this.loadExpirationStore()
}

PersistenceManager.prototype.getStore = function(moduleName) {
    if(!this.stores[moduleName]) {
        this.stores[moduleName] = localforage.createInstance({name: moduleName})
    }
    return this.stores[moduleName]
}


PersistenceManager.prototype.cached = async function(moduleName, iri, isURL = false, bufferSeconds = 600, ...args) {
    //var result = await this.read(moduleName, iri)        
    //var force = this.isExpired(iri, bufferSeconds)

    //if(!!result || (config && config.cache && config.cache.disabled || false) || !!force) {
        var data = await this.fetchFromSource(moduleName, iri, isURL, ...args)
       // await this.persist(moduleName, iri, data)
        return data
    //} else {
        return result;
   // }
}

PersistenceManager.prototype.isExpired = function(iri, bufferSeconds)
{
    if(bufferSeconds === true) {
        return true
    }
    if(!this.expireStore[iri]) { return true }
    let expired = moment().diff(moment(this.expireStore[iri]),'seconds') > bufferSeconds

    return expired
}

PersistenceManager.prototype.saveLastFetchDate = function(iri)
{
    this.expireStore[iri] = moment().toISOString()
    let currentStore = this.getStore('__EXPIRATION__')
    currentStore.setItem('exp', this.expireStore)
    return this.expireStore[iri]
}

PersistenceManager.prototype.loadExpirationStore = function()
{
    var currentStore = this.getStore('__EXPIRATION__')
    currentStore.getItem('exp').then((res) => {
        if(!!res) {
        this.expireStore = res
        } else {
            this.expireStore = {}
        }})
}

PersistenceManager.prototype.persist = async function(storeName, key, value)
{
    var result
    try {
        var currentStore = this.getStore(storeName)
        result = await currentStore.setItem(key, value)
        this.saveLastFetchDate(key)
    } catch(err) {
        console.warn(`could not persist ${storeName}: ${key}`)
    }
    return result
}

PersistenceManager.prototype.read = async function(storeName, key)
{
    var currentStore = this.getStore(storeName)
    var result = await currentStore.getItem(key)
    return result
}

PersistenceManager.prototype.isPending = function(iri)
{
    return this.pendingRequests[iri] || null
}

PersistenceManager.prototype.addPending = function(iri, activePromise)
{
    this.pendingRequests[iri] = activePromise
}

PersistenceManager.prototype.removePending = function(iri, activePromise)
{
    delete this.pendingRequests[iri]
}

PersistenceManager.prototype.fetchFromSource = async function(moduleName, iri, isURL = false, ...args) 
{
   
    var isPending = this.isPending(iri)
    if(!!isPending) {
        return (await isPending)
    }

    var request = null
    if(!isURL) {
        request = this.apihandler.fetch(moduleName, iri, ...args)
    } else {
        request = this.apihandler.fetchFromURL(iri, ...args)
    }
    
    this.addPending(iri, request)
    var result = await request
    this.removePending(iri)
    return result

}
PersistenceManager.prototype.install = function(Vue, options) {
      
    Vue.prototype.$cache = this

}

//alias cached with get
PersistenceManager.prototype.get = function(...args) {
    return this.cached(...args)
}

PersistenceManager.prototype.create = function(serviceName, endpoint, body, ...args)
{
    return this.apihandler.postToService(serviceName, endpoint, body, ...args)
}
PersistenceManager.prototype.post = function(serviceName, endpoint, body, ...args)
{
    return this.apihandler.postToService(serviceName, endpoint, body, ...args)
}


PersistenceManager.prototype.update = function(serviceName, endpoint, body, ...args)
{
    return this.apihandler.putToService(serviceName, endpoint, body, ...args)
}

PersistenceManager.prototype.delete = function(serviceName, endpoint, body, ...args)
{
    return this.apihandler.deleteFromService(serviceName, endpoint, body, ...args)
}


PersistenceManager.prototype.registerMiddleware = function(name, handler)
{
    if(typeof handler !== "function" || typeof name !== "string") {
        console.warn("Could not register middleware since wrong configuration")
        return false
    }

    if(this.apihandler.middleWares.findIndex(e => e.name === name) > -1) {
        console.warn("A Middleware with name "+name+" is already registered")
        return false
    }

    this.apihandler.middleWares.push({'name': name, 'handler': handler})
    return true
}

PersistenceManager.prototype.unregisterMiddleware = function(name) {
    
    let idx = this.apihandler.middleWares.findIndex(e => e.name === name)
    if(idx === -1) {
        console.warn("No Middleware with name "+name+" registered")
        return false
    }
    this.apihandler.middleWares.splice(idx,1)
    return true
}

PersistenceManager.prototype.clearMiddleware = function() {
    this.apihandler.middleWares = []
}

const persister = new PersistenceManager()

export { persister }
