<i18n lang="yaml" locale="de">
    item.comments: "Kommentare"
    newcomment: "Neuer Kommentar"
    confirm.delete: "Kommentar wirklich löschen?"
    confirm.delete.title: "Kommentar löschen"
    confirm.delete.yes: "Ja, löschen"
    confirm.delete.no: "Nein, abbrechen"
    delete.success: "Kommentar erfolgreich gelöscht"
    delete.failed: "Kommentar löschen fehlgeschlagen"
    update.success: "Kommentar aktualisiert"
    update.failed: "Kommentar konnte nicht aktualisiert werden"
    comments.fetch.failed: "Konnte Kommentare nicht laden"
    commentAfterCreatingAllowed: "Kommentare können erst nach dem Erstellen angelegt werden"
</i18n>
<i18n lang="yaml" locale="en">
    item.comments: "Comments"
    newcomment: "New comment"
    confirm.delete: "Delete this comment?"
    confirm.delete.title: "Delete comment"
    confirm.delete.yes: "Yes, delete"
    confirm.delete.no: "No, abort"
    delete.success: "Comment successfully deleted"
    delete.failed: "Comment deletion failed"
    update.success: "Comment updated"
    update.failed: "Comment update failed"
    comments.fetch.failed: "Could not load comments"
    commentAfterCreatingAllowed: "Comments can only be added after creating the element"
</i18n>
<template>
    <collapse-box :id="$id('collapse-comments')" :title="$t('item.comments')" :hidden="hidden">
        <template #actions>
            <b-button v-if="!showInModal" size="sm" class="p-1" variant="light" @click="toggleAllTo(!showAll)"><b-icon variant="primary" :icon="!showAll ? 'caret-down' : 'caret-up'"/></b-button>
        </template>
        <template #prepend>
        </template>
        <b-overlay :show="isFetching" rounded="sm">
                <b-alert
                    :show="!iri || iri == ''"
                    variant="warning"
                    >
                    {{ $t('commentAfterCreatingAllowed') }}    
                </b-alert>
                <div class="d-flex my-1">
                    <b-button 
                        :title="$t('newcomment')"
                        size="sm" 
                        :disabled="!iri || iri ==''"
                        class="p-1 mr-1" 
                        :variant="isSending ? 'warning' : 'success'" @click="submitComment()">
                        <b-spinner v-if="isSending" small></b-spinner>
                        <b-icon-check variant="dark" v-else/>                            
                    </b-button>
                    <div>{{ formattedDate() }}</div>
                    <markup-text 
                        :disabled="!iri || iri == ''"
                        v-model="newComment" 
                        optional 
                        :targetIri="this.iri" 
                        class="flex-grow-1 ml-1"></markup-text>
                </div>

                <table class="w-100">
                    <tbody>
                        <template 
                            v-for="(comment,idx) in comments" 
                        >
                        <tr 
                        :key="$id('comment-'+idx)"
                        class="base-table--row comment-row"
                        >
                            <td class="shrink px-2">
                                <b-badge 
                                    v-if="showInModal"
                                    variant="light" href="#" @click="modalCommentId = idx" v-b-modal="$id('commentModal')">
                                    <b-icon :icon="'box-arrow-up-right'" />
                                </b-badge>
                                <b-badge v-else href="#" @click="comment['@show'] = !comment['@show']" variant="light">
                                    <b-icon :icon="comment['@show'] ? 'chevron-down' : 'chevron-right'" />
                                </b-badge>
                                <template v-if="comment.creationUser == $store.getters.getCurrentUser">
                                    <b-badge 
                                        href="#"
                                        size="sm" 
                                        class="p-1" 
                                        v-show="comment['@disabled']"
                                        variant="light" @click="comment['@disabled'] = false; comment['@show'] = true">
                                        <b-icon-pencil variant="dark" />                            
                                    </b-badge>
                                    <b-badge 
                                        href="#"
                                        size="sm" 
                                        class="p-1" 
                                        v-show="!comment['@disabled']"
                                        variant="success" @click="updateComment(comment)">
                                        <b-icon-check variant="dark" />                            
                                    </b-badge>
                                    <b-badge 
                                        href="#"
                                        size="sm" 
                                        class="p-1" 
                                        v-show="!comment['@disabled']"
                                        variant="danger" @click="abortEditing(comment)">
                                        <b-icon-x variant="light" />                            
                                    </b-badge>
                                    <b-badge 
                                        href="#"
                                        size="sm" 
                                        class="p-1" 
                                        variant="light" @click="deleteComment(comment)">
                                        <b-icon-trash variant="danger" />
                                    </b-badge>
                                </template>
                            </td>
                            <td class="shrink px-2" v-b-tooltip.bottom.window.d500 :title="formattedDate(comment.creationDate, true)">{{ formattedDate(comment.creationDate) }}</td>
                            <td class="shrink px-2"><dynamic-loader widget="Display" module="user" :value="comment.creationUser" neverOverflow></dynamic-loader></td>
                            <td class="grow px-2" style="max-width: 50px;">
                                <div class="text-truncate w-100">{{ comment['@short'] }}</div>
                            </td>
                        </tr>
                        <tr :key="$id('comment-unfold'+idx)" v-show="comment['@show']">
                            <td></td>
                            <td colspan="5">
                                <markup-text 
                                :value="comment['@disabled'] ? comment.content : comment['@edited']"
                                @input="comment['@edited'] = $event"
                                optional 
                                :disabled="comment['@disabled']"></markup-text>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
                
        </b-overlay>
        <b-modal 
            v-if="showInModal"
            size="lg"
            ok-only
            no-footer
            body-class="p-1"
            :id="$id('commentModal')" >

        <template #modal-title>
            <h5 class="mx-2">
                {{ formattedDate(comments && comments[modalCommentId] && comments[modalCommentId].creationDate) }}
            </h5>
            <h5>
                <dynamic-loader widget="Display" module="user" :value="comments && comments[modalCommentId] && comments[modalCommentId].creationUser" neverOverflow></dynamic-loader>
            </h5>
            <b-button-group>
                <b-button :disabled="modalCommentId == 0" @click="modalCommentId = modalCommentId - 1"><b-icon-chevron-left />
                </b-button>
                <b-button :disabled="modalCommentId == comments.length - 1" @click="modalCommentId = modalCommentId + 1"><b-icon-chevron-right />
                </b-button>
            </b-button-group>
        </template>

            <markup-text 
                :value="comments && comments[modalCommentId] && comments[modalCommentId].content"
                optional 
                disabled>
                </markup-text>
        </b-modal>
    </collapse-box>
</template>
<style>
tr.base-table--row:hover .hoverButton {
    visibility: initial !important;
}

tr.comment-row:nth-of-type(4n-1) {
    background-color: #f2f4f6;
}

.comment-row {
    width: 100%;

}

.comment-row td {
    white-space: nowrap;      
    text-overflow: ellipsis;  
    overflow: hidden;
}

.comment-row .shrink {
    width: 1px;
}

.comment-row .grow {
    width: 100%;
}

</style>
<script>

const moment = require("moment")

export default {
    name: "itemComments",
    props: {
        /**
         * iri which manages the comments
         */
        iri: String,
        /**
         * module for service resolution
         */
        module: {
            required: true,
            type: String
        },
        /**
         * the name of the endpoint for the element
         */
        commentEndpoint: {
            required: false,
            type: String,
            default: "/comments"
        },
        /**
         * the name under which the parent iri needs to be placed
         */
        parentFieldName: {
            required: false,
            type: String,
            default: "/parent"
        },
        /**
         * display the comments in a modal instead of a dropdown
         */
        showInModal: {
            type: Boolean,
            default: false
        },
        /**
         * if the comment box should start collapsed
         */
        hidden: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            comments: [],
            showAll: false,
            newComment: "",
            newCommentBoxStatus: true,
            isFetching: false,
            isSending: false,
            modalCommentId: 0
        }   
    },
    watch: {
        iri : {
            immediate: true,
            handler: function(newValue, oldValue) {
            this.fetchComments()
            }
        }
    },
    mounted() {
        this.$root.$on('bts::editor::saving', this.saveCommentsOnTaskSave)
    },
    beforeDestroy() {

    },
    methods: {
        saveCommentsOnTaskSave(itemIri) {
            //only react if this item is saved
            if(itemIri !== this.iri) return
            
            //check if a new comment was started/created
            if(this.newComment == "") return

            this.submitComment()

        },
        toggleAllTo(state) {
            this.comments.forEach(c => c['@show'] = state)
            this.showAll = state
        },
        enhanceComment(comment) {
            let stripped = this.stripHTML(comment.content)
            comment['@short'] = stripped //.length >= 40 ? stripped.substr(0,37)+'...' : stripped
            this.$set(comment,'@disabled',true)
            this.$set(comment,'@show',false)
            this.$set(comment,'@edited',comment.content)
        },
        fetchComments: async function() {
            if(!this.iri) { return }
            try {
                this.isFetching = true;
                var commentArray = await this.$cache.cached(this.module, this.iri+`/comments?pagination=false&order[id]=desc`, false, 0)            
                this.comments = commentArray
                this.comments.forEach(this.enhanceComment)
            } catch(err) {
                this.$bvToast.toast(this.$t("comments.fetch.failed"))
            } finally {
                this.isFetching = false
            }
        },
        deleteComment: async function(comment) {
            let response = await this.$bvModal.msgBoxConfirm(this.$t('confirm.delete'),{
                title: this.$t('confirm.delete.title'),
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: this.$t('confirm.delete.yes'),
                cancelTitle: this.$t('confirm.delete.no'),
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
                })
            if(!response) return
            try {
            let result = await this.$cache.delete(this.module,this.commentEndpoint+'/'+comment.id, null, true)
                this.$bvToast.toast(this.$t('delete.success'),{ variant: "success"})
                this.comments = this.comments.filter((c => c.id != comment.id))
            } catch {
                this.$bvToast.toast(this.$t('delete.failed'),{ variant: "danger"})
            }
        },
        updateComment: async function(comment) {
            if(!comment) return
            try {
            let result = await this.$cache.update(this.module,this.commentEndpoint+'/'+comment.id, {content: comment['@edited'] })           
                
                let idx = this.comments.findIndex(c => c.id == result.id)
                if(idx == -1) {
                    console.error('could not find updated comment')
                    return
                }
                this.comments.splice(idx,1,Object.assign(this.comments[idx],result,{'@disabled' : true}))
                this.$bvToast.toast(this.$t('update.success'),{ variant: "success"})  
            } catch {
                this.$bvToast.toast(this.$t('update.failed'),{ variant: "danger"})
            }
        },
        abortEditing: function(comment) {
            comment['@edited'] = comment.content
            comment['@disabled'] = true
        },
        stripHTML: function(html)
        {
            let tmp = document.implementation.createHTMLDocument("New").body;
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        submitComment: async function() {
            if(this.newComment == "") return
            
            let payload = {
                "content": this.newComment,
                "creationDate": new moment().format(),
                "creationUser": this.$store.getters.getCurrentUser
            }
            payload[this.parentFieldName] = this.iri
            this.isSending = true
            let createRequest =  await this.$cache.create(this.module,this.commentEndpoint, payload)
            this.isSending = false
            this.enhanceComment(createRequest)
            this.comments.unshift(createRequest)
            this.newComment = ""

        },
        formattedDate: function(dstr, timeOnly = false) {
            return moment(dstr).format(timeOnly ? 'HH:mm' : 'DD.MM.YYYY')
        }
    }
}
</script>