<template>
    <div class="project-sidebar">

        <div v-for="(item, idx) in sidebar"
        :key="$id('group-'+idx)">
        
          <div v-if="item.type === 'group'">
            <div>
            <div :key="$id(idx)" class="pl-2">
              <div class="d-flex nav-sidebar--link p-1 font-weight-bold "  :class="{'nav-sidebar--link--active' : item.active}" v-b-toggle="item.name">
                <span class="when-open"
                  ><b-icon-chevron-down class="small" shift-v="-0.5"></b-icon-chevron-down>
                </span>
                <span class="when-closed"
                  ><b-icon-chevron-right class="small" shift-v="-0.5"></b-icon-chevron-right>
                </span>
                <dynamic-loader v-bind="item.prefix" v-if="item.prefix" class="ml-auto"/>
                <span class="pl-1">{{
                  item.label[$root.$i18n.locale] || item.label
                }}</span>
                <dynamic-loader v-bind="item.suffix" v-if="item.suffix" class="ml-auto"/>
              </div>
              <b-collapse :id="item.name" :visible="!!item.show">
                <div
                  class="nav-sidebar--link"
                  v-for="(entry, idy) in item.items"
                  :key="$id(idy + '-' + item.name)"
                >
                  <router-link 
                    :to="entry.path" 
                    class="pl-4 d-flex" 
                    exact-active-class="alert-primary" 
                    exact>
                    <dynamic-loader v-bind="entry.prefixComponent" v-if="entry.prefixComponent" />
                      <div>{{
                      entry.label[$root.$i18n.locale] || entry.path
                    }}</div>
                    <dynamic-loader v-bind="entry.suffixComponent" v-if="entry.suffixComponent" class="ml-auto"/>
                  </router-link>
                </div>
              </b-collapse>
            </div>
          </div>
          </div>
          <div v-else class="nav-sidebar--link" :key="$id(idx)">
            <router-link :to="item.path" exact-active-class="alert-primary" class="d-flex" exact>
              <dynamic-loader v-bind="item.prefixComponent" v-if="item.prefixComponent" />
              <div>{{ item.label[$i18n.locale] || item.path }}</div>
              <dynamic-loader v-bind="item.suffixComponent" v-if="item.suffixComponent" class="ml-auto"/>
            </router-link>
          </div>
         
        </div>
    </div>
</template>
<script>

export default {
  name: 'SideBar',

  data() { return {     
    defaultShow: {},
    showGroup: {},
    hideGroup: {},
  
  }},
   
    computed: {
      fullOwnerList: function(vm) {
            let activeOwners = vm.$store.getters.getOwnerContext
            let activeOwnersIri = activeOwners.map(owner => owner?.iri).filter(o => !!o)
            let fullOwners = activeOwnersIri.map(ownerIri => vm.$store.getters.getItemFromIri(ownerIri))
            return fullOwners
        },
        //for testing: owner requires a field called viewConfig
       // owner.viewConfig.sidebar.showGroups = ['projectConf','eng']
       // owner.viewConfig.sidebar.hideGroups = ['sales','production']

      sidebar: function () {
        let sidebarItems = this.$store.getters.getRouteConfiguration.filter(
          (element) => !!_.get(element, "meta.show.sidebar")
        );
        let sidebar = [];

        sidebarItems.forEach((element) => {
          let storeElement = {
            type: "item",
            label: _.get(element, "meta.label") || element.path || "ERROR",
            path: element.path || "ERROR",
            order: element.meta.order || sidebar.length,
            prefixComponent: _.get(element, "meta.prefixComponent") ?? false,
            suffixComponent: _.get(element, "meta.suffixComponent") ?? false
          };
          if(element.meta.allowAccess) {
            if(!element.meta.allowAccess()) {
              return
            }
          }

          if (element.meta.show.group) {
            if(!(this.groupVisibilities?.[element.meta.show.group] ?? false)) {
              return
            }

            let idx = sidebar.findIndex(
              (e) => e.type === "group" && e.name === element.meta.show.group
            );
            if (idx > -1) {
              sidebar[idx].items.push(storeElement);
              sidebar[idx].active = sidebar[idx].active || this.$router.match(storeElement.path).name === this.$route.name
            } else {
              let groupCfg = this.$store.getters.getSidebarGroups.find(
                (g) => g.name == element.meta.show.group
              );
              sidebar.push({
                type: "group",
                name: groupCfg.name,
                show: groupCfg.show ?? false,
                label: groupCfg.label || groupCfg.name,
                items: [storeElement],
                order: groupCfg.order || sidebar.length,
                prefix: groupCfg.prefix || false,
                suffix: groupCfg.suffix || false,
                active: this.$router.match(storeElement.path).name === this.$route.name
              });
            }
          } else {
            sidebar.push(storeElement);
          }
        });
        sidebar.sort((a, b) => a.order - b.order);

        return sidebar;
    },

    viewConfig: function (vm) {
        //get all viewConfigs for all ownwers by current user 
        return vm.fullOwnerList?.map(owner =>  (owner?.viewConfig ?? {})) ?? []
    },

    groupVisibilities: function(vm){
      let groupStatus = {}

      vm.$store.getters.getSidebarGroups.forEach(group => {
          //use default setting. If not defined, assume true
          groupStatus[group.name] = group.defaultDisplay ?? true

          let hiddenCount = 0
          let shownInAtLeastOneOwner = false
          //now check for the owner configuration
          vm.viewConfig.forEach(owner => {
            if((owner?.sidebar?.showGroup?.indexOf(group.name) ?? -1) > -1) {
              shownInAtLeastOneOwner = true
            }
            if((owner?.sidebar?.hideGroup?.indexOf(group.name) ?? -1) != -1) {
              hiddenCount++
            }
          })

          if(hiddenCount > 0 && hiddenCount == vm.viewConfig.length) {
            groupStatus[group.name] = false
          }

          if(shownInAtLeastOneOwner) {
              groupStatus[group.name] = true
          }

      })
        
      return groupStatus;
    },

    },

}
</script>
