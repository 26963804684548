<i18n locale="en" lang="yaml">
    delete.item: "Delete item"
    link.item: "Convert to task"
    confirmDeletion.message: "Should the row \"{rowname}\" be deleted? This can't be undone."
    confirmDeletion.title: "Confirm deletion"
    confirmDeletion.confirm: "Yes"
    confirmDeletion.deny: "No"
    popover.addDate: "New date"
    popover.deleteRow: "Delete row"
    popover.convertToTask: "Convert to task"
    conversionToTask.failed: "Conversion to task failed"
    conversionToTask.success: "Conversion to task successful"
    popover.checkout: "Checkout"
    popover.discard: "Discard"
    popover.unlinkTask: "Unlink task"
</i18n>

<i18n locale="de" lang="yaml">
    delete.item: "Element löschen"
    link.item: "In Aufgabe umwandeln"
    confirmDeletion.message: "Soll die Zeile \"{rowname}\" wirklich gelöscht werden? Dies kann nicht rückgängig gemacht werden."
    confirmDeletion.title: "Löschen bestätigen"
    confirmDeletion.confirm: "Ja"
    confirmDeletion.deny: "Nein"
    popover.addDate: "Neuer Zeitabschnitt"
    popover.deleteRow: "Zeile löschen"
    popover.convertToTask: "In Aufgabe umwandeln"
    conversionToTask.failed: "Umwandlung in Aufgabe fehlgeschlagen"
    conversionToTask.success: "Erfolgreich in Aufgabe umgewandelt"
    popover.checkout: "Bearbeiten"
    popover.discard: "Verwerfen"
    popover.unlinkTask: "Verknüpfung entfernen"
</i18n>
<template>
    <div 
        v-if="!hideNotRelevant || (localData && localData.status !== -1)"
        class=" timeline-row "
            
            >
        <div 
            class=" timeline-label 
                    bg-white
                    align-self-stretch 
                    align-items-baseline 
                    justify-content-end
                    d-flex" 
            :style="'max-width: '+labelWidth+'px;min-width: '+labelWidth+'px; width: '+labelWidth+'px;padding-left: '+ (((level > 0 ? level-1 : 0)*15))+'px'"
            :id="$id('element-title')"
            @dragover="dragover_handler"
            @drop="updateOrder(idx,$event)"
            >
            <div 
                v-if="localData && localData.type == 'external'" 
                class="flex-shrink-1 flex-grow-0 flex-nowrap position-absolute" 
                style="width: 20px; left: -25px;">
                    <b-badge class="rounded-0" href="#" :variant="isCheckedOut ? 'success' : 'danger'" block @click="isCheckedOut ? saveExternal() : checkoutElement()">
                        <b-icon icon="arrow-clockwise" animation="spin" v-if="processing"></b-icon>
                        <b-icon-unlock-fill v-else-if="isCheckedOut" />
                        <b-icon-lock-fill v-else />
                    </b-badge>
            </div>
            <span 
                    v-if="level > 0"
                    style="width: 15px;" 
                    class="grip-handler"
                    draggable 
                    @dragstart.self.capture="startReordering(idx,$event)">
                    <b-icon-grip-horizontal variant="dark" />
                </span>
            <div class="mr-2 d-flex align-items-center flex-grow-1">
                <div v-if="localData && localData.type == 'external'">
                    <b-badge href="#" variant="light" @click="activateModal(localData.fromIri)">
                        <b-icon-box-arrow-up-right />
                    </b-badge>
                </div>
                <div 
                    v-if="localData && localData.type == 'external' && isCheckedOut" 
                    class="mr-2 flex-shrink-1 flex-grow-0 flex-nowrap" 
                    style="width: 20px;">
                    <b-badge href="#" variant="danger" @click="discardExternal()" class="rounded-0">
                        <b-icon-x />
                    </b-badge>
                </div>
                <b-input :disabled="computedDisabled"  type="text" size="sm" :value="localData && localData.title || ''"  @input="updateEntry('title',$event)" class="p-0 m-0"></b-input>
                <div 
                    v-if="showResponsible"
                    style="width: 120px; min-width: 120px;"
                    class="d-flex">
                    <base-selector 
                        class="mx-1 flex-grow-1" 
                        inline
                        :value="localData && localData.responsible" 
                        @input="updateEntry('responsible',$event)" 
                        allowCustom 
                        :multiSelect="false" 
                        :selectables="['user']" 
                        :disabled="computedDisabled" 
                        optional>
                        <template v-slot:btn-label><b-icon-person></b-icon-person></template>
                    </base-selector>
                </div>
                <b-input 
                    v-if="showStatusPercent"
                    class="ml-1" 
                    style="width: 60px;" 
                    size="sm" 
                    type="number" 
                    :min="-1" 
                    :max="100" 

                    :value="localData && localData.status || 0"  
                    :disabled="computedDisabled || (localData && (typeof localData.statusCalculator == 'function'))" 
                    @input="updateEntry('status',parseInt($event))" >
                </b-input>
                <b-button
                    v-if="showStatus"
                    style="width: 30px;" 
                    class="py-0 px-1"
                    @click="(computedDisabled || (localData && (typeof localData.statusCalculator == 'function'))) ? false : updateEntry('status',!!localData ? (localData.status == 100 ? -1 : (localData.status == -1 ? 0 : 100)) : 0)" 
                    :variant="!!localData ? (localData.status == 100 ? 'success' : (localData.status == -1 ? 'warning' : 'secondary')) : 'secondary'"
                    >
                        <b-icon :icon="!!localData ? (localData.status == -1 ? 'dash' :'check2') : 'dash'" />
                </b-button>
            </div>
        </div>
            <b-popover 
                :target="$id('element-title')" 
                placement="right" 
                triggers="hover" 
                custom-class="modalpopover"
                container="scrollParent">               
                <div class="d-flex flex-row" style="font-size: 14px;">
                    <template
                        v-if="localData && !!localData.fromIri && (localData.type == 'external' || localData.type == 'externalGroup')">
                        <b-badge 
                            v-if="!isCheckedOut"
                            class="p-1"
                            href="#" 
                            variant="light"
                            :title="$t('popover.checkout')"
                            @click="checkoutElement()"><b-icon-lock-fill /></b-badge>   
                        <template v-if="!!isCheckedOut">
                        <b-badge 
                            class="ml-1"
                            href="#" 
                            variant="success" 
                            @click="saveExternal()">
                                <b-icon icon="arrow-clockwise" animation="spin" v-if="processing"></b-icon>
                                <b-icon-check v-else />
                            </b-badge>
                            <b-badge 
                                href="#" 
                                variant="danger" 
                                :title="$t('popover.discard')"
                                @click="discardExternal()">
                                <b-icon-x />
                            </b-badge>  
                        </template>
                    </template>
                    <!--
                    <b-badge
                        class="p-1 lg" 
                        href="#" 
                        variant="success"
                        @click="addDate()"><b-icon-calendar-plus class="mr-2"/>{{ $t('popover.addDate') }}</b-badge>
                        -->
                        
                    <b-badge
                        v-if="localData && ((localData.type != 'external' || localData.type == 'externalGroup') && !computedDisabled)"
                        class="p-1 " 
                        href="#" 
                        variant="success"
                        :title="$t('popover.convertToTask')"
                        @click="convertToTask()"><b-icon-file-plus/></b-badge>
                    <b-badge
                        v-else-if="!disabled && localData && !!localData.fromIri && localData.type == 'external'"
                        class="p-1 " 
                        href="#" 
                        variant="warning"
                        :title="$t('popover.unlinkTask')"
                        @click="unlinkTask()"
                        ><b-icon-file-minus/></b-badge>
                    <b-badge
                        v-if="localData && (!localData.fromIri && !disabled && !localData.indelible && !localData.noDelete)"
                        class="p-1"
                        href="#" 
                        variant="danger"
                        :title="$t('popover.deleteRow')"
                        @click="deleteRow()"><b-icon-trash /></b-badge>
                </div>
            </b-popover>
        <div 
            class="timeline-items d-flex overflow-hidden" 
            :style="'background-size: '+itemWidth+'px 10px; background-position-x: '+itemWidth/2+'px;width:'+(totalWidth)+'px;height:'+20*(Math.max(orderedDates.length,1)+(validDrag ? 1 : 0))+'px;'"
            @dragover="dragover_handler"   
            >
                <gantt-icon 
                    backdrop 
                    :value="today"
                    :startOffset="startOffset"
                    :totalWidth="totalWidth"
                    :itemWidth="itemWidth"></gantt-icon>
                <gantt-icon 
                    v-for="(mark,idx) in markings" 
                    :key="$id('mark-'+idx)"
                    marking 
                    :value="mark"
                    :startOffset="startOffset"
                    :totalWidth="totalWidth"
                    :itemWidth="itemWidth"></gantt-icon>
                <div 
                    v-for="(orderedDateSet,idy) in orderedDates"
                    :key="$id('time-item-row-'+idy)"
                    >
                        
                        <template v-for="(item,idx) in orderedDateSet">
                            <gantt-icon :key="$id('time-item-'+idy+'-'+idx+'-'+item.id)" 
                                :value="item"
                                :order="0"
                                :parent="$id('')"
                                :maxOrder="0"
                                :style="'top: '+((idy*20)+4)+'px;'"
                                :disabled="computedDisabled"
                                :showStatusIndicatorToday="showStatusIndicatorToday"
                                :showStatusIndicator="showStatusIndicator"
                                :hideNotRelevant="hideNotRelevant"
                                :labelled="item.labelled"
                                @input="updateDates(idy,idx, $event)"
                                draggable
                                allowStatus
                                allowResponsible
                                indelible
                                @dragstart="storeDragData($event, idx)"
                                @delete="removeDate(idx)"
                                
                                :startOffset="startOffset"
                                :totalWidth="totalWidth"
                                :itemWidth="itemWidth"
                            >
                                <template
                                    v-if="showStatusIndicator">
                                    <component 
                                        v-if="localData && !!localData['@statusIndicator']"
                                        :is="localData && localData['@statusIndicator']" 
                                        :value="localData" />
                                    <template v-else>
                                        <b-badge v-if="isOverdue" variant="danger" class="ml-2"><b-icon variant="light" icon="exclamation-triangle"/></b-badge>
                                        <b-badge v-else-if="localData && localData.status >= 100" variant="success" class="ml-2"><b-icon variant="dark" icon="check2"/></b-badge>
                                    </template>
                                </template>
                            </gantt-icon>
                        </template>
                </div>

        </div>

    </div>
</template>

<style lang="scss" scoped>
    .timeline-expand {
        position: absolute;
        bottom:0;
        width: 100%;
        height: 2px;
        background-color: red;
    }
    .timeline-label {
        position: sticky;
        left:30px;
        z-index: 1701;        
    }
    .timeline-label:hover {
        z-index: 100;
    }

</style>

<script>

import moment from 'moment'
import GanttIcon from './_ganttIcon'

export default {
    name: "ganttElement",
    components: {
        'ganttIcon' : GanttIcon
    },
    props: {
        value: Object,
        level: {
            type: Number,
            default: 0
        },
        idx: {
            type: Number,
            default: 0
        },
        group: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: true
        },
        markings: {
            type: Array,
            required: false,
            default: () => []
        },
        showResponsible: {
            type: Boolean,
            required: false,
            default: false
        },
        showStatus: {
            type: Boolean,
            required: false,
            default: false
        },
        showStatusPercent: {
            type: Boolean,
            required: false,
            default: false
        },
        showStatusIndicator: {
            type: Boolean,
            required: false,
            default: false
        },
        showStatusIndicatorToday: {
            type: Boolean,
            required: false,
            default: false
        },
        hideNotRelevant: {
            type: Boolean,
            required: false,
            default: false
        },
        startOffset: {
            type: null,
            required: true
        },
        totalWidth: {
            type: null,
            required: true
        },
        itemWidth: {
            type: Number,
            required: true
        }
    },
    inject: {
        registerCheckout: "registerCheckout",
        unregisterCheckout : "unregisterCheckout",
        getOwnerIri: {name: "getOwnerIri", default: () => () => null },
        getElementIRI: { name: "getElementIRI", default: () => () => null },
        activateModal: "activateModal"
    },
    watch: {
        'value' : {
            handler: async function() {
                if(!this.value) return
                if(this.isCheckedOut && this.value?.['@isCheckedOut']) return
                if(this.value?.type == "external" && this.value?.fromIri) {
                   let externalObject = await this.buildExternal(this.value.fromIri)
                   this.$set(this,"localData",externalObject)
                    this.isCheckedOut = this.value?.['@isCheckedOut'] ?? false
                } else {                    
                   this.$set(this,"localData",this.value)
                    this.isCheckedOut = this.value?.['@isCheckedOut'] ?? false
                }

                //add responsible to first date element if exactly one date element exists
                if(this.localData?.dates?.length === 1) {
                    if(!!this.localData.responsible) {
                        this.$set(this.localData.dates[0],'responsible',this.localData.responsible)
                    }
                    if(!!this.localData.status) {
                        this.$set(this.localData.dates[0],'status',this.localData.status)
                    }
                }
                this.$root.$emit('bts::gantt::recalculate')
            },
            immediate: true
        }
    },
    computed: {
        computedDisabled: function(vm) {
            return (vm?.localData?.type == 'external') ? !vm.isCheckedOut : vm.disabled
        },
        orderedDates: function(vm) {
            let result = {}
            vm.localData?.dates?.forEach(date => {
                //ensure an id field is set
                if(!date.id) {
                    date.id = this.uniqid("" , true)
                }

                if(!result[date.order || 0]) {
                    result[date.order || 0] = []
                }
                result[date.order || 0].push(date)
            })
            let cleanOrderSet = []
            Object.values(result).forEach((val,idx) => {
             this.$set(cleanOrderSet,idx,[])
             val.forEach(date => {
                 cleanOrderSet[idx].splice(cleanOrderSet[idx].length,0,(Object.assign({},date, {order: idx})))
             })
            })
                        return cleanOrderSet
        },
        labelWidth: function(vm) {
           return vm.startOffset
        },
        isOverdue: function(vm) {
            let endDates = vm.localData?.dates?.map((elem) => moment(elem.end)).filter(e => !!e)
            let endDate = moment.max(endDates) 

            return (vm.localData?.status > -1 && vm.localData?.status < 100) && endDate.isBefore()
        },
        
        start: {
            get: function() {
                //convert to moment format and set to 12am
                return moment(this.localData?.dates[0]?.start).startOf('day').format('YYYY-MM-DD')
            },
            set: function(newVal) {
                this.localData.dates[0].start = moment(newVal).format('YYYY-MM-DD')
                this.$emit('input',this.localData)
            }
        },

        end: {
            get: function() {
                //convert to moment format and set to 12am
                return moment(this?.localData?.dates[this.localData?.dates?.length - 1]?.end).startOf('day').format('YYYY-MM-DD')
            },
            set: function(newVal) {
                this.localData.dates[this.localData.dates.length - 1].end = moment(newVal).format('YYYY-MM-DD')
                this.$emit('input',this.localData)
            }
        },
        
    },
    methods: {
        uniqid(prefix = "", random = false) {
                        const sec = Date.now() * 1000 + Math.random() * 1000;
                        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
                        return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
        },
        updateDates(idy,idx, ev) {
            let parseData = this.orderedDates
            parseData[idy][idx] = ev;
            let flattenedArray = parseData.flat()
            this.localData.dates = flattenedArray
            if(ev.hasOwnProperty('responsible')) {
                this.localData.responsible = ev.responsible
            }
            if(ev.hasOwnProperty('status')) {
                this.localData.status = ev.status
            }


            this.$root.$emit('bts::gantt::recalculate')
            this.$emit('input',this.localData)
        },
        storeDragData(ev, idx) {
            ev.dataTransfer.setData("text/plain", idx);
        },
        updateEntry(field,$event,idx = null) {
            if(idx === null) {
                this.$set(this.localData,field,$event)
            } else {
                this.localData[field].splice(idx,1,$event)
            }
            this.$emit('input',this.localData)
        },
        startReordering(idx,ev) {
            ev.stopPropagation();
            if(this.disabled) return
            ev.dataTransfer.dropEffect = "move"
            ev.dataTransfer.setData('application/json',JSON.stringify({id: idx, group: this.group , action: "ordering"}))
        },
        updateOrder(targetIdx, ev) {
            if(this.disabled) return
            let transferString = ev.dataTransfer.getData('application/json')
            if(!transferString) {return}
            let transferObject = JSON.parse(transferString)
            let droppedId = transferObject.id
            let targetGroup = transferObject.group   
            let dragAction = transferObject.action         
            if(dragAction !== "ordering" || targetIdx == droppedId || targetGroup !== this.group) { return }
            this.$emit('reorder',{'targetIdx': targetIdx, 'droppedId': droppedId});
        },
        dragover_handler(ev) {
            ev.preventDefault();
            if(this.disabled) {
                ev.dataTransfer.dropEffect = "none";
                return
            } else {
                ev.dataTransfer.dropEffect = "move";
            }
        },
        addPseudoRow($event) {  
                if(this.disabled) return          
                this.validDrag = false;
                $event.dataTransfer.dropEffect = "move";
                this.validDrag = false;
            
        },
        addDate() {
            if(!this.localData.dates) {
                this.$set(this.localData,'dates',[])
            }
            this.localData.dates.splice(this.localData.dates.length,0, {title: "", color: "#000000", allowMarking: false, labelled: false, marking: false, start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD')})
            this.$emit('input',this.localData)
        },
        removeDate(idx) {
            this.localData.dates.splice(idx,1)
            this.$emit('input',this.localData)
        },
        deleteRow: async function() {
            let res = await this.$bvModal.msgBoxConfirm(this.$t('confirmDeletion.message', {rowname: this.localData.title}), {
                title: this.$t('confirmDeletion.title'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('confirmDeletion.confirm'),
                cancelTitle: this.$t('confirmDeletion.deny'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
            if(!res) { return }
            this.$emit('delete')
        },
        buildExternal: async function(iri) {
            //run script interpreter to build a full row set
            let mod = this.$store.getters.getModuleFromIri(iri)
            let externalObject = this.$store.getters.getItemFromIri(iri)

            
            //force loading of all related items
            this.$store.dispatch('relation/loadAllRelated',iri)

            if(!mod || !externalObject) return {}
            let ganttObj = await window?.[mod.module]?.['functions']?.['Timing']?.buildGantt?.(externalObject,{}) ?? {}
            ganttObj.type = "external"
            ganttObj.fromIri = iri
            return ganttObj
        },
        saveExternal: async function() {
            if(!this.localData.fromIri && !this.isCheckedOut) return
            try {
                this.processing = true
                let mod = this.$store.getters.getModuleFromIri(this.localData.fromIri)
                if(!mod || !mod.module) return
                await (window && window[mod.module] && window[mod.module]['functions']['Timing'] && window[mod.module]['functions']['Timing'].updateFromGantt(this.localData.fromIri,this.localData))
                await this.$cache.post(mod.module,'/unlock',{resource: this.localData.fromIri})                
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
                this.$emit('input',this.localData)
                this.unregisterCheckout( this.localData.fromIri)
            } catch(e) {
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
            } finally {
                this.processing = false
            }
        },
        discardExternal: async function() {
            if(!this.localData.fromIri && !this.isCheckedOut) return            
            this.localData = await this.buildExternal(this.localData.fromIri)
            try {
                let mod = this.$store.getters.getModuleFromIri(this.localData.fromIri)
                if(!mod || !mod.module) return
                await this.$cache.post(mod.module,'/unlock',{resource: this.localData.fromIri})
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
                this.unregisterCheckout( this.localData.fromIri)
            } catch (e) {
                this.isCheckedOut = true
                this.value['@isCheckedOut'] = true
            }
        },
        checkoutElement: async function() {
            if(!this.localData.fromIri) return
            this.processing = true
            try {
                let mod = this.$store.getters.getModuleFromIri(this.localData.fromIri)
                if(!mod || !mod.module) return
                await this.$cache.post(mod.module,'/lock',{resource: this.localData.fromIri})
                this.isCheckedOut = true
                this.value['@isCheckedOut'] = true
                this.localData['@isCheckedOut'] = true
                this.$emit('input',this.localData)             
                this.registerCheckout( this.localData.fromIri)
            } catch(e) {
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
            } finally {
                this.processing = false
            }     
        },
        convertToTask: async function() {
            
            let mod = 'task'
            this.processing = true
            try {
                this.isCheckedOut = true
                await this.$libraries.isAvailable(mod)
                let valueCopy = this.localData
                this.localData.owner = this.getOwnerIri()
                this.localData.responsible = valueCopy.responsible != '' ? valueCopy.responsible : this.$store.getters.getCurrentUser
                let resultIri = await (window?.[mod]?.['functions']?.['Timing']?.createFromGantt(this.localData))
                
                if(!resultIri) return 

                if(!!this.getElementIRI()) {
                    let resRelation = {
                        fromIRI : resultIri, 
                        fromOwnerIRI: this.getOwnerIri(),
                        toIRI: this.getElementIRI(),
                        toOwnerIRI: this.getOwnerIri(),
                        type: 'information',
                        status: 0,
                        statusThreshold: 0
                    }

                    //build link to the parent object
                    this.$cache.create('relation','/relations',resRelation)
                }
                
                this.$set(this.localData,'fromIri', resultIri)
                this.$set(this.localData,'type', 'external')
                this.$emit('input',this.localData)
                this.$bvToast.toast(this.$t('conversionToTask.success'), {variant: 'success'})
            } catch(e) {
                this.$bvToast.toast(this.$t('conversionToTask.failed'), {variant: 'danger'})
            } finally {
                this.processing = false
                this.isCheckedOut = false
            }

        },
        unlinkTask: async function() {
            //unlinking should not change or remove the task, just the link
            //1. set end date to due date of task
            //2. switch from external to element
            //3. remove fromIri field
            this.$set(this.localData,'type', 'element')
            this.$delete(this.localData,'fromIri')
            this.$emit('input',this.localData)
        }
    },
    data() { 
        return {
            localData: null,
            today: {
                start: moment().format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
                color: '#a59c4e8a'
            },
            validDrag: false,
            isCheckedOut: false,
            processing: false
        }
    }
}
</script>