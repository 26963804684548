<template>
	<b-overlay :show="isLoading" spinner-variant="primary" spinner-type="grow" spinner-small>
		<dynamic-loader widget="Display" :value="itemObject.iri" v-if="forceCustom" />
		<div 
			class="itemDisplay" 
			:id="$id('itemDisplay')" 
			:title="fullTitle">
				{{ valueDisplay }}						
				<b-tooltip v-if="itemObject" boundary="window" placement="bottomright" variant="light" :target="$id('itemDisplay')">
					<b-icon-box-arrow-up-right 
						v-b-modal="$id('itemDetails_')" 
						font-scale="1" 
						@click="fixateWindow(true)"></b-icon-box-arrow-up-right> {{ fullTitle }}
                    <!-- @slot add additional functionality to the dropdown
                        @binding {Object} itemObject the retrieved item object used to render
                     -->
                    <slot v-bind:itemObject="itemObject"  name="dropdown"></slot>
				</b-tooltip>	
				<dynamic-loader 
					@hidden="fixateWindow(false)" 
					v-if="isIri && (hovered || fixated)" 
					:module="displayModule" 
					:widget="displayComponent" 
					:value="itemObject && itemObject.iri" 
					:customProperties="{modal: $id('itemDetails_') }">
				</dynamic-loader>
		</div>
	</b-overlay>
</template>

<style lang="scss">
</style>

<script>
const moment = require('moment')

export default {
	name: 'DisplayGeneric',
	props: {
        /**
         * an IRI string for the component to render
         */
		value: [String],

        /**
         * any context that should be passed to the display function
         */
		context: null,

        /**
         * The module to use for displaying
         */
        displayModule: {type: String, required: false},

        /**
         * The component to use for displaying
         */
        displayComponent: { type: String, required: false, default: "Single"},

		/**
		* Allows the user to force the actual display component
		 */
		forceCustom: {required: false, type: Boolean, default: false}
	},
	data() {
		return {
			hovered: false,
			fixated: false
		}
	},
	methods: {
		hoverHandle(val) {
			this.hovered = val
		},
		fixateWindow() {
			this.fixated = true
		}
	},
	mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if(modalId == this.$id('itemDetails_')) {
				this.fixated = false
			}
		})
	},
	watch: {
		'value': {
			immediate: true,
			handler: function(newVal) {
				let iri = newVal || this.value
				if(iri?.startsWith('/')) { 
					this.$store.dispatch('loadByIRI',iri)
				}
			}
		}
	},
	computed: {	
		isIri: (vm) => vm.value?.startsWith('/') ?? false,
		itemObject: (vm) => vm.isIri ? vm.$store.getters.getItemFromIri(vm.value) : null,

		valueDisplay: function(vm) {
			if(!vm.isIri) { return vm.value }
			if(!vm.itemObject) { return "..." }
			return vm.$render.displayByIri(vm.itemObject.iri, vm.context)
		},
		fullTitle: function(vm) {
			if(!vm.isIri) { return vm.value }
			if(!vm.itemObject) { return "..." }
			return vm.$render.displayByIri(vm.itemObject.iri, null)
		},
		isLoading: function(vm) {
			if(!vm.itemObject || !vm.itemObject._isLoading) { return false }
			return vm.itemObject._isLoading
		}
	}
}
</script>