<i18n locale="en" lang="yaml">
    title.placeholder: "Title"
    delete.item: "Delete item"
    confirmDeletion.message: "Should the item \"{rowname}\" be deleted? This can't be undone."
    confirmDeletion.title: "Confirm deletion"
    confirmDeletion.confirm: "Yes"
    confirmDeletion.deny: "No"
    popover.addMilestone: "New milestone"
    popover.addSubelement: "New row"
    popover.addGroup: "New subgroup"
    popover.deleteGroup: "Delete group"
    popover.checkout: "Checkout"
    popover.addExternal: "Add element"
    group.unfold: "Show subgroups"
    group.expand: "Show subgroups of all direct subelements"
    group.collapse: "Collapse all subgroups"
    milestone.unfold: "Show milestones"
    milestone.expand: "Unfold all milestone rows"
    milestone.collapse: "Collapse all milestone rows"
    add.external: "Add external item"
</i18n>

<i18n locale="de" lang="yaml">
    title.placeholder: "Titel"
    delete.item: "Element löschen"
    confirmDeletion.message: "Soll das Element \"{rowname}\" wirklich gelöscht werden? Dies kann nicht rückgängig gemacht werden."
    confirmDeletion.title: "Löschen bestätigen"
    confirmDeletion.confirm: "Ja"
    confirmDeletion.deny: "Nein"
    popover.addMilestone: "Neuer Meilenstein"
    popover.addSubelement: "Neue Zeile"
    popover.addGroup: "Neue Untergruppe"
    popover.deleteGroup: "Gruppe löschen"
    popover.checkout: "Bearbeiten"
    popover.addExternal: "Element hinzufügen"

    group.unfold: "Zeige Untergruppen"
    group.expand: "Zeige alle Untergruppen unter dieser Ebene"
    group.collapse: "Verstecke alle Untergruppen"
    milestone.unfold: "Zeige Meilenstein"
    milestone.expand: "Zeige alle Meilensteine unter diesem Level"
    milestone.collapse: "Reduziere alle Meilensteine unter diesem Level"
    add.external: "Externe Referenz"

</i18n>
<template>
    <div
        @dblclick="checkoutByDblClick($event)">
        <div 
            class="d-flex timeline-groupheader"
            >
            <div 
                class=" timeline-label 
                        align-self-stretch 
                        align-items-baseline 
                        justify-content-end
                        d-flex bg-light" 
                :class="{   'border-right' : !timelineOnly, 'toplevel': toplevel }"
                :style="'max-width: '+labelWidth+'px;min-width: '+labelWidth+'px; width: '+labelWidth+'px;padding-left: '+ (timelineOnly ? 0 : ((level > 0 ? level-1 : 0)*15))+'px'"
                :id="$id('element-title')"
                @dragover="dragover_handler"                
                @drop="updateOrderSelf(idx,$event)"

                @mouseenter="killPopoverTimer(); !computedDisabled ? popoverShowStatus = true : ''"
                @mouseleave="addPopoverTimer"
                >
                <div 
                    v-if="localData && localData.type == 'externalGroup'" 
                    class="flex-shrink-1 flex-grow-0 flex-nowrap position-absolute" 
                    style="width: 20px; left: -25px;z-index:1701;">
                        <b-badge class="rounded-0" href="#" :variant="isCheckedOut ? 'success' : 'danger'" block @click="isCheckedOut ? saveExternal() : checkoutElement()">
                            <b-icon icon="arrow-clockwise" animation="spin" v-if="processing"></b-icon>
                            <b-icon-unlock-fill v-else-if="isCheckedOut" />
                            <b-icon-lock-fill v-else />
                        </b-badge>
                </div>
                <span 
                    v-if="level > 0"
                    style="width: 15px;" 
                    class="grip-handler"
                    draggable 
                    @dragstart.self.capture="startReordering(idx,$event)">
                    <b-icon-grip-horizontal variant="dark" />
                </span>
                <template v-if="!timelineOnly">
                    <div 
                        class="mr-2 d-flex align-items-baseline flex-grow-1 h-100"
                        :class="backgroundClass">
                        <b-icon 
                            v-if="groupChildren.length > 0" 
                            style="width: 15px;" 
                            :icon="collapseState == 'collapsed' ? 'chevron-right' : (collapseState == 'unfolded' ? 'chevron-down' : 'chevron-double-down')" 
                            href="#" 
                            :title="$t(collapseState == 'collapsed' ? 'group.unfold' : (collapseState == 'unfolded' ? 'group.expand' : 'group.collapse'))"
                            @click="rotateUnfolding()"/>
                        <b-icon v-else style="width: 15px;" icon="chevron-down" />

                        
                        <template v-if="localData && localData.type == 'externalGroup'">
                            <b-badge href="#" variant="light" @click="activateModal(localData.fromIri)">
                                <b-icon-box-arrow-up-right />
                            </b-badge>                            
                            <b-badge v-if="isCheckedOut" href="#" variant="danger" @click="discardExternal()" class="rounded-0">
                                <b-icon-x />
                            </b-badge>
                        </template>
                        
                        <div class="d-flex align-items-center flex-grow-1">
                            <slot name="title-prepend"></slot>
                            <div 
                                v-if="!!toplevel || localData && (localData.type == 'external' || localData.type == 'externalGroup')" 
                                class="text-truncate ml-1" 
                                style="font-size: 1em;width: 200px;" 
                                :title="localData.title">
                                    {{ localData.title }}
                            </div>
                            
                            <b-input 
                                v-else
                                type="text" 
                                size="sm" 
                                class="m-0 "
                                :value="localData && localData.title"  
                                :disabled="computedDisabled || toplevel"
                                @input="updateGroupTitle($event)" 
                                @dragstart.self.capture="$event.preventDefault()"
                                >
                            </b-input>
                            
                            <slot name="title-append"></slot>
                        </div>
                        <div 
                            v-if="showResponsible"
                            class="align-self-center"
                            style="width: 150px;" >
                            <base-selector 
                                inline 
                                optional
                                allowCustom 
                                class="mx-1" 
                                :value="localData && localData.responsible" 
                                :multiSelect="false" 
                                :selectables="['user']" 
                                :disabled="computedDisabled"  
                                @input="updateEntry('responsible',$event)" 
                                >
                                <template v-slot:btn-label><b-icon-person></b-icon-person></template>
                            </base-selector>
                        </div>
                        <b-input 
                            v-if="showStatusPercent"
                            class="ml-1" 
                            style="width: 60px;" 
                            size="sm" 
                            type="number" 
                            :min="-1" 
                            :max="100" 

                            :value="localData.status"  
                            :disabled="computedDisabled || (typeof localData.statusCalculator == 'function')" 
                            @input="updateEntry('status',parseInt($event))" >
                        </b-input>
                        <b-button
                            v-if="showStatus"
                            style="width: 30px;" 
                            class="py-0 px-1"
                            @click="updateEntry('status',localData.status == 100 ? -1 : (localData.status == -1 ? 0 : 100))" 
                            :variant="(computedDisabled || (typeof localData.statusCalculator == 'function')) ? 'transparent' : localData.status == 100 ? 'success' : (localData.status == -1 ? 'warning' : 'secondary')"
                            >
                                <b-icon :icon="localData.status == -1 ? 'dash' :'check2'" />
                        </b-button>
                        <b-icon 
                            v-if="orderedDates.length > 1"
                            :icon="(oneLineState == 'collapsed') ? 'caret-right' : (oneLineState == 'unfolded' ? 'caret-down' : 'skip-forward')"
                            variant="dark" 
                            :rotate="oneLineState == 'expanded' ? 90 : 0"
                            :title="$t(oneLineState == 'collapsed' ? 'milestone.unfold' : (oneLineState == 'unfolded' ? 'milestone.expand' : 'milestone.collapse'))"
                            @click="rotateMilestoneUnfolding()"/>
                        <b-icon 
                            v-else
                            icon="caret-down"
                            variant="dark" 
                            />
                    </div>
                </template>
            </div>
            <b-popover 
                :target="$id('element-title')" 
                placement="right" 
                triggers="" 
                :show.sync="popoverShowStatus"
                container="scrollParent"
                custom-class="modalpopover p-0"
                class="p-0"
                >               
                <div 
                    class="d-flex flex-row justify-content-between align-items-center" 
                    style="font-size: 14px; width: 110px;"
                    @mouseenter="killPopoverTimer"
                    @mouseleave="addPopoverTimer">   
                    <!-- disabled for now -->
                    <template
                        v-if="false && !!localData.fromIri">
                        <b-badge 
                            v-if="!isCheckedOut"
                            class="p-1 text-center"
                            href="#" 
                            variant="light"
                            :title="$t('popover.checkout')"
                            @click="checkoutElement()"><b-icon-file-plus /></b-badge>   
                        <template v-if="!!isCheckedOut">
                        <b-badge 
                            href="#" 
                            variant="success" 
                            @click="saveExternal()">
                                <b-icon icon="arrow-clockwise" animation="spin" v-if="processing"></b-icon>
                                <b-icon-check v-else />
                            </b-badge>
                            <b-badge href="#" variant="danger" @click="discardExternal()" class="mr-4">
                                <b-icon-x />
                            </b-badge>  
                        </template>
                    </template>
                    <!-- end disabled block -->
                    <template 
                        v-if="!computedDisabled"
                        >              
                        <b-badge 
                            v-if="!noMilestones || localData.noMilestones"
                            class="p-1 text-center"
                            href="#" 
                            variant="primary"
                            :title="$t('popover.addMilestone')"
                            @click="addDate()"><b-icon-file-plus /></b-badge>
                        <b-badge 
                            class="p-1 text-center"
                            href="#" 
                            variant="success"
                            :title="$t('popover.addSubelement')"
                            @click="addChild('element')"><b-icon-file-plus /></b-badge>
                        <b-badge 
                            class="p-1 text-center"
                            href="#" 
                            variant="warning"
                            :title="$t('popover.addGroup')"                            
                            @click="addChild('group')"><b-icon-folder-plus /></b-badge>
                        
                        <base-selector
                            class="align-self-center"
                             :selectables="selectables" 
                             buttonMode 
                             inline
                             @open="popoverLockStatus = true;killPopoverTimer()"
                             @finalSelection="addExternalItems($event);popoverLockStatus = false">
                             <template #btn-label>
                                <b-badge 
                                    class="p-1  text-center w-100"
                                    variant="secondary"
                                    :title="$t('add.external')">
                                     <span style="font-size: 8px; padding: 1px;"> <b-icon-search /></span>
                                </b-badge></template>
                        </base-selector>

                        <b-badge 
                            v-if="!localData.indelible"
                            class="p-1 text-center"
                            href="#" 
                            variant="danger"
                            :title="$t('popover.deleteGroup')"
                            @click="deleteSelf()"><b-icon-trash /></b-badge>
                    </template>
                </div>
            </b-popover>
            <div 
                class=" timeline-items overflow-hidden
                         d-flex" 
                :style="'background-size: '+itemWidth+'px 10px; background-position-x: '+itemWidth/2+'px;width:'+(totalWidth)+'px;height:'+20*(Math.max((oneLineOnly ? 1 : orderedDates.length),1)+(validDrag ? 1 : 0))+'px;'"                
                :class="backgroundClass"
                @dragover="dragover_handler"     
                >
                <gantt-icon 
                    backdrop 
                    :value="today"
                    :startOffset="startOffset"
                    :totalWidth="totalWidth"
                    :itemWidth="itemWidth"></gantt-icon>
                <!--<gantt-icon backdrop subtle :value="dateRange"></gantt-icon>-->
                <gantt-icon 
                    v-for="(mark,idx) in groupMarkings" 
                    :key="$id('mark-'+idx)"
                    marking 
                    :value="mark"
                                :startOffset="startOffset"
                                :totalWidth="totalWidth"
                                :itemWidth="itemWidth"
                    ></gantt-icon>

                <template
                >
                <div 
                    v-for="(orderedDateSet,idy) in orderedDates"
                    :key="$id('time-item-row-'+idy)"
                    >
                        
                        <template 
                            v-if="!oneLineOnly || idy == 0"
                            >
                            <gantt-icon 
                                v-for="(item,idx) in orderedDateSet"
                                :key="$id('time-item-'+item.id)" 
                                :value="item"
                                :order="item.order"
                                :parent="$id('')"
                                :maxOrder="orderedDates.length"
                                :style="'top: '+((idy*20)+4)+'px;'"
                                :disabled="computedDisabled || (typeof localData.timelineCalculator == 'function')"
                                allowStatus
                                allowResponsible
                                :showStatusIndicator="showStatusIndicator && !localData['@statusIndicator'] && !localData.timelineCalculator"
                                :showStatusIndicatorToday="showStatusIndicatorToday"
                                :hideNotRelevant="hideNotRelevant"
                                @input="updateDates(idy,idx, $event)"
                                draggable
                                @dragstart="storeDragData($event, idx)"
                                @delete="removeDate(idy,idx)"
                                @contentDragged="addPseudoRow"                                
                                @contentDropped="validDrag = false"
                                :labelled="item.labelled"
                                :startOffset="startOffset"
                                :totalWidth="totalWidth"
                                :itemWidth="itemWidth"
                            >
                                <template
                                    v-if="showStatusIndicator">
                                    <component 
                                        v-if="!!localData['@statusIndicator']"
                                        :is="localData['@statusIndicator']" 
                                        :value="localData" />
                                </template>
                            </gantt-icon>
                        </template>
                </div>
                </template>
            </div>
        
        </div>
        <template          
            v-if="!collapsed">
            <gantt-item-selector    
                v-for="(item,idx) in groupChildren" 
                :key="$id('children-'+idx)"
                :type="item.type || 'element'" 
                :value="item"
                :idx="idx"
                :disabled="computedDisabled"
                :group="$id('')"
                :level="level + 1"
                :markings="groupMarkings"
                :showResponsible="showResponsible"
                :showStatus="showStatus"
                :showStatusPercent="showStatusPercent"
                :showStatusIndicator="showStatusIndicator"
                :showStatusIndicatorToday="showStatusIndicatorToday"
                :hideNotRelevant="hideNotRelevant"
                :startOffset="startOffset"
                :totalWidth="totalWidth"
                :itemWidth="itemWidth"
                class="dragitem-1" 
                @reorder="updateOrderDrop($event)"
                @input="updateChild(idx,$event)"
                @delete="deleteChild(idx)"
            >
            {{ item.name }}
            </gantt-item-selector >
        </template>
    </div>
</template>

<script>

import moment from 'moment'
import GanttIcon from './_ganttIcon.vue'
import BaseSelector from '../../BaseSelector/BaseSelector.vue'

import folding from './../mixins/folding.js'

export default {
    name: "GanttGroupElement",
    mixins: [folding],
    components: {
        GanttIcon,
        BaseSelector
    },
    props: {
        value: null,
        level: {
            type: Number,
            default: 0
        },
        timelineOnly: {
            type: Boolean,
            default: false
        },
        toplevel: {
            type: Boolean,
            default: false
        },
        idx: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        owner: {
            type: String,
            required: false,
            default: ""
        },
        group: {
            type: String,
            required: false,
            default: ""
        },
        markings: {
            type: Array,
            required: false,
            default: () => []
        },
        showResponsible: {
            type: Boolean,
            required: false,
            default: false
        },
        showStatus: {
            type: Boolean,
            required: false,
            default: false
        },
        showStatusPercent: {
            type: Boolean,
            required: false,
            default: false
        },
        noMilestones: {
            type: Boolean,
            default: false
        },
        showStatusIndicator: {
            type: Boolean,
            required: false,
            default: false
        },        
        showStatusIndicatorToday: {
            type: Boolean,
            required: false,
            default: false
        },
        hideNotRelevant: {
            type: Boolean,
            required: false,
            default: false
        },
        backgroundClass: {
            type: [Object],
            required: false,
            default: () => ({'timeline-groupheader-bg' : true})
        },
        startOffset: {
            type: null,
            required: true
        },
        totalWidth: {
            type: null,
            required: true
        },
        itemWidth: {
            type: Number,
            required: true
        }
    },
    mounted() {
        this.$root.$on("gantt::checkoutAll", function() {
            this.checkoutTimeTableOnly()
        }.bind(this))
        this.$root.$on("gantt::checkAllIn')", function() {
            this.saveExternal()
        }.bind(this))
    },
    watch: {
        'value' : {
            handler: async function() {
                if(this.value?.type == "externalGroup" && this.value?.fromIri && !this.value?.['@isCheckedOut']) {
                    let externalResult = await this.buildExternal(this.value.fromIri)
                    this.$set(this,'localData',externalResult)
                    this.$set(this,"groupChildren",this.localData?.children ?? [])
                    this.isCheckedOut = this.value?.['@isCheckedOut'] ?? false
                    this.localData['@isCheckedOut'] = this.isCheckedOut
                } else if(this.value?.type == "tempGroup") {
                    this.$set(this,'localData', this.value)
                    this.$set(this,"groupChildren",this.value?.children ?? [])
                 } else {                    
                    this.$set(this,'localData',this.value)

                    this.$set(this,"groupChildren",this.value?.children ?? [])
                    this.isCheckedOut = this.value?.['@isCheckedOut'] ?? false
                    this.localData['@isCheckedOut'] = this.isCheckedOut
                }

                if(this.value?.statusCalculator && typeof this.value?.statusCalculator == "function") {
                    this.$set(this.localData,'status',this.value.statusCalculator(this.value));
                }

                if(this.value?.timelineCalculator && typeof this.value?.timelineCalculator == "function") {
                    this.$set(this.localData,'dates',await this.value.timelineCalculator(this.value));
                    this.$set(this.localData,'noMilestones',true)
                }

                this.$root.$emit('bts::gantt::recalculate')
            },
            immediate: true
        }
    },
    computed: {  
        computedDisabled: function(vm) {
            if(vm.localData && vm.localData?.type == 'externalGroup') {
                return !vm.isCheckedOut
            } else {
                return vm.disabled
            }
        },
        groupMarkings: function(vm) {
            return vm.markings.concat(vm.localData?.dates?.filter(d => !!d.marking) ?? [])
        },
        dateRange: function(vm) {

            const flattenDates = (dates) => {
                let startDate = dates?.reduce((prevDate,date) => {
                        if(!prevDate) return !!date?.start ? moment(date?.start) : null
                        if(!date?.start) return prevDate
                    return moment(date?.start).isBefore(prevDate) ? moment(date?.start) : prevDate
                    }, null)
                let endDate = dates?.reduce((prevDate,date) => {
                        if(!prevDate) return !!date?.end ? moment(date?.end) : null
                        if(!date?.end) return prevDate
                    return moment(date.end).isBefore(prevDate) ? prevDate : moment(date?.end)
                    }, null)

                if(!endDate || !startDate) {
                    return {title: '' , start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), color: 'rgba(0,0,0,0.2)'}
                }
                return {title: '' , start: startDate?.format('YYYY-MM-DD'), end: endDate?.format('YYYY-MM-DD'), color: 'rgba(0,0,0,0.2)'}
            }

            const reduceChildrenToDate = (children) => {

                let flattenedChildren = children?.map(child => {
                    let resultChildren = null
                    let resultDates = null
                    if(child.children) {
                        resultChildren = reduceChildrenToDate(child.children)                        
                    }
                    if(child.dates) {
                        resultDates = flattenDates(child.dates)
                    }

                    if(resultChildren === null && resultDates === null) return null
                    if(resultChildren === null) return resultDates
                    if(resultDates === null) return resultChildren

                    let resultStart = moment(resultChildren.start).isBefore(moment(resultDates.start)) ? resultChildren.start : resultDates.start
                    let resultEnd = moment(resultChildren.end).isAfter(moment(resultDates.end)) ? resultChildren.end : resultDates.end

                    return {start: resultStart?.format?.('YYYY-MM-DD'), end: resultEnd?.format?.('YYYY-MM-DD')}
                })
                return flattenDates(flattenedChildren)
            }

            let childListDate = vm.groupChildren?.length > 0 ? reduceChildrenToDate(vm.groupChildren) : []
            let dateListFlatten = vm.localData?.dates?.length > 0 ? flattenDates(vm.localData.dates) : []

            return {title: '', ...flattenDates([childListDate, dateListFlatten]) , color: 'rgba(0,0,0,0.2)' }
        },
        orderedDates: function(vm) {
            let result = {}
            vm.localData?.dates?.forEach(date => {
                //ensure an id field is set
                if(!date.id) {
                    date.id = this.uniqid("" , true)
                }

                if(!result[date.order || 0]) {
                    result[date.order || 0] = []
                }
                result[date.order || 0].push(date)
            })

            let cleanOrderSet = []
            //prevent empty rows from being deleted
            let maxIndex = Math.max(...Object.keys(result).map(e => parseInt(e)))

            //prefill orderSet with empty arrays
            for(let i=0;i<=maxIndex;i++) {
               cleanOrderSet.splice(cleanOrderSet.length,0,[])
            }

            for(const [key, val] of Object.entries(result)) {
                let idx = parseInt(key)
                val.forEach(date => {
                    cleanOrderSet[idx].splice(cleanOrderSet[idx].length,0,(Object.assign({},date, {order: idx})))
                })
            }

            return cleanOrderSet
        },   
        labelWidth: function(vm) {
           return vm.startOffset
        },
    },
    methods: {
        uniqid(prefix = "", random = false) {
                        const sec = Date.now() * 1000 + Math.random() * 1000;
                        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
                        return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
        },
        updateDates(idy,idx, ev) {
            let parseData = this.orderedDates
            parseData[idy][idx] = ev;
            let flattenedArray = parseData.flat()
            this.$set(this.localData,'dates',flattenedArray)

            //unfold if moved to new row and only one is displayed
            if(this.oneLineOnly && ev.order > 0) {
                this.oneLineOnly = false;
            }

            
            this.$root.$emit('bts::gantt::recalculate')
            
            if(this.isCheckedOut) return
            this.$emit('input',this.localData)
        },
        removeDate(idy,idx) {
            let parseData = this.orderedDates
            parseData[idy].splice(idx,1);
            let flattenedArray = parseData.flat()
            this.$set(this.localData,'dates',flattenedArray)
            this.$emit('input',this.localData)
        },
        updateEntry(field,$event,idx = null) {
            if(idx === null) {
                this.$set(this.localData,field,$event)
            } else {
                this.localData[field].splice(idx,1,$event)
            }
            if(this.isCheckedOut || this.localData.type == "tempGroup") return
            this.$emit('input',this.localData)
        },
        startReordering(idx,ev) {
            if(this.group == "") return
            ev.stopPropagation();
            ev.dataTransfer.dropEffect = "move"
            ev.dataTransfer.setData('application/json',JSON.stringify({id: idx, group: this.group, action: "ordering" }))
        },
        dragover_handler(ev) {
            ev.preventDefault();
            if(this.disabled) {
                ev.dataTransfer.dropEffect = "none";
                return
            } else {
                ev.dataTransfer.dropEffect = "move";
            }
        },
        updateOrderDrop({ targetIdx,droppedId }) {
            let removedObject = this.groupChildren.splice(droppedId,1)
            this.groupChildren.splice(targetIdx,0,removedObject[0])
        },
        updateChild(idx,data) {
            this.groupChildren[idx] = Object.assign({},this.groupChildren[idx],data)
            if(this.isCheckedOut || this.localData.type == "tempGroup") return
            this.$emit('input',Object.assign({},this.localData,{children: this.groupChildren }))
        },
        updateGroupTitle(title) {
            this.$set(this.localData,'title',title)
            if(this.isCheckedOut) return
            this.$emit('input',this.localData)
        },
        addPseudoRow($event) {            
                if(this.validDrag) return
                this.validDrag = true;            
        },
        updateOrderSelf(targetIdx, ev) {
            let transferString = ev.dataTransfer.getData('application/json')
            if(!transferString) {return}
            let transferObject = JSON.parse(transferString)
            let droppedId = transferObject.id
            let targetGroup = transferObject.group   
            let dragAction = transferObject.action         
            if(dragAction !== "ordering" || targetIdx == droppedId || targetGroup !== this.group) { return }
            this.$emit('reorder',{'targetIdx': targetIdx, 'droppedId': droppedId});
        },        
        addExternalItems(ev) {
            ev.forEach(async item => {
                if(this.groupChildren.findIndex(gc => gc.fromIri == item) != -1) return;
                let tt = await this.buildExternal(item)
                this.groupChildren.splice(this.localData.length,0,tt)
            })
        },
        addChild(type) {
            switch(type) {
                case 'element':
                    this.groupChildren.splice(this.groupChildren.length,0,{ "type": "element",  "title": "", "dates": [{title: "", color: "#000000", allowMarking: false, marking: false, labelled: false, start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD')}] })    
                    break;
                case 'group':
                    this.groupChildren.splice(this.groupChildren.length,0,{ "type": "group", "title": "", "children": [ ], "collapsed": false })    
                    break;
            }     
            this.collapsed = false;
            if(this.isCheckedOut || this.localData.type == "tempGroup") return               
            this.$emit('input',Object.assign({},this.value,{children: this.groupChildren }))
        },
        addDate() {
            if(this.isCheckedOut || this.noMilestones || this.localData.noMilestones) return
            if(!this.localData.dates) {
                this.$set(this.localData,'dates',[]);
            }
            this.localData.dates.splice(this.localData.dates.length,0, {title: "", color: "#000000", allowMarking: true, marking: false, labelled: true, start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD')})
            
            if(this.isCheckedOut || this.localData.type == "tempGroup") return
            this.$emit('input',this.localData)
        },
        deleteChild(idx) {      
            if(this.isCheckedOut || this.localData.type == "tempGroup") return
            this.groupChildren.splice(idx,1)      
            
            if(this.isCheckedOut) return
            this.$emit('input',Object.assign({},this.localData,{children: this.groupChildren }))
        },
        deleteSelf: async function() {
            let res = await this.$bvModal.msgBoxConfirm(this.$t('confirmDeletion.message', {rowname: this.localData.title}), {
                title: this.$t('confirmDeletion.title'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('confirmDeletion.confirm'),
                cancelTitle: this.$t('confirmDeletion.deny'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
            if(!res) { return }
            this.$emit('delete')
        },
        buildExternal: async function(iri) {
            //run script interpreter to build a full row set
            let mod = this.$store.getters.getModuleFromIri(iri)
            let externalObject = this.$store.getters.getItemFromIri(iri)

            //force loading of all related items
            this.$store.dispatch('relation/loadAllRelated',iri)

            if(!mod || !externalObject) return {}
            let ganttObj = await window?.[mod.module]?.['functions']?.['Timing']?.buildGantt?.(externalObject,{}) ?? {}
            if(!ganttObj.hasOwnProperty('type')) {
                ganttObj.type = !!ganttObj.children ? "externalGroup" : "external"
            }
            ganttObj.fromIri = iri
            return ganttObj
        },
        saveExternal: async function() {
            if(!this.localData.fromIri && !this.isCheckedOut) return
            try {
                this.processing = true
                let mod = this.$store.getters.getModuleFromIri(this.localData.fromIri)
                if(!mod || !mod.module) return
                await (window && window[mod.module] && window[mod.module]['functions']['Timing'] && window[mod.module]['functions']['Timing'].updateFromGantt(this.localData.fromIri,this.localData))
                await this.$cache.post(mod.module,'/unlock',{resource: this.localData.fromIri})                
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
                this.$emit('input',this.localData)
                this.unregisterCheckout( this.localData.fromIri)

            } catch(e) {
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
            } finally {
                this.processing = false
            }
        },
        discardExternal: async function() {
            if(!this.localData.fromIri && !this.isCheckedOut) return            
            this.localData = await this.buildExternal(this.localData.fromIri)
            try {
                let mod = this.$store.getters.getModuleFromIri(this.localData.fromIri)
                if(!mod || !mod.module) return
                await this.$cache.post(mod.module,'/unlock',{resource: this.localData.fromIri})
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
                this.unregisterCheckout( this.localData.fromIri)
            } catch (e) {
                this.isCheckedOut = true
                this.value['@isCheckedOut'] = false
            }
        },
        checkoutElement: async function() {
            if(!this.localData.fromIri) return
            this.processing = true
            try {
                let mod = this.$store.getters.getModuleFromIri(this.localData.fromIri)
                if(!mod || !mod.module) return
                await this.$cache.post(mod.module,'/lock',{resource: this.localData.fromIri})
                this.isCheckedOut = true
                this.value['@isCheckedOut'] = true
                this.localData['@isCheckedOut'] = true
                this.$emit('input',this.localData)

                this.registerCheckout( this.localData.fromIri)
            } catch(e) {
                this.isCheckedOut = false
                this.value['@isCheckedOut'] = false
            } finally {
                this.processing = false
            }     
        },
        checkoutByDblClick($event) {
            if(this.toplevel || this.value?.type == "externalGroup") {
                this.checkoutElement()
            }
        },
        checkoutTimeTableOnly() {
            if(this.value?.type != "externalGroup") {
                this.checkoutElement()
            }
        },
        killPopoverTimer() {
            clearTimeout(this.hideTimer);
            this.hideTimer = null;
        },
        addPopoverTimer() {
            if(this.popoverLockStatus) return 
            this.killPopoverTimer()
            this.hideTimer = setTimeout(function() {
                this.popoverShowStatus = false
            }.bind(this),500)
        }
    },
    data() {
        return {
            popoverShowStatus: false,
            hideTimer: null,
            popoverLockStatus: false,
            validDrag: false,
            localData: {},
            groupChildren: [],
            today: {
                start: moment().format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
                color: '#a59c4e8a'
            },
            processing: false,
            isCheckedOut: false,
            selectables: [
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'ELOP',
                    loader: {
                        filter: {"categories" : ["elop"]}
                    }
                },
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'PMLOP',
                    loader: {
                        filter: {"categories" : ["pmlop"]}
                    }
                },
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'QLOP',
                    loader: {
                        filter: {"categories" : ["qlop"]}
                    }
                },
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'Tasks',
                    loader: {
                        filter: {"categories" : function(e) { return !e || e.length === 0 }}
                    }
                },
                {   
                    module : "changemanagement",
                    widget : "TableSolver",
                    context: true, 
                    title: 'Solver'
                },
                {   
                    module : "changemanagement",
                    widget : "TablePermanent",
                    context: true, 
                    title: 'ECRs'
                },
                {   
                    module : "changemanagement",
                    widget : "TableContainment",
                    context: true, 
                    title: 'RWs'
                },
                
            ]
        }
    },
    inject: {
        registerCheckout: "registerCheckout",
        unregisterCheckout : "unregisterCheckout",
        isLocked : "locked",
        activateModal: "activateModal",
        getOwnerIri: {name: "getOwnerIri", default: () => null},
        getElementIRI: {name: "getElementIRI", default: () => null}
    },
    provide() {
        return {
            getOwnerIri: () => {
                if(!!this.owner) return this.owner
                //if this is an external element, return the owner fetched from the iri
                if(this.localData?.fromIri) {
                    return this.$store.getters.getOwnerFromIri(this.localData.fromIri)
                }
                return !!this.getOwnerIri ? this.getOwnerIri() : null
            },
            getElementIRI: () => {
                if(this.localData?.fromIri) {
                    return this.localData?.fromIri
                } else {
                    return this.getElementIRI()
                }
            }
        }
    }
}
</script>

<style lang="scss">
    .timeline-label {
        position: sticky;
        left:30px;
        z-index: 1701;        
    }


    .timeline-groupheader-bg {
        background-color: lightgrey !important;
        
        &.toplevel {
            background-color: rgb(43, 189, 169) !important;
        }
    }

</style>