<template>
    <div 
        class="comment-entry ml-3 border-secondary border-bottom"
        :class="{'alert-danger marked-for-deletion' : value['@delete']}"
        >
        <div 
            @click="collapsed = !collapsed" 
            class="comment-header d-flex justify-content-start align-items-center text-small overflow-auto">
            <b-icon :icon="collapsed ? 'caret-right' : 'caret-down'" />
            <div 
                :title="formattedDate(value.createdate)"
                class="comment-dates text-truncate text-black-50">
                <div>{{ formattedDate(value.createdate) }}
                </div>
            </div>
            <div 
                :title="$render.displayByIri(value.lastEditedBy || value.createUser)" 
                class="ml-2 comment-editor text-truncate">
                {{ $render.displayByIri(value.lastEditedBy || value.createUser) }}
            </div>
            <div 
                :title="stripHTML(value.content)"
                class="ml-2 text-truncate">
                {{ stripHTML(value.content) }}
            </div>


        </div>
        <div v-show="!collapsed">
        <div             
            class="commentEditing d-flex flex-row">
            <div 
                
                class="d-flex flex-column mr-1"
                >
                <template v-if="!disabled">
                    <b-badge 
                        v-if="!editComment"
                        variant="light" 
                        href="#"
                        @click="allowEdit()">
                        <b-icon-pencil />
                    </b-badge>
                    <b-badge 
                        v-else
                        variant="success" 
                        href="#"
                        @click="saveChanges()">
                        <b-icon-check2 />
                    </b-badge>
                    <b-badge 
                        v-if="(value.createUser == $store.getters.getCurrentUser) && !disabled"
                        :variant="value['@delete'] ? 'warning' : 'danger'" 
                        href="#" 
                        @click="toggleDeletion()">
                        <b-icon :icon="value['@delete'] ? 'arrow-counterclockwise' : 'trash'"  />
                    </b-badge>
                </template>
                <template v-else>
                    <b-badge variant="transparent" href="#">
                        <b-icon-blank />
                    </b-badge>
                </template>
            </div>
            <markup-text
                :disabled="!editComment"
                :value="editComment ? editContent : value.content" 
                @input="editContent = $event" />
        </div>
        </div>

    </div>
</template>

<script>


const moment = require("moment")
export default {
 name: "cheecklistComment",
 props: {
     /**
      * a single checklist_item_comment object
      */
     value: {
         type: Object,
         required: true
     },
     /**
      * disable changing or deleting
      */
     disabled: {
         type: Boolean,
         required: false,
         default: true
     },
     /**
      * id of the parent component to trigger unfold all
      */
     parentId: {
         type: String,
         required: true
     }
 },
 mounted() {
     this.$root.$on('bts::checklist::unfoldAllComments', this.forceUnfold.bind(this))
 },
 methods: {  
     forceUnfold(ev) {
         if(ev == this.parentId) {
            this.collapsed = false
         }
     },
    allowEdit: function() {
        this.editComment = true
        this.editContent = this.value.content
    },
    saveChanges: function() {
        this.$set(this.value,'@dirty',true)
        this.$set(this.value,'content',this.editContent)
        this.editComment = false
    },
    toggleDeletion: function() {
        this.$set(this.value,'@delete',!this.value['@delete'])
    },
    stripHTML: function(html)
    {
        let tmp = document.implementation.createHTMLDocument("New").body;
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    },
    formattedDate: function(dstr, timeOnly = false) {
        return moment(dstr).format(timeOnly ? 'HH:mm' : 'DD.MM.YYYY')
    }
 },
 data() {
     return {
         collapsed: true,
         editComment: false,
         editContent: ""
     }
 }
}
</script>

<style lang="scss">
@import "../../style/customvars";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import '../../../node_modules/bootstrap-vue/src/index.scss';

 .comment-entry:nth-child(even) > .comment-header {
  background-color: #f2f4f6;
}

 .marked-for-deletion {
    text-decoration: line-through;
    opacity: 0.5;
 }

 .comment-editor {
    width: 120px;
}

.comment-dates {
    width: 75px;
}

</style>