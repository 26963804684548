<template>
  <div>       
    <b-button
      block 
      variant="light" 
      @click="$emit('setFilterStatus',!filterStatus)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
      >
         <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('filters.filter.filter') }}
      </div>
    </b-button>
    <b-form-checkbox switch :checked="filterTag" @change="updateFilter($event)" />
  </div>
</template>

<script>


export default {
  name: "baseCellFilterBoolean",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: Boolean
    },
    filterTag: {
      type: Boolean
    }
  },  
  watch: {
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.filterValue || !this.filterValue.filterHandler)) {
          this.updateFilter(newVal === 1 || newVal === true)
        }
      }
    }
  },
  methods: {

    //generates the filter function based on the filterValue, which can preload some heavy lifting 


    /**
     * The filterHandler is a function that returns a boolean
     * it gets handed the arguments:
     * 
     * cellData     any   the cell data as specified by the type
     * filterValue  any   the filterValue as stored in the filter value
     * columnData   any   the full column configuration
     */
    filterGenerator: (filterValue) => {
          return (cellData) => {
            return (((typeof cellData == "object" && cellData.hasOwnProperty('value')) ? cellData.value : cellData) == filterValue)
          }
        },

    /**
     * filterTagGenerator
     * returns an Object which stores the configuration in a user-readable format
     * if an array of strings is returned, each element creates a tag. Should only be used if multiple 
     * filter configurations are available
     */
    filterTagGenerator: (filterValue) => !!filterValue,


    /**
     * filterValueGenerator
     * build the filterValue to pass to the table
     * the filterValue is only consumed by this component, so it is solely responsible for the layout
     */
    filterValueGenerator: (str) => {
      return !!str
    },

    /**
     * buildFilterValue
     * this should build the correct filter data element from the filter tag
     * this is used to initialize the filter
     */
    buildFilterValue: (props) => { 
      return this.filterValue ?? this.filterTag 
    },

    /**
     * buildFilter
     * builds the filter object to store in the table
     * The filter object needs to have the following fields:
     * 
     *  filterHandler   Function        the function called by the table 
     *  filterTag       Array | String  the tag(s) defined by this filter
     *  filterValue     any             the filter value as defined by this filter
     * 
     */
    buildFilter: function(val) {
      return {'filterHandler' : this.filterGenerator(val), 
              'filterTag' : this.filterTagGenerator(val), 
              'filterValue' : this.filterValueGenerator(val)}
    },

    
    updateFilter(val) {
      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue',this.buildFilter(val))
    },

  }
}
</script>