<i18n lang="yaml" locale="de">
  filters.group.down: "Gruppieren"
</i18n>
<i18n lang="yaml" locale="en">
  filters.group.down: "Group"
</i18n>
<template>
<div>                 
    <b-button
    @click="groupPosition > 0 ? $emit('groupOff') : $emit('setGroupData',groupConfiguration)"
        block
        squared
        variant="light"
        class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
    >
      <b-icon-list-ul
        :variant="'dark'"
      ></b-icon-list-ul>
      <div class="small flex-grow-1 text-left pl-1">
          {{ $t('filters.group.down') }}
      </div>
      <b-badge v-if="groupPosition > 0"
      >{{ groupPosition }}</b-badge>
    </b-button>
</div>
</template>
<script>
export default {
  name: "baseCellGroupDynamic",
  props: {
    groupData: {
      required: true,
      type: Object
    },
    groupPosition: {
      required: true,
      type: Number
    },
    groupTag: {
      required: false,
      type: String
    },
    config: {
      type: Object
    }
  },
  watch: {
    'groupTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.groupData || !this.groupData.groupHandler)) {
          this.$emit('setGroupData',this.groupConfiguration)
        }
      }
    }
  },
  computed: {
    groupConfiguration: function(vm) {
      return {'groupHandler' : vm.groupHandler, 
              'groupLabeller' : vm.groupLabeller,
              'groupSorter' : vm.groupSorter,
              'groupTag' : '+', 
              'groupData' : {}}
    }
  },
  methods: {
    groupHandler: function(cellData, groupData, column) {
        return window.globalVue.$render.displayByIri((typeof cellData == "object" && cellData.hasOwnProperty('value') ? cellData.value : cellData) ?? '')
    },
    groupLabeller: function(groupName) {
      return (typeof groupName == "object" && groupName.hasOwnProperty('value') ? groupName.value : groupName) ?? ''
    },
    groupSorter: (a,b) => {
      let aClean = (typeof a == "object" && a.hasOwnProperty('value') ? a.value : a) ?? ''
      let bClean = (typeof b == "object" && b.hasOwnProperty('value') ? b.value : b) ?? ''
      return aClean.localeCompare(bClean)
    }

  }
}
</script>