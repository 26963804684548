<i18n locale="en" lang="yaml">
    title: "Post its"
    description: "Data here is only stored locally and not send to the database."
    deprecation: "Locally stored postits will be removed in a future release. Please refrain from creating new local postits and use the online storage"
    oldPostits: "Locally stored postits"
</i18n>

<i18n locale="de" lang="yaml">
    title: "Post-Its"
    description: "Daten hier werden nur lokal abgelegt und nicht an die Datenbank geschickt."
    deprecation: "Die lokal gespeicherten Postits werden in einem der nächsten Releases entfernt. Bitte benutze nur noch Online-Postits"
    oldPostits: "Auf dem Rechner gespeicherte Postits"
</i18n>
<template>
    <div>
        <b-avatar v-b-toggle="$id('sidebar')" href="#" icon="book" variant="primary" v-show="!!loggedInUser"></b-avatar>
        <b-sidebar 
            :id="$id('sidebar')" 
            body-class="p-2" 
            header-class="flex-grow-1"
            shadow 
            right 
            width="600px" 
            @hidden="sendToServer(true);killRegularUpdates()" 
            @shown="addRegularUpdates">
            <template #header="{ hide }">
                <span class="h1 m-0" @click="hide" ><b-icon-x /></span>
                <div class="ml-2 d-flex align-items-center flex-grow-1">
                    <b-button class="" variant="success" @click="createRemotePostit">
                        <b-icon-file-plus />
                    </b-button>
                    <h4 class="flex-grow-1 text-right">{{ $t('title') }}</h4>
                    <div class="ml-3">
                        <b-icon-cloud-slash-fill v-if="syncStatus == POSTIT_ERROR" variant="danger" />
                        <b-icon-cloud-check v-else-if="syncStatus == POSTIT_SYNCED" variant="primary" />
                        <b-icon-cloud v-else-if="syncStatus == POSTIT_OUTOFSYNC" variant="primary" />
                        <b-icon-cloud-arrow-up v-else-if="syncStatus == POSTIT_SYNCING" variant="primary" />
                    </div>
                </div>
            </template>
            
                <post-it v-for="(postit, idx) in remotePostits"
                    :key="postit.id"
                    :value="postit"
                    @input="updateRemotePostit(idx,$event)"
                    @delete="deleteRemotePostit(idx)"
                />
            

             <collapse-box id="localPostits" :title="$t('oldPostits')" hidden >
                <b-alert show variant="danger" block class="mb-1">{{ $t('deprecation') }}</b-alert>
                <b-alert show variant="warning" block>{{ $t('description') }}</b-alert>
                <b-button class="" block variant="success" @click="createLocalPostit">
                    <b-icon-file-plus />
                </b-button>
                <post-it v-for="(postit, idx) in localPostits"
                    :key="postit.id"
                    :value="postit"
                    @input="updateLocalPostit(idx,$event)"
                    @delete="deleteLocalPostit(idx)"
                />
             </collapse-box>
        </b-sidebar>
    </div>
</template>

<script>
import CollapseBox from '../collapseBox.vue'

import Postit from './subcomponents/Postit'

const lf = require('localforage')
const moment = require('moment')

const POSTIT_ERROR = 0
const POSTIT_SYNCED = 1
const POSTIT_OUTOFSYNC = 2
const POSTIT_SYNCING = 3

export default {
    name: "PostItSidebar",
    components: {
        postIt: Postit,
        CollapseBox
    },
    data() {
        return {
            localPostits: [],
            remotePostits: [],
            syncStatus: POSTIT_ERROR,
            intervalTimer: null
        }
    },
    created() {
        this.POSTIT_ERROR = 0
        this.POSTIT_SYNCED = 1
        this.POSTIT_OUTOFSYNC = 2
        this.POSTIT_SYNCING = 3
    },
    mounted: async function() {
        
    },
    watch: {
        'loggedInUser' : {
            immediate: true,
            handler: async function(user) {
                if(user !== null) {
                    this.loadFromLocal()
                    this.loadFromServer()
                }
            }
        }
    },
    computed: {
        loggedInUser: function(vm) {
            return vm.$store.getters.isAuthenticated ? (vm.$store.getters.getCurrentUser ?? null) : null
        }
    },
    methods: {
        loadFromServer: async function() {
            try {
                let response = await this.$cache.cached('user','/postIt')                   
                this.$set(this,'remotePostits', response ?? [])
                this.syncStatus = POSTIT_SYNCED
            } catch {
                this.syncStatus = POSTIT_ERROR
            }
        },
        sendToServer: async function(sidebarHidden = false){
                 if(this.syncStatus == POSTIT_OUTOFSYNC || sidebarHidden) {
                    this.syncStatus = POSTIT_SYNCING
                    let body={
                        'postIts':this.remotePostits
                    }
                    try {
                        await this.$cache.post('user','/postIt',body)                    
                        this.syncStatus = POSTIT_SYNCED
                        if(sidebarHidden) {
                            this.$bvToast.toast('Postits saved', {variant: 'success'})
                        }
                    } catch(e) {
                        this.syncStatus = POSTIT_ERROR
                        if(sidebarHidden) {
                            this.$bvToast.toast('Posting failed', {variant: 'danger'})
                        }
                    }
                 }
            },

        
        updateRemotePostit: function(idx, text) {
            this.remotePostits[idx].text = text
            this.syncStatus = POSTIT_OUTOFSYNC
        },
        createRemotePostit: function() {
            this.remotePostits.splice(this.remotePostits.length,0,{
                id: moment().unix(),
                text: ""
            })
            this.syncStatus = POSTIT_OUTOFSYNC
        },
        deleteRemotePostit(idx) {
            this.remotePostits.splice(idx,1)
            this.syncStatus = POSTIT_OUTOFSYNC
        },
        
        addRegularUpdates: function() {
            this.intervalTimer = setInterval(this.sendToServer,10 * 1000) //call this.sendToServer every 10s
        },
        killRegularUpdates: function() {
            clearInterval(this.intervalTimer)
        },

        /** manage local postits */

        loadFromLocal: async function() {            
            let res = await lf.getItem(this.loggedInUser+'-localPostits') ?? []
            if(res) {
                this.localPostits = this.rotate(res)
            }
        },
        saveToLocal() {
            lf.setItem(this.loggedInUser+'-localPostits', this.rotate(this.localPostits))
        },
        updateLocalPostit: function(idx, text) {
            this.localPostits[idx].text = text
            this.saveToLocal()
        },
        createLocalPostit: function() {
            this.localPostits.splice(this.localPostits.length,0,{
                id: moment().unix(),
                text: ""
            })
        },
        deleteLocalPostit(idx) {
            this.localPostits.splice(idx,1)
            this.saveToLocal()
        },
        rotate(arr) {
            var input     = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            var output    = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
            var index     = x => input.indexOf(x);
            var translate = x => index(x) > -1 ? output[index(x)] : x;
            return arr.map(postit => ({...postit, 'text' : postit.text.split('').map(translate).join('')}));
        }
    }
}
</script>

<style>

</style>