<i18n locale="en" lang="yaml" >
    overview: "Management overview"
    linkText: "Overview"
    popup.blocked: "The popup has been blocked. Please check your browser settings and try again"
</i18n>
<i18n locale="de" lang="yaml" >
    overview: "Managementübersicht"
    linkText: "Übersicht"
    popup.blocked: "Das neue Fenster wurde blockiert. Bitte checken Sie Ihre Browsereinstellungen"
</i18n>
<template>
    <div>
        <b-button 
            v-if="directOpen"
            class="px-0 mx-0"
            variant="transparent" 
            v-b-tooltip
            :title="$t('overview')"
            @click="openToOverview()"
            ><b-spinner v-if="preparing" small /><b-icon-card-heading variant="primary" /> <span v-show="!iconOnly">{{ $t('linkText') }}</span></b-button>
            
        <b-button 
            v-else
            class="px-0 mx-0"
            variant="transparent" 
            v-b-tooltip
            :title="$t('overview')"
            v-b-modal="$id('modal')"
            ><b-icon-card-heading variant="dark" /></b-button>

        <b-modal :id="$id('modal')" size="xl" ok-only>
            <div class="d-flex">
                <b-form-select :options="templateList" @input="updateViewerContent($event)" :value="currentTemplate">
                </b-form-select>
                <b-button @click="newWindow()">Open in new window</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
const moment = require('moment')

export default {
    name: "BaseViewer",
    props: {
        iconOnly: { required: false, default: true},
        directOpen: { required: false, default: true},
        openForPrinting: { required: false, default: true},
        dataValues: Object,
        fieldConfig: Array,
        useOrigin: { required: false, default: false},
        templates: {required: false, type: Array, default: () => []}
    },
    computed: {
        valueList: function(vm) {
            let res = {}
            for(const [key,v] of Object.entries(vm.dataValues)) {
                res[key] = (typeof v == "object" && v !== null && v.hasOwnProperty('value')) ? v.value : v
            }
            return res
        },
        templateList: function(vm) {
            let owners = []
                //if noOwner is set, use all available templates
                owners = vm.$store.getters['owner/getActiveOwners'].map(o => o.iri)
            
            if(!!vm.dataValues?.owner) { 
                owners = [vm.dataValues.owner]
            }
            
            
            let origin = vm.getOrigin()
            if(!origin) return []
            let moduleName = origin.module + '-' + origin.widget
            let templates = 
                vm.$store.getters['owner/getTemplates'].filter(t => 
                    owners.indexOf(t.owner) != -1 && 
                    t.module == moduleName
                    )
            let result = templates.map(t => (
                {   "body": t.body, 
                    "text": t.name, 
                    "fields": [].concat(t.fieldConfig ?? [], vm.fieldConfig)
                }
            ))
            if(vm.templates.length > 0) {
                return result.concat(vm.templates)
            }
            return result
        }
    },
    inject: {
        getOrigin: "getOrigin"
    },
    data() {
        return {
            fileURL: "",
            currentTemplate: "",
            currentDisplay: "",
            renderingScreenshot: false,
            preparing: false
        }
    },
    mounted() {
        this.$registry.getURLForModuleByName('file').then(res => {
            this.fileURL = res
        })
    },
    methods: {  
        openToOverview: async function() {
            if(this.templateList.length == 0) {
                return
            }
            this.preparing = true

            await this.updateViewerContent(this.templateList[0])
            this.newWindow()
            this.preparing = false
        },
        newWindow: function() {
            let w = window.open(null,'_blank',"menubar=true,scrollbars=1,toolbar=1,location,statusbar=1,height=1000,width=900")
            if(!w) {
                //show an error toast
                this.$bvToast.toast(this.$t('popup.blocked'),{variant: 'danger'})
                return;
            }
            w.document.title = "Overview"
            w.document.write(this.currentDisplay)

            if(this.openForPrinting) {
                //wait for 1 second for the system to load
                setTimeout(() => w.print(), 1000);
            }
        },   
        renderScreenshot: async function(ref) {
        this.renderingScreenshot = true
        let iframeBody = this.$refs[ref].contentDocument.body
        await this.$screenshot.render(iframeBody)
        this.renderingScreenshot = false
        },
        updateViewerContent: async function(template) {
            this.currentTemplate = template.body

             let origin = this.getOrigin()           
            //run the overview handler for the module where defined
            let enhancedValues = await (window && origin && origin.module && window[origin.module] && 
                                    window[origin.module]['functions']['Overview'] && 
                                    window[origin.module]['functions']['Overview'].enhance.bind(this)(this.valueList))


            this.currentDisplay = this.renderTemplate(template.body,template.fields, enhancedValues)
        },
        renderTemplate: function(fullString, fields, values) {
            //check for loops in the fullString structure
            let loopedString = fullString.replace(/\{\{loop:(.+?)\}\}(.*?)\{\{endloop:\1\}\}/mgs,function(_ , loopFieldName, loopContent) {
                let loopFields = fields.find(f => f.name == loopFieldName)
                let loopValues = values[loopFieldName] ?? []
                return loopValues  .map((value) => this.renderTemplate.bind(this)(loopContent,loopFields.loopConfig,value))
                                    .join('')
            }.bind(this))

            return this.renderString(loopedString, fields, values)
        },
        renderString: function(htmlstring, fields, values) {

            
            let plainRenderedFields = htmlstring.replace(/\{\{\!(.+?)\}\}/g,function(_ , fieldName) {
                return values[fieldName] ?? "n/a"
            }.bind(this))

            //replace all placeholders with its corresponding field value string
            let fieldReplace = plainRenderedFields.replace(/\{\{(.+?)\}\}/g,function(_ , fieldName) {
                let field = fields.find(f => f.name == fieldName)
                if(!field) return 'n/a'
                return this.renderToDisplay.bind(this)(field, values[fieldName])
            }.bind(this))

            return fieldReplace
        },
        renderToDisplay: function(field, value) {

            

            if(value === null) return ""
            if(!field) return value
            switch(field.type) {
                case "date":
                    return moment(value).format('[(KW] W [)] DD.MM.YYYY')
                case "dynamic":
                    if(Array.isArray(value)) {
                        return value.map((val) => this.renderToDisplay.bind(this)(field,val)).join(', ')
                    }

                    let mod = this.$store.getters.getModuleFromIri(value)
                        if(!mod) return value            
                        let dynItem = this.$store.getters[mod.module+'/getItems'] && this.$store.getters[mod.module+'/getItems'][value]
                        if(!dynItem) return value
                        return window && window[mod.module] && window[mod.module]['functions']['Display'].display(dynItem,{}) || value
                case 'markup':
                    const fileReplacement = /https?:\/\/.*?\/(preview|download)(.*?)(version=\/versions\/[0-9]+)(.*?)(&|&amp;)token=(^&amp;|[^\?\"&\s])+/g
                    return value.replace(fileReplacement,this.fileURL + "/$1$2$3$4" + "&amp;token="+this.$store.getters.getAuthenticationToken)
                case 'bool':
                    return '<span class="font-weight-bold">x</span>'
                case 'select':
                    let selectOptions = field.selectOptions || {}
                    let valArray = selectOptions.multiple ? value : [value]
                    let tokens = valArray.map(
                        token => (
                            selectOptions && selectOptions.entries.find(
                                            i => token == i.value) || {text: {}})
                                .text[this.$root.$i18n.locale] 
                        )
                    return tokens.join(', ')
                default: 
                    return value || ""
            }
        }
    }
}
</script>

<style>

</style>