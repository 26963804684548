
var CurrencyClass = function() 
{
    this.acceptedCurrency = []
    this.conversionTable = {}

    this.currentDisplayCurrency = null
    this.displayCurrency = null

    this.rendering = {
        list: [],
        display: ""
    }
}

CurrencyClass.prototype.loadCurrencyConfig = async function()
{
    const globalConfig = require('@/configuration.json');
    let currencyResponse = await fetch(globalConfig.baseUrl + 'currency.json')
    let config = await currencyResponse.json()
    globalVue.$set(this,"acceptedCurrency",config.acceptedCurrencies)
    globalVue.$set(this.rendering,"list",config.acceptedCurrencies)
    this.conversionTable = config.conversionRates
    globalVue.$set(this,"currentDisplayCurrency",this.acceptedCurrency[0])
    globalVue.$set(this.rendering,"display",this.acceptedCurrency[0])
}

CurrencyClass.prototype.getAcceptedCurrencies = function()
{
    return this.rendering.list
}

CurrencyClass.prototype.setCurrency = function(currency)
{
    if(this.acceptedCurrency.indexOf(currency) > -1) {
        globalVue.$set(this,"currentDisplayCurrency",currency)
        globalVue.$set(this,"displayCurrency",this.currentDisplayCurrency)
        globalVue.$set(this.rendering,"display",this.currentDisplayCurrency)
    }
}

CurrencyClass.prototype.convertFromTo = function(from, to, fromValue)
{
    if(this.acceptedCurrency.length == 0) return 0 //currency configuration not loaded yet
    if(from == to) return fromValue
    if(this.acceptedCurrency.indexOf(from) == -1 || this.acceptedCurrency.indexOf(to) == -1) {
       throw "non supported currency requested" //if one currency is not supported, conversion i
    }
    //verify the conversion is in the conversion table
    if(!this.conversionTable[from] || !this.conversionTable[from][to]) {
        throw "requested conversion rate not available"
    }

    return fromValue * this.conversionTable[from][to]
}

CurrencyClass.prototype.convertToDisplayCurrency = function(from, fromValue)
{
    if(!this.currentDisplayCurrency) { return 0 }
    return this.convertFromTo(from, this.currentDisplayCurrency,fromValue)
}


CurrencyClass.prototype.getDisplayCurrency = function()
{
    return this.rendering.display
}

CurrencyClass.prototype.getValueObject = function(val) {

    let splitted,currency,centValue
    

    splitted = !!val ? val.split('::') : null
    currency = !!val ? splitted[0] : 'EUR'
    centValue = !!val ? parseInt(splitted[1]) : 0

    let actualValue = (centValue / 100)

      return {currency: currency, actualValue: actualValue, centValue: centValue}
    }

CurrencyClass.prototype.valueFromStringInDisplayCurrency = function(dataString) 
{
    let obj = this.getValueObject(dataString)
    return this.convertToDisplayCurrency(obj.currency, obj.actualValue)
}

CurrencyClass.prototype.valueInTargetCurrency = function(dataString, targetCurrency) 
{
    let obj = this.getValueObject(dataString)
    return this.convertFromTo(obj.currency, targetCurrency, obj.actualValue)
}

CurrencyClass.prototype.install = function(Vue, options) 
{
      
    Vue.prototype.$currency = this

}




const Currency = new CurrencyClass()
export { Currency }