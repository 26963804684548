const cfg = require('@/configuration.json')
const RESTART_INTERVAL = 2*60*1000 //reboot all 2 minutes

import { io } from "socket.io-client";

export default {
  name: "sse-connector",
  params: {
    topic: String
  },
  render() {
      return {};
  },
  mounted() {
    this.initializeConnection()
    this.registerToEventBus()
    this.registerAutoRestart()
  },
  beforeDestroy() {
    this.eventSource.close()
  },
  data() {
    return {
      eventSource: null,
      lastMessageId: null,
      autoRestartTimer: null,
      verificationTable: {}
    }
  },
  methods: {
    registerToEventBus: function() {
      this.$root.$on('bts::mercure::restart',this.initializeConnection.bind(this))
    },

    registerAutoRestart: function() {
      if(this.autoRestartTimer !== null) {
        clearInterval(this.autoRestartTimer)
      }
      //this.autoRestartTimer = setInterval(this.initializeConnection.bind(this),RESTART_INTERVAL)
    },

    initializeConnection: function() {
      if(this.eventSource) {
        this.eventSource.close();
        this.eventSource = null;
      }

      //const subUrl = new URL("http://bos-ts.app:3002/.well-known/mercure?topic=/updates")
      let subUrl = cfg.mercureURL
      this.loadLastMessageIdFromCookie()  
      if(this.lastMessageId) {
        //subUrl = subUrl+"&Last-Event-ID="+this.lastMessageId
      }

      this.eventSource = io(subUrl)        
      
      this.eventSource.on("update", this.handleMessage)
      //this.eventSource.addEventListener('message', this.handleMessage.bind(this))

      this.eventSource.on("notify", this.getNotification.bind(this))
      
      this.registerAutoRestart()
    },

    loadLastMessageIdFromCookie: function() {
      let cookie = document.cookie.split('; ').find(row => row.startsWith('lastMessageId='))
        this.lastMessageId = null
        if(!!cookie ) {
          this.lastMessageId = cookie.split('=')[1] ?? null
          if(this.lastMessageId == "null") {
            this.lastMessageId = null
          }
        }
        return this.lastMessageId
    },

    saveLastMessageId: function(lastMessageId) {
      document.cookie = "lastMessageId="+lastMessageId
      this.lastMessageId = lastMessageId
    },

    handleMessage: function(msgEvent) {
      //try to save the last message id to a cookie
      //this.saveLastMessageId(msgEvent.lastEventId)


      var msgObj = msgEvent
      var itemIRI = msgObj.iri
      switch(msgObj.type) {
        case "create": 
          this.forceLoadCreated.bind(this)(itemIRI);
          break;
        case "update" : 
          this.forceUpdate.bind(this)(itemIRI); 
          break;
        case "delete" : 
          this.removeItem.bind(this)(itemIRI); 
          break;
      }      
    },

    forceUpdate: function(iri) {      
      if(!this.$store.getters.isIriLoaded(iri)) { return }
      //force entity update
      this.$store.dispatch('loadByIRI', {"iri": iri, "force": true})
      this.verifyItem(iri);
    },

    forceLoadCreated: function(iri) {
      var res = this.$store.getters.getIriMap.find(iriTest => iriTest.regex.test(iri))
      if(!res || !this.$store.getters[res.module+'/getItemByIRI']) { return }

      this.$store.dispatch(res.module+'/loadByIRI', {"iri": iri, "force": true})

      this.verifyItem(iri);
    },

    removeItem: function(iri) {
      var res = this.$store.getters.getIriMap.find(iriTest => iriTest.regex.test(iri))
      if(!res || !this.$store.dispatch(res.module+'/deleteItemByIri',iri)) { return }
    },

    verifyItem: function(iri) {
      if(this.verificationTable[iri]?.timer !== undefined && this.verificationTable[iri]?.timer !== null) {
        clearTimeout(this.verificationTable[iri].timer)
      }
      this.verificationTable[iri] = { 
          timer: setTimeout(this.verifyItemWasLoaded.bind(this),5000,iri) ,
          retries: 0
        }
    },

    verifyItemWasLoaded: async function(iri) {
      let res = await this.$store.dispatch('loadByIRI', {"iri": iri});
      if((typeof res['_isLoading'] == "object" && res['_isLoading'].hasOwnProperty('state') || res['_isLoading'] === false) || this.verificationTable[iri]?.retries > 5) {
        clearTimeout(this.verificationTable[iri]?.timer)
        this.verificationTable[iri].timer = null

        if(this.verificationTable[iri]?.retries > 5) {
          console.log("Could not load "+ iri)
        }
      } else {
        this.$store.dispatch('loadByIRI', {"iri": iri, "force": true})
        this.verificationTable[iri].timer = setTimeout(this.verifyItemWasLoaded.bind(this),1000,iri) 
        this.verificationTable[iri].retries++
      }
    },

    
    getNotification: async function(msgObj) {
      msgObj.iri
      if(msgObj.receiver !== this.$store.getters.getCurrentUser) return
      let res = await this.$store.dispatch('relation/loadNotification',msgObj.iri)
      let title = this.replaceTemplateStrings(res.title || '')
      let body = this.$t(res.body)
      let sender = res.sender

      
      this.$bvToast.toast(body, {title: title, variant: 'info', to: sender, solid: true})

    },
    replaceTemplateStrings: function(str) {
      //Step 1: find all tags with [[/identifier/number]]
      return str.replace(/\[\[(\/[\S]+?)\]\]/g,function(_, p1) {
        let mod = this.$store.getters.getModuleFromIri(p1)
        if(!mod) return p1

        let item = this.$store.getters[mod.module+'/getItems'][p1]
        if(!item) return p1
        return window && window[mod.module] && window[mod.module]['functions']['Display'].display(item, {short: true}) || p1
      }.bind(this))
    }
  }
}