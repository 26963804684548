<i18n lang="yaml" locale="de">
  less: "<"
  lessOrEqual: "≤"
  equal: "="
  biggerOrEqual: "≥"
  bigger: ">"
</i18n>
<i18n lang="yaml" locale="en">
  less: "<"
  lessOrEqual: "≤"
  equal: "="
  biggerOrEqual: "≥"
  bigger: ">"
</i18n>

<template>
  <div>       
    <b-button
      block 
      variant="light" 
      @click="$emit('setFilterStatus',!filterStatus)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
      >
         <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('filters.filter.filter') }}
      </div>
    </b-button>

    <div class="p-1">
      <b-form-group >
        <b-form-radio-group
          v-model="filterTypeMode"
          class="w-100"
          :options="options"
          name="buttons-1"
          @change="updateFilter()"
          buttons
        ></b-form-radio-group>
      </b-form-group>
      <b-form-input 
            type="number" 
            v-model="field1" 
            debounce="500"
        />
    </div>

    <b-input class="d-none" type="text" />
  
  </div>
</template>

<script>

const moment = require('moment')

export default {
  name: "baseCellFilterNumber",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: Object
    },
    filterTag: {
      type: String
    }
  },
  //automatically push the filter back up on initialization
  watch: {
    'field1' : {
      handler: function() {
        this.updateFilter()
      }
    },
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.filterValue || !this.filterValue.filterHandler)) {
          this.buildFromTag()
        }
      }
    }
  },
  data() {
    return {
      field1: null,      
      filterTypeMode: null
    }
  },
  computed: {
    options: function(vm) { return [
          { text: vm.$t('less'), value: 'less' },
          { text: vm.$t('lessOrEqual'), value: 'lessOrEqual' },
          { text: vm.$t('equal'), value: 'equal' },
          { text: vm.$t('biggerOrEqual'), value: 'biggerOrEqual' },
          { text: vm.$t('bigger'), value: 'bigger' },
        ]},
    filterConfiguration: function(vm) {
      return {
          type: vm.filterTypeMode,
          value: parseFloat(vm.field1)
        }
    },
    filterObject: function(vm) {
      return {
        "filterHandler": vm.filterHandler(),
        "filterTag" : vm.filterTagComputed,
        "filterValue" : vm.filterConfiguration
      }
    },
    filterTagComputed: function(vm) {
      return vm.filterTypeMode + '@@' + vm.field1
    }
  },
  methods: {    

    buildFromTag() {
      let res = this.filterTag.split('@@')
      if(res.length !== 2) return
      this.filterTypeMode = res[0]
      this.field1 = res[1]
      this.updateFilter()
    },
    /**
     * updateFilter
     * pushed the filter object to store in the table
     * The filter object needs to have the following fields:
     * 
     *  filterHandler   Function        the function called by the table 
     *  filterTag       Array | String  the tag(s) defined by this filter
     *  filterValue     any             the filter value as defined by this filter
     * 
     */
    updateFilter() {
      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue',this.filterObject)
    },

    /**
     * The filterHandler is a function that returns a boolean
     * it gets handed the arguments:
     * 
     * cellData     any   the cell data as specified by the type
     * filterValue  any   the filterValue as stored in the filter value
     * columnData   any   the full column configuration
     */
    filterHandler: function() {

      return (cellData, filterData, columnData) => {
        let val = typeof cellData == "object" ? cellData.value : cellData
        let split = !!val ? val.split('::') : 0
        let centValue = !!val && split.length == 2 ? parseInt(split[1]) : 0
        switch(filterData.type) {
          case "less" : return centValue < filterData.value * 100
          case "lessOrEqual" : return centValue <= filterData.value * 100
          case "equal" : return centValue == filterData.value * 100
          case "biggerOrEqual" : return centValue >= filterData.value * 100
          case "bigger" : return centValue > filterData.value * 100
        }



      //failsafe
      return true
      }        
    }
  }
}
</script>