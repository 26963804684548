<i18n lang="yaml" locale="de">
    tooltip.addComment: "Neuen Kommentar hinzufügen"
    noComments: "Keine Kommentare vorhanden"
</i18n>
<i18n lang="yaml" locale="en">
    tooltip.addComment: "Add new comment"
    noComments: "No comments available"
</i18n>
<template>
    <div class="d-flex flex-column">
        <div class="d-flex">
            <b-icon :icon="collapsed ? 'caret-right' : 'caret-down'" @click="collapsed = !collapsed" @dblclick="forceAllCommentsOpen()" />
            <b-badge v-if="!disabled" variant="success" href="#" @click="addNewComment()">
                <b-icon-file-plus />
            </b-badge>
            <div class="last-comment ml-2 d-flex justify-content-start align-items-center text-small overflow-auto">
                    <b-badge variant="secondary" class="mr-2">{{ value.length }}<b-icon-chat-left-fill class="ml-1" /></b-badge>
                <template v-if="value.length > 0">
                    <div :title="formattedDate(value[0].createdate)" class="comment-dates text-truncate  text-black-50">
                        <div>{{ formattedDate(value[0].createdate) }}
                        
                        </div>
                    </div>
                    <div :title="$render.displayByIri(value[0].lastEditedBy || value[0].createUser)" class="ml-2 comment-editor text-truncate">{{ $render.displayByIri(value[0].lastEditedBy || value[0].createUser) }}</div>
                    <div :title="stripHTML(value[0].content)" class="ml-2 text-truncate">{{ stripHTML(value[0].content) }}</div>
                </template>
            </div>
        </div>
        <div v-if="showCreateWindow" class="ml-3 p-1">
            <div             
                class="commentEditing d-flex flex-row">
                <div 
                    
                    class="d-flex flex-column mr-1"
                    >
                        <b-badge 
                            variant="success" 
                            href="#"
                            @click="saveNewComment()">
                            <b-icon-check2 />
                        </b-badge>
                        <b-badge 
                            variant="danger" 
                            href="#" 
                            @click="abortCreation()">
                            <b-icon-x />
                        </b-badge>
                </div>
                <markup-text 
                    :disabled="disabled"
                    optional
                    :value="newCommentContent"
                    @input="newCommentContent = $event"
                    />
            </div>
        </div>
        <div v-if="!collapsed">
            <checklist-comment
                v-for="(comment) in value"
                :key="$id(comment.iri || comment['@new'])"
                :disabled="disabled"
                :parentId="$id('parent')"
                :value="comment" />
        </div>
    </div>
</template>
<style>

</style>
<script>
import MarkupText from '../BaseEditors/MarkupText.vue'
import checklistComment from './_checklistComment.vue'

const moment = require("moment")

export default {
  components: { MarkupText },
    name: "checklistComments",
    components: {
        checklistComment
    },
    props: {       
        /**
         * array of checklist_item_comments objects
         */ 
        value: {
            type: Array,
            required: true
        },
        /**
         * enable modification or editing/deleting
         */
        disabled: {
            type: Boolean,
            required: false
        },
        /**
         * if the comment box should start collapsed
         */
        hidden: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            collapsed: true,
            showCreateWindow: false,
            newCommentContent: ""
        }   
    },
    methods: {    
        forceAllCommentsOpen: function() {
            this.collapsed = false
            this.$root.$emit('bts::checklist::unfoldAllComments', this.$id('parent'))
        },
        addNewComment: function() {
            this.showCreateWindow = true
            this.newCommentContent = ""
        }, 
        saveNewComment: function() {
            let commentList = [...this.value]
            commentList.splice(0,0, {
                'content': String(this.newCommentContent),
                'createUser': this.$store.getters.getCurrentUser,
                '@new': moment().valueOf()
            })
            this.$emit('input',commentList)
            this.showCreateWindow = false
            this.collapsed = false
        }, 
        abortCreation() {
            this,this.showCreateWindow = false
        },  
        stripHTML: function(html)
        {
            let tmp = document.implementation.createHTMLDocument("New").body;
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        formattedDate: function(dstr, timeOnly = false) {
            return moment(dstr).format(timeOnly ? 'HH:mm' : 'DD.MM.YYYY')
        }
    }
}
</script>