<i18n lang="yaml" locale="de">
  all: "alle"
  empty: "(leer)"
  hide.selected: "sichtbare ausblenden"
  only.selected: "nur sichtbare anzeigen"
  select.add: "Hinzufügen"
  select.all: "Auswahl setzen"
  select.remove: "Abwählen"

</i18n>
<i18n lang="yaml" locale="en">
  all: "all"
  empty: "(empty)"
  hide.selected: "hide visible"
  only.selected: "only show visible"
  select.add: "Add"
  select.all: "Set selection"
  select.remove: "Deselect"
</i18n>
<template>
  <div>   
    
    <div class="border border-secondary p-1" >
      <div class="d-flex align-items-center">
        <b-button
        variant="light" 
        @click="toggleFilter(!filterStatus)" 
        class="d-flex flex-nowrap justify-content-start align-items-center"
        >
          <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      </b-button>
      <b-form-input type="search" class="mb-0" v-model="selectListFilter" debounce="500" trim @keyup="updateIfEnter($event)"/>
      </div>
      <div class="d-flex w-100 align-items-center justify-content-between border-bottom border-secondary mb-2 pb-1">
        <b-button type="button" size="sm" class="p-1 m-1 text-left" variant="secondary" @click="removeFromBlacklist()">{{ $t('select.add') }}</b-button>
        <b-button type="button" size="sm" class="p-1 m-1 text-left" variant="secondary" @click="addShownToBlacklist()">{{ $t('select.remove') }}</b-button>
        <b-button type="button" size="sm" class="p-1 m-1 text-left" variant="primary" @click="blacklistAllButShown()">{{ $t('select.all') }}</b-button>
      </div>
      <div class="pl-1 overflow-auto text-truncate"
          style="max-height: 200px; max-width: 200px; " >
        <b-form-checkbox 
          v-for="(checkbox,idx) in optionArray"
          :key="$id('cb-'+checkbox.value+'-'+idx)"
          :checked="checkbox.checked"
          :title="checkbox.text"
          class="text-truncate"
          @change="updateFilter(checkbox,$event)" >{{ checkbox.text }}</b-form-checkbox>
      </div>

    </div>
    <!--
    <b-form-input :value="filterTag" @update="updateFilter($event)" debounce="500" trim/>
    -->
  </div>
</template>

<script>


export default {
  name: "baseCellFilterDynamic",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: Array
    },
    filterTag: {
      type: String
    },
    rowData: {
      type: Array,
      required: true,
      default: () => ([])
    },
    config: {
      type: Object,
      required: true
    },
    filteredRowData: {
      type: Array,
      required: true,
      default: () => ([])
    },
  },  
  watch: {
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.filterValue || !this.filterValue.filterHandler)) {
          this.updateFilter()
        }
      }
    }
  },
  methods: {
    toggleFilter(ev) {
      this.$emit('setFilterStatus', ev)
      if(ev) {
        this.$emit('setFilterValue',this.buildFilter())
      }
    },

    //generates the filter function based on the filterValue, which can preload some heavy lifting 


    /**
     * The filterHandler is a function that returns a boolean
     * it gets handed the arguments:
     * 
     * cellData     any   the cell data as specified by the type
     * filterValue  any   the filterValue as stored in the filter value
     * columnData   any   the full column configuration
     */
    filterGenerator: function() {
           //if selectShowAll is selected, just return true
           //otherwise, compare to the selection array
           //if selectShowEmpty is checked, also show empty cells

            return ((cellData) => {
              let safeData = (cellData?.hasOwnProperty('value') ? cellData.value : cellData) ?? ""
              if(Array.isArray(safeData)) {
                return safeData.some(item => (this.blacklistArray.findIndex(sel => sel == item) == -1))
              } else {
                return (this.blacklistArray.findIndex(sel => sel == safeData) == -1)
              }

            }).bind(this)
      },

    /**
     * filterTagGenerator
     * returns an Object which stores the configuration in a user-readable format
     * if an array of strings is returned, each element creates a tag. Should only be used if multiple 
     * filter configurations are available
     */
    filterTagGenerator: function() {
      let newTag = this.blacklistArray.join(':_:')
      
      return newTag
    },


    /**
     * filterValueGenerator
     * build the filterValue to pass to the table
     * the filterValue is only consumed by this component, so it is solely responsible for the layout
     */
    filterValueGenerator: function() {
      return this.blacklistArray
    },

    updateFilter(ev = null, val) {           

      //now dissect the filterTag and remove selected items
      
      if(ev !== null) {
        //remove or add ev.value to blacklist
        let blacklistIndex = this.blacklistArray.findIndex(element => element == ev.value)
        
        if(blacklistIndex == -1 && !val) {
          //add to blacklist 
          this.blacklistArray.push(ev.value)
        } else if(blacklistIndex > -1 && !!val) {
          //remove from list
          this.blacklistArray.splice(blacklistIndex,1)
        }
      } else {
        if(this.filterTag) {
          let tagEntries = this.filterTag.split(':_:')
          this.blacklistArray = tagEntries
        }
      }


      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue',this.buildFilter())
    },
    updateIfEnter: function(ev) {
      if(ev.keyCode == 13) {
        if(ev.ctrlKey) {
          this.removeFromBlacklist()
        } else {
          this.blacklistAllButShown()
        }
      }
    },
    clearBlacklist: function() {
      this.blacklistArray = []
      this.$emit('setFilterValue',this.buildFilter())
    },
    hideVisible: function() {
        //add each item to the blacklist array
        this.optionArray.forEach(option => {
          if(!this.blacklistArray.find(bl => bl == option.value)) {
            this.blacklistArray.push(option.value)
          }
        })
        this.$emit('setFilterValue',this.buildFilter())        
    },
    blacklistAllButShown: function() {
      this.blacklistArray = []
      
      this.fullOptionArray.forEach(fo => {
        if(this.optionArray.findIndex(oa => oa.value == fo.value ) === -1) {
          this.blacklistArray.push(fo.value)
        }
      })

      this.$emit('setFilterValue', this.buildFilter())
    },
    addShownToBlacklist: function() {
      this.optionArray.forEach(oa => {
        let idx = this.blacklistArray.findIndex(ba => ba == oa.value)  
        if(idx === -1) {
          this.blacklistArray.push(oa.value)
        }
      })
      this.$emit('setFilterValue', this.buildFilter())
    },
    removeFromBlacklist: function() {      
      this.optionArray.forEach(oa => {
          //prevent duplicates in blacklistArray
          let idx = this.blacklistArray.findIndex(ba => ba == oa.value)            
          if(idx > -1) {
            this.blacklistArray.splice(idx,1)
          }            
      })
      this.$emit('setFilterValue', this.buildFilter())
    },


    /**
     * buildFilter
     * builds the filter object to store in the table
     * The filter object needs to have the following fields:
     * 
     *  filterHandler   Function        the function called by the table 
     *  filterTag       Array | String  the tag(s) defined by this filter
     *  filterValue     any             the filter value as defined by this filter
     * 
     */
    buildFilter: function() {
      return {'filterHandler' : this.filterGenerator(), 
              'filterTag' : this.filterTagGenerator(), 
              'filterValue' : this.filterValueGenerator()}
    },

  },
  computed: {
    fullOptionArray: function(vm) {
      //this row creates a dependency to filteredRowData, causing the list to update automatically when the filtering changes.
      vm.filteredRowData
      //build a full list of all available options
      let optionArray = vm.rowData.filter(row => {
        if(!row?.iri?.value) { return false } //dont show any rows without an iri (like groups etc...)
        for(const [key, val] of Object.entries(row?.['@filterConfig'] ?? {})) {
          if(!val && key !== vm.config.name) { return false }
        }

        return true
        
      })
      
      let mappedOptions = []
      optionArray.forEach(row => {
        let valElement = (row?.[vm.config.name]?.hasOwnProperty('value') ? row[vm.config.name].value : row[vm.config.name]) ?? ""
        if(Array.isArray(valElement)) {
          valElement.forEach(val => {
            mappedOptions.push({
              text: vm.$render.displayByIri(val),
              value: val,
              checked: true
            })
          })
        } else {
            mappedOptions.push({
              text: vm.$render.displayByIri(valElement),
              value: valElement,
              checked: true
            })
        }
      })

      return mappedOptions
    },  
    optionArray: function(vm) {
      let optionArray = vm.fullOptionArray.filter((item,idx,selArray) => {
        //now remove any duplicates from the system by checking if the first element found equals the element index itself
        let duplicate = selArray.findIndex((element) => element.text === item.text) === idx
        let textfilter = true
        if(vm.selectListFilter !== "") {
          try {
            textfilter = item.text?.toLowerCase().search(vm.selectListFilter.toLowerCase()) !== -1
          } catch { 
            textfilter = true
          }
        }
        

        return duplicate && textfilter
      })

      optionArray.sort((a,b) => {
        try {
          return (a?.text ?? "")?.localeCompare(b?.text ?? "") ?? 0
        } catch {
          return 0
        }
      })

      optionArray.forEach(option => {
        if(!!vm.blacklistArray.find(bl => bl == option.value)) {
          option.checked = false
        }
      })

      return optionArray
    }
  },
  data() {
    return {
      blacklistArray: [],
      selectListFilter: ""
    }
  }
}
</script>