<i18n locale="en" lang="yaml">
table.tooltip: "Saved table configurations"
table.storeFilter: "Save configuration"
table.saveAsDefault: "Save/Update layout as default"
table.resetToFactory: "Reset to factory default"
table.filterName.description: "The name for the configuration"
table.filterName.title: "Configuration name"
filter.confirm.delete: "Are you sure you want to delete configuration \"{name}\" ? This cannot be undone."
filter.confirm.update: "Should configuration \"{name}\" be updated to the current settings? This cannot be undone."
table.tooltip.applyFilter: "Apply configuration"
table.tooltip.updateFilter: "Update configuration"
table.tooltip.killFilter: "Delete configuration"
filter.body.delete.success: "Configuration \"{name}\" was deleted"
filter.text.delete.success: "Configuration deleted"
filter.body.delete.failed: "Failed to delete the configuration \"{name}\""
filter.text.delete.failed: "Deletion failed"
filter.body.create.success: "Configuration \"{name}\" saved"
filter.text.create.success: "Configuration saved"
filter.body.create.failed: "Could not save the configuration \"{name}\""
filter.text.create.failed: "Saving failed"
filter.body.update.success: "Configuration \"{name}\" was updated"
filter.text.update.success: "Configuration updated"
filter.body.update.failed: "Failed to update the configuration \"{name}\""
filter.text.update.failed: "Updating failed"
filter.title.default.success: "Default view saved"
filter.text.default.success: "Default view was saved"
filter.title.deletedefault.success: "Default view reset"
filter.text.deletedefault.success: "Default view was reset"
</i18n>

<i18n locale="de" lang="yaml">
table.tooltip: "Gespeicherte Tabellenkonfigurationen"
table.storeFilter: "Konfiguration speichern"
table.saveAsDefault: "Layout als Standard speichern"
table.filterName.description: "Name unter der die Konfiguration gespeichert werden soll"
table.filterName.title: "Konfigurationsname"
filter.confirm.delete: "Sind Sie sicher, dass Sie die Konfiguration \"{name}\" löschen möchten? Dies kann nicht rückgängig gemacht werden."
filter.confirm.update: "Soll die Konfiguration \"{name}\" auf die aktuelle Einstellung aktualisiert werden? Dies kann nicht rückgängig gemacht werden."
table.tooltip.applyFilter: "Konfiguration anwenden"
table.tooltip.updateFilter: "Konfiguration aktualisieren"
table.tooltip.killFilter: "Konfiguration löschen"
filter.body.delete.success: "Konfiguration \"{name}\" wurde gelöscht"
filter.text.delete.success: "Konfiguration gelöscht"
filter.body.delete.failed: "Konfiguration \"{name}\" konnte nicht gelöscht werden"
filter.text.delete.failed: "Löschung fehlgeschlagen"
filter.body.create.success: "Konfiguration \"{name}\" gespeichert"
filter.text.create.success: "Konfiguration gespeichert"
filter.body.create.failed: "Konnte Konfiguration \"{name}\" nicht speichern"
filter.text.create.failed: "Speichern fehlgeschlagen"
filter.body.update.success: "Konfiguration \"{name}\" aktualisiert"
filter.text.update.success: "Konfiguration aktualisiert"
filter.body.update.failed: "Failed to update the configuration \"{name}\""
filter.text.update.failed: "Aktualisierung fehlgeschalgen"
filter.title.default.success: "Standard gespeichert"
filter.text.default.success: "Standard-Spaltenkonfiguration gespeichert"
filter.title.deletedefault.success: "Standard zurückgesetzt"
filter.text.deletedefault.success: "Standard-Spaltenkonfiguration auf Voreinstellungen zurückgesetzt"
</i18n>
<template>
    <div>
        <b-dropdown boundary="window" :variant="variant">
            <template #button-content>
                <b-icon :icon="buttonIcon" :variant="buttonVariant"/>
            </template>
            <b-dropdown-text>
                <b-button-toolbar class="d-flex justify-content-between">
                    <b-button v-b-modal="$id('modal')" variant="success"  v-b-tooltip :title="$t('table.storeFilter')"><b-icon-file-plus /></b-button>
                    <b-button-group>                    
                        <b-button v-if="!noDefault" @click="saveAsDefault()" variant="primary"  v-b-tooltip :title="$t('table.saveAsDefault')"><b-icon-check2-circle /></b-button>
                        <b-button v-if="!noReset" @click="resetToFactory()" variant="danger"  v-b-tooltip :title="$t('table.resetToFactory')"><b-icon-x-square /></b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-dropdown-text>
            
            <b-dropdown-item 
                v-for="(filter,idx) in filterListWithPresets" 
                :key="$id('filter-'+filter.id)"  
                :link-class="'d-flex ' + ((filter.id === lastSelected) ? 'nav-sidebar--link--active' : '') "
                @click="applyFilter(idx,$event)"  
                variant="dark"
                >
                <div class="pr-3" v-b-tooltip :title="$t('table.tooltip.applyFilter')">{{ filter.name }}</div>   
                <b-button-group class="ml-auto">             
                <b-button  v-b-tooltip :title="$t('table.tooltip.updateFilter')" @click="updateFilter(filter,$event)" variant="success" size="sm"><b-icon-arrow-repeat /></b-button>
                <b-button  v-b-tooltip :title="$t('table.tooltip.killFilter')"  @click="killFilter(filter)" variant="danger" size="sm">   <b-icon-trash /></b-button>
                </b-button-group>
            </b-dropdown-item>
        </b-dropdown>
        <b-modal :id="$id('modal')" @ok="saveConfiguration">
            <b-form-group
                id="fieldset-1"
                :description="$t('table.filterName.description')"
                :label="$t('table.filterName.title')"
                label-for="modal-text"
                >
                <b-form-input id="modal-text" v-model="filterName" trim></b-form-input>
            </b-form-group>
            <slot></slot>
        </b-modal>
    </div>
</template>

<script>

const lf = require('localforage')

export default {
    name: "FilterStorage",
    props: {
        currentConfig: Object,
        suffix: {
            required: false,
            type: [String],
            default: ''
        },
        presetFilters: Array,
        defaultFilter: {
            required: false,
            type: [Boolean, Number, String],
            default: false
        },
        cleared: {
            required: false,
            type: Boolean,
            default: false
        },
        noDefault: {
            required: false,
            type: Boolean,
            default: false
        },
        noReset: {
            required: false,
            type: Boolean,
            default: false
        },
        global: {
            required: false,
            type: Boolean,
            default: false
        },
        variant: {
            required: false,
            type: String,
            defaut: 'transparent'
        },
        buttonVariant: {
            required: false,
            type: String,
            default: 'primary'
        },
        buttonIcon: {
            required: false,
            type: String,
            default: "table"
        },
        restoreFilter: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            filterName: "",
            filterList: [],
            activeFilter: null,
            lastSelected: "",
            initialized: false,
            deleting: false
        }
    },
    inject: {
        "getOrigin" : { name: "getOrigin" , default: false }
    },
    watch: {
        'currentConfig' : {
            immediate: true,
            handler: function() {
                this.lastSelected = this?.currentConfig?.id ?? false
                if(!this.cleared && !!this.lastSelected && this.initialized) {
                    this.saveLastConfig(this.lastSelected)
                }
            }
        },
        'cleared' : {
            handler: function() {
                if(this.cleared) {
                    this.saveLastConfig(false)
                }
            }
        }
    },
    mounted: async function() {
        await this.$libraries.isAvailable('user')       
        this.$watch(
            function() { 
                let moduleFilters = this.$store.getters['user/getFiltersForModule'](this.getOrigin()['module']) || {}
                return (moduleFilters[this.getOrigin()['widget']] || []).length 
            }, 
            function() {                
                this.reloadFromStore()
                this.initialized = true
            }, {immediate: true})
        this.getDefaultForTable()
    },
    computed: {
        filterListWithPresets: function(vm) {
            return (this.presetFilters || []).concat(vm.filterList)
        }
    },
    methods: {
        reloadFromStore() {
            let moduleFilters = this.$store.getters['user/getFiltersForModule'](this.getOrigin()['module']) || {}
            let widgetFilters = moduleFilters[this.getOrigin()['widget']] || []
            this.filterList = widgetFilters.filter(filter => {
                return (this.suffix == '' && (filter.suffix === '' || !filter.suffix || filter.suffix === null)) || filter.suffix === this.suffix
            })
            

            //try activate the last filter
            if(this.restoreFilter) {
                this.loadLastConfig()
            }
        },
        applyFilter: function(idx,ev = null) {
            if(ev) {
                ev.preventDefault()
                ev.stopPropagation()
            }

            this.activeFilter = this.filterListWithPresets[idx]?.id ?? null
            if(this.activeFilter == null){ return}
            this.saveLastConfig(this.filterListWithPresets[idx].id)
            this.$emit('forceReset',true)
            this.$nextTick(() => {
                this.$emit('restoreFilter',this.filterListWithPresets[idx])
            })
        },
        killFilter: async function(filter) {
            
            try {
                
                let res = await this.$bvModal.msgBoxConfirm(this.$t('filter.confirm.delete', {name: filter.name}), {})
                if(!res) return
                await this.$cache.delete('user','/filters/'+filter.id)
                let listIndex = this.filterListWithPresets.findIndex(l => l.id == filter.id)
                this.filterListWithPresets.splice(listIndex,1)

                
                await this.$store.dispatch('user/getUserFiltersFromServer')
                
                this.$bvToast.toast(this.$t('filter.body.delete.success', {name: filter.name}), {
                    title: this.$t('filter.text.delete.success', {name: filter.name}),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true
                })
                this.activeFilter = null
                this.reloadFromStore()
            } catch {
                this.$bvToast.toast(this.$t('filter.body.delete.failed', {name: filter.name}), {
                    title: this.$t('filter.text.delete.failed', {name: filter.name}),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                })
            }
            
        },
        updateFilter: async function(filter,ev) {
            ev.stopPropagation()
            ev.preventDefault()
            try {
                let res = await this.$bvModal.msgBoxConfirm(this.$t('filter.confirm.update', {name: filter.name}), {})
                if(!res) return
                
                await this.$cache.update('user','/filters/'+filter.id, {configuration: {...this.currentConfig}})
                await this.$store.dispatch('user/getUserFiltersFromServer')
                this.$bvToast.toast(this.$t('filter.body.update.success', {name: filter.name}), {
                    title: this.$t('filter.text.update.success', {name: filter.name}),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true
                })                
                this.reloadFromStore()
            } catch {
                this.$bvToast.toast(this.$t('filter.body.update.failed', {name: filter.name}), {
                    title: this.$t('filter.text.update.failed', {name: filter.name}),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                })
            }
        },
        saveConfiguration: async function() {
            if(!this.currentConfig || this.filterName == "") return

            let storageObject = {}
            storageObject.configuration = {...this.currentConfig}
            storageObject.name = this.filterName
            storageObject.user = this.$store.getters.getCurrentUser
            storageObject.module = this.getOrigin()['module']
            storageObject.widget = this.getOrigin()['widget']
            storageObject.suffix = this.suffix
            try {
                let response = await this.$cache.create('user','/filters',storageObject)

                this.$bvToast.toast(this.$t('filter.body.create.success', {name: storageObject.name}), {
                    title: this.$t('filter.text.create.success', {name: storageObject.name}),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true
                })

                storageObject.id = response.id
                
                await this.$store.dispatch('user/getUserFiltersFromServer')
                this.saveLastConfig(storageObject.id)

                
                this.reloadFromStore()
            } catch {
                this.$bvToast.toast(this.$t('filter.body.create.failed', {name: storageObject.name}), {
                    title: this.$t('filter.text.create.failed', {name: storageObject.name}),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                })
            }


        },
        
        getViewSaveName: function() {
            let origin = this.$parent.$options.name
            //check if a getOrigin is provided and get the name from there if possible
            if(this.getOrigin) {
                origin = this.getOrigin().widget
            }

            let routeName = this.$route.name

            if(this.global) {
                routeName = "__GLOBAL__"
            }

          return origin+'@'+routeName + '_' + this.suffix
        },

        saveLastConfig: function(activeConfigId) {
          //view is currently identified by route name + component name. Save order and widths in localstorage using persister
          let saveKey = this.getViewSaveName()
          this.lastSelected = activeConfigId
          lf.setItem(saveKey, activeConfigId)
        },
        loadLastConfig: async function() {
            //load view by fetching the data from localstorage using route name and component name
            
            //dont change anything if the user changed the filter
            if(this.activeFilter) return
            
            let saveKey = this.getViewSaveName()
            let lastActiveConfigId = await lf.getItem(saveKey)
            if(!lastActiveConfigId) {
                //maybe display a message that the last config could not be restored (deleted/unavailable/other)
                return
            }
            //find id in filterList
            let filter = this.filterListWithPresets.find(f => f.id == lastActiveConfigId)

            this.$emit('restoreFilter',filter)
            this.lastSelected = lastActiveConfigId
         
        },
        saveAsDefault: async function() {
             let saveKey = this.getViewSaveName() + '__DEFAULT'
             try {
                await lf.setItem(saveKey,  this.currentConfig)
                this.$bvToast.toast(this.$t('filter.title.default.success'), {
                        title: this.$t('filter.text.default.success'),
                        variant: "success",
                        autoHideDelay: 500,
                        appendToast: true
                    })
                this.$emit('setdefault',this.currentConfig)
             } catch(e) {}
        },
        resetToFactory: async function() {
             let saveKey = this.getViewSaveName() + '__DEFAULT'
             try {
                await lf.removeItem(saveKey)
                this.$bvToast.toast(this.$t('filter.title.deletedefault.success'), {
                        title: this.$t('filter.text.deletedefault.success'),
                        variant: "success",
                        autoHideDelay: 500,
                        appendToast: true
                    })

                this.$emit('forceReset',true)
             } catch(e) {}

             
             if(this.defaultFilter) {
                 //instead of deleting the filter, reset to the preset id
                 let newDefault = this.filterListWithPresets.find(f => f.id == this.defaultFilter)
                 if(newDefault) {
                     this.$emit('setdefault',JSON.parse(JSON.stringify(newDefault)))
                 }
             }

        },
        getDefaultForTable: async function() {
            let saveKey = this.getViewSaveName() + '__DEFAULT'
            let defaultSettings = await lf.getItem(saveKey)
            if(defaultSettings) {
                this.$emit('setdefault',JSON.parse(JSON.stringify(defaultSettings)))
            } else if(this.defaultFilter) {
                let newDefault = this.filterListWithPresets.find(f => f.id == this.defaultFilter)
                if(newDefault) {
                    this.$emit('setdefault',JSON.parse(JSON.stringify(newDefault)))
                }
            }
        }
    }
}
</script>

<style lang="scss">

@import "src/style/customvars";
@import "node_modules/bootstrap/scss/bootstrap";

    .dropdown-item.nav-sidebar--link--active {
        @extend .alert-primary;
        color: black;
    }
</style>