import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@/store'
import { APIHandler } from '@/helper/APIHandler'

import { routes } from '@/assets/Routes.js'


//import views from '@/assets/views.json'

import { libraryManager } from '@/helper/LibraryManager'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async function(to, fr, next) {
    try {
      store.dispatch('setNavigationState',true)
      //check if the requested view has been defined
      var requestedView = to
      if(!requestedView) {
        return; 
      }
      let securityCheck = await (new APIHandler()).securityCheck()

      
      if(requestedView.meta.requiresAuthentication && !securityCheck) {
        //force redirect to landing page
        /**
         * @todo add landing page property
         */
        store.commit('setRedirect', to.path)
        next('\/')
        return
      }

      
      let allowAccess = requestedView.meta?.allowAccess?.() ?? true
      if(!allowAccess) {
        next('\/') 
        return
      }


      //check if a redirect is set. If so, remove the redirect and apply it

      var redirectTarget = store.dispatch('getRedirect')
      if(redirectTarget.length > 0) {
        next(redirectTarget);
        return;
      }

      //before changing the layout, check if any navigation hooks have been set
      let hooks = await Promise.all(store.getters.navigationHooks.map((hook) => hook(to.path)))
      if(!hooks.every(i => i)) {
        next(false);
        return;
      }

      window.globalVue.$cache.clearMiddleware()


      //if defined, load the view for the route
      store.commit('changeGridLayout', to.meta.gridLayout)

      if (to.matched.length === 0 && to.path !== fr.path) {
        //split url to get the requested route
        var modulename = to.path.split('/').slice(1,2)
        if(modulename === undefined ) { return }
        var lib = libraryManager.addLibrary(modulename)
        module = await lib;
        var isLibset = (module && module.routes) || false;
        var routeDefined = isLibset && module.routes.find((elem) => elem.path === to.path) !== undefined
        
        if(!isLibset || !routeDefined) { 
          return; 
        }
    
        next(to.path)
      } else {
        next()
      }
    } catch(e) {

    } finally {
      store.dispatch('setNavigationState',false)
    }
})

export default router
