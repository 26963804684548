<script>
export default {
  functional: true,
  name: "BaseCellDisplayCurrency",
  render: function(createElement, context) {

      let val = context.props.value && ((typeof context.props.value == "object" && !Array.isArray(context.props.value)) ? context.props.value.value : context.props.value) || null
      let actualValue,currency,centValue;
      
      
      if(!val) {
        return createElement('td',{class: "base-table--cell " + (context.props.value && context.props.value.cellClasses || '') } ,'-')
      }

      let prefixComponent = null
      
      if(context?.props?.config?.prefixComponent) {
        prefixComponent = createElement(context.props.config.prefixComponent, context.props)
      }

      let obj = context.parent.$currency.getValueObject(val)
      currency = obj.currency
      actualValue = obj.actualValue
      centValue = obj.centValue

      let displayCurrency = context.parent.$currency.convertToDisplayCurrency(currency,actualValue)

      let currencyDisplay = context.parent.$currency.getDisplayCurrency()+' '+ displayCurrency.toLocaleString([],{minimumFractionDigits: 2,maximumFractionDigits: 2})

      let originalCurrency = currency+' '+ actualValue.toLocaleString([],{minimumFractionDigits: 2,maximumFractionDigits: 2})

      let conversionDisplay = null
      if(currency !== context.parent.$currency.getDisplayCurrency()) {
        conversionDisplay = createElement('b-icon-arrow-left-right',{class: "ml-1 small", attrs: { title: originalCurrency}},null)
      }


      return createElement('td',{class: "base-table--cell "+(context.props.value && context.props.value.cellClasses || '')}, [prefixComponent, currencyDisplay, conversionDisplay])
      
        
    }
}
</script>

<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
