import { store } from '@/store'
import { persister } from '@/helper/PersistenceManager'


const OwnerManager = function() {
    this.storage = "__OWNERS__"
    this.loadedOwners = {}
    this.loadingOwners = {}
}

OwnerManager.prototype.getOwner = async function(ownerIRI)
{
    if(ownerIRI === "") { return {} }
    if(this.loadedOwners[ownerIRI]) {
        return this.loadedOwners[ownerIRI]
    }

    if(!this.loadingOwners[ownerIRI]) 
    { 
        this.loadingOwners[ownerIRI] = 
            persister.cached('owner', ownerIRI, false, 7200)
    }     
    var ownerConfig = await this.loadingOwners[ownerIRI]
    this.loadedOwners[ownerIRI] = ownerConfig
    return this.loadedOwners[ownerIRI]
}

const ownerManager = new OwnerManager()

export { ownerManager }