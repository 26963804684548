<template functional>    
    <td class="base-table--cell base-cell-edit-date" 
     :class="props.value && props.value.cellClasses || ''"
     :title="props.value && 
            props.value.mouseover && 
            (typeof props.value.mouseover == 'Object' ? props.value.mouseover[parent.$i18n.locale] : props.value.mouseover) 
            || props.value && props.value.value || ''"
        >
        <b-input type="time"
          :value="props.value.value" 
          @input="listeners.input($event)"
          :optional="props.config && props.config.optional || false"
          :style="'width: '+(32+props.config['@width'])+'px;'"
          :showCw="false"
        />
       </td>

</template>
<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>
export default {
  name: "baseCellEditTime",
  methods: {
  }
}
</script>
