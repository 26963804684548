<i18n lang="yaml" locale="en">
    markedForDeletion: "marked for deletion"
    add.external: "add external reference"
</i18n>
<i18n lang="yaml" locale="de">
    markedForDeletion: "Zur Löschung  vorgemerkt"
    add.external: "Externe Referenz hinzufügen"
</i18n>

<template>
    <div 
        class="checklist--wrapper"
        :class="{'alert-danger marked-for-deletion' : localData['@delete'] }"
        :title="localData['@delete'] ? $t('markedForDeletion') : ''"
        >
        
        <div 
            class="checklist--entry border-bottom"
            :class="{'group' : submap.length > 0}"
            >
            <div class="checklist-fixed-width-block d-flex flex-nowrap">
                <div 
                    class="checklist--toggle"
                    :style="'padding-left: '+level*15+'px;'"
                >
                    <b-icon
                        @click="collapsed = !collapsed"
                        @dblclick="forceAllChildren()"
                        variant="dark" 
                        :icon="submap.length > 0 ? (collapsed ? 'chevron-right' : 'chevron-down') : 'blank'" />
                </div>
                <div class="checklist--extern d-flex">
                    <b-badge
                        variant="transparent"
                        href="#"
                        @click="prepareExternalReference()"
                    >
                    <b-icon
                        variant="dark"
                        :icon="!!localData.externalReference ? 'box-arrow-up-right' : 'blank'" />
                    </b-badge>
                </div>

                <div class="checklist--title d-flex flex-grow-1 overflow-auto">
                    <span>{{ cid }}</span>
                    <div 
                        v-if="disabled"
                        :title="localData.title"
                        class="mx-2 text-truncate">
                        {{ localData.title }}
                    </div>
                    <b-input 
                        v-else 
                        size="sm"
                        class="mx-2"
                        :value="localData.title"                
                        @input="updateField('title',$event)"
                    />
                </div>                
            </div>
            <div class="checklist-status">
                <status-trafficlight 
                    v-if="statusType == 'trafficlight'"
                    :value="localData.status" 
                    :disabled="disabled"
                    @input="updateField('status',$event)" />
                <status-checkbox 
                    v-if="statusType == 'checkbox'"
                    :value="localData.status" 
                    :disabled="disabled"
                    @input="updateField('status',$event)" />
            </div>
            <div class="checklist-responsible">
                <div v-if="disabled">{{ $render.displayByIri(localData.responsible) }}</div>
                <base-selector
                    v-else
                    :selectables='[{module: "user", title: {"de": "Projektteilnehmer", "en": "Project users"}, context: true}]'
                    :multiSelect="false"
                    :allowCustom="false"
                    :allowCreate="false"
                    :value="localData.responsible"
                    optional
                    @finalSelection="updateField('responsible',$event)"
                >
                <template v-slot:btn-label><b-icon-person></b-icon-person></template>
                </base-selector>
            </div>
            <div class="checklist-comments">
                <checklist-comments 
                    :disabled="disabled"
                    :value="localData.checklistItemComments || []"
                    @input="updateField('checklistItemComments',$event)"
                />
            </div>
            <div class="checklist-actions mr-2 d-flex align-items-center">
                    <b-badge v-if="!disabled" variant="success" @click="addChild" href="#" class="p-1 mr-1">
                        <span style="font-size: 11px; padding: 1px;"><b-icon-file-plus variant="dark" /></span>
                    </b-badge>
                    <base-selector
                        v-if="!localData.externalReference && !disabled"
                        class="align-self-center"
                            :selectables="selectables" 
                            :multiSelect="false"
                            buttonMode 
                            inline
                            @finalSelection="addExternalReference($event)">
                            <template #btn-label>
                            <b-badge 
                                class="p-1  text-center w-100"
                                variant="secondary"
                                :title="$t('add.external')">
                                    <span style="font-size: 8px; padding: 1px;"> <b-icon-share /></span>
                            </b-badge></template>
                    </base-selector>
                    <b-badge v-else-if="!disabled" variant="warning" @click="addExternalReference(null)" href="#" class="p-1">
                        <span style="font-size: 11px; padding: 1px;"><b-icon-x variant="light" /></span>
                    </b-badge>
                    <b-badge v-if="!disabled && !localData['@delete']" variant="danger" @click="removeChild()" href="#" class="ml-1 p-1">
                        <span style="font-size: 11px; padding: 1px;"><b-icon-trash variant="light" /></span>
                    </b-badge>
                    <b-badge v-else-if="!disabled && !!localData['@delete']" variant="warning" @click="restoreChild()" href="#" class="ml-1 p-1">
                        <span style="font-size: 11px; padding: 1px;"><b-icon-arrow-counterclockwise variant="dark" /></span>
                    </b-badge>
                </div>
        </div>

        <div 
        v-if="!collapsed"
        class="subitems">

            <checklist-item 
                v-for="(entry,idx) in submap"
                :key="$id(entry.iri || entry['@new'])"
                :checklistMap="checklistMap"
                :level="level + 1"
                :value="entry"
                :statusType="statusType"
                :cid="cid+(idx+1)+'.'"
                :owner="owner"
                :disabled="disabled"                
                :isSnapshot="isSnapshot"
                :parentId="$id('parent')">
            </checklist-item>

        </div>

    </div>
</template>

<script>
import moment from 'moment'
import statusTrafficlight from './_statusTrafficlight.vue'
import statusCheckbox from './_statusCheckbox.vue'
import ChecklistComments from './ChecklistComments.vue'
export default {
  components: { statusTrafficlight, statusCheckbox, ChecklistComments },
    name: "checklistItem",
    props: {
        /**
         * disables the component
         */
        disabled: {
            type: Boolean,
            required: false
        },
        /**
         * a map listing all children by the parents iri
         */
        checklistMap: {
            type: Object,
            required: true
        },
        /**
         * the current depth level
         */
        level: {
            type: Number,
            required: true
        },
        /**
         * the items current number prefix
         */
        cid: {
            type: String,
            required: true
        },
        /**
         * a checklist_item object
         */
        value: {
            type: Object,
            required: true
        },
        /**
         * the owner of the checklist
         */
        owner: {
            type: String,
            required: true
        },
        /**
         * the status type as defined for the checklist
         */
        statusType: {
            type: String,
            required: true
        },
        /**
         * the uuid of the parent object
         * used for group folding operations
         */
        parentId: {
            type: String,
            required: true
        },
        /**
         * used to decouple information from loaded data
         */
        isSnapshot: {
            type: Boolean,
            required: false,
            default: false
        }

    },
    mounted() {
        this.$root.$on('bts::checklist::toggleAllItems', this.forceFolding.bind(this))
        
    },
    inject: {
        'openExternalReference' : 'openExternalReference',
        'addChildToMap' : 'addChildToMap'
    },
    watch: {
        'value' : {
            immediate: true,
            handler: function() {
                this.localData = this.value
                //attach responsible if externalReference is set
                if(this.localData?.externalReference !== null && !this.isSnapshot) {
                    this.localData.responsible = this.$store?.getters?.['relation/getResponsible'](this.localData.externalReference)
                }
                if(!this.localData.hasOwnProperty('@dirty')) {
                    this.localData['@dirty'] = false
                }
            }
        }
    },
    computed: {
        checkListItemIdentifier: function(vm) {
            return vm.localData?.iri ?? vm.localData['@new']
        },
        submap: function(vm) {
            return (vm.checklistMap[vm.checkListItemIdentifier] ?? [])
        }
    },
    methods: {
        forceFolding(ev) {
            if(ev.id == this.parentId) {
                this.collapsed = ev.collapsed
            }
        },
        forceAllChildren: function() {
            this.collapsed = false
            this.$nextTick(function() {
                this.$root.$emit('bts::checklist::toggleAllItems', {id: this.$id('parent'), collapsed: false })
            })
            
        },
        addExternalReference: function(ev) {
            this.localData.externalReference = ev
            //adding an external reference should set the responsible to the current responsibility of the owner
            this.localData.responsible = this.$store?.getters?.['relation/getResponsible'](this.localData.externalReference)
            this.localData['@dirty'] = true
            //alternative: importing an element will automatically overwrite the current responsibility to the responsibility in the checklist
        },
        prepareExternalReference: function() {
            if(!!this.localData?.externalReference) {
                this.openExternalReference(this.localData.externalReference)
            }
        },
        addChild: function() {            
            this.addChildToMap(this.checkListItemIdentifier,{
                "title": "",
                "status": "r",
                "externalReference": null,
                "parent": this.checkListItemIdentifier,
                "responsible" : null,
                "@new" : moment().valueOf(),
                "@dirty": true,
                "@delete" : false
            })
            this.collapsed = false
        },
        updateField: function(fieldName, newValue) {
            this.$set(this.localData,fieldName,newValue)
            this.localData['@dirty'] = true
        },
        removeChild: function() {
            this.$set(this.localData,'@delete',true)
        },
        restoreChild: function() {
            this.$set(this.localData,'@delete',false)
        }
    },
    data() {
        return {
            localData: {},
            collapsed: true,

            selectables: [
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'ELOP',
                    loader: {
                        filter: {"categories" : ["elop"]}
                    }
                },
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'PMLOP',
                    loader: {
                        filter: {"categories" : ["pmlop"]}
                    }
                },
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'QLOP',
                    loader: {
                        filter: {"categories" : ["qlop"]}
                    }
                },
                {   module: 'task', 
                    widget: 'Table', 
                    context: true, 
                    title: 'Tasks',
                    loader: {
                        filter: {"categories" : function(e) { return !e || e.length === 0 }}
                    }
                },
                {   
                    module : "changemanagement",
                    widget : "TableSolver",
                    context: true, 
                    title: 'Solver'
                },
                {   
                    module : "changemanagement",
                    widget : "TablePermanent",
                    context: true, 
                    title: 'ECRs'
                },
                {   
                    module : "changemanagement",
                    widget : "TableContainment",
                    context: true, 
                    title: 'RWs'
                },
                
            ]
        }
    }
}
</script>

<style>
 .checklist--entry {
     display: grid;
    grid-template-columns: 500px 50px 200px 1fr 75px;
    grid-template-rows: 1fr;
    align-items: start;
    justify-items: stretch;
    padding-top: 2px;
 }

 
 .checklist--entry.group {
     background-color: rgba(66, 67, 67, 0.066)!important;
 }

 .checklist--entry:hover {
     background-color: rgba(0,168,176,.05)!important;
 }


 .marked-for-deletion {
    text-decoration: line-through;
    opacity: 0.5;
 }
</style>
