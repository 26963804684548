<template>
  <b-dropdown
    v-if="!disabled"
    no-caret 
    dropright 
    variant="transparent" 
    boundary="viewport"
    menu-class="dropdown-mini"
    toggle-class="p-0"
    >
      <template #button-content>
          <b-avatar size="1.5em" :variant="activeVariant" :icon="activeIcon" />
      </template>
        <div class="d-flex align-items-center justify-content-center">
            <b-avatar button @click="$emit('input','r')" class="mr-1" size="1.5em" variant="danger" icon="x" />
            <b-avatar button @click="$emit('input','y')" class="mr-1" size="1.5em" variant="warning" icon="exclamation" />
            <b-avatar button @click="$emit('input','g')" class="mr-1" size="1.5em" variant="success" icon="check2" />
            <b-avatar button @click="$emit('input','nr')" class="mr-1" size="1.5em" variant="secondary" text="n.r." />
        </div>
  </b-dropdown>
   <b-avatar v-else size="1.5em" :variant="activeVariant" :icon="activeIcon" />
</template>

<script>
export default {
    name: "statusTrafficlight",
    props: {
        value : {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean
        }
    },
    computed: {
        activeVariant: function(vm) {
            switch(vm.value) {
                case "r": return "danger"
                case "y": return "warning"
                case "g": return "success"
                default: return "secondary"
            }
        },
        activeIcon: function(vm) {
            switch(vm.value) {
                case "r": return "x"
                case "y": return "exclamation"
                case "g": return "check2"
                default: return "question"
            }
        }
    }
}
</script>

<style>
.dropdown-mini {
    min-width: 90px;
}
</style>