import { Library } from '@/helper/Library'
import Vue from 'vue'

var LibraryManager = function() {
	this.libraries = {}
	this.libraryInstances = {}
}

LibraryManager.prototype.addLibrary = function(name)
{
	if(!!this.libraries[name]) { return this.libraries[name]}
	//filter undefined or null libraries
	if(name === undefined || name === null) {
		console.warn(name)
		return null
	}

	try {
		let lib = (new Library(name))
		let libPromise = lib.loadLibrary()
		.catch((err) => {
			Vue.set(this.libraries,name,null)
		})
		this.libraryInstances[name] = lib
		Vue.set(this.libraries,name,libPromise)
	} catch (e) {
		Vue.set(this.libraries,name,null)
	}
	return this.libraries[name]
}

LibraryManager.prototype.isAvailable = function(name)
{
	return this.libraries[name] || false
}

LibraryManager.prototype.list = function() {
	return Object.keys(this.libraries)
}

LibraryManager.prototype.install = function(Vue, options) {      
    Vue.prototype.$libraries = this
}

LibraryManager.prototype.hotReload = async function(moduleName) {
	//force registry reload
	await globalVue.$registry.loadRegistry(true)
	let appUrl = await globalVue.$registry.getAppURLForModuleByName(moduleName)

	if(this.libraryInstances[moduleName]) {
		await this.libraryInstances[moduleName].unmount()
		delete this.libraries[moduleName]
		delete this.libraryInstances[moduleName]
	}

	this.addLibrary(moduleName)
}

LibraryManager.prototype.forceStoreReinitialization = async function(libraryName)
{
	try {
		let libraryObject = await this.libraries[libraryName]
		if(!!(libraryObject?.runStoreInitialisation))
		await libraryObject.runStoreInitialisation()
		return true		
	} catch (e) {
		// error status
		return false
	}
}


const libraryManager = new LibraryManager()
export { libraryManager }