<i18n locale="en" lang="yaml">
    title.placeholder: "Title"
    labelled: "Always show label"
    marking: "Show vertical line"
    delete.item: "Delete item"
    undo.item: "Undo"
    confirmDeletion.message: "Should the item \"{rowname}\" be deleted? This can't be undone."
    confirmDeletion.title: "Confirm deletion"
    confirmDeletion.confirm: "Yes"
    confirmDeletion.deny: "No"
    linkedItemBlocked: "The following item could not move with linked item: \"{name}\""
    linkedItemBlockedTitle: "Linked item blocked"
</i18n>

<i18n locale="de" lang="yaml">
    title.placeholder: "Titel"
    labelled: "Beschriftung immer anzeigen"
    marking: "Vertikale Linie anzeigen"
    delete.item: "Element löschen"
    undo.item: "Rückgängig"
    confirmDeletion.message: "Soll das Element \"{rowname}\" wirklich gelöscht werden? Dies kann nicht rückgängig gemacht werden."
    confirmDeletion.title: "Löschen bestätigen"
    confirmDeletion.confirm: "Ja"
    confirmDeletion.deny: "Nein"
    linkedItemBlocked: "Folgendes Element konnte nicht mit verlinktem Element bewegt werden: \"{name}\""
    linkedItemBlockedTitle: "Verlinktes Element blockiert"
</i18n>

<template>
    <div
        class="time-icon"
        :class="{   'subtle': subtle,
                    'process' : !isMilestone,
                    'notrelevant' : localData.status == -1,
                    'd-none' : isHidden
                }"
        :style="'left:'+absoluteStartPositionInPxFromValue+'px;z-index: '+((!isMilestone ? 1043: 1044)+offsetFromStartInSteps)+';'"
        
        v-if="!backdrop && !marking"
        :title="localData.title" 
        :draggable="!disabled"
        @click="emitDataToGlobalClickEvent()"
        @dragstart.self="saveStartPosition($event)"
        @dragend.self="calculateNewPosition($event)"
        v-b-hover="highlightLinked"
            >

            
            <div class="d-flex"
                :id="$id('icon')">
                <template v-if="isMilestone">
                    <template v-if="(!hideNotRelevant || (localData && localData.status !== -1))">
                        <span 
                            :style="'position: absolute;left: '+ (showStatusIndicatorToday ? todayOffset : '-33px') +'; top:-5px;z-index: 1051;'">
                                <slot></slot>
                        </span>
                            <b-icon :icon="localData.customer ? 'circle-fill' : 'diamond-fill'"
                                :class="{   'text-primary': !localData.color ,
                                            'immutable' : localData.immutable ,
                                            'highlightItem' : highlight}"
                                :style="'color:'+localData.color+';'"     
                                size="lg" 
                                scale="1.0"
                                :title="localData.title"
                                @dblclick="showConfigPopover"
                                ></b-icon>     
                    </template>        
                </template>

                <template v-else>
                        <span :style="'position: absolute;left: '+ (showStatusIndicatorToday ? todayOffset : '-33px') +'; top:-5px;z-index: 1051;'"><slot></slot></span>
                        <div 
                            class="time-process rounded text-truncate" 
                            :class="{'immutable' : localData.immutable, 'highlightItem' : highlight}"
                            :style="'width:'+absoluteLengthInPxFromValue+'px;background-color: '+(localData.color || 'black')+';color: '+fontColor+';'"
                            @dblclick="showConfigPopover">
                                <div 
                                    class="position-absolute" 
                                    style="left:0; cursor: w-resize; width: 5px; height: 100%;"
                                    @mousedown.capture.stop.self="extend($event,-1)"></div>
                                <div 
                                    class="position-absolute" 
                                    style="right:0; cursor: e-resize; width: 5px; height: 100%;"
                                    @mousedown.capture.stop.self="extend($event,1)"></div>

                                    {{ labelled ? localData.title : '' }}

                                <span v-if="hasChildrenHidden" @click="showChildren()"><b-icon flip-h icon="eye-slash"></b-icon></span>
                        </div>
                </template>
            
            </div>
            <span 
                v-if="(!hideNotRelevant || (localData && localData.status !== -1)) && isMilestone && labelled && showLabels()"
                class="ms-label"
                :class="{'notrelevant' : localData.status == -1 }"
                >
                <b-badge 
                    v-if="showStatusIndicator && (localData.status != -1 && (isOverdue || localData.status == 100))" 
                    :variant="localData.status == 100 ? 'success' : (isOverdue ? 'danger' : 'transparent')"
                    class="mr-1"
                    >

                    <b-icon :icon="localData.status == 100 ? 'check2' : (isOverdue ? 'exclamation-triangle' : 'blank')" />
                </b-badge>
                {{ localData.title }}
                <span v-if="hasChildrenHidden" class="ml-1" @click="showChildren()"><b-icon flip-h icon="eye-slash"></b-icon></span>
            </span>
            <b-popover
                triggers="" 
                placement="bottom" 
                boundary="viewport"
                style="width: 200px;"
                custom-class="ganttIcon--popover"
                :show.sync="showPopover"
                :target="$id('icon')" >
                 <div class=" d-flex flex-column">
                    
                    <div v-if="localData && localData.linkedElements" class="d-flex flex-column pb-1 mb-1 border-dark border-bottom">
                        <div class="d-flex align-items-center justify-content-start py-1"
                            v-for="(link,idx) in localData.linkedElements"
                            :key="$id('linked-'+idx)"
                            >
                            <b-badge v-if="!!link.iri" href="#" variant="light" @click="activateModal(link.iri)">
                                <b-icon-box-arrow-up-right />
                            </b-badge>
                            <b-badge v-else href="#" variant="light">
                                <b-icon-blank />
                            </b-badge>
                            <div class=" ml-1 text-truncate" :title="link.module+' - '+link.title">{{ link.module }} - {{ link.title }}</div>
                        </div>
                    </div>
                    <b-input-group>
                    <b-input type="text" size="sm" :disabled="disabled || localData.immutable" :value="localData.title" @input="updateByForm('title',$event)" :placeholder="$t('title.placeholder')">

                    </b-input>
                          <template #append>
                            <b-button class="py-0 px-1" @click="hideChildrenButtonClicked()">

                            <b-icon v-if="!hasChildrenHidden" icon="eye"></b-icon>
                            <b-icon v-else flip-h icon="eye-slash"></b-icon>

                            </b-button>
                    </template>
                    </b-input-group>
                    <base-date-picker class="border rounded" :disabled="disabled || localData.immutable" :value="localData.start" @input="updateDateByForm('start',$event)"/>
                    <base-date-picker class="border rounded"   :disabled="disabled || localData.immutable"  :value="localData.end"  @input="updateDateByForm('end',$event)"/>
                    <div v-if="allowResponsible">
                        <base-selector 
                             class="border rounded" 
                            :value="localData.responsible" 
                            @input="updateByForm('responsible',$event)" 
                            allowCustom 
                            :multiSelect="false" 
                            :selectables="[{module: 'user', context: false, title: 'User'}]" 
                            :disabled="disabled || localData.immutable"  
                            optional>
                            <template v-slot:btn-label><b-icon-person></b-icon-person></template>
                        </base-selector>
                    </div>
                    <b-input-group
                            v-if="allowStatus">
                        <b-input 
                            size="sm" 
                            type="number" 
                            :min="0" 
                            :max="100" 
                            :value="localData.status"  
                            @input="updateByForm('status',parseInt($event))" 
                            :disabled="disabled || localData.immutable || (localData && (typeof localData.statusCalculator == 'function'))" ></b-input>
                        
                        <template #append>
                            <b-button
                                class="py-0 px-1"
                                @click="((disabled || localData.immutable) || (localData && (typeof localData.statusCalculator == 'function'))) ? false : updateByForm('status',!!localData ? (localData.status == 100 ? -1 : (localData.status == -1 ? 0 : 100)) : 0)" 
                                :variant="!!localData ? (localData.status == 100 ? 'success' : (localData.status == -1 ? 'warning' : 'secondary')) : 'secondary'"
                                >
                                    <b-icon :icon="!!localData ? (localData.status == -1 ? 'dash' :'check2') : 'dash'" />
                            </b-button>
                        </template>
                    </b-input-group>
                    <b-form-checkbox :disabled="disabled || localData.immutable" switch class="my-1" :checked="localData.labelled" @input="updateByForm('labelled',$event)" >{{ $t('labelled') }}</b-form-checkbox>
                    <b-form-checkbox v-if="localData.allowMarking" :disabled="disabled || localData.immutable" switch class="my-1" :checked="localData.marking" @input="updateByForm('marking',$event)" >{{ $t('marking') }}</b-form-checkbox>
                    
                    <base-color-picker :disabled="disabled || localData.immutable"  :value="localData.color" @input="updateByForm('color',$event)" />
                    
                    <b-input-group>
                        <b-button 
                            type="button"
                            @click="waitForLinkable()"
                            :variant="!!localData.linkedTo ? 'success' : 'secondary'"
                            :disabled="disabled"
                            >
                            <b-icon :icon="!!localData.linkedTo ? 'cursor-fill' : 'cursor'" flip-h></b-icon>{{ !!localData.linkedTo ? 'Linked' : 'New link' }}
                        </b-button>
                        <b-input-group-append is-text>
                            <b-form-checkbox 
                                :disabled="disabled"  
                                :checked="localData.linkedMustContainElement" 
                                @input="updateByForm('linkedMustContainElement',$event)">Contain</b-form-checkbox>
                        </b-input-group-append>
                    </b-input-group>
                    
                    <div class="d-flex justify-content-between">
                        <b-button class=""  variant="success" size="sm" @click="saveConfigPopover"><b-icon-check2 /></b-button>
                        <b-button-group>
                        <b-button class="" :title="$t('undo.item')" size="sm" variant="warning" @click="undoButton"><b-icon-arrow-counterclockwise/></b-button>
                        <b-button class="ml-auto" :title="$t('delete.item')" v-if="!localData.indelible && !indelible" :disabled="disabled || localData.immutable" variant="danger" size="sm" @click="deleteDate"><b-icon-trash /></b-button>
                        </b-button-group>
                    </div>
                    </div>
            </b-popover>

    </div> 


    <div 
        v-else-if="backdrop"
        class="time-backdrop"
        @dragover="dragover_handler"
        :class="{'multirow': isMilestone}"
        :style="'width:'+absoluteLengthInPxFromValue+'px; left:'+(absoluteStartPositionInPxFromValue) +'px; background-color:'+localData.color+'; '"
        
        >
    </div>
    <div 
        v-else-if="marking"
        class="time-marking"
        @dragover="dragover_handler"
        :class="{'multirow': isMilestone}"
        :style="'width:2px; left:'+(absoluteStartPositionInPxFromValue) +'px; background-color:'+localData.color+'; '"
        :title="localData.title"
        
        >
    </div>
</template> 
<style lang="scss">
    body.scrollicon {
        cursor: w-resize !important;
    }

    .ms-label {
        position: absolute;
        left: calc(100% + 2px);
        top: -2px;
        font-size: 10px;
        white-space: nowrap;
        background-color: white;
        padding: 0px 5px;
        border-radius: 5px;
        border: 1px solid grey;
        display: flex;
        align-items: center;

    }

    .time-icon {
        position: absolute;
        z-index: 1044;

        &.notrelevant {
            filter: opacity(0.4);
        }

        &.subtle {
            opacity: 0.3;
        }

        &.process {
            z-index: 1043;
        }
    }

    .time-backdrop, .time-marking {
        z-index: 4;
        position:absolute;
        top:0;
        bottom:0px;
        margin: 0 5px;

        &.multirow {
            bottom: -2px;
        }
    }

    .time-process {
        margin: 0px 5px;
        height: 12px;
        position: relative;
        font-size: 9px;
        padding: 0 5px;

        & > div {
            background-color: transparent;
        }
    }

    .popover.ganttIcon--popover {
        z-index: 2000;

        & .popover-body {
            width: 200px;

            & > div > * {
                margin: 2px 0;
            }
        }
    }

    .highlightItem {
        box-shadow: 0px 0px 1px 4px var(--primary);
    }

</style>
<script>
import moment from 'moment'


export default {
    name: "ganttIcon",
    props: {
        value: Object,
        editable: { type: Boolean, default: true},
        backdrop: { type: Boolean, default: false},
        marking: { type: Boolean, default: false},
        subtle: { type: Boolean, default: false},
        order: { type: Number, default: 0},
        maxOrder: { type: Number, default: 1},
        parent: { type: String, default: ""},
        disabled: { type: Boolean, default: false},
        labelled: { type: Boolean, default: false},
        allowStatus: { type: Boolean, required: false, default: false},
        allowResponsible: { type: Boolean, required: false, default: false},
        indelible: {type: Boolean, required: false, default: false },
        showStatusIndicatorToday: {type: Boolean, required: false, default: false },
        showStatusIndicator: {
            type: Boolean,
            required: false,
            default: false
        },
        hideNotRelevant: {
            type: Boolean,
            required: false,
            default: false
        },
        startOffset: {
            type: null,
            required: true
        },
        totalWidth: {
            type: null,
            required: true
        },
        itemWidth: {
            type: Number,
            required: true
        }
    },
    watch: {
        'value' : {
            immediate: true,
            handler: function() {
                this.$set(this,'localData',this.value)
                this.$set(this.localData,'labelled',!!this.value.labelled)
                //force a status value
                if(!this.localData.hasOwnProperty('status')) {
                    this.$set(this.localData,'status',0)
                }

                //add an id property if it does not exist:
                if(!this.localData.hasOwnProperty('id')) {
                    this.$set(this.localData,'id', this.uniqid())
                    this.$emit('input', this.localData)
                }
            }
        }
    },
    mounted() {

         
        if(!!this.localData['@undoStack']) {
            this.undoStack = this.localData['@undoStack']

        } 
        else {
            this.undDo("initialize");
        }
        
        if(!!this.localData['hasChildrenHidden']){
           this.$nextTick(()=> this.hideChildren())
        }

        if(!!this.localData['isHidden']){
            this.hideSelf()
        }
   
       
          

        this.$root.$on('bts::gantt::showIconPopover', function(id) {
            if(id !== this.$id('popover')) {
                this.saveConfigPopover()
                this.showPopover = false

            }
        }.bind(this))
        
        this.$root.$on('bts::gantt::moveItem', function(ev) {
            if(ev.id == this.localData.linkedTo) {
                this.updateByLinkedItem(ev.item , ev.changeSet)
            }
        }.bind(this))
        
        this.$root.$on('bts::gantt::highlightLinked', function(ev) {
            if(this.localData.linkedTo == ev.linkedBy || this.localData.id == ev.linkedTo) {
                this.highlight = ev.mode               
            }
        }.bind(this))


        this.$root.$on('bts::gantt::hideChildren', function( ev ) {
            if(this.localData.linkedTo == ev.parentId){
            this.hideSelf();            
        }}.bind(this));

        this.$root.$on('bts::gantt::showChildren', function( ev ) {
            if(this.localData.linkedTo == ev.parentId){
            this.showSelf();            
        }}.bind(this));
       

    },
    beforeDestroy() {

    },
    data() {
        return {
            extensionChange: 0,
            mouseNull: 0,
            updating: false,
            showConfig: this.labelled,
            localData: {},
            tempData: null,
            isVisible: true,
            timer: null,
            showPopover: false,
            highlight: false,
            isListeningforLink: null,
            undoStack: [],
            redoStack: [],
            undoStackLastEditedType: null,
            isHidden: false,
            hasChildrenHidden: false,
            temp: {
                start: null,
                end: null
            }

        }
    },
    computed: {
        fontColor: function(vm) {
            if(vm.localData.color == null) return 'black'
            let hex = vm.localData.color
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
            });

            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            let rgbObj = result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
            if(!rgbObj) return 'black'
            let brightness = Math.round(((parseInt(rgbObj.r) * 299) +
                      (parseInt(rgbObj.g) * 587) +
                      (parseInt(rgbObj.b) * 114)) / 1000);

            return (brightness > 125) ? 'black' : 'white';
        },
        momentStart: {
            get: function(vm) {
                //use the end date for markings (esp. processes)
                if(vm.marking) {
                    return moment(vm.localData.end).startOf('day')
                }
                
                //convert to moment format and set to 12am
                return moment(vm.localData.start).startOf('day')
            },
            set: function(newVal) {
                if(this.disabled || this.localData?.immutable) return
                
               
                this.localData.start = newVal.format('YYYY-MM-DD')
                this.undDo('date')
                this.$emit('input', this.localData)
            }
        },

        momentEnd: {
            get: function(vm) {
                //convert to moment format and set to 12am
                return moment(vm.localData.end).startOf('day')
            },
            set: function(newVal) {
                if(this.disabled || this.localData?.immutable) return                
                    this.localData.end = newVal.format('YYYY-MM-DD')
                    this.undDo('date')
                this.$emit('input', this.localData)
            }
        },
        todayOffset: function(vm) {
            let today = moment().startOf('isoWeek')
            let position = today.diff(moment(vm.momentStart).startOf('isoWeek'), vm.getMode()) * vm.itemWidth
            position += (vm.isMilestone ? -(vm.itemWidth/2-5) : 0);
            return position+'px'
        },

        offsetFromStartInSteps: function(vm) {
            return vm.momentStart.diff(vm.startDate(), vm.getMode())
        },
        absoluteStartPositionInPxFromValue: function(vm) {
            let position = vm.offsetFromStartInSteps * vm.itemWidth
            if(vm.isMilestone && !vm.backdrop) {
                //add half of width and remove half of width (8)
                position = position +-5 + vm.itemWidth/2;
            }
            return position
        },
        itemDiff: function(vm) {
            let diff = moment.duration(vm.momentEnd.diff(vm.momentStart))
            return diff
        },
        absoluteLengthInPxFromValue: function(vm) {
            let mode = moment.normalizeUnits(vm.getMode())

            let start, end
            let modeDiff = vm.itemWidth
            
            switch(mode) {
                case "week":            
                    let weekDiff = moment.duration(moment(vm.momentEnd).startOf('isoWeek').diff(moment(vm.momentStart).startOf('isoWeek')))
                    modeDiff = (weekDiff.asWeeks() + (vm.momentStart.isBefore(vm.startDate()) ? 0 : 1)) * vm.itemWidth
                    break
                case "day":
                    modeDiff = (vm.itemDiff.asDays() + 1) * vm.itemWidth
                    break   
                case "month":
                    modeDiff = (vm.itemDiff.asMonths() + 1) * vm.itemWidth
                    break                    
            }
                //ziehe margin ab (2*5px)
            modeDiff -= 2*5
            return modeDiff
        },
        isMilestone: function(vm) {
            //milestone if start and end are the same
            return vm.momentEnd.diff(vm.momentStart, 'days') == 0;
        },
        isOverdue: function(vm) {
            return vm.momentEnd.isBefore() && vm.localData.status >= 0;
        }
    },
    methods: {
      
        waitForLinkable: function() {
            this.isListeningforLink  = this.$root.$once('bts::gantt::clicked', function(id) {
                if(id !== this.localData.id) {
                    this.localData.linkedTo = id
                    this.$emit('input',this.localData)
                }
            }.bind(this))
        },
        emitDataToGlobalClickEvent: function() {
            this.$root.$emit('bts::gantt::clicked', this.localData.id)
        },
        highlightLinked: function(hovered) {
            this.$root.$emit('bts::gantt::highlightLinked', {linkedBy: this.localData.id , linkedTo: this.localData.linkedTo , mode: hovered})
        },
       
        hideChildrenButtonClicked() {
            if(this.hasChildrenHidden) {
                this.showChildren()
                                
            } else {
                this.hideChildren()     
            }
           this.$emit('input', Object.assign({},this.localData,{'hasChildrenHidden':this.hasChildrenHidden,'isHidden':this.isHidden}));
          
        } , 

        hideChildren() {
            this.hasChildrenHidden = true
            this.$root.$emit('bts::gantt::hideChildren', { parentId: this.localData.id })
        },

        showChildren() {
            this.hasChildrenHidden = false
            this.$root.$emit('bts::gantt::showChildren', { parentId: this.localData.id })
        },

        hideSelf() {
            this.isHidden = true

            //only hide children if not hidden already
            if(!this.hasChildrenHidden) {
                //recursive event call
                this.$root.$emit('bts::gantt::hideChildren', { parentId: this.localData.id })
            }

        },

        showSelf() {
            this.isHidden = false

            //only show children if they are not hidden
            if(!this.hasChildrenHidden) {
                this.$root.$emit('bts::gantt::showChildren', { parentId: this.localData.id })
            }

        },


        updateByLinkedItem: function(linkedItem, changeSet) {
            if(this.disabled) {
                this.$bvToast.toast(this.$t('linkedItemBlocked', {name : this.localData.title}), { title: this.$t('linkedItemBlockedTitle') })
                return
            }
            if(changeSet.type == "move") {
                this.updatePosition(changeSet.amount)
            } else if(changeSet.type == "extend" && !!this.localData.linkedMustContainElement) {
                if(changeSet.start.isAfter(this.momentStart)) {
                    this.momentStart = changeSet.start   
                    if(this.momentEnd.isBefore(this.momentStart)) {
                        this.momentEnd = this.momentStart
                    }
                }
                if(changeSet.end.isBefore(this.momentEnd)) {
                    this.momentEnd = changeSet.end     
                    if(this.momentStart.isAfter(this.momentEnd)) {
                        this.momentStart = this.momentEnd
                    }               
                }
            }
        },
        saveConfigPopover: function(){
            this.showPopover = !this.showPopover
            
            if(this.temp?.start){
                this.localData = {...this.localData, start: this.temp.start}
            }
            if(this.temp?.end){
                this.localData = {...this.localData, end: this.temp.end}
            }
            
            this.$emit('input',this.localData)
        },

        showConfigPopover: function()
        {      
            this.showPopover = !this.showPopover
            if(this.showPopover) {
                this.$root.$emit('bts::gantt::showIconPopover',this.$id('popover'));
                }

            if(this.tempData !== null && Object.keys(this.tempData).length !== 0){
          
                return this.tempData;
            }    
            else{
                this.tempData = {...this.localData,
                start: moment(this.localData.start),
                end: moment(this.localData.end)}
              
                return this.tempData;
            }
        },
        undDo : function(fieldname, customData = {}) {

            if(this.undoStackLastEditedType == fieldname) {
                this.undoStack.pop()
            } else {
                this.undoStackLastEditedType = fieldname
            }
                this.undoStack.push({
                        ...this.localData,
                        start: moment(this.localData.start),
                        end: moment(this.localData.end),
                        ...customData
                })
            if(this.undoStack.length > 10) {
                this.undoStack.shift();
            }
            return this.undoStack;

        },
        undoButton: function(){

            if (this.undoStack.length >= 2) {
                if (this.undoStack.length >= 10) {
                    this.undoStack.shift();
                    }
                const lastItem = this.undoStack.pop();
                this.redoStack.push(lastItem);
                   this.$emit('input',Object.assign({},this.undoStack[this.undoStack.length - 1], {'@undoStack' : this.undoStack }))
                
            } 
        
           
        },

        updateByForm(fieldname, newValue) {
             
            this.localData[fieldname]= newValue
            this.undDo(fieldname)
            if(this.disabled || this.localData?.immutable) return
            this.$emit('input', Object.assign({},this.localData,{[fieldname] : newValue}));
        },
        updateDateByForm(field,ev) {
     
            let momStart = moment(this.localData.start)
            let momEnd = moment(this.localData.end)
            let momData = moment(ev)
            if(field === "start"){
                this.temp.start = ev
                if(momData.isAfter(momEnd)) {
                    //set the end date to equal the start date
                    this.temp.end = ev
                }
            }
            if(field === "end") {
                this.temp.end = ev
                if(momData.isBefore(momStart)) {
                    this.temp.start = ev
                }
            }
            this.undDo('dateform')
        },
        toggleConfig() {
            this.showConfig = !this.showConfig
        },
        extend(ev,dir) {
            ev.preventDefault();            
            if(this.disabled) return
            this.mouseNull = {x : ev.x , y : ev.y }
            this.extensionChange = dir
            document.addEventListener('mouseup',this.endExtend)
            document.body.classList.toggle('scrollicon',true)
        },
        endExtend(ev) {
            document.removeEventListener('mouseup',this.endExtend)
            document.body.classList.toggle('scrollicon',false)     
            if(this.disabled) return
            let newX = ev.x
            let xDistanceMoved = Math.abs(newX - this.mouseNull.x) 
            let direction = Math.sign(newX - this.mouseNull.x)
            let movedUnits = Math.round(xDistanceMoved / this.itemWidth)

            if(this.extensionChange == -1) {
                this.momentStart = this.momentStart.add(direction * movedUnits , this.getMode())
            }

            if(this.extensionChange == 1) {
                this.momentEnd = this.momentEnd.add(direction * movedUnits , this.getMode())
            }

            this.$root.$emit('bts::gantt::moveItem', {
                    id: this.localData.id,
                    item: this.localData , 
                    changeSet: { 
                        type: "extend" , 
                        start: this.momentStart,
                        end: this.momentEnd
                    }
            })

            this.extensionChange = 0

        },
        dragover_handler(ev) {
            ev.preventDefault();
            if(this.disabled) {
                ev.dataTransfer.dropEffect = "none";
                return
            } else {
                ev.dataTransfer.dropEffect = "move";
            }
        },

        saveStartPosition($event) {     
            if(this.disabled) return
            let bb = this.getBoundingBox()
            this.mouseNull = {x : $event.x + bb.scrollLeft , y : $event.y }
            $event.dataTransfer.dropEffect = "move"
            $event.dataTransfer.setData('application/json',JSON.stringify({id: null, group: this.parent , order: this.order, action: "moveDate"}))

            //emit an event to add a new row in the origin
            this.$emit('contentDragged',this)
        },
        calculateNewPosition($event) {
            if(this.disabled) return 
            let bb = this.getBoundingBox()
            let newX = $event.x + bb.scrollLeft
            let newY = $event.y

            //convert to days/months/weeks
            let xDistanceMoved = Math.abs(newX - this.mouseNull.x) / this.getScalingFacor()
            let yDistanceMoved = Math.abs(newY - this.mouseNull.y) / this.getScalingFacor()

            let toLeft = this.mouseNull.x > newX
            let upped = this.mouseNull.y < newY

            let movedUnits = Math.round(xDistanceMoved / this.itemWidth)


            let rowHeight = 30

            let orderUpdate = Math.round(yDistanceMoved / rowHeight);
            
            
            

            this.updatePosition((toLeft ? -1 : 1)*movedUnits)

            

            if(upped)  {
                if(orderUpdate != 0) {
                    
                    this.undDo(movedUnits > 0 ? 'date' : 'order', {order: Math.min(this.order + orderUpdate,this.maxOrder)});                
                }
                this.$emit('input',Object.assign({},this.localData, {order: Math.min(this.order + orderUpdate,this.maxOrder) , '@undoStack' : this.undoStack }))
            } else {
                if(orderUpdate != 0) {
                    this.undDo(movedUnits > 0 ? 'date' : 'order', {order: Math.max(this.order - orderUpdate,0)});                
                }
                this.$emit('input', Object.assign({},this.localData, {order: Math.max(this.order - orderUpdate,0)  , '@undoStack' : this.undoStack }))
            }

            this.$emit('contentDropped',this)

            
        },    
        updatePosition: function(amount) {
            this.momentStart = this.momentStart.add(amount , this.getMode())
            this.momentEnd = this.momentEnd.add(amount , this.getMode())

            this.$root.$emit('bts::gantt::moveItem', {
                    id: this.localData.id,
                    item: this.localData , 
                    changeSet: { 
                        type: "move" , 
                        amount: amount
                    }
            })
        }, 
        deleteDate: async function() {
            if(this.localData?.immutable || this.localData?.indelible || this.disabled) return
            let res = await this.$bvModal.msgBoxConfirm(this.$t('confirmDeletion.message', {rowname: this.localData.title}), {
                title: this.$t('confirmDeletion.title'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('confirmDeletion.confirm'),
                cancelTitle: this.$t('confirmDeletion.deny'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
            if(!res) { return }
            this.$emit('delete')
        },
        uniqid: function(prefix = "", random = false) {
            const sec = Date.now() * 1000 + Math.random() * 1000;
            const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
            return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
        }
    },
    inject: {
        startDate : "getStartDate",        
        getMode : "getMode",
        getBoundingBox: "getBoundingBox",
        showLabels: "showLabels",
        activateModal: "activateModal",
        getScalingFacor: "getScalingFactor"

    }

}
</script>