<i18n lang="yaml" locale="de">
  btn.newfile: "Neue Checkliste"
  checklist.titlelabel: "Titel der Checklist"
  checklist.descriptionlabel: "Beschreibung zu der Checkliste"
  creation.success: "Checkliste erfolgreich erstellt"
  creation.title.success: "Erstellung erfolgreich"
  checklist.statusType: "Statustyp der Einträge"
  btn.createChecklist: "Checkliste erstellen"
</i18n>
<i18n lang="yaml" locale="en">
  btn.newfile: "New checklist"
  checklist.titlelabel: "Checklist title"
  checklist.descriptionlabel: "Checklist description"
  creation.success: "Successfully created checklist"
  creation.title.success: "Creation successful"
  checklist.statusType: "Status type for entries"
  btn.createChecklist: "Create checklist"
</i18n>

<template>
  <div>
    <b-button v-b-modal="$id('modalChecklistCreate')" size="sm" variant="light"><b-icon-plus /></b-button>
    <b-modal :id="$id('modalChecklistCreate')" :title="$t('btn.createChecklist')">
      
      
      
      <b-form-group
        :id="$id('fieldset-1')"
        :label="$t('checklist.titlelabel')"
        :label-for="$id('title')"
      >
        <b-input v-model="checklistTitle" :id="$id('title')"></b-input>
      </b-form-group>

      <b-form-group
        id="fieldset-2"
        :label="$t('checklist.descriptionlabel')"
        :label-for="$id('description')"
      >
        <markup-text v-model="checklistDescription" :id="$id('description')"/>
      </b-form-group>

       <b-form-group
        id="fieldset-3"
        :label="$t('checklist.statusType')"
        :label-for="$id('statusType')"
      >
        <b-form-select class="ml-1 flex-shrink-1" v-model="checklistStatusType" :options="statusOptions" :id="$id('statusType')"/>
      </b-form-group>
      


      <template #modal-footer="{ ok }">
        <b-button variant="success" type="button" @click="createChecklist($event, ok)" ><b-spinner small v-if="isProcessing"/>{{ $t('btn.createChecklist') }}</b-button>
      </template>
      </b-modal>
    </div>
</template>
<style scoped>

</style>
<script>


export default {
    name: "ChecklistCreator",
    props: {
      relationTarget: null
    },
    watch: {
    },
    methods: {
      createChecklist: async function(ev, ok) { 
        if(!this.relationTarget || !this.relationTarget.owner) return

        try {
          this.isProcessing = true
          let checklist = {
            "title" : this.checklistTitle,
            "owner" : this.relationTarget.owner,
            "description" : this.checklistDescription,
            "statusType" : this.checklistStatusType,
            "status" : "active"
          } 
          
          let res = await this.$cache.post('relation','/checklists',checklist)


          if(this.$libraries.isAvailable('relation') && this.relationTarget !== null) {
            var body = {
              "fromIRI": this.relationTarget.iri,
              "toIRI": res.iri,
              "fromOwnerIRI": this.relationTarget.owner || "",
              "toOwnerIRI": res.owner,
              "type": "information",
              "status": 0,
              "statusThreshold": 0
            }
              let result = await this.$cache.create('relation','/relations',body)
              
          }
          
          this.$bvToast.toast(this.$t('creation.success'),{variant: "success", title: this.$t("creation.title.success",{name: this.checklistTitle}) })

          //reset create window
          this.checklistTitle = ""
          this.checklistDescription = ""
          this.checklistStatusType = ""

          ok()

        } finally {
          this.isProcessing = false
        }
      }
    },
    provide: function() {return {
      getOrigin: () => this.origin,
    }},
    data() {
        return {
          isProcessing: false,
          origin: {module: "", widget: "ChecklistCreator"},
          checklistTitle: "",
          checklistDescription: "",
          checklistStatusType: "trafficlight",
          statusOptions: [
              { value: 'trafficlight', text: 'Traffic lights' },
              { value: 'checkbox', text: 'Checkboxes' }
          ],
        }
    }
}
</script>