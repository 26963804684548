import Vue from 'vue'
import { libraryManager } from '@/helper/LibraryManager'
import moment from 'moment'

var Renderer = function() {
}


Renderer.prototype.getLanguageString = function(obj) {
    if(!obj) return obj
    if(typeof obj == "object" && obj.hasOwnProperty(window.globalVue.$root.$i18n.locale)) {
        return obj[window.globalVue.$root.$i18n.locale]
    } else if(typeof obj == "object" && obj.hasOwnProperty('en')) {
        return obj['en']
    } else {
        return obj
    }
}

Renderer.prototype.displayModule = function(iri) {
    let module = this.getModuleNameFromIri(iri)
    let dF = window?.[module]?.functions?.['Display']?.['displayModule'] ?? false
    if(!dF) return module
    return dF.bind(globalVue)(iri)
}

Renderer.prototype.displayByModule = function(module, content, config) {
    let dFunc = this.getDisplayFunction(module)
    if(!dFunc || !content) return content
    return dFunc.bind(globalVue)(content, config)
}

Renderer.prototype.displayByIri = function(iri, config = {}) {
    try {
        if(Array.isArray(iri)) {
            return iri.map(this.displayByIri.bind(this)).join(', ')
        }
        let module = this.getModuleNameFromIri(iri)
        let result, element
        if(module) {
            element = this.getElementByIri(iri)
            let dFunc = this.getDisplayFunction(module)
            if(!dFunc || !element) return iri
            result = dFunc.bind(globalVue)(element, config)
        } else {
            result = iri
        }
        return result
    } catch {
        return "Error rendering " + iri
    }
}

Renderer.prototype.displayByField = function(value, field) {
    if(Array.isArray(value)) {
        return value.map((val) => this.displayByField.bind(this)(val, field)).join(', ')
    }
    switch(field.type ?? 'text') {
        case 'select':
            let valArray = Array.isArray(value) ? value : [value]
            let tokens = valArray.map(
                item => (field.selectOptions.entries.find(
                        i => item == i.value) || {text: {}}).text[globalVue.$root.$i18n.locale] 
                )
            return tokens.join(', ')
        case 'date':
            return value && typeof value == 'object' && 
                    value.isValid && value.isValid() && value.format('DD.MM.YYYY')
        case 'datetime':
            return value && typeof value == 'object' && 
                    value.isValid && value.isValid() && value.format('DD.MM.YYYY HH:mm')
        case 'boolean':
            return !!value ? 'x' : ''
        case 'approval':
            if(value === null) {
                return "-"
            } else {
                //parse field approvalDate into moment
                let parseData = null
                if(!!value) {
                    parseData = JSON.parse(value)
                }
                let approvalMoment = moment(parseData.approvalDate)
                let approvedByString = globalVue.$render.displayByIri(parseData.approvedBy)

                return approvalMoment.format('DD.MM.YYYY') + " - " + approvedByString

            }
        case 'dynamic':

            return globalVue.$render.displayByIri(value, field.customProperties ?? {})
        default:
            return value


    }
}

Renderer.prototype.getModuleNameFromIri = function(iri)
{
    let module = globalVue.$store.getters['getModuleFromIri'](iri)
    //force automatic loading of the corresponding library
    if(!!module?.module) {
        libraryManager.addLibrary(module.module)
    }
    return module?.module || ""
}

Renderer.prototype.getElementByIri = function(iri)
{
    return globalVue.$store.getters['getItemFromIri'](iri) 
}

Renderer.prototype.getDisplayFunction = function(module)
{
    return window?.[module]?.functions?.['Display']?.['display'] ?? null
}

Renderer.prototype.render = function(displayFunction, object, config)
{
    return displayFunction.bind(globalVue)(object, config)
}


Renderer.prototype.install = function(Vue, options) {
      
    Vue.prototype.$render = this

}


const Render = new Renderer()
export { Render }