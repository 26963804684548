const landing = require('./blocks/landing.json')
import task from './blocks/task.js'
import personal from './blocks/personal.js'
import mailbox from './blocks/mailbox.js'
import changemanagement from './blocks/changemanagement'
import bts from './blocks/bts.js'
import order from './blocks/order.js'
const fmea = require('./blocks/fmea.json')
const user = require('./blocks/user.json')
const owner = require('./blocks/owner.json')
const meeting = require('./blocks/meeting.json')
const part = require('./blocks/part.json')
const ticket = require('./blocks/ticket.json')
const supplychain = require('./blocks/supplychain.json')
const siteMap = [
    ...landing,
    ...personal,
    ...task,
    ...owner,
    ...changemanagement,
    ...part,
    ...user,
    ...fmea,
    ...mailbox,
    ...ticket,
    ...supplychain,
    ...bts,
    ...order

]
const groups = [    
    {
        "name" : "project",
        "label": {"en": "Project modules", "de": "Projektmodule"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "changemanagement", context: {iri: "cm_permanents"} },
                    {module: "changemanagement", context: {iri: "cm_containments"} },
                    {module: "changemanagement", context: {iri: "cm_solvers"} }
                ]
            }
        },
        "order" : 1,
        "show" : true,
        "defaultDisplay" : true,
        "isWorkstream" : false //used in ownerWorkstreamManager/viewConfigurator component
    },    
    {
        "name" : "pm",
        "label": {"en": "Project management", "de": "Projektmanagement"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "pmlop"} }
                ]
            }
        },
        "order" : 2,
        "show" : true,
        "defaultDisplay" : true,
        "isWorkstream" : false //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "eng",
        "label": {"en": "Design Engineering", "de": "Design Entwicklung"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "elop"} }
            ]
            }
        },
        "order" : 3,
        "show" : false,
        "defaultDisplay" : true,
        "isWorkstream" : true //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "qual",
        "label": {"en": "Quality", "de": "Qualität"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "qlop"} },
                    {module: "changemanagement", context: {iri: "cm_solvers", solver: "pqm"} }
                ]
            }
        },
        "order" : 3,
        "show" : false,
        "defaultDisplay" : true,
        "isWorkstream" : true //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "inl",
        "label": {"en": "Industrialization & Launch", "de": "Industrialisierung & Launch"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "plop"} },
                    {module: "changemanagement", context: {solver: "pip", iri: "cm_solvers"} }
                ]
            }
        },
        "order" : 4,
        "show" : false,
        "defaultDisplay" : true,
        "isWorkstream" : true //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "sales",
        "label": {"en": "Sales", "de": "Sales"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "slop"} }
                ]
            }
        },
        "order" : 7,
        "show" : false,
        "defaultDisplay" : true,
        "isWorkstream" : true //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "sci",
        "label": {"en": "Supply Chain", "de": "Supply Chain"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "sclop"} }
                ]
            }
        },
        "order" : 8,
        "show" : false,
        "defaultDisplay" : true,
        "isWorkstream" : true //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "cfg",
        "label": {"en": "Configuration", "de": "Konfiguration"},
        "order" : 100,
        "show" : false,
        "defaultDisplay" : false,
        "isWorkstream" : false //used in ownerWorkstreamManager/viewConfigurator component
    },
    {
        "name" : "bts",
        "label": {"en": "BTS Administration", "de": "BTS Administration"},
        "suffix" : {
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {"module": "production", "context": {"mode" : "prototype_orders"} },
                    {"module": "production", "context": {"mode" : "travel"} },
                    {"module": "production", "context": {"mode" : "bas"} }
                ]
            }
        },
        "order" : 99,
        "show" : false,
        "defaultDisplay" : false,
        "isWorkstream" : false //used in ownerWorkstreamManager/viewConfigurator component
    }
]

const routes = siteMap
export { siteMap, routes, groups }
