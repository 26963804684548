export default {
    watch: {
        'value' : {
            handler: function() {
                this.collapsed = this.level == 1
                this.collapseState = this.collapsed ? "collapsed" : "unfolded"

                if(!!this.value['@collapse_children']) {
                    switch(this.value['@collapse_children']) {
                        case "unfolded":
                            this.collapseState = "unfolded"
                            this.collapsed = false
                            break;
                        case "collapsed":
                            //delay the event by two ticks, so the subitems should be rendered
                            this.$nextTick(() => 
                                this.$nextTick(() => 
                                    this.$root.$emit('bts::gantt::fold',{groupId: this.$id(''), type: "groups"})
                            ))
                            
                            this.collapseState = "collapsed"
                            this.collapsed = true
                            break;
                        case "expanded":
                            //delay the event by two ticks, so the subitems should be rendered
                            this.$nextTick(() => 
                                this.$nextTick(() => 
                                    this.$root.$emit('bts::gantt::unfold',{groupId: this.$id(''), type: "groups"})
                            ))
                            this.collapseState = "expanded"
                            this.collapsed = false
                            break;
                    }
                }

                if(!!this.value['@collapse_milestone']) {
                    switch(this.value['@collapse_milestone']) {
                        case "unfolded":
                            this.oneLineState = "unfolded"
                            this.oneLineOnly = false
                            break;
                        case "collapsed":
                            //delay the event by two ticks, so the subitems should be rendered
                            this.$nextTick(() => 
                                this.$nextTick(() => 
                                    this.$root.$emit('bts::gantt::fold',{groupId: this.$id(''), type: "milestones"})
                            ))
                            this.oneLineState = "collapsed"
                            this.oneLineOnly = true
                            break;
                        case "expanded":
                            //delay the event by two ticks, so the subitems should be rendered
                            this.$nextTick(() => 
                                this.$nextTick(() => 
                                    this.$root.$emit('bts::gantt::unfold',{groupId: this.$id(''), type: "milestones"})
                            ))
                            this.oneLineState = "expanded"
                            this.oneLineOnly = true
                            break;
                    }
                }

            },
            immediate: true
        }
    },
    inject: {
        "getUniqueFoldingId" : {
            from: "getUniqueFoldingId",
            default: false
        },
        "updateFolding" : {
            from: "updateFolding",
            default: false
        },
        "getFoldingSettings": {
            from: "getFoldingSettings",
            default: false
        }
    },
    mounted() {
        this.$root.$on("bts::gantt::unfold", function(ev) {
            if(ev.groupId != this.group) { return }
            
            if(ev.type == "groups") {
                this.collapsed = false
                this.collapseState = "unfolded"
            }
            if(ev.type == "milestones") {
                this.oneLineOnly = false
                this.oneLineState = "unfolded"
            }

            this.localData['@collapse_children'] = this.collapseState
            this.localData['@collapse_milestone'] = this.oneLineState
            
            this.commitToFilterManager()
            
        }.bind(this))

        this.$root.$on("bts::gantt::fold", function(ev) {
            if(ev.groupId != this.group) { return }
            
            if(ev.type == "groups") {
                this.collapsed = true
                this.collapseState = "collapsed"
            }
            if(ev.type == "milestones") {
                this.oneLineOnly = true
                this.oneLineState = "collapsed"
            }

            this.localData['@collapse_children'] = this.collapseState
            this.localData['@collapse_milestone'] = this.oneLineState

            this.commitToFilterManager()
            
        }.bind(this))

        this.$root.$on("bts::gantt::triggerFilter", this.loadFromFilterManager.bind(this))

        if(this.getFoldingSettings) {
            this.loadFromFilterManager(this.getFoldingSettings())
        }
    
    },
    computed: {
        foldingId: function(vm) {
            return vm.localData?.['$foldId'] ?? vm.localData?.fromIri ?? null
        }
    },
    methods: {
        loadFromFilterManager(filterList) {
            if(!this?.foldingId) return
            if(filterList?.[this.foldingId]) {
                this.collapseState = filterList[this.foldingId]?.collapseState ?? 'collapsed'
                this.collapsed = this.collapseState == 'collapsed'

                if(this.collapseState == "expanded") {
                    this.$root.$emit('bts::gantt::unfold',{groupId: this.$id(''), type: "groups"})
                }

                this.oneLineState = filterList[this.foldingId]?.oneLineState ?? 'collapsed'
                this.oneLineOnly = this.oneLineState == 'collapsed'

                if(this.collapseState == "collapsed") {
                    this.$root.$emit('bts::gantt::unfold',{groupId: this.$id(''), type: "groups"})
                }
            }
        },
        commitToFilterManager() {
            if(!this.updateFolding) return
            //build $foldId if not existing
            if(!this.foldingId) {
                this.localData['$foldId'] = this.getUniqueFoldingId()
            }
            this.updateFolding(this.foldingId, {
                collapseState: this.collapseState,
                oneLineState: this.oneLineState
            })
        },
        rotateUnfolding(nextState = null) {
            switch(nextState ?? this.collapseState) {
                case "collapsed":
                    this.collapseState = "unfolded"
                    this.collapsed = false
                    break;
                case "unfolded":
                    this.$root.$emit('bts::gantt::unfold',{groupId: this.$id(''), type: "groups"})
                    this.collapseState = "expanded"
                    break;
                case "expanded":
                    this.$root.$emit('bts::gantt::fold',{groupId: this.$id(''), type: "groups"})
                    this.collapseState = "collapsed"
                    this.collapsed = true
                    break;
            }
        
        if(!!this.localData) { //localdata is not always set when rotateUnfolding is called
            this.localData['@collapse_children'] = this.collapseState
            this.localData['@collapse_milestone'] = this.oneLineState
        }
            //also send data to folding manager if available
            this.commitToFilterManager()
        },
        rotateMilestoneUnfolding(nextState = null) {
            switch(nextState ?? this.oneLineState) {
                case "collapsed":
                    this.oneLineState = "unfolded"
                    this.oneLineOnly = false
                    break;
                case "unfolded":
                    this.$root.$emit('bts::gantt::unfold',{groupId: this.$id(''), type: "milestones"})
                    this.oneLineState = "expanded"
                    break;
                case "expanded":
                    this.$root.$emit('bts::gantt::fold',{groupId: this.$id(''), type: "milestones"})
                    this.oneLineState = "collapsed"
                    this.oneLineOnly = true
                    break;
            }

            if(!!this.localData) { //localdata is not always set when rotateMilestoneFolding is called
                this.localData['@collapse_children'] = this.collapseState
                this.localData['@collapse_milestone'] = this.oneLineState
            }
            //also send data to folding manager if available
            this.commitToFilterManager()
        },
    },
    data() {
        return {
            localData: {},
            collapsed: true,
            oneLineOnly: true,
            collapseState: "collapsed",
            oneLineState: "collapsed",
        }
    }

}