<i18n lang="yaml" locale="en">
    search.placeholder: "Quick Search"
    search.noResults: "No results found"
</i18n>
<i18n lang="yaml" locale="de">
    search.placeholder: "Schnellsuche"
    search.noResults: "Keine Treffer"
</i18n>

<template>
  <div>
    <b-input type="search" v-model="searchTerm" debounce="500" :placeholder="$t('search.placeholder')" class="px-1" style="height: 32px;"/>
    <div id="globalSearchResultDropdown" v-if="searchTerm.length > 0">
        <b-list-group class="searchResultDropdown"
            >
            <b-list-group-item
                v-for="(result) in searchResults"
                :key="$id(result)"
                
                class="searchResult"
                @click="openSearchResultModal(result)">
                <div class="searchResult--module text-secondary text-small">{{ $render.displayModule(result) }}</div>
                <div class="text-truncate" :title="$render.displayByIri(result)">{{ $render.displayByIri(result) }}</div>
            </b-list-group-item>
            <b-list-group-item v-if="searchResults.length == 0">{{ $t('search.noResults') }}</b-list-group-item>
        </b-list-group>
    </div>
  
    <dynamic-loader 
        :module="subItemModal && subItemModal.module" 
        widget="Single" 
        :value="subItemModal && subItemModal.iri" 
        :customProperties="{ showModalOnMount: true, modal: $id('showSearchResult') }" />
  </div>
</template>
<style lang="scss" scoped>
    @import "./../../style/globalStyles";

    #globalSearchResultDropdown {
        position: relative;
        z-index: 999;
        color: black;
    }

    .searchResultDropdown {
        position: absolute;
        top: calc(100% + 2px);
        right: 0px;
        min-width: 100%;
        max-width: 500px;
        max-height: 90vh;
        overflow:auto;
        @extend .bg-white
    }

    .searchResult {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
            background-color: $hover-color;
        }
    }

    .searchResult--module {
        font-size: 8pt;
    }
</style>
<script>


    export default {
        name: "BaseSearch",
        props: {

        },
        data() {
            return {
                searchTerm : "",
                subItemModal: {},
                searchResults: [],
            }
        },
        watch: {
            'searchTerm' : async function() {
                this.searchAllModules(this.searchTerm)
            }
        },
        methods: {
            searchAllModules: async function(searchTerm) {
                let libraries = this.$libraries.list()
                let ownerIris = this.$store.getters.getOwnerContext?.map(owner => owner?.iri)
                this.searchResults = []
                let promiseList = []
                libraries.forEach(lib => {
                    if(this.$store.getters[lib+'/searchModule']) {
                        let getterAction = this.$store.getters[lib+'/searchModule'](searchTerm, ownerIris)
                        if(Array.isArray(getterAction)) {
                            this.searchResults = this.searchResults.concat( getterAction.map(r => r?.iri || null).filter(res => !!res) )
                        }          
                    } 
                    if(this.$store._actions[lib+'/searchModule']) {
                        promiseList.push(
                            this.$store.dispatch(lib+'/searchModule',searchTerm,{root: true}).then(res => {
                                if(Array.isArray(res)) {
                                    this.searchResults = this.searchResults.concat( res.map(r => r?.iri || null).filter(res => !!res) )
                                }
                            })
                        )                    
                    }
                });
                await Promise.allSettled(promiseList)
                return this.searchResults
            },
            openSearchResultModal(iri) {
                this.subItemModal = {module: this.$store.getters.getModuleFromIri(iri).module, iri: iri}
                if(!this.subItemModal) return
                this.$nextTick(function() {
                this.$nextTick(function() {
                this.$nextTick(function() {
                this.$nextTick(function() {
                    this.$bvModal.show(this.$id('showSearchResult'))      
                })})})          
                })            
            },
        }
    }
</script>

