import { render, staticRenderFns } from "./Impressum.vue?vue&type=template&id=ad67bfcc&"
import script from "./Impressum.vue?vue&type=script&lang=js&"
export * from "./Impressum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Impressum.vue?vue&type=custom&index=0&blockType=i18n&locale=en&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./Impressum.vue?vue&type=custom&index=1&blockType=i18n&locale=de&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports