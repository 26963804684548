<i18n locale="en" lang="yaml" >
    help.ok: "Understood"
</i18n>
<i18n locale="de" lang="yaml" >
    help.ok: "Verstanden"
</i18n>
<template>
    <div>
      <b-icon-info-circle variant="primary" v-b-modal="$id('helper')" class="ml-1 helper" size="sm" />
        <b-modal 
            :id="$id('helper')" 
            :title="title && title[$root.$i18n.locale] || title || ''"
            ok-only
            :ok-title="$t('help.ok')"
        >
            <template #default>
                <div v-html="content && content[$root.$i18n.locale] || content || ''">
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "baseHelp",
    props: {
        title: [Object,String],
        content: [Object, String]
    }
}
</script>

<style>

</style>