<i18n lang="yaml" locale="de">
  uploading.title: "{name} wird hochgeladen..."
  uploading.success: "Upload abgeschlossen"
  uploading.title.success: "{name} erfolgreich hochgeladen"
</i18n>
<i18n lang="yaml" locale="en">
  uploading.title: "{name} is uploading..."
  uploading.success: "Upload completed"
  uploading.title.success: "{name} successfully uploaded"
</i18n>
<template>
    <b-overlay :show="isLoading" :class="{'border rounded border-secondary' : !disabled}" :style="'width: '+width+';height: '+height+';min-height: '+minheight+';'">
        <template v-if="!disabled">
            <ckeditor 
                :editor="editor" 
                :value="markupData" 
                @input="emitValue($event)" 
                @ready="editorReady = true; updateData()"
                :config="editorConfig" 
                class="newMarkupContainer zindex-21"
                :class="{'border-danger border' : !optional && markupData == '', 'limit-image-height' : height != 'auto'}"
                :style="'width: '+width+';height: '+height+';min-height: '+minheight+';'"
                ></ckeditor>
                <div v-if="!containsText" class="position-absolute text-black-50" style="top:12px;left: 10px;">{{ calculatedPlaceholder }}</div>
                
            <b-toast 
                v-for="(file,idx) in fileQueue" 
                :key="$id(file.fileName+'-'+idx)"
                no-auto-hide 
                no-close-button 
                solid 
                visible
                variant="primary">
                <template #toast-title>
                {{ $t('uploading.title', { name: file.fileName }) }}
                </template> 
                <b-progress :value="100" variant="primary" striped :animated="true"></b-progress>
            </b-toast>
        </template>
        <div 
            v-else 
            v-html="markupData" 
            class="newMarkupContainer border rounded p-1 overflow-auto disabled" 
            :class="{'limit-image-height' : height != 'auto'}"
            :style="'width: '+width+';height: '+height+';min-height: '+minheight+';'"></div>
    </b-overlay>
</template>
<style lang="scss" >

.newMarkupContainer {
    & img {
        max-width: 100%;
    }

    &>:first-child {
        margin-top: var(--ck-spacing-large);
    }

    padding: 0 var(--ck-spacing-standard);

}

.limit-image-height {
    .image {
        position: relative !important;
        height: 100%;
        width: 100%;

        
        & img {
            max-height: 100%;
            max-width: 100%;
        }
    }
}

.image-style-side {
    float:right;
    max-width: 50%;
    img {
        width:100%;
    }
}

.newMarkupContainer {
    .text-tiny {
        font-size: .7em;
    }
    .text-small {
        font-size: .85em;
        }
    .text-big {
        font-size: 1.4em;
        }
    .text-huge {
        font-size: 1.8em;
    }

    blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: 5px solid #ccc;
    }

    .todo-list {
        list-style: none;

        li {
            margin-bottom: 5px;
        }

        .todo-list__label>input {
            display: inline-block;
            position: relative;
            width: var(--ck-todo-list-checkmark-size);
            height: var(--ck-todo-list-checkmark-size);
            vertical-align: middle;
            border: 0;
            left: -25px;
            margin-right: -15px;
            right: 0;
            margin-left: 0;
        }
    }

    .table table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: 100%;
        border: 1px double #b3b3b3;
    }
}

:root {
    --ck-z-default: 500 !important;
}
</style>
<script>

import CKEditor from '@ckeditor/ckeditor5-vue2';
import CustomEditor from './ckeditor';
//import CustomEditor from '@ckeditor/ckeditor5-build-classic';

import { UploadManager } from '@/helper/UploadManager/UploadManager'


export default {
    name: "MarkupText",
    components: {
        'ckeditor' : CKEditor.component
    },
    props: {
        value: String,
        targetIri: String,
        optional: Boolean,
        disabled: Boolean,
        width: { required: false, type: String, default: "100%"},
        height: { required: false, type: String, default: "auto"},
        minheight: { required: false, type: String, default: "3em"},
        field: {type: Object, default: () => ({})},
        placeholder: { type: [Object,String], default: ""}
    },
    data() {
        return {
            editor: CustomEditor,
            isLoading: true,
            fileURL: "",
            markupData: "",
            oldValue: "",
            editorReady: false,
            fileQueue: []
        }
    },
    created() {
        this.loadFileServer()
    },
    inject: {
        getElementIRI: { from: "getElementIRI", default: false },
        getOwnerIRI : { from: "getOwnerIri", default: false }
    },
    computed: {
        calculatedPlaceholder: function(vm) {
            let tmpPlaceholder = ""
            if(vm.placeholder !== "") {
                tmpPlaceholder = vm.placeholder
            } else if(vm.field?.placeholder) {
                tmpPlaceholder = vm.field.placeholder
            }
            return typeof tmpPlaceholder == 'object' ? tmpPlaceholder[vm.$root.$i18n.locale] ?? tmpPlaceholder['en'] : tmpPlaceholder
        },  
        containsText: function(vm) {
            let tester = document.createElement('div')
            tester.innerHTML = vm.markupData
            return tester.innerText.length > 0
        },
        editorConfig: function(vm) { 
            let cfg = {
                    extraPlugins: [ vm.buildUploader() ],
                    removePlugins: ['mediaEmbed'],
                    toolbar: {
                        items: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            'alignment',
                            'fontColor',
                            'fontSize',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'strikethrough',
                            '|',
                            'bulletedList',
                            'numberedList',
                            'todoList',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'imageUpload',
                            'blockQuote',
                            'insertTable',
                        ]
                    },
                    image: {
                        toolbar: [
                            'imageStyle:alignBlockLeft',
                            'imageStyle:alignLeft',
                            'imageStyle:inline',
                            'imageStyle:alignRight',
                            'imageStyle:alignBlockRight',
                        ],
                        insert: {
                            type: 'inline'
                        }
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells'
                        ]
                    },             
                } 
            return cfg
            }
    },
    watch: {
        'value': {
            immediate: true,
            handler: function() {this.updateData()}
        },
        'disabled': {
            immediate: true,
            handler: function() {this.updateData()}
        },
    },
    methods: {
        loadFileServer: async function() {
            let url = await (await this.$registry).getURLForModuleByName('file')
            this.fileURL = url
            this.isLoading = false  
            this.updateData()
        },
        emitValue: function(data) {
                this.$emit('input',data, false)
        },
        updateData: function() {
            
            //only update if the user editor window is disabled (no editor required) or the editor is ready
            if(!this.editorReady && !this.disabled) return 

            let data = this.value ?? ''
            const fileReplacement = /https?:\/\/.*?\/(preview|download)(.*?)(version=\/versions\/[0-9]+)(.*?)(&|&amp;)token=(^&amp;|[^\?\"&\s])+/g
            if(typeof data == "string") {
                data = data.replace(fileReplacement,this.fileURL + "/$1$2$3$4" + "&amp;token="+this.$store.getters.getAuthenticationToken)
            }

            //only update on actual change
            //this prevents the editor from rebooting and setting the cursor back to the start
            this.markupData = data
        },
        buildUploader: function() {
            let manager = new UploadManager(null)
            this.$root.$emit('bts::root::fileDropped',true)
            if(this.getElementIRI) {
                manager.targetIri = this.getElementIRI.bind(this)
            } else {
                manager.targetIri = function() { return this.targetIri }.bind(this)
            }

            if(this.getOwnerIRI) {                
                manager.ownerIri = this.getOwnerIRI.bind(this)
            }

            manager.registerFileUpload = function(file) {
                this.fileQueue.splice(-1,0,file)
            }.bind(this)

            manager.unregisterFileUpload = function(file) {
                 let idx = this.fileQueue.findIndex(e => e === file)
                 if(idx !== -1) this.fileQueue.splice(idx,1)
                 this.$bvToast.toast(this.$t('uploading.success'),{variant: "success", title: this.$t("uploading.title.success",{name: file.fileName}) })
       
            }.bind(this)
            
            return function(editor) {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = function( loader ) {   
                    manager.ckEditorLoader = loader
                    return manager
                }
            }
        }
    }
}
</script>
