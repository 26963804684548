<template functional>    
    <td class="base-table--cell" 
     :class="props.value && props.value.cellClasses || ''"
        :title="props.value && (props.value.mouseover && props.value.mouseover[parent.$i18n.locale] || $options.methods.getDateString(props.value)) || ''"
        :style="(props.config['@stickyStyle'] || '')"
        >
       <div class="cell-nowrap text-center d-flex justify-content-center" :style="'width: '+(32+props.config['@width'])+'px;'">
         <div class="d-flex justify-content-start align-items-center" >
         <div :class="{'ml-2' : props.config['dateConfig'] && !!props.config['dateConfig'].prefixComponent, 'mr-2' : props.config['dateConfig'] && !!props.config['dateConfig'].suffixComponent }">
           {{ $options.methods.getDateString(props.value) }}
          </div>
          <component v-if="props.config['dateConfig'] && props.config['dateConfig'].suffixComponent" :is="props.config['dateConfig'].suffixComponent" :value="props.value.value" :valueObject="props.value" :row="props.row">
            </component>
          </div>
       </div>
       
    </td>
</template>
<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>

import moment from 'moment'
export default {
  name: "baseCellDisplayDate",
  methods: {
    getDateString: function(val) {
      let valObj = (val && typeof val == "object") ? (val.hasOwnProperty('value') ? val.value : val) : val
      if(typeof valObj == "string") {
        //automatically create a moment object
        valObj = moment(valObj)
      }
      if(valObj && valObj.isValid && valObj.isValid()) {
        return valObj.format('DD.MM.YYYY')
      } else {
        return ''
      }
    }
  }
}
</script>
