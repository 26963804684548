<template functional>    
    <td class="base-table--cell cell-edit-component" 
     :class="props.value && props.value.cellClasses || ''"
        :style="(props.config['@stickyStyle'] || '')">
       <div class="cell-nowrap" :style="'width: '+(32+props.config['@width'])+'px;'">
         <component 
         :is="$options.methods.getRemoteComponent(props.config)" 
         @input="listeners.input($event)" 
         :value="props.value && props.value.hasOwnProperty('value') ? props.value.value : props.value" 
         v-bind="(props.config.editProvider || props.config).customProperties" 
         isInTable
         />
       </div>
    </td>

</template>
<style lang="scss" scoped>
.cell-nowrap {
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>
export default {
  name: "baseCellEditDynamic",
  methods: {
    getRemoteComponent: function(cfg) {
      if(!cfg.editProvider) {
        return window[cfg.provider.module] && 
        window[cfg.provider.module+'-'+cfg.provider.widget] || cfg.provider.widget || null
      } else {
        return window[cfg.editProvider.module] && 
        window[cfg.editProvider.module+'-'+cfg.editProvider.widget] || cfg.editProvider.widget ||null
      }
    }
  }
}
</script>
