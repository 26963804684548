import { render, staticRenderFns } from "./BaseGantt2.vue?vue&type=template&id=5f7ac1bc&"
import script from "./BaseGantt2.vue?vue&type=script&lang=js&"
export * from "./BaseGantt2.vue?vue&type=script&lang=js&"
import style0 from "./BaseGantt2.vue?vue&type=style&index=0&id=5f7ac1bc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaseGantt2.vue?vue&type=custom&index=0&blockType=i18n&locale=en&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./BaseGantt2.vue?vue&type=custom&index=1&blockType=i18n&locale=de&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports