<i18n lang="yaml" locale="de">
    filters.sort.down: "nach Werten Approval Status"
    filters.sort.up: "von Z nach A sortieren"
  </i18n>
  <i18n lang="yaml" locale="en">
    filters.sort.down: "sort by Approval Status"
    filters.sort.up: "sort from Z to A"
  </i18n>
  <template>
  <div>                 
      <b-button
          block
          squared
          variant="light"
          @click="(!!sortData && sortData.direction != 1) ? $emit('setSortData',buildSorter(1)) : $emit('sortOff')"
          class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
      >
        <b-icon-sort-alpha-down
          :variant="sortData.direction === 1 ? 'danger' : 'dark'"
        ></b-icon-sort-alpha-down>
        <div class="small flex-grow-1 text-left pl-1">
            {{ $t('filters.sort.down') }}
        </div>
        <b-badge
          v-if="sortData.direction == 1"
        >{{ sortPosition }}</b-badge>
      </b-button>
  </div>
  </template>
  <script>
import moment from 'moment'

  export default {
    name: "baseCellSortApproval",
    props: {
      sortData: {
        required: true,
        type: Object
      },
      sortPosition: {
        required: true,
        type: Number
      },
      sortTag: {
        required: false,
        type: Number
      }
    },
    watch: {
      'sortTag' : {
        immediate: true,
        handler: function(newVal, oldVal) {
          if(newVal && newVal !== oldVal && (!this.sortData || !this.sortData.sortHandler)) {
            this.$emit('setSortData',this.buildSorter(newVal))
          }
        }
      }
    },
    methods: {
  
      //generates the sorter function based on the sorterValue, which can preload some heavy lifting 
  
  
      /**
       * The sortHandler is a compare function that returns an int
       * it gets handed the arguments:
       * 
       * a      any   cell data as specified by the type
       * b      any   cell data as specified by the type
       */
      sortHandlerGenerator: function(direction) {
          return (a,b) => {
            let aVal =  (typeof a == "object" && a.hasOwnProperty('value')) ? a.value : a
            let bVal =  (typeof b == "object" && b.hasOwnProperty('value')) ? b.value : b

            let aParsed = JSON.parse(aVal )
            let bParsed = JSON.parse(bVal)
            if(!!aParsed && !!bParsed && aParsed.hasOwnProperty('approvalState') && bParsed.hasOwnProperty('approvalState') && aParsed.hasOwnProperty('approvalState') == bParsed.hasOwnProperty('approvalState') ){
                if(aParsed.hasOwnProperty('approvalDate') && bParsed.hasOwnProperty('approvalDate')) {
                    return moment(aParsed.approvalDate) - moment(bParsed.approvalDate)
                }

            } 
            let aSortValue = 0
            let bSortValue = 0
            
            aSortValue = aParsed == null ? 1 : (aParsed.hasOwnProperty('approvalState') ? (aParsed.approvalState == "approved" ? 3 : (aParsed.approvalState == "declined" ? 2 : 1)) : 1)
            bSortValue = bParsed == null ? 1 : (bParsed.hasOwnProperty('approvalState') ? (bParsed.approvalState == "approved" ? 3 : (bParsed.approvalState == "declined" ? 2 : 1)) : 1)
            
            return aSortValue - bSortValue
            
          }
        },
  
      /**
       * sorterTagGenerator
       * returns an Object which stores the configuration in a user-readable format
       * if an array of strings is returned, each element creates a tag. Should only be used if multiple 
       * sorter configurations are available
       */
      sortTagGenerator: (dir) => dir,
  
  
      /**
       * sortDataGenerator
       * build the sortData to pass to the table
       * the sortData is only consumed by this component, so it is solely responsible for the layout
       */
      sortDataGenerator: (dir) => {
        return { direction: parseInt(dir)}
      },
  
      /**
       * buildSorterValue
       * this should build the correct sorter data element from the sorter tag
       * this is used to initialize the sorter
       */
      buildSortData: () => { 
          if(this.sortData.direction) { return this.sortData.direction } 
          if(this.sortTag) {
            //build sorter value from sorter tag
            return this.sortTag
          }
      },
  
      /**
       * buildSorter
       * builds the sorter object to store in the table
       * The sorter object needs to have the following fields:
       * 
       *  sorterHandler   Function        the function called by the table 
       *  sorterTag       Array | String  the tag(s) defined by this sorter
       *  sorterValue     any             the sorter value as defined by this sorter
       * 
       */
      buildSorter: function(direction) {
        return {'sortHandler' : this.sortHandlerGenerator(direction), 
          'sortTag' : this.sortTagGenerator(direction), 
          'sortData' : this.sortDataGenerator(direction)}
      },
  
    }
  }
  </script>