import { store } from '@/store'
import  router from '@/router'

const moment = require('moment')

const APIHandler = function() {
    this.securityCheckPromise = null;
    this.middleWares = []
}

APIHandler.prototype.fetch = async function(serviceName, endpoint, allowAnonymous = false) 
{
    return await this.runRequest(serviceName, endpoint, "GET", null, false, allowAnonymous)
}

APIHandler.prototype.fetchFromURL = async function(url, allowAnonymous = false)
{
        let axiosConfig = await this.buildAxiosConfig(allowAnonymous)

        let adjusted = await this.middleWare(url, axiosConfig, "GET")
        if(adjusted['@abort']) {
            return {}
        }
        let cfg = Object.assign(adjusted.config, {method: adjusted.config['@method'], body: JSON.stringify(adjusted.body)})
        if(adjusted.config['@method'] == "GET") { delete cfg.body}
        let response = await fetch(adjusted.url, cfg)      


        return response.json()
}

APIHandler.prototype.postToService = async function(serviceName, endpoint, body, returnFullRequest = false, allowAnonymous = false)
{
    return await this.runRequest(serviceName, endpoint, "POST", body, returnFullRequest, allowAnonymous)
}

APIHandler.prototype.deleteFromService = async function(serviceName, endpoint, body, returnFullRequest = false, allowAnonymous = false)
{
    return await this.runRequest(serviceName, endpoint, "DELETE", body, returnFullRequest, allowAnonymous)
}

APIHandler.prototype.putToService = async function(serviceName, endpoint, body, returnFullRequest = false, allowAnonymous = false)
{
    return await this.runRequest(serviceName, endpoint, "PUT", body, returnFullRequest, allowAnonymous)
}

APIHandler.prototype.runRequest = async function(serviceName, endpoint, method, body, returnFullRequest, allowAnonymous = false)
{
    let registry = await store.getters.getRegistry
    let url = null
    if(serviceName !== null) {
        let serviceUrl = await registry.getURLForModuleByName(serviceName)
        if(serviceUrl === false) {
            console.warn('Illegal request to service '+serviceName)
            return {}
        }
        url = serviceUrl+endpoint
    } else {
        url = endpoint
    }
    let axiosConfig = await this.buildAxiosConfig(allowAnonymous)

    let adjusted = await this.middleWare(url,axiosConfig, method, body)
    if(adjusted['@abort']) {
        return {}
    }
    let cfg = Object.assign(adjusted.config, {method: adjusted.config['@method'], body: JSON.stringify(adjusted.body)})
    if(adjusted.config['@method'] == "GET") { delete cfg.body}
    let response = await fetch(adjusted.url, cfg)
    
    //add error handling: if response is not ok reject the promise
    if(!response.ok) {
        window.baitLog.push('Request failed for '+adjusted.url+' with config '+JSON.stringify(cfg))
        throw new Error(response.status)
    }

    //no body return code causes an error when trying to parse json
    if(response.status == 204) return {} 
    return returnFullRequest ? response : response.json()
}

APIHandler.prototype.middleWare = async function(url, config, method = "GET", body = null)
{
    let adjUrl = url
    let adjConfig = {...config, "@method": method} 
    let adjBody = body
    let adjRequestSet = {url: adjUrl, config: adjConfig, body: adjBody}
    
    for(let i=0;i<this.middleWares.length; i++) {
        let middleWare = this.middleWares[i].handler
        if(typeof middleWare === "function") {
            adjRequestSet = await middleWare(adjRequestSet);      
        }
    }

    if(adjRequestSet.config['@method'] == 'GET') {
        adjRequestSet.body = null;
    }
    return adjRequestSet
}



APIHandler.prototype.buildAxiosConfig = async function(allowAnonymous = false)
{
    let status = allowAnonymous
    if(!allowAnonymous) {
        status = (await this.securityCheck())
    }
    let cfg = {
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
        //  'Accept-Encoding' : 'gzip, deflate, br'
        }
    }
    if(!allowAnonymous && status) {
        cfg.headers['Authorization'] = 'Bearer ' + store.getters.getAuthenticationToken
    }
    return cfg;
}

APIHandler.prototype.securityCheck = function()
{
    if(this.securityCheckPromise) { return this.securityCheckPromise; }
    
    this.securityCheckPromise = this.checkAuthenticationStatus()
    this.securityCheckPromise.then((response) => this.securityCheckPromise = null)

    return this.securityCheckPromise
}

APIHandler.prototype.checkAuthenticationStatus = async function()
{
    try {
        if(this.checkStoreAuthentication()) { return true }
        //if invalid load from storage and try again    
        if(!store.getters.isAuthenticated) {
            await store.dispatch('loadTokenFromStorage')
        }
        //check again if stored data is valid
        if(this.checkStoreAuthentication()) { return true }

        //require a refresh of access token 
        if( await this.sendRefreshRequest() ) {
            return true
        } else {
            //refreshing was not possible; invalidate session and show login screen
            store.dispatch("logout", true)
            store.commit('showLoadScreen',true)
        }
    } catch {
        //catch all errors and require reauthentication on any error
        return false
    }
}

APIHandler.prototype.checkStoreAuthentication = function()
{
    //if login data has not been loaded yet
    if(!store.getters.isAuthenticated || !store.getters.authenticatedUntil) { return false}
    let validWithGracePeriod = moment.utc(store.getters.authenticatedUntil).subtract(5,'minutes').isAfter(new moment.utc());
    return validWithGracePeriod && store.getters.isAuthenticated
}

APIHandler.prototype.sendRefreshRequest = async function()
{
    //check if refresh token exists
    let refreshTokenString = store.getters.getRefreshToken
    if(refreshTokenString === null) { return false }

    //get refresh tokens remaining lifetime
    let rtValid = this.isValidFor(refreshTokenString,10)
    //if not valid force relog
    if(!rtValid) { return false }

    //at this point, we can be certain that the refresh token is set and not expired 
    //now send the refresh request to the user server
    return await this.requestNewTokenByRefreshToken()
    
}

APIHandler.prototype.isValidFor = function(tokenString, graceInSeconds)
{
    let splitted = tokenString.split('.');
    let payload = JSON.parse(atob(splitted[1]))

    //milliseconds since EPOCH
    let validUntil = new moment.unix(payload.exp);
    return validUntil.subtract(graceInSeconds,'seconds').isAfter(new moment.utc())
}

APIHandler.prototype.requestNewTokenByRefreshToken = async function()
{
    let registry = await store.getters.getRegistry
    let serviceUrl = await registry.getURLForModuleByName('user')
    let body = {
        'refresh' : store.getters.getRefreshToken
    }
    let axiosConfig = {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
    }
    let cfg = {
        method: 'POST',
        "body" : JSON.stringify(body),
        headers: {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
        //  'Accept-Encoding' : 'gzip, deflate, br'
        }
    }
    let responseStream = await fetch(serviceUrl+'/refresh', cfg)
    
    if(responseStream.status === 200)
    {
        let response = await responseStream.json()
        // now commit the new access token and refresh token to the store
        await store.dispatch('saveToken',{token: response.token, forceReload: false})
        await store.dispatch('saveRefreshToken', response.refreshToken)
        return this.checkStoreAuthentication()
    } else {
        return false
    }
}

export { APIHandler }
