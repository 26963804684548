import baseTableHeader from './_BaseTableHeader'
import baseTableFilter from './_BaseTableFilter'
import baseCellSelector from './BaseCellSelector'


import baseCellDisplayText from './DisplayComponents/BaseCellDisplayText'
import baseCellDisplayTitle from './DisplayComponents/BaseCellDisplayTitle'
import baseCellDisplayDate from './DisplayComponents/BaseCellDisplayDate'
import baseCellDisplayDateTime from './DisplayComponents/BaseCellDisplayDateTime'
import baseCellDisplayTime from './DisplayComponents/BaseCellDisplayTime'
import baseCellDisplayComponent from './DisplayComponents/BaseCellDisplayComponent'
import baseCellDisplayBoolean from './DisplayComponents/BaseCellDisplayBoolean'
import baseCellDisplayCheckmark from './DisplayComponents/BaseCellDisplayCheckmark'
import baseCellDisplaySelect from './DisplayComponents/BaseCellDisplaySelect'
import baseCellDisplayDynamic from './DisplayComponents/BaseCellDisplayDynamic'
import baseCellDisplayUserGroup from './DisplayComponents/BaseCellDisplayUserGroup'
import baseCellDisplayCurrency from './DisplayComponents/BaseCellDisplayCurrency'
import baseCellDisplayApproval from './DisplayComponents/BaseCellDisplayApproval'
import baseCellDisplayList from './DisplayComponents/BaseCellDisplayList'
import baseCellDisplayEvaluator from './DisplayComponents/BaseCellDisplayEvaluator'


import baseCellEditText from './EditComponents/BaseCellEditText'
import baseCellEditDate from './EditComponents/BaseCellEditDate'
import baseCellEditNumber from './EditComponents/BaseCellEditNumber'
import baseCellEditDateTime from './EditComponents/BaseCellEditDateTime'
import baseCellEditTime from './EditComponents/BaseCellEditTime'
import baseCellEditComponent from './EditComponents/BaseCellEditComponent'
import baseCellEditBoolean from './EditComponents/BaseCellEditBoolean'
import baseCellEditCheckmark from './EditComponents/BaseCellEditCheckmark'
import baseCellEditSelect from './EditComponents/BaseCellEditSelect'
import baseCellEditDynamic from './EditComponents/BaseCellEditDynamic'
import baseCellEditUserGroup from './EditComponents/BaseCellEditUserGroup'
import baseCellEditCurrency from './EditComponents/BaseCellEditCurrency'
import baseCellEditApproval from './EditComponents/BaseCellEditApproval'

import baseCellFilterText from './FilterComponents/BaseCellFilterText'
import baseCellFilterDate from './FilterComponents/BaseCellFilterDate'
import baseCellFilterNumber from './FilterComponents/BaseCellFilterNumber'
import baseCellFilterSelect from './FilterComponents/BaseCellFilterSelect'
import baseCellFilterComponent from './FilterComponents/BaseCellFilterComponent'
import baseCellFilterDynamic from './FilterComponents/BaseCellFilterDynamic'
import baseCellFilterBoolean from './FilterComponents/BaseCellFilterBoolean'
import baseCellFilterUserGroup from './FilterComponents/BaseCellFilterUserGroup'
import baseCellFilterCurrency from './FilterComponents/BaseCellFilterCurrency'
import baseCellFilterApproval from './FilterComponents/BaseCellFilterApproval'


import baseCellGroupText from './GroupComponents/BaseCellGroupText'
import baseCellGroupDate from './GroupComponents/BaseCellGroupDate'
import baseCellGroupNumber from './GroupComponents/BaseCellGroupNumber'
import baseCellGroupSelect from './GroupComponents/BaseCellGroupSelect'
import baseCellGroupComponent from './GroupComponents/BaseCellGroupComponent'
import baseCellGroupDynamic from './GroupComponents/BaseCellGroupDynamic'
import baseCellGroupBoolean from './GroupComponents/BaseCellGroupBoolean'
import baseCellGroupApproval from './GroupComponents/BaseCellGroupApproval'

import baseCellSortText from './SortComponents/BaseCellSortText'
import baseCellSortDate from './SortComponents/BaseCellSortDate'
import baseCellSortNumber from './SortComponents/BaseCellSortNumber'
import baseCellSortSelect from './SortComponents/BaseCellSortSelect'
import baseCellSortComponent from './SortComponents/BaseCellSortComponent'
import baseCellSortDynamic from './SortComponents/BaseCellSortDynamic'
import baseCellSortBoolean from './SortComponents/BaseCellSortBoolean'
import baseCellSortCurrency from './SortComponents/BaseCellSortCurrency'
import baseCellSortApproval from './SortComponents/BaseCellSortApproval'



export default {
    components: {
        
        "baseTableHeader": baseTableHeader,
        "baseTableFilter": baseTableFilter,
        "baseCellSelector": baseCellSelector,

        
        "baseCellDisplayText": baseCellDisplayText,
        "baseCellDisplayDate": baseCellDisplayDate,
        "baseCellDisplayDateTime": baseCellDisplayDateTime,
        "baseCellDisplayTime": baseCellDisplayTime,
        "baseCellDisplayComponent": baseCellDisplayComponent,
        "baseCellDisplayBoolean": baseCellDisplayBoolean,
        "baseCellDisplayCheckmark": baseCellDisplayCheckmark,
        "baseCellDisplayTitle": baseCellDisplayTitle,
        "baseCellDisplaySelect": baseCellDisplaySelect,
        "baseCellDisplayDynamic": baseCellDisplayDynamic,
        "baseCellDisplayUserGroup": baseCellDisplayUserGroup,
        "baseCellDisplayCurrency": baseCellDisplayCurrency,
        "baseCellDisplayApproval": baseCellDisplayApproval,
        "baseCellDisplayList": baseCellDisplayList,
        "baseCellDisplayEvaluator": baseCellDisplayEvaluator,
        
        "baseCellEditText": baseCellEditText,
        "baseCellEditDate": baseCellEditDate,
        "baseCellEditTime": baseCellEditTime,
        "baseCellEditNumber": baseCellEditNumber,
        "baseCellEditComponent": baseCellEditComponent,
        "baseCellEditBoolean": baseCellEditBoolean,
        "baseCellEditCheckmark": baseCellEditCheckmark,
        "baseCellEditSelect": baseCellEditSelect,
        "baseCellEditDynamic": baseCellEditDynamic,
        "baseCellEditUserGroup": baseCellEditUserGroup,
        "baseCellEditCurrency": baseCellEditCurrency,
        "baseCellEditApproval": baseCellEditApproval,

        
        "baseCellFilterText": baseCellFilterText,
        "baseCellFilterDate": baseCellFilterDate,
        "baseCellFilterNumber": baseCellFilterNumber,
        "baseCellFilterSelect": baseCellFilterSelect,
        "baseCellFilterComponent": baseCellFilterComponent,
        "baseCellFilterDynamic": baseCellFilterDynamic,
        "baseCellFilterBoolean" : baseCellFilterBoolean,
        "baseCellFilterUserGroup" : baseCellFilterUserGroup,
        "baseCellFilterCurrency" : baseCellFilterCurrency,
        "baseCellFilterApproval" : baseCellFilterApproval,

        
        "baseCellGroupText": baseCellGroupText,
        "baseCellGroupDate": baseCellGroupDate,
        "baseCellGroupNumber": baseCellGroupNumber,
        "baseCellGroupSelect": baseCellGroupSelect,
        "baseCellGroupComponent": baseCellGroupComponent,
        "baseCellGroupDynamic": baseCellGroupDynamic,
        "baseCellGroupBoolean": baseCellGroupBoolean,
        "baseCellGroupApproval": baseCellGroupApproval,

        
        "baseCellSortText": baseCellSortText,
        "baseCellSortDate": baseCellSortDate,
        "baseCellSortNumber": baseCellSortNumber,
        "baseCellSortSelect": baseCellSortSelect,
        "baseCellSortComponent": baseCellSortComponent,
        "baseCellSortDynamic": baseCellSortDynamic,
        "baseCellSortBoolean": baseCellSortBoolean,
        "baseCellSortCurrency": baseCellSortCurrency,
        "baseCellSortApproval": baseCellSortApproval,

    }
}