<script>

 import sc from './subcomponents'

export default {
    functional: true,
    props: {
        config: {
            type: Object,
            required: true
        },        
        mode: {
            type: String,
            required: true
        },
        fallback: {
            type: String
        }
    },
    render: function (createElement, context) {
        function selectComponent () {
            let type = context.props.config && context.props.config.type || 'text'
            let compName = null


            switch(type) {
                case 'text': compName  = 'baseCell'+context.props.mode+'Text'; break;
                case 'date': compName  = 'baseCell'+context.props.mode+'Date';break;
                case 'number': compName  = 'baseCell'+context.props.mode+'Number';break;
                case 'currency': compName  = 'baseCell'+context.props.mode+'Currency';break;
                case 'datetime' : compName  = 'baseCell'+context.props.mode+'DateTime';break;
                case 'time' : compName  = 'baseCell'+context.props.mode+'Time';break;
                case 'boolean' : 
                //case 'checkmark':
                case 'bool' : compName  = 'baseCell'+context.props.mode+'Boolean';break;
                case 'checkmark' : compName  = 'baseCell'+context.props.mode+'Checkmark';break;
                case 'component' : compName  = 'baseCell'+context.props.mode+'Component';break;
                case 'title' : compName  = 'baseCell'+context.props.mode+'Title';break;
                case 'userGroup' : compName  = 'baseCell'+context.props.mode+'UserGroup';break;
                case 'select' : compName  = 'baseCell'+context.props.mode+'Select';break;
                case 'list' : compName  = 'baseCell'+context.props.mode+'List';break;
                case 'dynamic' : compName  = 'baseCell'+context.props.mode+'Dynamic';break;
                case 'approval' : compName  = 'baseCell'+context.props.mode+'Approval';break;
                case 'evaluation' : compName  = 'baseCellDisplayEvaluator';break;
            }

            //allow custom component based filters for each type of element            
            if((context.props.config?.filter?.filter ?? false) && context.props.mode == "Filter") {
                //switch to component element
                compName  = 'baseCellFilterComponent'
            }
            if((context.props.config?.filter?.sort ?? false) && context.props.mode == "Sort") {
                //switch to component element
                compName  = 'baseCellSortComponent'
            }
            if((context.props.config?.filter?.group ?? false) && context.props.mode == "Group") {
                //switch to component element
                compName  = 'baseCellGroupComponent'
            }

            //check if component exists
            if(!!sc.components[compName]) return sc.components[compName] 
            //security fallback. If no fallback is provided use text mode
            return !!context.props.fallback ? sc.components[context.props.fallback] : sc.components['baseCell'+context.props.mode+'Text']
        }

        return createElement(
        selectComponent(),
        {...context.data, 
        'attrs' : {
            ...context.data.attrs, 
            'config' : context.props.config
            }
        }, 
        context.children
        )
    }
}
</script>