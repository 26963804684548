<i18n lang="yaml" locale="de">
  group-older: "mehr als zwei Wochen her"
  group-old: "mehr als eine Woche her"
  group-recently: "letzte Woche"
  group-today: "heute"
  group-soon: "nächste Woche"
  group-upcoming: "übernächste Woche"
  group-future: "mehr als zwei Wochen"
  group-unknown: "ohne Datum"
  group-invalid: "ungültiges Datum"
  filters.group : "Gruppieren"
</i18n>
<i18n lang="yaml" locale="en">
  group-older: "more than two weeks ago"
  group-old: "more than a week ago"
  group-recently: "last week"
  group-today: "today"
  group-soon: "next week"
  group-upcoming: "week after next week"
  group-future: "more than 2 weeks"
  group-unknown: "no date"
  group-invalid: "invalid date"
  filters.group : "Group"
</i18n>
<template>
<div>                 
    <b-button
    @click="groupPosition > 0 ? $emit('groupOff') : $emit('setGroupData',groupConfiguration)"
        block
        squared
        variant="light"
        class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
    >
      <b-icon-list-ul
        :variant="'dark'"
      ></b-icon-list-ul>
      <div class="small flex-grow-1 text-left pl-1">
          {{ $t('filters.group') }}
      </div>
      <b-badge v-if="groupPosition > 0"
      >{{ groupPosition }}</b-badge>
    </b-button>
</div>
</template>
<script>
const moment = require('moment')

export default {
  name: "baseCellGroupDate",
  props: {
    groupData: {
      required: true,
      type: Object
    },
    groupPosition: {
      required: true,
      type: Number
    },
    groupTag: {
      required: false,
      type: String
    }
  },
  watch: {
    'groupTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.groupData || !this.groupData.groupHandler)) {
          this.$emit('setGroupData',this.groupConfiguration)
        }
      }
    }
  },
  computed: {
    groupConfiguration: function(vm) {
      return {'groupHandler' : vm.groupHandler(), 
              'groupLabeller' : vm.groupLabeller,
              'groupSorter' : vm.groupSorter,
              'groupTag' : 'x', 
              'groupData' : {}}
    }
  },
  methods: {
    groupHandler: function() {
      let twoWeeksAgo = moment().subtract(2,'weeks').startOf('day')
      let oneWeekAgo = moment().subtract(1,'weeks').startOf('day')
      let today = moment().startOf('day')
      let oneWeek = moment().add(1,'weeks').startOf('day')
      let twoWeeks = moment().add(2,'weeks').startOf('day')
       
      return function(cellData, groupData, column) {
        if(!cellData || !cellData.value) return 'unknown'
        let actualValue = (cellData.value || cellData).startOf('day')
        if(!actualValue.isValid()) return 'invalid'
        switch(true) {

          case actualValue.isSameOrBefore(twoWeeksAgo) :
            return 'older'
          case actualValue.isSameOrBefore(oneWeekAgo) :
            return 'old'
          case actualValue.isSame(today) : 
            return 'today'
          case actualValue.isSameOrAfter(twoWeeks) :
            return 'future'
          case actualValue.isSameOrAfter(oneWeek) :
            return 'upcoming'
          case actualValue.isAfter(today) :
            return 'soon'
          case actualValue.isBefore(today) :
            return 'recently'
        }
        return 'unknown'
      }
    },
    groupLabeller: function(groupName) {
      return this.$t('group-'+groupName)
    },
    groupSorter: (a,b) => {
      let luMap = {older: -3, old: -2, recently: -1, today: 0, soon: 1, future: 3, upcoming: 2, unknown: 4, invalid: 5}
      return luMap[a] - luMap[b]
    }

  }
}
</script>