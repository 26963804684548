<template functional>
  <b-form-checkbox
    v-if="!props.disabled"
    :checked="props.value"
    value="g"
    unchecked-value="r"
    @change="listeners.input($event)"
  />
  <div v-else>
      <b-badge v-if="!!props.value" variant="success" ><b-icon-check2  /></b-badge>
  </div>
</template>

<script>
export default {
    name: "statusCheckbox",
    props: {
        value : {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean
        }
    },
}
</script>
