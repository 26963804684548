<i18n locale="en" lang="yaml" >
    confirm.remove.approval: "Are you sure you want to remove the approval?"
    confirm.remove.yes: "Yes, remove approval"
    confirm.remove.no: "No"
    approval.required: "Approval pending"
    approval.not.required: "not required / optional"
    modal.approve: "Approve request"
    modal.decline: "Decline request"
    modal.unset: "Reset field"
</i18n>
<i18n locale="de" lang="yaml" >
    confirm.remove.approval: "Soll die Freigabe wirklich gelöscht werden?"
    confirm.remove.yes: "Ja, Freigabe löschen"
    confirm.remove.no: "Nein"
    approval.required: "Freigabe ausstehend"
    approval.not.required: "nicht benötigt / optional"
    modal.approve: "Anfrage bestätigen"
    modal.decline: "Anfrage ablehnen"
    modal.unset: "Feld zurücksetzen"
</i18n>
<template>
   <b-input-group >    
        <b-input-group-prepend>
            <b-button 
                size="md" 
                :id="`editorField_${field.name}`"
                @click="disabled ? false : $bvModal.show($id('approvalWindow'))"
                text="Button" 
                :variant="approvalStatus">
                    <b-icon variant="dark" :icon="approvalIcon" />
            </b-button>
        </b-input-group-prepend>
        
        <b-form-input disabled :value="approvalString"></b-form-input>
        <b-modal 
            size="sm"
            :id="$id('approvalWindow')"
            
            hide-footer >
                <b-button class="m-2" @click="toggleApproval('approve')" type="button" variant="success" block>{{ $t('modal.approve') }}</b-button>
                <b-button class="m-2" @click="toggleApproval('decline')" type="button" variant="danger" block>{{ $t('modal.decline') }}</b-button>
                <b-button class="m-2" @click="toggleApproval('reset')" type="button" variant="warning" block>{{ $t('modal.unset') }}</b-button>
        </b-modal>
    </b-input-group>
</template>

<script>

const moment = require('moment')

export default {
    name: "baseApproval",
    props: {
        "value" : {
            required: true,
            type: null
        },
        "field" : {
            type: Object,
            required: true   
        },
        "dataSet" : {
            type: Object,
            required: false,
            default: () => null
        },
        "disabled" : {
            type: Boolean,
            required: false,
            default: false
        },
        "isRequired" : {
            type: Function,
            required: false,
            default: () => (d) => true
        }
    },
    watch: {
        "value" : {
            immediate: true,
            handler: function() {
                this.localData = this.value
            }
        }
    },
    inject: {
        getDataSnapshot: {
            from: "getDataSnapshot",
            default: () => () => {}
        }
    },
    methods: {
        toggleApproval: function(ev) {
            //build approval object
            let approvalObject = {
                "approvalState" : null,
                "approvedBy" : this.$store.getters.getCurrentUser,
                "approvalDate" : moment().format('YYYY-MM-DD')
            }

             switch(ev) {
                case "approve":
                    approvalObject.approvalState = "approved"
                    this.$emit('input',JSON.stringify(approvalObject))
                    break;
                case "decline":
                    approvalObject.approvalState = "declined"
                    this.$emit('input',JSON.stringify(approvalObject))
                    break;
                case "reset":
                    this.$emit('input',null);
                    break;
             }
            this.$bvModal.hide(this.$id('approvalWindow'))
        }
    },
    computed: {
        safeDataSet: function(vm) {
            if(!!vm.dataSet) {
                return vm.dataSet
            } else {
                return vm.getDataSnapshot() ?? {}
            }
        },  
        parsedLocalData: function(vm) {
            let parsedData =  JSON.parse(vm.localData ?? null)
            if(!!parsedData && typeof parsedData == "object" && 
                parsedData.hasOwnProperty('approvalDate') && parsedData.approvalDate !== null &&
                parsedData.hasOwnProperty('approvedBy') && parsedData.approvedBy !== null && parsedData.approvedBy !== "") {
                
                parsedData['@momentDate'] = moment(parsedData.approvalDate)
                return parsedData
            }
            return null
        },
        approvalString: function(vm) {
            if(!vm.parsedLocalData) {
                if(vm.isRequired(vm.safeDataSet)) {
                    return vm.$t('approval.required')
                } else {
                    return vm.$t('approval.not.required')
                }
            } else {
                //parse field approvalDate into moment
                let approvalMoment = moment(vm.parsedLocalData['@momentDate'])
                let approvedByString = vm.$render.displayByIri(vm.parsedLocalData.approvedBy)

                return approvalMoment.format('DD.MM.YYYY') + " - " + approvedByString

            }   
        },
        approvalStatus: function(vm) {
            if(!!vm.parsedLocalData) {
                if(vm.parsedLocalData?.approvalState === "approved" || 
                    (!vm.parsedLocalData.hasOwnProperty('approvalState') && vm.parsedLocalData !== null)) {
                    return "success"
                }
                if(vm.parsedLocalData?.approvalState === "declined") {
                    return "danger"
                }
            } else {
                if(vm.isRequired(vm.safeDataSet)) {
                    return "warning"
                } else {
                    return "secondary"
                }
            }
        },
        approvalIcon: function(vm) {
            if(!!vm.parsedLocalData) {
                if(vm.parsedLocalData?.approvalState === "approved" || 
                    (!vm.parsedLocalData.hasOwnProperty('approvalState') && vm.parsedLocalData !== null)) {
                    return "check2"
                }
                if(vm.parsedLocalData?.approvalState === "declined") {
                    return "x"
                }
            } else {
                if(vm.isRequired(vm.safeDataSet)) {
                    return "square"
                } else {
                    return "dash"
                }
            }
        }
    },
    data() {
        return {
            localData: null
        }
    }
}
</script>

<style>

</style>