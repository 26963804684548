<i18n locale="en" lang="yaml">
    placeholder: "dd.mm.yyyy"
</i18n>

<i18n locale="de" lang="yaml">
    placeholder: "tt.mm.jjjj"
</i18n>


<template>
  <b-form-input
            :value="displayValue"
            @input="handleInput"
            class="border-0"
            :placeholder="$t('placeholder')"
            :size="size"
            :disabled="disabled"
    ></b-form-input>
</template>

<script>
const moment = require('moment');
export default {
    name: "DatePickerInputField",
    props: {
        'value' : {
            required: true,
            type: null        
        },
        'size': {
            required: false,
            type: String
        },
        'disabled': {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: null,
            userInput: "",
            debounceTimer:null
        }
    },
    computed: {
        locale: function(vm) {
            return ["de","en-gb"];
        },
        displayValue: {
            get: function() {
                return this.userInput;
            },
            set: function(newVal) {
                this.userInput = newVal;
            }
        }
    },
    watch: {
        'value': {
            immediate: true,
            deep: true,
            handler: function(newVal) {
                if (!newVal) {
                    this.userInput = "";
                    return;
                }
                
                let momDate = moment(newVal, ['DD.MM.YYYY','DD-MM-YYYY','YYYY-MM-DD','DD/MM/YYYY'], true).locale(this.locale);
                if (momDate.isValid()) {
                    this.userInput = momDate.format('L');
              
                }
            }
        }
    },
    methods: {
        handleInput(value) {
            this.displayValue = value; 
            let formats = ['D.M.YY', 'D.M.YYYY', 'DD.MM.YYYY','D/M/YY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY'];            
            let momDate = moment(value, formats, true).locale(this.locale);

             if (this.debounceTimer) {
                clearTimeout(this.debounceTimer);
            }

        
            this.debounceTimer = setTimeout(() => {
                if (momDate.isValid()) {
                    this.localValue = momDate;
                    this.emitValue();
                }
            }, 1000); 
        },

        emitValue() {
            this.$emit('input', this.localValue);
        }
       
    }
}
</script>

<style>

</style>
