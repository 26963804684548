

const moment = require("moment")


var TimingHelper = function() {
}

TimingHelper.prototype.flattenDates = function(dates)
{
    let startDate = dates?.reduce((prevDate,date) => {
            if(!prevDate) return !!date?.start ? moment(date?.start) : null
            if(!date?.start) return prevDate
        return moment(date?.start).isBefore(prevDate) ? moment(date?.start) : prevDate
        }, null)
    let endDate = dates?.reduce((prevDate,date) => {
            if(!prevDate) return !!date?.end ? moment(date?.end) : null
            if(!date?.end) return prevDate
        return moment(date.end).isBefore(prevDate) ? prevDate : moment(date?.end)
        }, null)

    if(!endDate || !startDate) {
        return {start: null, end: null}
    }
    return {start: moment(startDate), end: moment(endDate)}
}

TimingHelper.prototype.reduceChildrenToDate = function(children)
{
    let safeChildrenArray = Array.isArray(children) ? children : [children]
    let flattenedChildren = safeChildrenArray?.map(child => {
        let resultChildren = null
        let resultDates = null
        if(child.children) {
            resultChildren = this.reduceChildrenToDate(child.children)                        
        }
        if(child.dates) {
            resultDates = this.flattenDates(child.dates)
        }

        if(resultChildren === null && resultDates === null) return null
        if(resultChildren === null) return resultDates
        if(resultDates === null) return resultChildren

        let resultStart = moment(resultChildren.start).isBefore(moment(resultDates.start)) ? resultChildren.start : resultDates.start
        let resultEnd = moment(resultChildren.end).isAfter(moment(resultDates.end)) ? resultChildren.end : resultDates.end

        return {start: moment(resultStart), end: moment(resultEnd)}
    })
    return this.flattenDates(flattenedChildren)
}

TimingHelper.prototype.getEndDateByTitle = function(data,title) 
{
    let element = this.findItemBy(data, "title", title)
    if(!element) return null
    let flattenDates = this.getDateRange(element)
    return (flattenDates?.end && moment(flattenDates.end)) ?? null
}

TimingHelper.prototype.getEndDateByName = function(data,name) 
{
    let element = this.findItemBy(data, "name", name)
    if(!element) return null
    let flattenDates = this.getDateRange(element)
    return (flattenDates?.end && moment(flattenDates.end)) ?? null
}

TimingHelper.prototype.getDateRange = function(item) {
    if(Array.isArray(item)) {
        let dateList = item.map(this.getLatestDate)
        return this.flattenDates(dateList)
    } else if(!!item && item.hasOwnProperty('start') && item.hasOwnProperty('end')) {
        return {start: moment(item.start), end: moment(item.end)}
     } else {
        let childListDate = item?.children?.length > 0 ? this.reduceChildrenToDate(item.children) : []
        let dateListFlatten = item?.dates?.length > 0 ? this.flattenDates(item.dates) : []

        let startAndEnd = this.flattenDates([childListDate, dateListFlatten])
        return startAndEnd
    }
}


//returns null or the component containing an exactly matching field
TimingHelper.prototype.findItemBy = function(item, field, value) 
{
    //check if item is null or undefined
    if(typeof item == "object" && !item) {
        return null
    }

    let subresult = null

    //if array, iterate over content
    if(Array.isArray(item)) {
        for(let i=0;i<item.length;i++) {
            let element =item[i]
            //support both old structure and new structure (new structure moves everything into a data property, old has the data on top level)
            subresult = this.findItemBy(element.hasOwnProperty('data') ? element.data : element, field, value)
            
            if(!!subresult) {
                return subresult
            }
        }

    } else {   
        //check if field exists directly at the element
        if(item?.[field] == value) {
            return item
        }
        
        //if dates are sset, check if one of the dates matches
        if(!!item?.dates) {
            subresult = this.findItemBy(item.dates, field, value)
            if(!!subresult) {
                return subresult
            }
        }
        //if no date was found, run through the children
        if(!!item?.children) {
            subresult = this.findItemBy(item.children, field, value)
            if(!!subresult) {
                return subresult
            }
        }
    }

        
    //fallback if no element was found
    return null
}


TimingHelper.prototype.clearDataForSaving = function(data) {
    //decouple object
    const removeMetaProperties = function(element) {
        for(const key in element) {
            if(key[0] == "@") {
                delete element[key];
            }
            if(typeof element[key] == "object") {
                element[key] = removeMetaProperties(element[key])
            }
        }
        return element
    }


    return removeMetaProperties(data)

},



TimingHelper.prototype.install = function(Vue, options) 
{
      
    Vue.prototype.$gantt = this

}


const Timing = new TimingHelper()
export { Timing }