import { render, staticRenderFns } from "./BaseCellEditNumber.vue?vue&type=template&id=8c043922&scoped=true&functional=true&"
var script = {}
import style0 from "./BaseCellEditNumber.vue?vue&type=style&index=0&id=8c043922&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "8c043922",
  null
  
)

export default component.exports