<i18n locale="en" lang="yaml" >
    grouped.labels: "Label groups"
    labelgroup.explain: "These can be used to show all labels in the corresponding category in all selected projects"
</i18n>
<i18n locale="de" lang="yaml" >
    grouped.labels: "Labelgruppen"
    labelgroup.explain: "Diese Filter zeigen alle Labels an, die entsprechend kategorisiert worden sind (in allen ausgewählten Projekten)"
</i18n>
<template>
  <div>       
    <b-button
      block 
      variant="light" 
      @click="$emit('setFilterStatus',!filterStatus)" 
      class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
      >
         <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('filters.filter.filter') }}
      </div>
    </b-button>
    <div class="py-2 px-1 border-bottom border-dark">
          <h4 :title="$t('labelgroup.explain')" v-b-tooltip.window.d500.v-info.bottomright>{{ $t('grouped.labels') }}</h4>
          <dynamic-loader         
            module="owner" 
            widget="UserGroupCategories" 
            :value="filterCategories || []" 
            @input="filterCategories = $event; updateFilter()" 
            />
        </div>
    <template 
      v-for="(owner, idx) in $store.getters.getOwnerContext"
    >
      <div class="p-1 border-bottom border-light"
          :key="$id('owners-'+idx)">
        
        <dynamic-loader         
            module="owner" 
            widget="Display" 
            :value="owner.iri" 
            />
        <dynamic-loader         
            module="owner" 
            widget="UserGroups" 
            :value="filterGroups || []" 
            @input="filterGroups = $event; updateFilter()" 
            :customProperties="{'edit' : true, 
                        'ownerIri' : owner.iri }" 
            />
      </div>
    </template>
  </div>
</template>

<script>



export default {
  name: "baseCellFilterTitle",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: Object
    },
    filterTag: {
      type: String
    }
  },  
  data() {
    return {
      filterGroups: [],
      filterCategories: []
    }
  },
  watch: {
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal) {
          
          this.buildFromTag(newVal)
        }
      }
    }
  },
  computed: {
    filterBody: function(vm) {
      return {
        filterHandler: vm.filterHandler,
        filterTag: vm.filterGroups.join('::',)+'+++'+vm.filterCategories.join('::'),
        filterValue: {
            groups: vm.filterGroups,
            text: vm.filterText,
            categories: vm.filterCategories
        }
      }
    }
  },
  methods: {
    buildFromTag(tag) {
      let parts = tag.split('+++')
      this.filterGroups = parts[0].split('::').filter(a => a != "")
      this.filterCategories = parts[1].split('::').filter(a => a != "")
      this.updateFilter()
    },
    updateFilter() {
      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue', this.filterBody)
    },
    //generates the filter function based on the filterValue, which can preload some heavy lifting 

    filterHandler(cellData, filterData) {

      let textfilter = true
      let groupfilter = true
      let categoryfilter = true

      if(filterData.categories.length > 0) {
        categoryfilter = (cellData.userGroup || []).some(g => {
          let uGObj = this.$store.getters['owner/getUserGroups'][g]
          return uGObj && uGObj.category && 
          filterData.categories.findIndex(f => uGObj.category.findIndex(u => u === f) > -1) > -1 })
      }

      if(filterData.groups.length > 0) {
        groupfilter = (cellData.userGroup || []).some(g => filterData.groups.findIndex(f => f == g) > -1)
      }

      if(!groupfilter || !categoryfilter) return false

      if(filterData.text != "") {
        let regex
            try {
                regex = new RegExp(filterData.text,'i')
                textfilter = regex.test(cellData.value)
            } catch {
                textfilter = cellData.value.indexOf(filterValue) > -1
            }
            if(!textfilter) return false
      }

      return true

    },
    buildFilterValue: (props) => { 
        if(this.filterValue) { return this.filterValue } 
        if(this.filterTag) {
          //build filter value from filter tag
          return this.filterTag
        }
    },

  }
}
</script>