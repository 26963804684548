<template>
    <div class="border-bottom shadow border-secondary my-2 p-1 d-flex flex-column" style="background-color: lightgoldenrodyellow;">
        <b-dropdown boundary="window" right variant="primary" class="ml-auto">
            <div class="d-flex justify-content-center align-items-center">
                <dynamic-loader widget="Creator" module="task" :customProperties="{templateOverrides: { 'description': value.text }}" /> 
                <div class="mx-1">Convert to task</div>
            </div>
            <b-dropdown-item @click="$emit('delete')" variant="danger">Delete</b-dropdown-item>
        </b-dropdown>
        <markup-text optional :value="value.text" @input="$emit('input',$event)" />
    </div>
</template>

<script>
export default {
    name: "Postit",
    props: {
        value: null
    }
}
</script>

<style>

</style>