import env from './../../../public/environment.json'
const buildRoutes = function(
    alias, 
    labelsForSidebar, 
    groupForSidebar, 
    suffixDefinition, 
    filter,
    additionalProps = {}) {
    return [{
        "path" : "\/"+alias+"\/:id",
        "name" : alias + "Single",
        "meta" : {
            "show" : {"sidebar" : false },
            "label" : {"de" : "Aufgabendetails", "en" : "Task details"},
            "requiresAuthentication" : true,
            "gridLayout" : [                
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": alias+"taS",
                    "static": false,
                    "module": "task",
                    "widget": "Single",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": alias+"taT",
                    "static": false,
                    "module": "task",
                    "widget": "Table",
                    "customProperties": {
                        "card": true,
                        "iriAlias" : "/"+alias+"/",
                        "filter": filter                        
                    }           
                }
            ]
        }
    },
    {
        "path" : "\/"+alias,
        "name" : alias+"Overview",
        "meta" : {
            "show" : {"sidebar" : true, "group" : groupForSidebar },
            "label" : labelsForSidebar,
            "suffixComponent" : suffixDefinition,
            "requiresAuthentication" : true,
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": alias+"taT",
                    "static": false,
                    "module" : "task",
                    "widget" : "Table",
                    "customProperties": {
                        "iriAlias" : "/"+alias+"/",
                        "openAsModal" : true,
                        "filter": filter,
                        ...additionalProps
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    }]
}

export default [
    ...buildRoutes(
        'tasks',
        {"de" : "Aufgabenübersicht", "en" : "Task overview"},
        null,
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [{module: "task", context: {} }]
            }
        },
        {
            "categories" : function(category, item) { 
                //check if item has a category and zero open linked 
                if(!category || category.length === 0) return true
                if(!item?.iri) return true //ignore if item is missing

                if(item?.subtasks?.overdue + item?.subtasks?.approaching + item?.subtasks?.others == 0) return true

                let precedingRelations = globalVue.$store.getters['relation/incomingRelations']?.[item.iri] ?? []

                return precedingRelations.filter((item) => (item.type !== "responsibility") && item.fromIRI.includes('/tasks/'))
                        .map(rel => globalVue.$store.getters['task/getItems'][rel.fromIRI])          
                        .every(task => {
                            return !task || (task._isLoading || task.status == "completed" || task.status == "cancelled" || task.status == "onhold")
                          })
            }
        },

        ),
    ...buildRoutes(
        'elops',
        {"de" : "E-LOP", "en" : "E-LOP"},
        'eng',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "elop"} }
                ]
            }
        },
        {"categories" : ["elop"]},
        ),  
    ...buildRoutes(
        'pmlops',
        {"de" : "PM-LOP", "en" : "PM-LOP"},
        'pm',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "pmlop"} }
                ]
            }
        },
        {"categories" : ["pmlop"]},
        ),  
    ...buildRoutes(
        'qlops',
        {"de" : "Q-LOP", "en" : "Q-LOP"},
        'qual',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "qlop"} }
                ]
            }
        },
        {"categories" : ["qlop"]},
        ), 


    ...buildRoutes(
        'sclops',
        {"de" : "SC-LOP", "en" : "SC-LOP"},
        'sci',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "sclop"} }
                ]
            }
        },
        {"categories" : ["sclop"]},
        ), 

    ...buildRoutes(
        'inllops',
        {"de" : "InL-LOP", "en" : "InL-LOP"},
        'inl',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: ["melop","inllop","plop"]} }
                ]
            }
        },
        {"categories" : ["melop","inllop","plop"]},
        ),

    ...buildRoutes(
        'slops',
        {"de" : "S-LOP", "en" : "S-LOP"},
        'sales',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "slop"} }
                ]
            }
        },
        {"categories" : ["slop"]},
        ),

    ...buildRoutes(
        'btslop',
        {"de" : "BTS-LOP", "en" : "BTS-LOP"},
        'bts',
        { 
            widget: "notificationDisplay",
            customProperties: {
                dataSources: [
                    {module: "task", context: {category: "bts"} }
                ]
            }
        },
        {"categories" : ["bts"]},
        {"createOwner" : env.btsOwner }
        )
]