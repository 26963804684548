<i18n lang="yaml" locale="de">
  title.subtasks: "Subtasks"
</i18n>
<i18n lang="yaml" locale="en">
  title.subtasks: "Unteraufgaben"
</i18n>
<template>
    <div class="p-2">
      <collapse-box class="my-1" :id="$id('subtask')" bodyClass="p-0" :title="$t('title.subtasks')">
      <template #actions>
        <b-button :variant="toggleCompleted ? 'warning' : 'outline-warning'" size="sm" @click="toggleCompleted = !toggleCompleted"><b-icon-archive variant="dark" /></b-button>
      </template>
        <dynamic-loader 
          widget="Table" 
          module="task" 
          :customProperties="{ 
                                filter: {iri : subTasks }, 
                                noSubtasks: true, 
                                noHeader: true,
                                noTitle: true,
                                openAsModal: true, 
                                hideArrow: true, 
                                templateOverrides: {},
                                showCreateRow: true,
                                relateTo : relateTo,
                                fixed: true,
                                forceCompleted: toggleCompleted
                                }">
        </dynamic-loader>
      </collapse-box>
    </div>
</template>

<script>

export default {
    name: "tableSubtaskItem",
    props: {
        iri: null
    },
    computed: {
        relateTo: function(vm) {
          let module = vm.$store.getters.getModuleFromIri(vm.iri)
          let item = vm.$store.getters[module.module+'/getItems'][vm.iri]
          return {
            owner: item.owner,
            to: item.iri,
            type: "information"
          }
        },
        subTasks: function(vm) {
            let rels = vm.$store.getters['relation/incomingRelations'] && vm.$store.getters['relation/incomingRelations'][vm.iri] || []  
            return rels.filter(r => r && r.fromIRI && r.fromIRI.includes('/tasks/')).map(r => r.fromIRI)
        }
    },
    data() {
      return {
        toggleCompleted: false
      }
    }
}
</script>

<style>

</style>