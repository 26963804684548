<i18n lang="yaml" locale="de">
    filters.group.down: "Gruppieren"
    pending: "Pending"
    declined: "Declined"
    approved: "Approved"
  </i18n>
  <i18n lang="yaml" locale="en">
    filters.group.down: "Group"
    pending: "Pending"
    declined: "Declined"
    approved: "Approved"
  </i18n>
  <template>
  <div>                 
      <b-button
      @click="(groupPosition > -1) ? turnGroupOff() : $emit('setGroupData',groupConfiguration);"
          block
          squared
          variant="light"
          class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
      >
        <b-icon-list-ul
          :variant="'dark'"
        ></b-icon-list-ul>
        <div class="small flex-grow-1 text-left pl-1">
            {{ $t('filters.group.down') }}
        </div>
        <b-badge v-if="groupPosition > 0"
        >{{ groupPosition }}</b-badge>
      </b-button>
  </div>
  </template>
  <script>
  export default {
    name: "baseCellGroupApproval",
    props: {
      groupData: {
        required: true,
        type: Object
      },
      groupPosition: {
        required: true,
        type: Number
      },
      groupTag: {
        required: false,
        type: String
      },
      config: {
        type: Object,
        required: true
      }
    },
    watch: {
      'groupTag' : {
        immediate: true,
        handler: function(newVal, oldVal) {
          if(newVal && newVal !== oldVal && (!this.groupData || !this.groupData.groupHandler)) {
            this.updateByTag(newVal)
          }
        }
      }
    },
    computed: {
      groupConfiguration: function(vm) {
        return {'groupHandler' : vm.groupHandler, 
                'groupLabeller' : vm.groupLabeller(),
                'groupSorter' : vm.groupSorter(),
                'groupTag' : '+', 
                'groupData' : {}}
      }
    },
    methods: {
      updateByTag: function(tag) {
        this.$emit('setGroupData',this.groupConfiguration)
      },
      groupHandler: function(cellData, groupData, column) {
        let safeValue = cellData && typeof cellData == "object" && cellData.hasOwnProperty('value') ? cellData.value : cellData
        let parsedData = JSON.parse(safeValue ?? null)

        return parsedData == null ? "pending" : (parsedData.hasOwnProperty('approvalState') ? parsedData.approvalState : "pending")
      },
      groupLabeller: function() {
        let cfg = ['pending','declined','approved']
        return this.$t((fieldName) => (cfg.find(i => i == fieldName)) || fieldName )
      },
      groupSorter: function() {
        let cfg = ['pending','declined','approved'] 
       return (a,b) => {
            let aVal =  (typeof a == "object" && a.hasOwnProperty('value')) ? a.value : a
            let bVal =  (typeof b == "object" && b.hasOwnProperty('value')) ? b.value : b

            let aSortValue = 0
            let bSortValue = 0
            aSortValue = aVal == "pending" ? 1: (aVal == "approved" ? 3 : aVal == "declined" ? 2 : 0)
            bSortValue = bVal == "pending" ? 1: (bVal == "approved" ? 3 : bVal == "declined" ? 2 : 0)
            return aSortValue - bSortValue
       }
      },
      turnGroupOff() {
        this.groupDirection = 0
        this.$emit('groupOff')
      },
  
    }
  }
  </script>