<i18n locale="en" lang="yaml">
  tooltip.hide: "Hide column"
  tooltip.show: "Show column"
  tooltip.moveup: "Move column to left"
  tooltip.movedown: "Move column to right"
</i18n>

<i18n locale="de" lang="yaml">
  tooltip.hide: "Spalte verstecken"
  tooltip.show: "Spalte anzeigen"
  tooltip.moveup: "Spalte nach links schieben"
  tooltip.movedown: "Spalte nach rechts schieben"
</i18n>

<template>
  <div>
      <div v-for="(option, idx) in options" :key="$id('col-'+idx)" @dragover="dragover_handler"
            @drop="updateOrder(idx,$event)" class="border-bottom border-secondary align-items-center justify-content-start d-flex flex-row flex-nowrap">
            <div class="d-flex flex-column small">
                <b-button size="sm" class="p-0" variant="light" @click="move(option.value,-1)" 
                    v-b-tooltip.window.d600.left
                    :title="$t('tooltip.moveup')"
                >
                    <b-icon-caret-up-fill scale="0.5"/>
                </b-button>
                <b-button size="sm"  class="p-0" variant="light" @click="move(option.value, 1)"
                    v-b-tooltip.window.d600.left
                    :title="$t('tooltip.movedown')">
                    <b-icon-caret-down-fill scale="0.5"/>
                </b-button>
            </div>
            <div class="flex-grow-1 grabbing" draggable @dragstart="startReordering(idx,$event)">{{ option.text }}</div>
            <b-form-checkbox
                :checked="option.value.show"
                @change="setVisible(option.value, $event)"
                switch
                size="sm"
                variant="danger"
                class="p-0"
                v-b-tooltip.window.d600.right
                :title="$t(option.value.show ? 'tooltip.hide' : 'tooltip.show')"
                >
            </b-form-checkbox>
            
        </div>
  </div>
</template>
<style scoped>
.grabbing  {
    cursor: grabbing !important;
}
</style>

<script>
export default {
    name: "tableViewManager",
    props: {
        options: {
            required: true,
            type: Array
        }
    },
    methods: {
        isVisible(name) {
            let col = this.options.find(c => c.value.name == name)
            return col && col.show
        },
        setVisible(col, state) {
            if(col) {
                col.show = state
                this.$emit('input',this.options.map(c => c.value))
            } 
        },
        move(col, amount) {
            if(col) {
                let orderLvl = col.order 
                let colIdx = this.options.findIndex(c => c.value.name == col.name)
                let relevantNeigbour = this.options[colIdx + amount]
                if(relevantNeigbour) { relevantNeigbour.value.order -= amount }
                this.options[colIdx].value.order += amount
                this.$emit('input',this.options.map(c => c.value))
            }
        },
        dragover_handler(ev) {
            ev.preventDefault();
            if(this.disabled) {
                ev.dataTransfer.dropEffect = "none";
                return
            } else {
                ev.dataTransfer.dropEffect = "move";
            }
        },
        startReordering(idx,ev) {
            ev.stopPropagation();
            ev.dataTransfer.dropEffect = "move"
            ev.dataTransfer.setData('application/json',JSON.stringify({id: idx,  action: "ordering"}))
        },
        updateOrder(targetIdx, ev) {
            
            let transferString = ev.dataTransfer.getData('application/json')
            if(!transferString) {return}
            let transferObject = JSON.parse(transferString)
            let droppedId = transferObject.id
            let dragAction = transferObject.action         
            if(dragAction !== "ordering" || targetIdx == droppedId ) { return }
            this.reorder(targetIdx, droppedId) ;
        },
        reorder(targetId, droppedId) {
             
                let targetOldOrder = this.options[targetId].value.order
                let dropOldOrder = this.options[droppedId].value.order
                if(targetOldOrder < dropOldOrder)
                {
                for(let i= targetId; i< droppedId; i++){
                    this.options[i].value.order++
                }
                }
                if(dropOldOrder < targetOldOrder ){
                for(let i= droppedId+1; i<= targetId; i++){
                    this.options[i].value.order--     
                }
                }
                this.options[droppedId].value.order = targetOldOrder
                this.$emit('input',this.options.map(c => c.value))
        },
    }

}
</script>

<style>

</style>