import ganttGroupElement from './ganttGroupElement.vue'
import ganttItemElement from './ganttItemElement.vue'



export default {
    components: {
        'ganttGroupElement' : ganttGroupElement,
        'ganttItemElement' : ganttItemElement
    }
}