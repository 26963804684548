<i18n locale="en" lang="yaml">
  calendar.monday: "Mon"
  calendar.tuesday: "Tue"
  calendar.wednesday: "Wed"
  calendar.thursday: "Thu"
  calendar.friday: "Fri"
  calendar.saturday: "Sat"
  calendar.sunday: "Sun"
  calendar.week: "Wk"
  today: "Today"
</i18n>

<i18n locale="de" lang="yaml">
  calendar.monday: "Mo"
  calendar.tuesday: "Di"
  calendar.wednesday: "Mi"
  calendar.thursday: "Do"
  calendar.friday: "Fr"
  calendar.saturday: "Sa"
  calendar.sunday: "So"
  calendar.week: "KW"
  today: "Heute"
</i18n>
<template>
    <table>
        <thead>
            <tr>
                <td colspan="8">
                    <slot name="topbuttons"></slot><!-- BUTTONGROUP -->
                </td>
            </tr>
            <tr>
                <td colspan="8" class="text-left">
                    
                    <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                    <b-button class="mr-1"  variant="light" @click="gotoToday()">
                        {{ $t('today')}}
                    </b-button>
                    <b-button-group class="flex-grow-1 text-center">
                    <b-button class="px-0"  variant="light" @click="changeMonth(-1)"><b-icon-chevron-compact-left /></b-button>
                      <b-input-group-text class="w-100 justify-content-center">  {{ (localValue).format('MMMM') }}</b-input-group-text>
                    <b-button class="px-0"  variant="light" @click="changeMonth(1)"><b-icon-chevron-compact-right /></b-button>
                    </b-button-group>
                    <b-button-group class="ml-auto">
                    <b-button class="px-0" variant="light" @click="changeMonth(-12)"><b-icon-chevron-compact-left /></b-button>
                        <b-input-group-text>  {{ (localValue).format('YYYY') }}</b-input-group-text>
                    <b-button class="px-0" variant="light" @click="changeMonth(12)"><b-icon-chevron-compact-right /></b-button>
                    </b-button-group>
                    </b-button-toolbar>
                </td>
            </tr>
            <tr class="header-row font-bold border-bottom border-secondary text-center">
                <td style="width: 12.5%;">{{ $t('calendar.week') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.monday') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.tuesday') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.wednesday') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.thursday') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.friday') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.saturday') }}</td>
                <td style="width: 12.5%;">{{ $t('calendar.sunday') }}</td>
            </tr>
        </thead>
        <tbody>
            <tr 
                v-for="(start,i) in rowStarts" 
                :key="$id('cal-'+i)">
                <td class="cw-field border-right border-secondary text-muted text-left" style="height: 34px;">{{ start.format('WW') }}</td>
                <td 
                    @click="setValue(offset(start,n-1))" 
                    style=" vertical-align: top;" 
                    class="text-center" 
                    v-for="n in 7" 
                    :key="'cal-'+i+'-'+n"    
                    >
                    <div
                     :style="buildOwnerMilestoneStyle(offset(start,n-1))+'padding: 2px;'"
                     v-b-tooltip :title="buildOwnerMilestoneTitle(offset(start,n-1))"
                    >
                        <b-button squared variant="outline" :class="addClasses(start,n-1)">
                            {{ offset(start,n-1).format('DD') }}
                            
                        </b-button>
                    </div>
                </td>
                <!-- ACTUAL CALENDAR FIELDS -->
            </tr>
            <tr v-for="j in (6 - rowStarts.length) " :key="$id('spacer-'+j)">
                <td style="height: 34px;">&nbsp;</td>
            </tr>
        </tbody>
        <tfoot>            
            <tr>
                <td colspan="8">
                    <slot name="bottombuttons"></slot><!-- BUTTONGROUP -->
                </td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
const moment = require('moment')
export default {
    name: "DatePickerCalendar",
    props: {
        'value' : {
            required: true,
            type: null        
        },
        'owner' : {
            required: false,
            type: null,
            default: null
        }
    },
    inject: {
        getOwnerIri: {
            from: "getOwnerIri",
            default: () => false
        }
    },
    data() {
        return {
            localValue: null,
            rowStartsData: []
        }
    },
    computed: {
        selectedOwnerMilestones: function(vm) {
            if(!this.getOwnerIri) return []
            let owner = (vm.$store.getters['owner/getItems'] && vm.$store.getters['owner/getItems'][this.owner ?? this.getOwnerIri()]) ?? {}
            
            let milestones = [];
            
            if(owner.gantt && owner.gantt.content && Array.isArray(owner.gantt.content)) {
                //support for old gantts
                milestones = owner.gantt && 
                owner.gantt.content &&
                owner.gantt.content.find(el => el.type == "milestones") || {}
            } else {
                //fetch all milestones defined in top level
                milestones = owner?.gantt?.content ?? {}

            }


            return (milestones.dates && 
                milestones.dates.map(ms => Object.assign({},ms,{start: moment(ms.start)}))) ?? []
        },


        today: function(vm) {
            return moment()
        },
        locale: function(vm) {
            return ["de","en-gb"]
        },
        calendarStart: function(vm) {
            return moment(vm.localValue).startOf('month').startOf('isoWeek')
        },
        calendarEnd: function(vm) {
            return moment(vm.localValue).endOf('month').endOf('isoWeek')
        },
        rowStarts: function(vm) {
            let rowCount = parseInt(Math.floor(this.calendarEnd.diff(this.calendarStart,'days') / 7))
            let rowArray = []
            for(let i=0; i<=rowCount; i++) {
                rowArray.push(moment(this.calendarStart).add(i,'weeks'))
            }
            return rowArray
        }
    },
    watch: {
        'value': {
            immediate: true,
            handler: function(newVal) {
                this.localValue = moment(newVal) || moment()
                if(!this.localValue.isValid()) {
                    this.localValue = moment()
                }
            }
        }
    },
    methods: {
        getOwnerMilestonesByDate: function(date) {
           return this.selectedOwnerMilestones.filter(ms => ms.start.isSame(date) )
        },
        buildOwnerMilestoneStyle: function(date) {
            let mss = this.getOwnerMilestonesByDate(date) ?? []
            return "background-color: " + (mss.length > 0 ? mss[0].color : "#ffffff") + ";"
        },
        buildOwnerMilestoneTitle: function(date) {
            let mss = this.getOwnerMilestonesByDate(date) ?? []
            return mss.map(ms => ms.title).join("\n")
        },
        emitValue() {
            this.$emit('input',this.localValue)
        },
        changeMonth(amount) {
            this.localValue = moment(this.localValue.add(amount, 'months'))
        },
        gotoToday() {
            this.localValue = moment()
        },
        offset(start,n) {
            return moment(start).add(n,'days')
        },
        addClasses(start,n) {
            let list = ['border-white']
            let momentDate = this.offset(start,n)
            let localValueMonth = this.localValue.format('M')
            let background = "bg-white";

            if(moment().startOf('day').unix() === momentDate.unix()) {
                background = 'bg-warning text-white'
            }
            if(moment(this.value).unix() === momentDate.unix()) {
                background = 'bg-primary text-white'
            }


            if(localValueMonth !== momentDate.format('M'))
            {
                list.push('text-black-50')
            } 
            return list.join(' ') + ' '+background
        },
        setValue(val) {
            this.$emit('input',val)
        }

    }
}
</script>

<style>

</style>