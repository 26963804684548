<script>
export default {
  functional: true,
  name: "BaseCellEditCurrency",
  render: function(createElement, context) {

      let val = (context.props.value && (typeof context.props.value == "object") ? context.props.value.value : context.props.value) || null
      let obj = context.parent.$currency.getValueObject(val)

      return createElement('td',{
        attrs: {
          class: "base-table--cell "+(context.props.value && context.props.value.cellClasses || ''),
          style: context.props.config['@stickyStyle'] || ''
          }
        },[
        createElement('div',{attrs: {class: "d-flex"}},[
          createElement('b-form-select', {
            on: {'input' : (ev) => {
              let val = (context.props.value && (typeof context.props.value == "object") ? context.props.value.value : context.props.value) || null
              let obj = context.parent.$currency.getValueObject(val)
              context.listeners.input(ev+'::'+obj.centValue)
            }},
            attrs: {
              style: 'width: 60px;    padding-top: 0.15rem;'
            },
            props: {
              size: 'sm',
              options: context.parent.$currency.getAcceptedCurrencies(),
              value: obj.currency ?? 'EUR'
            }
          }),
          createElement('b-form-input', {
            on: {'input' : (ev) => {
              let val = (context.props.value && (typeof context.props.value == "object") ? context.props.value.value : context.props.value) || null
              let obj = context.parent.$currency.getValueObject(val)
              context.listeners.input(obj.currency+'::'+parseInt(Math.round(ev*100)))
            }},
            props: {
              size: "sm",
              value: obj.actualValue,
              number: true,
              type: 'number'
            }
          })
        ])
      ])
    }
}
</script>

<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
