import Vue from 'vue'
import { libraryManager } from '@/helper/LibraryManager'

const HotkeyDelegator = function() 
{
    this.hotkeyList = []
}

HotkeyDelegator.prototype.register = function(name, keyCombination, callback) 
{
    let hotkey = new Hotkey(name, keyCombination, callback.bind(this))
    this.hotkeyList.unshift(hotkey)
}

HotkeyDelegator.prototype.unregister = function(name)
{
    let idx = this.hotkeyList.findIndex(hk => hk.name == name)
    if(idx > -1) {
        this.hotkeyList.splice(idx,1)
    }
}

HotkeyDelegator.prototype.findHotkey = function(ev)
{
    //this forces the component to use the newest reegistered hotkey
    let matchedHotkey = this.hotkeyList.find(hotkey => hotkey.matches(ev))
    if(matchedHotkey) {
        matchedHotkey.execute()
    }
}


HotkeyDelegator.prototype.install = function(Vue, options) 
{      
    Vue.prototype.$hotkey = this
}

const Hotkey = function(name, keyCombination, callback) 
{
    this.name = name
    this.keyString = keyCombination
    this.callback = callback

    let allKeys = keyCombination.split('+')

    this.alt = allKeys.indexOf('alt') > -1
    this.ctrl = allKeys.indexOf('ctrl') > -1
    this.shift = allKeys.indexOf('shift') > -1
    let notmatched = ['ctrl','alt','shift']
    this.keyString = allKeys.filter(key => !notmatched.includes(key))
    if(this.keyString.length >= 1) {
        this.keyString = this.keyString[0]
    } else {
        this.keyString = "invalid"
    }
}

Hotkey.prototype.matches = function(keyEvent) 
{
    return  keyEvent.ctrlKey == this.ctrl &&
            keyEvent.altKey == this.alt &&
            keyEvent.key == this.keyString
}

Hotkey.prototype.execute = function() 
{
    this.callback()
}



const HkDelegator = new HotkeyDelegator()

window.addEventListener('keydown',HkDelegator.findHotkey.bind(HkDelegator))
export { HkDelegator }