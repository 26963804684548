<i18n lang="yaml" locale="de">
  deselectAll: "Alle abwählen"
  selectAll: "Alle auswählen"
</i18n>
<i18n lang="yaml" locale="en">
  deselectAll: "Deselect all"
  selectAll: "Select all"
</i18n>
<template>
  <div>       
    <b-button
      block 
      variant="light" 
      @click="$emit('setFilterStatus',!filterStatus)" 
      class="d-flex flex-nowrap mb-1 justify-content-start align-items-center"
      >
         <b-icon :icon="!!filterStatus ? 'funnel-fill' : 'funnel'" />
      <div class="small flex-grow-1 text-left pl-1">
       {{ $t('filters.filter.filter') }}
      </div>
    </b-button>
    <b-form-checkbox
      size="sm"
      class="ml-1"
      :checked="displayItems.length === options.length"
      :indeterminate="activeItems.length > 0 && activeItems.length !== options.length"
      @change="updateList($event)"
      >{{ $t(activeItems.length === options.length ? 'deselectAll' : 'selectAll')}}</b-form-checkbox>
    <b-form-checkbox-group
      class="pl-1 ml-2"
      size="sm"
      stacked
      :checked="displayItems"
      @change="updateFilter($event)"
      :options="options"
    />
  </div>
</template>

<script>


export default {
  name: "baseCellFilterSelect",
  props: {
    filterStatus: {
      type: Boolean
    },
    filterValue: {
      type: null
    },
    filterTag: {
      type: String
    },
    config: {
      type: Object,
      required: true,
    }
  },  
  watch: {
    'filterTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(typeof newVal == "string" && newVal !== oldVal && (!this.filterValue || !this.filterValue.filterHandler)) {
          
          if(newVal.substr(0,7) != 'select-') return
          let list = newVal.substr(7).split('@@')
          this.updateFilter(list)
        }
      }
    }
  },
  data() {
    return {
      activeItems: []
    }
  },
  computed: {
    displayItems: function(vm) {
      if(!vm.filterStatus) return vm.options.map(i => i.value)
      return vm.activeItems
    },
    options: function(vm) {
      if(!vm.config || !vm.config.selectOptions) return []
      let empty = {value: "__EMPTY__", text: {de: "Leer", en: "Empty"}}
      let options = [...vm.config.selectOptions.entries]
      if(vm.config.optional && vm.config.optional === true)
      {
         options.unshift(empty)
      }
      return options.map(item => ({value: item.value, text: item?.text?.[vm.$root.$i18n.locale] ?? item.value}))
     

    },
    localFilterTag: function(vm) {
      return 'select-'+ vm.activeItems.join('@@')
    },
    filterConfiguration: function(vm) {
      return {'filterHandler' : vm.filterHandler, 
              'filterTag' : vm.localFilterTag, 
              'filterValue' : vm.activeItems}
    }
  },
  methods: {
    updateList(ev) {
      this.activeItems = ev ? this.options.map(i => i.value).slice() : []
      this.$emit('setFilterValue', this.filterConfiguration)
    },
    updateFilter: function($event) {
      this.activeItems = $event
      this.$emit('setFilterStatus', true)
      this.$emit('setFilterValue', this.filterConfiguration)
      
    },
    //generates the filter function based on the filterValue, which can preload some heavy lifting 


    /**
     * The filterHandler is a function that returns a boolean
     * it gets handed the arguments:
     * 
     * cellData     any   the cell data as specified by the type
     * filterValue  any   the filterValue as stored in the filter value
     * columnData   any   the full column configuration
     */
    filterHandler: (cellData, filterData) => {
            let safeValue = cellData && typeof cellData == "object" && cellData.hasOwnProperty('value') ? cellData.value : cellData            
            let valAsArray = Array.isArray(safeValue) ? safeValue : [safeValue]
            return valAsArray.length == 0 ? filterData.indexOf("__EMPTY__") > -1 :valAsArray.some(
              data => filterData.some(
                (item) => {
                  if(item == "__EMPTY__") {
                    return data === null || data === undefined || data === ""
                  } else {
                    return data == item
                  }
                }
              )
            )
        }
  }
}
</script>