<i18n lang="yaml" locale="de">
  filters.group.down: "Gruppieren (absteigend)"
  filters.group.up: "Gruppieren (aufsteigend)"
</i18n>
<i18n lang="yaml" locale="en">
  filters.group.down: "Group (ascending)"
  filters.group.up: "Group (descending)"
</i18n>
<template>
<div>           
                     
    <b-button
    @click="(groupDirection == -1 && groupPosition > -1) ? turnGroupOff() : updateGroupSettings(-1); "
        block
        squared
        variant="light"
        class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
    >
      <b-icon-list-ul
        :variant="'dark'"
      ></b-icon-list-ul>
      <div class="small flex-grow-1 text-left pl-1">
          {{ $t('filters.group.down') }}
      </div>
      <b-badge v-if="groupDirection == -1"
      >{{ groupPosition }}</b-badge>
    </b-button>  

    <b-button
    @click="(groupDirection == 1 && groupPosition > -1) ? turnGroupOff() : updateGroupSettings(1); "
        block
        squared
        variant="light"
        class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
    >
      <b-icon-list-ul
        :variant="'dark'"
      ></b-icon-list-ul>
      <div class="small flex-grow-1 text-left pl-1">
          {{ $t('filters.group.up') }}
      </div>
      <b-badge v-if="groupDirection == 1 && groupPosition > -1"
      >{{ groupPosition }}</b-badge>
    </b-button>
</div>
</template>
<script>
export default {
  name: "baseCellGroupBoolean",
  props: {
    groupData: {
      required: true,
      type: Object
    },
    groupPosition: {
      required: true,
      type: Number
    },
    groupTag: {
      required: false,
      type: Number
    }
  },
  data() {
    return {
      groupDirection: 1
    }
  },
  watch: {
    'groupTag' : {
      immediate: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal !== oldVal && (!this.groupData || !this.groupData.groupHandler)) {
          this.updateByGroupTag(newVal)
        }
      }
    }
  },
  computed: {
    groupConfiguration: function(vm) {
      return {'groupHandler' : vm.groupHandler, 
              'groupLabeller' : vm.groupLabeller,
              'groupSorter' : vm.groupSorter,
              'groupTag' : vm.groupDirection, 
              'groupData' : {}}
    }
  },
  methods: {
    updateByGroupTag(tag) {
      this.groupDirection = tag
      this.$emit('setGroupData',this.groupConfiguration)
    },
    updateGroupSettings(dir) {
      this.groupDirection = dir
      this.$emit('setGroupData',this.groupConfiguration)
    },
    turnGroupOff() {
      this.groupDirection = 0
      this.$emit('groupOff')
    },
    groupHandler: function(cellData, groupData, column) {
      return (typeof cellData == "object" && cellData.hasOwnProperty('value')) ? cellData.value : cellData
    },
    groupLabeller: function(groupName) {
      return groupName ? 'x' : '-'
    },
    groupSorter: function(a,b) {return  (b - a) * this.groupDirection }

  }
}
</script>