export default [
    {
        "path" : "\/messages\/:id",
        "name" : "mailboxSingle",
        "meta" : {
            "show" : {"sidebar" : false },
            "label" : {"de" : "Datentransfermanagement", "en" : "Data transfer management"},
            "requiresAuthentication" : true,
            "gridLayout" : [                
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "mbS",
                    "static": false,
                    "module": "mailbox",
                    "widget": "Single",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": "taT",
                    "static": false,
                    "module": "mailbox",
                    "widget": "Table",
                    "customProperties": {
                        "card": true,
                        "filter": {
                        }
                    }           
                }
            ]
        }
    },{
        "path" : "\/messages",
        "name" : "mailboxOverview",
        "meta" : {
            "show" : {"sidebar" : true, "group": "project" },
            "label" : {"de" : "DBS", "en" : "DTS"},
            "requiresAuthentication" : true,
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "taT",
                    "static": false,
                    "module" : "mailbox",
                    "widget" : "Table",
                    
                    "customProperties": {
                        "openAsModal" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : true
                    }
                }
            ]
        }
    }
]