<i18n locale="en" lang="yaml" >
    confirm.remove.approval: "Are you sure you want to remove the approval?"
    confirm.remove.yes: "Yes, remove approval"
    confirm.remove.no: "No"
    approval.required: "Approval pending"
    approval.not.required: "not required / optional"
    modal.approve: "Approve request"
    modal.decline: "Decline request"
    modal.unset: "Reset field"
</i18n>
<i18n locale="de" lang="yaml" >
    confirm.remove.approval: "Soll die Freigabe wirklich gelöscht werden?"
    confirm.remove.yes: "Ja, Freigabe löschen"
    confirm.remove.no: "Nein"
    approval.required: "Freigabe ausstehend"
    approval.not.required: "nicht benötigt / optional"
    modal.approve: "Anfrage bestätigen"
    modal.decline: "Anfrage ablehnen"
    modal.unset: "Feld zurücksetzen"
</i18n>
<template>    
    <td class="base-table--cell" 
     :class="value && value.cellClasses || ''"
        >
       <div class="cell-nowrap d-flex flex-nowrap align-items-center" :style="'width: '+(32+config['@width'])+'px;'">
         <b-button 
              size="sm" 
              class="p-1"
              :id="$id('button')"
              v-b-modal="$id('approvalWindow')"
              text="Button" 
              :variant="approvalStatus">
                  <b-icon variant="dark" :icon="approvalIcon" />
          </b-button>
          <b-popover triggers="hover focus" boundary="viewport" :target="$id('button')" placement="bottom">
              {{ approvalString }}
          </b-popover>
      </div>
      <b-modal 
            size="sm"
            :id="$id('approvalWindow')"
            
            hide-footer >
                <b-button class="m-2" @click="toggleApproval('approve')" type="button" variant="success" block>{{ $t('modal.approve') }}</b-button>
                <b-button class="m-2" @click="toggleApproval('decline')" type="button" variant="danger" block>{{ $t('modal.decline') }}</b-button>
                <b-button class="m-2" @click="toggleApproval('reset')" type="button" variant="warning" block>{{ $t('modal.unset') }}</b-button>
        </b-modal>
    </td>

</template>
<script>
const moment = require("moment")

 export default {
  name: "baseCellEditApproval",
  props: {
    value: {
      type: null
    },
    config: {
      type: Object
    },
    row: {
        type: Object
    }
  },
  
    watch: {
        "value" : {
            immediate: true,
            handler: function() {
                this.localData = this.value
            }
        }
    },
    methods: {
        toggleApproval: function(ev) {
            //build approval object
            let approvalObject = {
                "approvalState" : null,
                "approvedBy" : this.$store.getters.getCurrentUser,
                "approvalDate" : moment().format('YYYY-MM-DD')
            }

             switch(ev) {
                case "approve":
                    approvalObject.approvalState = "approved"
                    this.$emit('input',JSON.stringify(approvalObject))
                    break;
                case "decline":
                    approvalObject.approvalState = "declined"
                    this.$emit('input',JSON.stringify(approvalObject))
                    break;
                case "reset":
                    this.$emit('input',null);
                    break;
             }
            this.$bvModal.hide(this.$id('approvalWindow'))
        }
    },
    computed: {
        cleanValueObject: function(vm) {
            if(typeof vm.localData == "object" && vm.localData !== null && vm.localData.hasOwnProperty('value')){
                return vm.localData.value                                
            } else {
                return vm.localData
            }
            return null
        },
        isRequiredValue: function(vm) {
          return vm.config?.customProperties?.isRequired?.(vm.row) ?? true
        },
        parsedLocalData: function(vm) {
            let parsedData =  JSON.parse(vm.cleanValueObject ?? null)
            if(!!parsedData && typeof parsedData == "object" && 
                parsedData.hasOwnProperty('approvalDate') && parsedData.approvalDate !== null &&
                parsedData.hasOwnProperty('approvedBy') && parsedData.approvedBy !== null && parsedData.approvedBy !== "") {
                
                parsedData['@momentDate'] = moment(parsedData.approvalDate)
                return parsedData
            }
            return null
        },
        approvalString: function(vm) {
            if(!vm.parsedLocalData) {
                if(vm.isRequiredValue) {
                    return vm.$t('approval.required')
                } else {
                    return vm.$t('approval.not.required')
                }
            } else {
                //parse field approvalDate into moment
                let approvalMoment = moment(vm.parsedLocalData['@momentDate'])
                let approvedByString = vm.$render.displayByIri(vm.parsedLocalData.approvedBy)

                return approvalMoment.format('DD.MM.YYYY') + " - " + approvedByString

            }   
        },
        approvalStatus: function(vm) {
            if(!!vm.parsedLocalData) {
                if(vm.parsedLocalData?.approvalState === "approved" || 
                    (!vm.parsedLocalData.hasOwnProperty('approvalState') && vm.parsedLocalData !== null)) {
                    return "success"
                }
                if(vm.parsedLocalData?.approvalState === "declined") {
                    return "danger"
                }
            } else {
                if(vm.isRequiredValue) {
                    return "warning"
                } else {
                    return "secondary"
                }
            }
        },
        approvalIcon: function(vm) {
            if(!!vm.parsedLocalData) {
                if(vm.parsedLocalData?.approvalState === "approved" || 
                    (!vm.parsedLocalData.hasOwnProperty('approvalState') && vm.parsedLocalData !== null)) {
                    return "check2"
                }
                if(vm.parsedLocalData?.approvalState === "declined") {
                    return "x"
                }
            } else {
                if(vm.isRequiredValue) {
                    return "square"
                } else {
                    return "dash"
                }
            }
        }
    },
    data() {
        return {
            localData: null
        }
    }
 }
</script>
<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
