<i18n locale="en" lang="yaml" >
    Lock:
        fetching: "Lock element"
        updating: "Update element"
        committing: "Saving..."
        unlocking: "Unlocking"
    redacted: "REDACTED"
    help.ok: "Understood"
</i18n>
<i18n locale="de" lang="yaml" >
    Lock:
        fetching: "Element sperren"
        updating: "Daten aktualisieren"
        committing: "Speichern..."
        unlocking: "Entsperren"
    redacted: "GESCHWÄRZT"
    help.ok: "Verstanden"
</i18n>
<template>
    <div
        :size="size"
        class="flex-nowrap d-flex flex-column justify-content-start  mb-0"
        :class="{[field.classes || ''] : true, ['align-items-'+(align || 'start')] : true, 'my-1' : (noLabel || useIcon), 'editorField' : !(noLabel || useIcon) }"
        @dblclick="$emit('checkout')"
        >
        
        <div v-if="!(noLabel || useIcon)" class="d-flex justify-content-start label-nowrap mb-0 text-bold">
            <span 
                style="font-size: 11.2px;" 
                :class="{'flex-grow-1' : field.hasOwnProperty('gridspan'), 'flex-shrink-1' : !field.hasOwnProperty('gridspan')}" 
                class=" text-truncate" v-b-tooltip.bottom :title="field.mouseover && field.mouseover[$root.$i18n.locale] || field.label && field.label[$root.$i18n.locale] ||''">
                    {{ field.label && field.label[$root.$i18n.locale] || '' }}
            </span>
            <template v-if="field.help">
                <base-help v-bind="field.help" />
            </template>
        </div>
        <div class="d-flex">
        <div 
            v-if="useIcon" 
            class="d-flex align-items-center justify-content-center px-1 alert-secondary rounded-left border" 
            style="font-size: 19px; margin-right: -1px;"
            :title="field.mouseover && field.mouseover[$root.$i18n.locale] || field.label && field.label[$root.$i18n.locale] ||''"
        >
            <slot name="icon">
                <b-icon :icon="iconLabel" :title="field.mouseover && field.mouseover[$root.$i18n.locale] || field.label && field.label[$root.$i18n.locale] ||''"/>
            </slot>
        </div>

        <b-input-group v-if="field.redacted || false">
            <b-form-input 
                class="border-dark"
                :id="`editorField_${field.name}`" 
                type="text" 
                :size="size" 
                disabled
                :autofocus="field.autofocus"
                value=""
            >
            </b-form-input>
        </b-input-group>
        <markup-text 
            v-else-if="field.type === 'markup'" 
            :value="dataValue"
            @input="dataValue = $event; instantCommit()"
            :optional="field.optional || noValidation"
                :id="`editorField_${field.name}`" 
            :field="field" 
            v-bind="field.customProperties || {}"
            :disabled="!creationMode && !(editMode && field.canEdit) || immutable"
            ></markup-text>
        <template v-else-if="field.type === 'dynamic'" >
            <dynamic-loader 
                :customProperties="dynConfiguration"
                :module="dynModule"
                :widget="dynWidget"
                :disabled="!editable"
                :value="dataValue"
                overflow
                @input="dataValue = $event" >
            </dynamic-loader>
        </template>        
        <template v-else-if="field.type === 'bool' || field.type === 'boolean'" >
            <b-form-checkbox 
                :customProperties="dynConfiguration"
                :size="size"
                :id="`editorField_${field.name}`" 
                :checked="dataValue"
                :disabled="!creationMode && !(editMode && field.canEdit) || immutable"
                @input="dataValue = $event; instantCommit()"
                @blur="emitUpdate"
                ></b-form-checkbox>
        </template>
        <template v-else-if="field.type === 'select'">
            <v-select 
                v-if="(field.selectOptions || field.customProperties.selectOptions).multiple || false"
                :options="selectList"
                :reduce="res => res.value"
                :value="selectList.filter(item => dataValue && dataValue.indexOf(item.value) !== -1)"
                @input="dataValue = $event"
                @blur="emitUpdate"
                label="text"
                :id="`editorField_${field.name}`" 
                :size="size"
                class="small bg-white"
                :placeholder="field.placeholder && field.placeholder[$root.$i18n.locale] || field.placeholder || ''"
                :class="{'border-danger border' : (!field.optional && !noValidation) && (!dataValue || dataValue.length == 0)}"
                :disabled="!creationMode && !(editMode && field.canEdit) || immutable"
                :multiple="true">
            </v-select>
            <b-form-select   
                v-else             
                :value="dataValue"
                :size="size"
                class="py-0"
                :id="`editorField_${field.name}`" 
                :options="selectList"
                :placeholder="field.placeholder && field.placeholder[$root.$i18n.locale] || field.placeholder || ''"
                :disabled="!creationMode && !(editMode && field.canEdit) || immutable"
                :state="(!!field.optional || noValidation)  ? true : (field.validator ? field.validator(value)  : !!value)"
                @change="dataValue = $event"
                @blur="emitUpdate"
            ></b-form-select>
        </template>
        <template v-else-if="field.type === 'date'">
            
            <base-date-picker
                :value="dataValue" 
                @input="dataValue = $event"                
                :optional="field.optional"
                :id="`editorField_${field.name}`" 
                :size="size"
                :disabled="!creationMode && !(editMode && field.canEdit) || immutable"
                @blur="emitUpdate">
            </base-date-picker>
        </template>
        
        <template v-else-if="field.type === 'history'">
            <dynamic-loader 
                :customProperties="{}"
                module="file"
                widget="FormField"
                :disabled="!creationMode && !(editMode && (field.canEdit || true)) || immutable"
                overflow
                :value="dataValue" 
                @input="dataValue = $event">
            </dynamic-loader>
        </template>
        <template v-else-if="field.type === 'currency'">
            <div class="d-flex">
                <b-form-select 
                    v-if="!field.useCurrency"
                    size="sm" 
                    style="width: 60px; padding-top: 0.15rem;"
                    :options="$currency.getAcceptedCurrencies()"
                    :disabled="!creationMode && !(editMode && (field.canEdit || true)) || immutable"
                    :value="dataValue.currency" 
                    @input="$emit('input',$event+'::'+dataValue.centValue)">
                </b-form-select>
                <b-form-input
                    size="sm"
                    type="number"
                    :number="true"
                    :value="dataValue.actualValue"
                    :disabled="!creationMode && !(editMode && (field.canEdit || true)) || immutable"
                    @input="$emit('input',
                        !!field.useCurrency ? field.useCurrency+'::'+(parseInt(Math.round($event*100)))
                        : dataValue.currency +'::'+(parseInt(Math.round($event*100)))
                        )"
                >                    
                </b-form-input>
            </div>
        </template>
           
        <base-approval
            v-else-if="field.type === 'approval'" 
            :id="`editorField_${field.name}`" 
            :value="dataValue"
            @input="dataValue = $event; instantCommit()"
            :field="field" 
            v-bind="field.customProperties || {}"
            :disabled="!creationMode && !(editMode && field.canEdit) || immutable"            
        />
        
        <b-input-group v-else>   
            <b-form-input 
                :ref="$id('inputField')"
                :id="`editorField_${field.name}`" 
                :type="field.type == 'title' ? 'text' : field.type" 
                :size="size"
                :value="dataValue"
                @input="dataValue = $event; saveCursor()"  
                @blur="emitUpdate"         
                :title="dataValue"     
                :autofocus="field.autofocus"
                :placeholder="field.placeholder && field.placeholder[$root.$i18n.locale] || field.placeholder || ''"
                :disabled="!creationMode && !(editMode && field.canEdit) || immutable"
                :state="(!!field.optional || noValidation) ? null : (field.validator ? field.validator(value)  : !!value)"
            >
            </b-form-input>
        </b-input-group>
    </div>
        <b-tooltip 
            :target="`editorField_${field.name}`"
            placement="right"
            container="window"
            variant="danger" 
            noninteractive
            :show="showInvalidPopover"
            :disabled="!showInvalidPopover"
            triggers="hover"
            > 
                 {{ (field.invalid && field.invalid[$i18n.locale]) || "" }}
        </b-tooltip>
        <b-popover 
            v-if="field.mouseover" 
            triggers="hover focus" 
            :target="`editorField_${field.name}`" 
            :placement="field.type == 'select' ? 'right' : 'bottom'" 
            delay="500">
            {{ (field.mouseover && field.mouseover[$root.$i18n.locale]) || "" }}
        </b-popover>
    </div>
</template>

<style>
    .editorField {
        min-height: 40px;
    }

    .label-nowrap {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .helper {
        cursor: help !important;
    }

    .titleLabel input {
        font-size: 19px;
        font-weight: 600;
    }
</style>

<script>

const moment = require('moment')

import baseApproval from './subcomponents/baseApproval.vue'

export default {
    name: "baseEditorField",
    components: {
        baseApproval
    },
    props: {
        field: Object,
        editMode: Boolean,
        creationMode: null,
        align: {type: String, default: "stretch"},
        value: null,
        immutable: {type: Boolean, default: false },
        size: {type: String, default: "sm"},
        noLabel: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        iconLabel: { type: [Boolean,String], default: false }    
    },
    data() { return {    
        acquiringLock: false,
        lazyTimer: null,
        lazyValue: null,
        selectionPosition: 0
    }
    },
    watch: {
        'value' : function() {
            if(this.field?.type == "text" || this.field?.type == "title") {
                this.$nextTick(function() {
                    this?.$refs?.[this.$id('inputField')]?.setSelectionRange(this.selectionPosition, this.selectionPosition)
                })
            }
        }
    },
    computed: {
        showInvalidPopover: function(vm) {
            return vm.isValid === false;
        },
        useIcon: function(vm) {
            return !!vm.$scopedSlots['icon'] || !!vm.$slots['icon'] || !!vm.iconLabel
        },
        isValid: function(vm) {
            return (!!vm?.field?.optional || vm.noValidation) ? null : (vm?.field?.validator ? vm.field.validator(vm.value)  : !!vm.value)
        },
        dynModule: function(vm) {
            if(vm.field.type !== "dynamic") return null
            return vm.editable && vm.field.canEdit && !vm.field.immutable ? (vm.field?.editProvider?.module ?? vm.field?.provider?.module ?? '') : (vm.field?.editProvider?.module ?? vm.field?.provider?.module ?? '')
        },
        dynWidget: function(vm) {            
            return vm.editable && vm.field.canEdit && !vm.field.immutable ? (vm.field?.editProvider?.widget ?? vm.field?.provider?.widget ?? '') : (vm.field?.editProvider?.widget ?? vm.field?.provider?.widget ?? '')
        },
        dynConfiguration: function(vm) {
            return { ...(vm.editable && vm.field.canEdit && !vm.field.immutable ? vm.field.editProvider && vm.field.editProvider.customProperties || vm.field.configuration || {} : vm.field.editProvider && vm.field.editProvider.customProperties || vm.field.configuration || {}) , 
                    "disabled" : !vm.editable
                    }
        },
        editable: function(vm) {
            return (!!vm.creationMode || !!vm.editMode) && !vm.immutable
        },
        registry: (vm) => vm.$store.getters.getRegistry,
        dataValue: { 
            get: function() { 
                let curVal = this.lazyTimer !== null ? this.lazyValue : this.value
                if(this.field.type == 'date' && curVal) {
                    return moment(curVal).format('YYYY-MM-DD')
                }
                if(this.field.type == 'currency' && curVal) {
                    let currency = this.$currency.getValueObject(curVal)
                    
                    if(this.field.useCurrency) {
                        currency.actualValue = this.$currency.convertFromTo(currency.currency , this.field.useCurrency, currency.actualValue)
                        currency.centValue = this.$currency.convertFromTo(currency.currency , this.field.useCurrency, currency.centValue)
                    }

                    return currency
                }
                return curVal },
            set: function(val) {
                if(this.field.preSave) {
                    val = this.field.preSave(val)
                }
                this.lazyValue = val
                this.resetTrigger()
            }
        },
        selectList: function(vm) {
            if(vm.field.type !== "select") {
                return []
            }
            return ((vm.field.selectOptions && vm.field.selectOptions.entries) || 
                    (vm.field.customProperties && vm.field.customProperties.selectOptions)).map(option => ({  value: option.value, 
                                                                    text: (!!option.text[vm.$root.$i18n.locale] && option.text[vm.$root.$i18n.locale]) || option.text['en'] || option.text,
                                                                    disabled: !!option.disabled}))

        }
    },
    beforeDestroy() {
        this.killTimer()
    },
    methods: {
        saveCursor: function() {
                this.selectionPosition = this?.$refs?.[this.$id('inputField')]?.selectionStart
        },
        killTimer: function() {
            if(this.lazyTimer !== null) {
                clearTimeout(this.lazyTimer)
                this.lazyTimer = null
            }
        },
        resetTrigger: function() {
            this.killTimer()
            this.lazyTimer = setTimeout(this.emitUpdate,500)

        },
        emitUpdate: function() {
                this.$emit('input', this.lazyValue) 
                this.killTimer()
        },
        instantCommit: function() {
            this.emitUpdate()
        }
    }


}
</script>
