<i18n locale="de" lang="yaml">
    mailbody: "Folgende Links sollen reviewed werden: \n{list}"
    subject: "[BAIT][{modules}] Bitte {count} Elemente reviewen"
    tooltip: "Links zu selektierten Elementen per Mail verschicken"

</i18n>
<i18n locale="en" lang="yaml">
    mailbody: "Please review the following links: \n{list}"
    subject: "[BAIT][{modules}] Please review {count} items"
    tooltip: "Send links to selected elements by mail"
</i18n>

<template>
  <b-button :href="mailtoString" variant="link" class="py-0 d-flex align-items-center justify-content-center">
      <b-icon-envelope-fill />
  </b-button>
</template>

<script>
export default {
    name: "MailIcon",
    props: {
        items: {required: false, default: () => []}
    },
    computed: {
        mailtoString: function(vm) {
            let str = "mailto:?subject="+escape(vm.subject)+"&body="+escape(vm.body)
            return str
        }, 
        subject: function(vm) {
            let modules = new Set()
            vm.items.forEach(item => {
                modules.add(vm.$render.displayModule(item))
            })
            return vm.$t('subject', {"count": vm.items.length, "modules": [...modules].join('/')} )
        },
        body: function(vm) {
            let baseUrl = window.location.origin

            let list = vm.items.map(item => vm.$render.displayByIri(item)+'\t\t('+baseUrl+item+')')

            return vm.$t('mailbody', { list: '\n'+list.join('\n')+'\n' })
        }
    }
}
</script>

<style>

</style>