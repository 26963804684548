<template>
  <span>
      {{ count > 0 ? ("("+count+")") : "" }}
  </span>
</template>

<script>
export default {
    name: "NotificationDisplay",
    props: {
        /**
         * Layout: array of objectx containing: 
         * module: String the module from which the evaluator should be fetched
         * context: Any type (context is specific to each module and thereby defined by the module function itself)
         */
        dataSources: Array
    },
    data() {
        return {
            timer: null,
            delay: 5000,
            count: 0
        }
    },
    mounted() {
        this.timer = setInterval(() => this.totalCount(), this.delay)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        /**
         * this fetches all the given evaluator functions from the dataSources array and calls the notificationCounter.
         * 
         */
        totalCount: function() {
            //Step 1: iterate over all dataSources and reduce the results to a single Number
            this.count = this.dataSources.reduce((prev,source) => {



                //check if evaluation object exists
                let evaluation = (window && window[source.module] && window[source.module].functions && window[source.module].functions.Evaluation) ?? false
                //return 0 if no evaluation can be executed
                if(!evaluation || !evaluation.notificationCount) return prev
                
                return prev + evaluation.notificationCount(this,source.context || {})

            },0)
        }
    }
}
</script>

<style>

</style>