<template functional>    
    <td class="base-table--cell"  :class="props.value && props.value.cellClasses || ''" :style="(props.config['@stickyStyle'] || '')">
       <div class="cell-nowrap" :style="'width: '+(32+props.config['@width'])+'px;'">
         <component 
         :is="$options.methods.getRemoteComponent(props.config)" 
         :value="props.value && props.value.hasOwnProperty('value') ? props.value.value : props.value" 
         :config="props.config" 
         isInTable
         v-bind="props.config.customProperties || {}"
         />
       </div>
    </td>

</template>
<style lang="scss" scoped>
.cell-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>
export default {
  name: "baseCellDisplayDynamic",
  methods: {
    getRemoteComponent: function(cfg) {
      return window[cfg.provider.module] && 
      window[cfg.provider.module+'-'+cfg.provider.widget] || cfg.provider.widget || null
    }
  }
}
</script>
