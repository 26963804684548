import router from '@/router'
import { store } from '@/store'
import { Registry } from '@/helper/Registry'
import { APIHandler } from '@/helper/APIHandler'

import { persister } from '@/helper/PersistenceManager'
import { libraryManager } from '../LibraryManager'

const moment = require('moment')
const config = require('@/configuration.json')

const Library = function(name) {
  this.modulePrefix = '__MODULE__'
  this.metaStore = '__MODULE_META__'

  this.metaData = null;
	this.registry = Registry;
  this.apiHandler = new APIHandler()
  this.libPromise = this.getLibrary()
    this.name = name

  //hardcode unrestricted libraries
  this.unrestrictedLibraries = ['user']
  this.css = null

}

Library.prototype.loadLibrary = function() {
  return this.libPromise
}

Library.prototype.unmount = async function() {

  //unmount css
  document.head.removeChild(this.css)

  //unmount all components
  let moduleComponents = window[this.name].components;	
  let componentNames = Object.keys(moduleComponents)	
  componentNames.forEach((widget) => {
    delete window[widget]
  })	

  //unmount store
  await store.unregisterModule(this.name)
}

Library.prototype.getLibrary = async function() {
  try {    
    var registryVersion = await this.registry.getAppVersionForModuleByName(this.name)
    //this.metaData = await persister.read(this.metaStore, this.name)   
    let library = await this.loadLibraryFromCache(3600)
    return library
  } catch(e) {
    throw new Error("Library "+this.name+" could not be fetched")
  }
}

Library.prototype.loadLibraryFromCache = function(force = 3600)
{
  return new Promise(function(resolve, reject) {
    this.force = force
    this.fetchLibraryURLFromRegistry(this.name).then((libURL) => {
      if(!libURL) { reject('invalid component') }
      this.registry.getURLForModuleByName(this.name).then((serviceUrl) => {
        if(!serviceUrl) { reject('unregistered component')}
        let script = document.createElement('script')
        script.src = serviceUrl + libURL;
        script.async = true;
        script.onload = () => {this.scriptLoaded().then(() => resolve(this)) }
        script.onerror = reject
        document.head.appendChild(script)
      }).catch((res) => { reject("loading failed") })
    }).catch((res) => { reject("error fetching items") })
  }.bind(this))  
}


Library.prototype.fetchLibraryURLFromRegistry = async function(name) {
  let appPath = this.registry.getAppPath()
  let versionHash = await this.registry.getAppVersionForModuleByName(name)
  if(!appPath || !versionHash || !name){
    throw "unknown library"
  }

  let path = appPath + "/"+name+"."+ versionHash +".umd.min.js"

  return await this.addAuthenticationToPath(path)
}


Library.prototype.addAuthenticationToPath = async function(path) {
  
  let authPath = path
  if(this.unrestrictedLibraries.indexOf(this.name) == -1){
      //ensure valid login data or reject promise
    let loginStatus = await this.apiHandler.checkAuthenticationStatus();
    if(!!loginStatus) {
      authPath = authPath
    } else {
      throw "can't load library while not authenticated"
    }    
  } 

  return authPath
}

Library.prototype.scriptLoaded = async function()
{
      await this.injectRoutes(this.name)
      await this.injectStore(this.name)
      await this.registerAllComponents(this.name)
      await this.loadCSS()
      await this.checkDependencies()
      await this.runStoreInitialisation()
      return true
}

Library.prototype.runStoreInitialisation = async function()
{
  if(store._actions[this.name+'/init']) {
    try {
      await store.dispatch(this.name+'/init')
    } finally {
      return true
    }
  }
  return true
}

Library.prototype.checkDependencies = async function()
{
  if(!!window[this.name].requirements) {
    Promise.allSettled(window[this.name].requirements.map((req) => {
      libraryManager.addLibrary(req)
    }))
  }
}

Library.prototype.loadCSS = async function()
{
  let baseUrl = await this.registry.getURLForModuleByName(this.name)
  var cssRoute = baseUrl + (await this.registry.getAppPath()) + "/"+this.name+".css"

  var authenticatedRoute = await this.addAuthenticationToPath(cssRoute)


  this.css = document.createElement('link')
  this.css.type ="text/css"
  this.css.href = authenticatedRoute
  this.css.rel = "stylesheet"
  document.head.appendChild(this.css)
}

Library.prototype.injectRoutes = function(name) {
  var routesObject = window[name].routes
  if(routesObject) {
    routesObject.forEach(route => router.addRoute(route)	) 
  }
}

Library.prototype.injectStore = function(name) {
	var storeToInject = window[name].store	 
	 //assure store is namespaced
	 if(!storeToInject.namespaced) {
		 storeToInject.namespaced = true
	 }	  
	 store.registerModule(name, window[name].store)
}

Library.prototype.registerAllComponents = function(name) {
   let moduleComponents = window[name].components;	
   let componentNames = Object.keys(moduleComponents)	
   
   componentNames.forEach((widget) => {
	   window[widget] = moduleComponents[widget]
   })	
}


export { Library }