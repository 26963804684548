<template>
    <div 
            class="gantt--timeline-legend d-flex align-items-end justify-content position-relative" 
            :style="'background-size: '+itemWidth+'px 10px; background-position-x: '+itemWidth/2+'px; z-index:1700;'">
                <div 
                    class="year-legend inset-border" 
                    v-for="year in calendarLabelling" 
                    :key="$id('years-'+year.value)"
                >
                    <div class="year-label ">{{ year.value }}</div>
                    <div class="d-flex flex-nowrap inset-border">
                        <div 
                            class="monthContainer inset-border" 
                            v-for="month in year.months" 
                            :key="$id('months-'+year.value+'-'+month.value)"
                        >
                            <div class="month-label text-center">{{ month.value }}</div>
                            <div class="d-flex flex-nowrap">
                                <div 
                                    class="item-legend text-center inset-border" 
                                    :style="legendWeekStyle" 
                                    v-for="(week) in month.units" 
                                    :key="$id('weeks-'+year.value+'-'+month.value+'-'+week)"
                                >
                                    {{week}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="month-legend"></div>
                
                <gantt-icon 
                    backdrop 
                    :value="today"                    
                    :startOffset="startOffset"
                    :totalWidth="totalWidth"
                    :itemWidth="itemWidth"
                    ></gantt-icon>
        </div>
</template>
<style lang="scss" scoped>
    .inset-border {
        box-shadow: inset 0px 0px 0px 1px #0000004f
    }
</style>
<script>
import moment from 'moment'
import GanttIcon from './_ganttIcon.vue'
export default {
    name: "GanttHeaderTimeline",
    components: {
        GanttIcon
    },
    props: {
        maximumDateRange: null,
        displayMode: String,
        startOffset: {
            type: null,
            required: true,
            default: 0
        },
        totalWidth: {
            type: null,
            required: true,
            default: 100
        },
        itemWidth: {
            type: Number,
            required: true,
            default: 30
        }
    },
    computed: {
        today: function(vm) {
            return {
                start: moment().format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
                color: '#a59c4e8a'
            }
        },
        legendWeekStyle: function(vm) {
            let w = vm.itemWidth
            return 'width: '+w+'px;max-width: '+w+'px; min-width: '+w+'px;font-size: 0.8em;'
        },
        calendarLabelling: function(vm) {
            let i = moment(vm.maximumDateRange.min).startOf('isoWeek')
            let end = moment(vm.maximumDateRange.max).endOf('isoWeek')


            let years = []
            let mode = moment.normalizeUnits(this.displayMode)

            let curYearObj = {value: "", months: []}
            let curMonthObj = {value: "", units: []}
            //now build from the maximumDateRange 
            //initialize year an month
            curYearObj.value = moment(i).format('YYYY')
            curMonthObj.value = moment(i).format('MMM')

            while(i < end) {

                if(curMonthObj.value !== i.format('MMM') ) {
                    //change of month -> save month obj to year and reset month
                    curYearObj.months.push({...curMonthObj})
                    curMonthObj = {value: i.format('MMM'), units: []}

                }
                if(curYearObj.value !== i.format('YYYY')) {
                    years.push({...curYearObj})
                    curYearObj = {value: i.format('YYYY'), months: []}
                }

                switch(mode) {
                    case "day": 
                        curMonthObj.units.push(i.format("DD"))
                        break;
                    case "week":
                        curMonthObj.units.push(i.format('WW'))
                        break;
                    case "month":
                        curMonthObj.units.push(i.format('MMM'))
                        break;                
                }
                i.add(1,mode)
            }
            //push current month to currentYear
            curYearObj.months.push({...curMonthObj})
            years.push({...curYearObj})

            return years
        },
    },
    methods: {

    }
}
</script>
