const lf = require('localforage')

export default {
    props: {
        columns: Array,
        rows: Array,
        contextSensitive: {
          type: Boolean,
          default: true
        },
        noOwner: {
          type: Boolean,
          default: false
        }
      },
    computed: {
      currentFilterConfiguration: {
        get: function() {
          let filterTags = {}
          for(const [key, val] of Object.entries(this.filterData)) {
            if(val.filter && !!val.status) {
              filterTags[key] = val.filter.filterTag
            }
          }

          let tmpSortData = []
          this.sortData.forEach((s,idx) => {
            this.$set(tmpSortData,idx, {
              name: s.name,
              sortPosition: s.sortPosition,
              sortTag: s.sortTag
            })
          })

          return {
              columns: this.allColumns.map(col => ({
                name: col.name, 
                width: col['@width'], 
                show: col.show, 
                order: col.order})),
              itemsPerPage: this.itemsPerPage,
              sortData: tmpSortData,
              filterTags: filterTags,
              groupData: this.groupData.map((g) => ({
                name: g.name,
                groupPosition: g.groupPosition,
                groupTag: g.groupTag
              })),
              stickyColumns: [...this.stickyColumnNames],
              id: this.presetId || -1
            }
        },
        set: function(val) {
          if(!val) return
          this.itemsPerPage = val.itemsPerPage ?? 25
          this.sortData = val?.sortData ?? []
          //check if group data already exists, if so, just reorganize
          let newGroupData = []
          val?.groupData?.forEach((g) => {
            let idx = this.groupData.findIndex(a => a.name == g.name)
            if(idx == -1) {
              //add to new groupData
              newGroupData.push(g)
            } else {
              //move existing group to new position
              newGroupData.push(this.groupData[idx])
            }
          })
          //disable all stickies that have been set so far
          this.stickyColumnNames.forEach(this.toggleStickyByName);
          if(val.stickyColumns) {
            val.stickyColumns.forEach(this.toggleStickyByName)
          }
          this.$set(this,"groupData", newGroupData)
          this.presetId = val.id || -1
          let filterObj = {}

          if(val.filterTags) {
            for(const[key,value] of Object.entries(val.filterTags))
            {
              filterObj[key] = {status: true, filter: { filterTag: value}}
            }
            this.filterData = filterObj
          }

          //reset default widths
          this.widths = {}
          this.allColumns.forEach(col => {
            let viewConfig = val?.columns?.find(v => v.name === col.name)
            if(!viewConfig) return
            col.show = viewConfig.show
            col['@width'] = viewConfig.width
            col.order = viewConfig.order
          })
        }
      }
    },
    methods: {
        columnWidth(colName) {
          return this.widths[colName] || null
        },
        getViewSaveName: function(suffix = "_default") {
          return this.$options.name+'@'+this.$route.name+ suffix;
        },
        saveView: function() {
          //view is currently identified by route name + component name. Save order and widths in localstorage using persister
          let saveKey = this.getViewSaveName('_v1')
          let defaultKey = this.getViewSaveName()
          let viewObject = {}
          viewObject['columns'] = this.allColumns.map(col => ({
            name: col.name, 
            width: col['@width'], 
            show: col.show, 
            order: col.order})),
          viewObject['itemsPerPage'] = this.itemsPerPage
          lf.setItem(defaultKey, viewObject)
        },
        forceReset: function() {
          this.defaultSetup = {}
          this.clearAll(true)
        },
        loadDefault: function() {
          //load view by fetching the data from localstorage using route name and component name
          if(!this.defaultSetup) return
          this.currentFilterConfiguration = JSON.parse(JSON.stringify(this.defaultSetup))
          
          this.buildFilteredRows()
        },
        resetConfiguration: function() {
          this.defaultSetup = {}
          this.clearAll(true)

        }
    },
    data() {
        return {
            widths: {},
            columnOrder: null,
            presetId: false
        }
    }
}