<i18n lang="yaml" locale="en">
  icon.meeting: "will be hidden in customer meeting"
</i18n>
<i18n lang="yaml" locale="de">
  icon.meeting: "wird in Kundenmeeting versteckt"
</i18n>
<template>
    <b-card no-body class="my-2" v-if="!checkMeetingModes">
          <b-card-header header-tag="header" class="p-0 d-flex align-items-center justify-content-start collapseHeader" role="tab" :header-bg-variant="variant || 'light'"  >
            <b-button :size="size" type="button" :variant="variant" class="p-1 my-0"  @click="showState = !showState">
                <b-icon-chevron-right scale="0.5" class="when-closed align-self-start"  v-show="!showState"/>
                <b-icon-chevron-down scale="0.5" class="when-open align-self-start" v-show="showState"/>
            </b-button>
            <b-button :size="size" type="button" :variant="variant" class="ml-1 my-0 p-1 flex-grow-1 text-left"  @click="showState = !showState">
                <span class="text-left h6"><slot name="title">{{ title }}</slot></span>
            </b-button>
            <div class="actionButtons my-0">              
              <slot name="actions"></slot>
              <b-button size="sm" v-if="showInModal" variant="none" v-b-modal="$id('modalView')"><b-icon-box-arrow-up-right variant="primary"/></b-button>
            </div>
          </b-card-header>
          <div
            :id="id" 
            v-if="showState"
            role="tabpanel" 
            >
              <b-card-body :class="bodyClass">
                  <slot v-bind:isModalShown="isModalShown"></slot>
              </b-card-body>
          </div>
          <b-modal 
            dialog-class="baseditor-maxwidth" 
            body-class="overflow-auto p-0" 
            size="xl" 
            v-if="showInModal" 
            :id="$id('modalView')" 
            :title="title" 
            hide-footer 
            @shown="isModalShown = true" 
            @hidden="isModalShown = false">
            <slot v-bind:isModalShown="isModalShown"></slot>
          </b-modal>
      </b-card>
</template>
<script>
export default {
  name: "CollapseBox",
  props: {
    meetingConfiguration: {
      type: Object,
      required: false,
      default: function () {
      return  { hideFor: [] } }  
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: true
    },
    hidden: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'sm'
    },
    variant: {
      type: String,
      required: false,
      default: 'light'
    },
    accordion: {
      type: String,
      required: false,
      default: null
    },
    bodyClass: {
      type: null,
      required: false,
      default: () => {}
    },
    showInModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "No Title given"
    }
  },
  data() {
    return {
      showState: !this.hidden,
      isModalShown: false
    }
  },
  computed: {
    isRestrictedInCustomerMeeting: function(vm) {
      return (vm?.meetingConfiguration?.hideFor?.includes('customer') ?? false);
    },
    checkMeetingModes: function(vm)
    {
       let meetingModes= vm.$store.getters.getMeetingModes
       let inMeeting= meetingModes.some(function(item){
          return (vm?.meetingConfiguration?.hideFor?.includes(item) ?? false);
        });
        return inMeeting

    }   

  }
}

</script>
<style lang="scss">
.collapsed .when-open,
.not-collapsed .when-closed {
  display: none !important;
}

.hoverButton {
  visibility: hidden;
}

.collapseHeader:hover .hoverButton {
  visibility: inherit;
}
</style>
