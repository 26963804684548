/* eslint-disable no-console */

import { register } from 'register-service-worker'

import {registerRoute} from 'workbox-routing';
import {NetworkFirst, CacheFirst, StaleWhileRevalidate} from 'workbox-strategies';

const ignoreQueryStringPlugin = {
  cacheKeyWillBeUsed: async ({request, mode, params, event, state}) => {
   //here you can extract the fix part of the url you want to cache without the query
   curl = new URL(request.url);
   return curl.pathname;
 
  }
 };

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )

    },
    registered () {
      console.log('Service worker has been registered.')
      

      //cache styles and scripts for longer to prevent a reload on every request
      registerRoute(({ request, url }) => (request.destination === 'style' || request.destination === 'script' || /preview/.test(url)), new CacheFirst({
        cacheName: 'app-data-cache',
        plugins: [
          ignoreQueryStringPlugin          
        ]
      }));
      
      //all user data, project data, file data and part data is considered "slow", non-volatile data,
      //therefore can be cached and fetched later
      registerRoute(/(user|owner)s?/, new CacheFirst({
        cacheName: 'slow-data-cache',
        plugins: [
        ]
      })); 
      
      registerRoute(({ url }) => /preview/.test(url), new CacheFirst({
        cacheName: 'image-data-cache',
        plugins: [
          ignoreQueryStringPlugin
        ]
      }));
      registerRoute(/(file|part)s?/, new StaleWhileRevalidate({
        cacheName: 'revalidate-data-cache',
        plugins: []
      }));
      
      registerRoute(({request}) => true, new NetworkFirst());
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      if(window.globalVue) {
        window.globalVue.$bvModal.msgBoxOk("New version found. Reload the page by pressing Ctrl+F5. \n\nNeue Version verfügbar. Bitte App mit Strg+F5 neu laden.",{
          title: 'Update',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        }).then(() => (cleanupOutdatedCaches(), window.location.reload()))
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
      window.globalVue.$store.dispatch('setOfflineMode',true)
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
