<template>
    <tr
        class="base-table--row" 
        >
        <td
            class="base-table--cell opaque"
            style="position:sticky;z-index: 5; left:0;"
        > 
            <b-badge href="#" variant="success" @click="save()">
                <b-icon icon="arrow-clockwise" animation="spin" v-if="processing"></b-icon>
                <b-icon-check v-else />
            </b-badge>
            <b-badge href="#" variant="danger" @click="discard()">
                <b-icon-x />
            </b-badge>
        </td>
        <td
            v-if="foldingStatus !== null"
            class="base-table--cell"     
            style="position:sticky;z-index: 5; left:28px;"      
            @click="$emit('toggleSubgroup')" 
            >
            <b-icon :icon="foldingStatus ? 'chevron-down' : 'chevron-right'" />
        </td>
        <td v-if="!noLink" class="base-table--cell"
            :style="'position:sticky;z-index: 5; left:'+(foldingStatus !== null ? 43 : 28)+'px;'">
            
        </td>
         <base-cell-selector
            v-for="(cell,idx) in columnData" 
            :key="$id('-bc-'+idx)" 
            :value="safeGet(cell.name)"
            :config="cell"
            :row="adjustedRowData"
            :mode="cell.immutable ? 'Display' : 'Edit'"
            @input="addToUpdateObject(cell.name,$event)"
        ></base-cell-selector>
    </tr>
</template>
<style lang="scss">
</style>
<script>

import componentMixin from './subcomponents.js'

export default {
    name: "editableRow",
    mixins: [componentMixin],
    props: {
        foldingStatus: Boolean,
        rowData: Object,
        columnData: Array,
        indent: Number,
        noLink: Boolean
    },
    data() {
        return {
            loadEditMode: false,
            editBody: {},
            fieldStates: {},
            processing: false
        }
    },
    created() {
        //start locking data
        this.edit();
    },
    provide() {
        return {
            "getOwnerIri": () => this.ownerIri
        }
    },
    inject: {
        
        "storeData" : { from: "storeData", default: false },
        saveData: {from: 'storeData', default: false },
        lockData: {from: 'lockData', default: false },
        getOrigin: 'getOrigin',
    },
    computed: {
        rowIRI: (vm) => {
            return (vm.rowData.iri && vm.rowData.iri.value) || null
        },
        ownerIri: (vm) => {
            return vm.rowData.owner && vm.rowData.owner.value || ''
        },
        adjustedRowData: function(vm) {
            return Object.assign({},vm.rowData, vm.editBody)
        }
    },

    methods: {
        safeGet(name) {
            //the item can be an object, which needs to be decoupled, so changes in the edit dont affect the original data
            let item = (this.editBody !== null && this.editBody.hasOwnProperty(name)) ? this.editBody[name] : (this.rowData[name] ?? null)
            if(item !== null && typeof item == 'object' && !Array.isArray(item)) {
                return {...item}
            } else {
                return item 
            }
        },
        edit: async function(ev) {
            try {
                let result = 0
                if(this.lockData) {
                    result = await this.lockData('lock',this.rowData.iri.value, this.rowData)
                } else {
                    result = await this.$cache.post(this.getOrigin().module, '/lock',{'resource': this.rowData.iri.value})
                }
                if(result.status !== 200) {
                    this.$emit('leaveEditMode',true)
                    return
                }
                this.loadEditMode = false
            } catch(e) { 
                this.$emit('leaveEditMode',true)                   
                this.$bvToast.toast('could not lock item',{variant: 'danger'})
            } finally {
                this.loadEditMode = false
            }
        },
        save: async function(ev) {
            try {
            this.processing = true
            if(Object.values(this.fieldStates).findIndex(item => !item) > -1) {
                await this.$bvModal.msgBoxOk("Eingabe ungültig")
                return
            }

            //do component specific stuff here if required
            // - check if required elements have been unset
            // - disassemble title component
            let abort = false;
            let changedFields = Object.keys(this.editBody)
            changedFields.forEach(fieldName => {
                let field = this.columnData.find((k) => k.name == fieldName)
                if(!field) { return } //the data has no field linked to it -> ignore
                if(field.required && this.editBody[fieldName] === null || (!field.optional && this.editBody[fieldName] === null)) {
                    this.$bvToast.toast('required property was removed. Could not save')
                    abort = true
                    return
                }

                switch(field.type) {
                    case "title":
                        //if(this.editBody[fieldName].hasOwnProperty('userGroup')) {
                          //  this.editBody['userGroup'] = (this.editBody[fieldName]['userGroup'] || []).filter(uG => uG != '') ?? []
                        //}
                        if(this.editBody[fieldName].hasOwnProperty('value')) {
                            this.editBody['title'] = this.editBody[fieldName]['value'] ?? ""
                            if(fieldName != "title") {
                                this.editBody[fieldName] = this.editBody[fieldName]['value'] ?? ""
                            }
                        }
                        break;
                    case "userGroup":
                        if(this.editBody[fieldName].hasOwnProperty('userGroup')) {
                            this.editBody['userGroup'] = (this.editBody[fieldName]['userGroup'] || []).filter(uG => uG != '') ?? []
                        }
                        break;
                }

                if(field.isAddedField) {
                    if(!this.editBody.hasOwnProperty('addedFields')) {
                        this.editBody['addedFields'] = {};
                    }
                    this.editBody['addedFields'][fieldName] = this.editBody[fieldName]
                }

            })

            if(abort) return 


            //set loading state
            this.loadEditMode = true

                //convert values if preSave Callback is defined
                try {
                    let response = {status: 500}
                    
                    this.$root.$emit('bts::editor::saving',this.rowIRI)  
                    if(!!this.saveData) {
                        this.editBody.iri = this.rowIRI
                        response.status = await this.saveData(this.editBody, this.rowData)
                    } else {
                        response = await this.$cache.update(this.getOrigin().module, this.rowData.iri.value, this.editBody)
                        response.status = 200
                    }
                    if(response.status === 200) {
                        this.$root.$bvToast.toast('update erfolgreich')
                        this.$emit('leaveEditMode')
                    }
                    this.editMode = false
                    this.unlockItem()
                } catch(e) {
                    console.error(e.message,e)
                } finally {
                    this.loadEditMode = false
                }

            } catch {
                this.$root.$bvToast.toast('Update failed')
            } finally {
                this.processing = false
            }
            
        },
        discard: async function(ev) {
            this.unlockItem();
            this.$emit('leaveEditMode')
        },
        addToUpdateObject: function(name, val) {
            if(!this.editBody.hasOwnProperty(name)) {
                this.$set(this.editBody,name, val)
            } else {
                 this.$set(this.editBody,name,val)
            }
            
        },
        unlockItem: async function() {
            if(this.lockData) {
                return await this.lockData('unlock',this.rowData.iri.value, this.rowData)
            } else {
                return await this.$cache.post(this.getOrigin().module, '/unlock',{'resource': this.rowData.iri.value})
            }
        }
    }
}
</script>