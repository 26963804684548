<i18n locale="en" lang="yaml">
    overview: "Export selected as file"
    btn.post: "Export"
</i18n>

<i18n locale="de" lang="yaml">
    overview: "Ausgewählte als Datei exportieren"
    btn.post: "Exportieren"
</i18n>
<template>
    <div>   
        <b-button 
            class="mx-0"
            variant="light" 
            v-b-tooltip
            :title="$t('overview')"
            v-b-modal="$id('modal')"
            ><b-icon icon="cloud-download" variant="primary"></b-icon></b-button>

        <b-modal :id="$id('modal')" size="xl" @shown="rowData()" @ok="send($event)">
            <form method="post" :action="action" class="d-flex" ref="form">
                <b-form-select :options="exportOptions" v-model="selectedExportOption" name="target">
                </b-form-select>
                <input 
                    type="hidden" name="rowValues" :value="selectedRowValues" />
                <input 
                    type="hidden" name="columns" :value="columnsJSON" />
            </form>
        </b-modal>
    </div>
</template>

<script>

import moment from 'moment'

export default {
    name: "BaseExporter",
    props: {
        selectedIris: { required: false, type: Array},
        displayedColumns: Array,
        selectedRows: { required: false, type: Array},

    },
    computed: {
        moduleName : function(vm) {
            return 'task' //vm.getOrigin().module
        } 
    },  
    watch: {
        'moduleName' : {
            immediate: true,
            handler: function() { this.buildAction() }
        }
    },  
    inject: {
        "getOrigin" : "getOrigin"
    },
    data() {
        return {
            isCreating:false,
            selectedExportOption: "excel",
            action: "",
            columnsJSON: "",
            selectedRowValues:"",
            exportOptions: [
                { value: 'excel', text: 'Excel' },
                { value: 'csv', text: 'CSV' }
                ]  
        }
    },
    methods: {  
       // this.$cache.post(this.moduleName,'/export',{})
        send() {
            this.buildAction()
            this.isCreating = true
                window.open('', 'formpopup', 'width=400,height=400,resizeable,scrollbars');
                this.$refs.form.target = 'formpopup';
                this.$refs.form.action = this.action
                this.$refs.form.submit()
            this.isCreating = false
        },
        buildAction: async function() {
            await this.$cache.apihandler.checkAuthenticationStatus()
            this.action = (await this.$registry.getURLForModuleByName(this.moduleName)) + '/export?token=' + this.$store.getters.getAuthenticationToken
        },
        rowData: function() {
            let rowData
            if(this.selectedRows) {
                rowData = this.selectedRows
            } else {
                rowData = this.selectedIris.map(iri=> this.$store.getters.getItemFromIri(iri))
            }
            let cleanRowData = rowData.map(row => {
                //loop over all columns
                let cleanRow = [row['iri'],...this.displayedColumns.map((field) => {
                    let type = field.type || "text"
                    if(!row[field.name]) return ""
                    switch(type) {
                        case 'text': 
                            return row[field.name]
                        case 'date': 
                        //add isValid check here to prevent errors on unset/invalid data
                            return moment(row[field.name]).format('DD.MM.YYYY [(CW] WW[)]')
                        case 'number': 
                            return row[field.name]
                        case 'datetime' : 
                            return moment(row[field.name]).format('DD.MM.YYYY hh:mm [(CW] WW[)]');
                        case 'bool' : 
                            return !!row[field.name] ? 'x': ''
                        case 'title' : 
                            return row[field.name]
                        case 'currency' : 
                            let val = this.$currency.getValueObject(row[field.name])
                            return val.currency+' '+val.actualValue
                        case 'userGroup' : 
                            if(!Array.isArray(row[field.name])) {
                                return row[field.name].userGroup.map(r => this.$render.displayByIri(r)).join(', ');
                            } else {
                                return row[field.name].map(r => this.$render.displayByIri(r)).join(', ');
                            }
                        case 'select' : 
                            let valArray = field.selectOptions.multiple ? row[field.name] : [row[field.name]]

                            let tokens = valArray.map(
                                item => (field.selectOptions.entries.find(
                                                    i => item == i.value) || {text: {}}).text[this.$root.$i18n.locale] 
                                )
                            return tokens.join(', ')
                       case 'dynamic' : return this.$render.displayByIri(row[field.name]);break;
                       // case 'component' : compName  = 'baseCell'+context.props.mode+'Component';break;
                    }
                })]
                return cleanRow;
                
            })

            this.selectedRowValues = JSON.stringify(cleanRowData) 
            this.columnsJSON = JSON.stringify(['@iri',...this.displayedColumns.map(c => c.label && c.label[this.$root.$i18n.locale] || c.label || c.name)])


        }
    }
}
</script>

<style>

</style>
