/*
// yellow
const yellow = [
    {
        name: "Gold",
        rgb: "#FFD700" 
    },
    {
        name: "Yellow",
        rgb: "#FFFF00" 
    },
    {
        name: "LightYellow",
        rgb: "#FFFFE0" 
    },
    {
        name: "LemonChiffon",
        rgb: "#FFFACD" 
    },
    {
        name: "LightGoldenrodYellow",
        rgb: "#FAFAD2" 
    },
    {
        name: "PapayaWhip",
        rgb: "#FFEFD5" 
    },
    {
        name: "Moccasin",
        rgb: "#FFE4B5" 
    },
    {
        name: "PeachPuff",
        rgb: "#FFDAB9" 
    },
    {
        name: "PaleGoldenrod",
        rgb: "#EEE8AA" 
    },
    {
        name: "Khaki",
        rgb: "#F0E68C" 
    },
    {
        name: "DarkKhaki",
        rgb: "#BDB76B" 
    }
]
const gray = [
    {
        name: "Gainsboro",
        rgb:	"#DCDCDC"
    },
    {
        name: "LightGray",
        rgb:	"#D3D3D3"
    },
    {
        name: "Silver",
        rgb:	"#C0C0C0"
    },
    {
        name: "DarkGray",
        rgb:	"#A9A9A9"
    },
    {
        name: "Gray",
        rgb:	"#808080"
    },
    {
        name: "DimGray",
        rgb:	"#696969"
    },
    {
        name: "LightSlateGray",
        rgb:	"#778899"
    },
    {
        name: "SlateGray",
        rgb:	"#708090"
    },
    {
        name: "DarkSlateGray",
        rgb:	"#2F4F4F"
    },
    {
        name: "Black",
        rgb:	"#000000"
    }
]
const green = [
    {
        name: "GreenYellow",
        rgb:	"#ADFF2F"
    },
    {
        name: "Chartreuse",
        rgb:	"#7FFF00"
    },
    {
        name: "LawnGreen",
        rgb:	"#7CFC00"
    },
    {
        name: "Lime",
        rgb:	"#00FF00"
    },
    {
        name: "LimeGreen",
        rgb:	"#32CD32"
    },
    {
        name: "PaleGreen",
        rgb:	"#98FB98"
    },
    {
        name: "LightGreen",
        rgb:	"#90EE90"
    },
    {
        name: "MediumSpringGreen",
        rgb:	"#00FA9A"
    },
    {
        name: "SpringGreen",
        rgb:	"#00FF7F"
    },
    {
        name: "MediumSeaGreen",
        rgb:	"#3CB371"
    },
    {
        name: "SeaGreen",
        rgb:	"#2E8B57"
    },
    {
        name: "ForestGreen",
        rgb:	"#228B22"
    },
    {
        name: "Green",
        rgb:	"#008000"
    },
    {
        name: "DarkGreen",
        rgb:	"#006400"
    },
    {
        name: "YellowGreen",
        rgb:	"#9ACD32"
    },
    {
        name: "OliveDrab",
        rgb:	"#6B8E23"
    },
    {
        name: "Olive",
        rgb:	"#808000"
    },
    {
        name: "DarkOliveGreen",
        rgb:	"#556B2F"
    },
    {
        name: "MediumAquamarine",
        rgb:	"#66CDAA"
    },
    {
        name: "DarkSeaGreen",
        rgb:	"#8FBC8B"
    },
    {
        name: "LightSeaGreen",
        rgb:	"#20B2AA"
    },
    {
        name: "DarkCyan",
        rgb:	"#008B8B"
    },
    {
        name: "Teal",
        rgb:	"#008080"
    }
]
const blue = [
    {
        name: "Aqua",
        rgb:	"#00FFFF"
    },
    {
        name: "Cyan",
        rgb:	"#00FFFF"
    },
    {
        name: "LightCyan",
        rgb:	"#E0FFFF"
    },
    {
        name: "PaleTurquoise",
        rgb:	"#AFEEEE"
    },
    {
        name: "Aquamarine",
        rgb:	"#7FFFD4"
    },
    {
        name: "Turquoise",
        rgb:	"#40E0D0"
    },
    {
        name: "MediumTurquoise",
        rgb:	"#48D1CC"
    },
    {
        name: "DarkTurquoise",
        rgb:	"#00CED1"
    },
    {
        name: "CadetBlue",
        rgb:	"#5F9EA0"
    },
    {
        name: "SteelBlue",
        rgb:	"#4682B4"
    },
    {
        name: "LightSteelBlue",
        rgb:	"#B0C4DE"
    },
    {
        name: "PowderBlue",
        rgb:	"#B0E0E6"
    },
    {
        name: "LightBlue",
        rgb:	"#ADD8E6"
    },
    {
        name: "SkyBlue",
        rgb:	"#87CEEB"
    },
    {
        name: "LightSkyBlue",
        rgb:	"#87CEFA"
    },
    {
        name: "DeepSkyBlue",
        rgb:	"#00BFFF"
    },
    {
        name: "DodgerBlue",
        rgb:	"#1E90FF"
    },
    {
        name: "CornflowerBlue",
        rgb:	"#6495ED"
    },
    {
        name: "MediumSlateBlue",
        rgb:	"#7B68EE"
    },
    {
        name: "RoyalBlue",
        rgb:	"#4169E1"
    },
    {
        name: "Blue",
        rgb:	"#0000FF"
    },
    {
        name: "MediumBlue",
        rgb:	"#0000CD"
    },
    {
        name: "DarkBlue",
        rgb:	"#00008B"
    },
    {
        name: "Navy",
        rgb:	"#000080"
    },
    {
        name: "MidnightBlue",
        rgb:	"#191970"
    }
]
const red = [
    {
        name: "IndianRed",
        rgb:	"#CD5C5C"
    },
    {
        name: "LightCoral",
        rgb:	"#F08080"
    },
    {
        name: "Salmon",
        rgb:	"#FA8072"
    },
    {
        name: "DarkSalmon",
        rgb:	"#E9967A"
    },
    {
        name: "LightSalmon",
        rgb:	"#FFA07A"
    },
    {
        name: "Crimson",
        rgb:	"#DC143C"
    },
    {
        name: "Red",
        rgb:	"#FF0000"
    },
    {
        name: "FireBrick",
        rgb:	"#B22222"
    },
    {
        name: "DarkRed",
        rgb:	"#8B0000"
    }
]
*/
const gray = [
    { name: "Grauton", rgb: "#000000" },
    { name: "Grauton", rgb: "#BFBFBF" },		
    { name: "Grauton", rgb: "#A6A6A6" },		
    { name: "Grauton", rgb: "#808080" },		
    { name: "Grauton", rgb: "#404040" },		
    { name: "Grauton", rgb: "#262626" }		
]

const blue = [
{ name: "Blauton", rgb: "#4472C4" },
{ name: "Blauton", rgb: "#D9E1F2" },
{ name: "Blauton", rgb: "#B4C6E7" },
{ name: "Blauton", rgb: "#8EA9DB" },
{ name: "Blauton", rgb: "#305496" },
{ name: "Blauton", rgb: "#203764" }
]

const red = [
{ name: "Rotton", rgb: "#ED7D31" },
{ name: "Rotton", rgb: "#FCE4D6" },
{ name: "Rotton", rgb: "#F8CBAD" },
{ name: "Rotton", rgb: "#F4B084" },
{ name: "Rotton", rgb: "#C65911" },
{ name: "Rotton", rgb: "#833C0C" },
]

const yellow = [
{ name: "Gelbton", rgb: "#FFC000" },
{ name: "Gelbton", rgb: "#FFF2CC" },
{ name: "Gelbton", rgb: "#FFE699" },
{ name: "Gelbton", rgb: "#FFD966" },
{ name: "Gelbton", rgb: "#BF8F00" },
{ name: "Gelbton", rgb: "#806000" }
]

const green = [
{ name: "Gelbton", rgb: "#70AD47" },
{ name: "Gelbton", rgb: "#E2EFDA" },
{ name: "Gelbton", rgb: "#C6E0B4" },
{ name: "Gelbton", rgb: "#A9D08E" },
{ name: "Gelbton", rgb: "#548235" },
{ name: "Gelbton", rgb: "#375623" }
]



const swatchlist = [
    ...gray, 
    ...blue,
    ...red,
    ...yellow,
    ...green
]

const basicColors = [
    { name: "Dunkelrot", rgb: "#C00000" },
    { name: "Rot", rgb: "#FF0000" },
    { name: "Orange", rgb: "#FFC000" },
    { name: "Gelb", rgb: "#FFFF00" },
    { name: "Grün", rgb: "#92D050" },
    { name: "Dunkelgrün", rgb: "#00B050" },
    { name: "Hellblau", rgb: "#00B0F0" },
    { name: "Blau", rgb: "#0070C0" },
    { name: "Dunkelblau", rgb: "#002060" },
    { name: "Lila", rgb: "#7030A0" }
]

export { swatchlist, gray, blue, red, yellow, green, basicColors }