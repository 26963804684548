
import { persister } from '@/helper/PersistenceManager'
import { store } from '@/store'

const moment = require('moment')
const config = require('@/configuration.json')

var RegistryObject = function()
{
	this.loadRegistry(false)
}

RegistryObject.prototype.install = function(Vue, options) {
      
    Vue.prototype.$registry = this

}

RegistryObject.prototype.loadRegistry = function(force = false) {
	this.registryKey = '__REGISTRY__'	
	this.fetching = this.fetchRegistry(force)
	this.fetching.then((res) => {
		this.registry = res
		this.buildIRIMap()

		//force the user login / refresh process to be executed once here, before resolving the registry fetching. This causes the token to be in storage if it exists

		store.dispatch('loadTokenFromStorage').then(() => {
			//force a login try and refreshing the login token if possible
			globalVue.$cache.apihandler.checkAuthenticationStatus()
		}).finally(() => {			
			this.fetching = false
		})


	}).catch(() => {
		this.registry = {}
		this.fetching = false
	})
}

RegistryObject.prototype.fetchRegistry = async function(force = false) 
{
	var cachedRegistry = await persister.cached(this.registryKey, config.registry.path, true, force, true)
	let validUntil = cachedRegistry && cachedRegistry['validUntil'] && (new moment(cachedRegistry['validUntil']))
	if(!validUntil || validUntil.isBefore()) {
		cachedRegistry = await persister.cached(this.registryKey, config.registry.path, true, true, true)
	}
	



	return cachedRegistry	
}

RegistryObject.prototype.getURLForModuleByName = async function(name)
{
	await this.fetching
	return (this.registry && this.registry.availableServices && this.registry.availableServices[name] && this.registry.availableServices[name][0].url) || false
}

 RegistryObject.prototype.getAppURLForModuleByName = async function(name) 
 {
	let url = await this.getURLForModuleByName(name); 
	return (url && (url + this.getAppPath())) || false
}

RegistryObject.prototype.getAppPath = function() {
	return (config.registry && config.registry.appPath) || "/app"
}

RegistryObject.prototype.getAppVersionForModuleByName = async function(name) 
{
	await this.fetching
	return (this.registry && this.registry.availableServices && this.registry.availableServices[name] && this.registry.availableServices[name][0].version) || false
}

RegistryObject.prototype.buildIRIMap = function() {
	if(!this.registry) { throw new Error('invalid function call in Registry object') }
	if(!this.registry.availableServices) { return } //no services registered / illegal registration file

	var serviceNames = Object.keys(this.registry.availableServices)
	 var iriMap = serviceNames.map((name) => {
		var firstServer = this.registry.availableServices[name][0]
		var iriRegex = new RegExp(firstServer.iriRegex)
		return {"module": name, "regex": iriRegex}
	})

	this.iriMap = iriMap
	store.commit('setRegisteredIRIs', iriMap)

}

RegistryObject.prototype.getModules = function()
{
	return this.registry && this.registry.availableServices && this.registry.availableServices || {}
}

RegistryObject.prototype.getModuleRegistration = async function(moduleName)
{
	await this.fetching //assure registry has been fetched
	if(!this.registry) { throw new Error('invalid function call in Registry object') }
	if(!this.registry.availableServices) { return false } //no services registered / illegal registration file

	return this.registry.availableServices[moduleName] || false
}

var Registry = new RegistryObject()

export { Registry }




